import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService extends BaseService {
  constructor(http: HttpClient, injector: Injector,
    private _cookieService: CookieService) {
    super(http, injector, `${environment.apiDomain.financialEndpoint}/shoppingcart`);
  }

  getsMyShoppingCart(): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsMyShoppingCart/${this._cookieService.get(environment.cookieName)}`;
    return this.defaultGet(apiUrl);
  }

  addCourses(listIdCourse: any[]) {
    const apiUrl = `${this.serviceUri}/AddCourses/${this._cookieService.get(environment.cookieName)}`;
    return this.defaultPost(apiUrl, listIdCourse);
  }

  deleteCourses(listIdCourse: any[]) {
    const apiUrl = `${this.serviceUri}/DeleteCourses/${this._cookieService.get(environment.cookieName)}`;
    return this.defaultPost(apiUrl, listIdCourse);
  }

  getsChildrenShoppingCart(userIdStudent: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsChildrenShoppingCart/${userIdStudent}`;
    return this.defaultGet(apiUrl);
  }

  deleteChildrenCourses(userId: number, listIdCourse: any[]) {
    const apiUrl = `${this.serviceUri}/DeleteChildrenCourses/${userId}`;
    return this.defaultPost(apiUrl, listIdCourse);
  }

}
