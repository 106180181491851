import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../../services/course.service';
import { CategoryLearningStatus } from '../../../config/enums';
import { Router } from '@angular/router';
import { UserCertificateService } from '../../../services/user-certificate.service';
import { ExamUserService } from '../../../services/exam-user.service';
import { AuthenticationService, BasicUserInfo, NotifierService } from 'sv-shared';
import { SvCoreUsersService } from '../../../services/svcore-users.service';

@Component({
  selector: 'app-learning-history-children',
  templateUrl: './learning-history-children.component.html',
  styleUrls: ['./learning-history-children.component.scss']
})
export class LearningHistoryChildrenComponent implements OnInit {

  listMyLearningCourse = [];
  listMyCertificate = [];
  listMyExamHistory = [];
  listMyFinishCourse = [];
  listMyCourseHaventStudy = [];

  currentUser = new BasicUserInfo();
  listUserChildren = [];

  constructor(
    private _courseService: CourseService,
    private _userCertificateService: UserCertificateService,
    private _router: Router,
    private _examUserService: ExamUserService,
    private _authenticationService: AuthenticationService,
    private _svCoreUsersService: SvCoreUsersService,
    private _notifierService: NotifierService,
  ) {
    this.currentUser = this._authenticationService.getCurrentUser();
    if (!this.currentUser) {
      this._router.navigate(['/']);
    }
  }

  ngOnInit() {

    this._svCoreUsersService.getsChildren().then(rs => {
      if (rs.success) {
        this.listUserChildren = rs.data;
        if (rs.data.length == 0) {
          this._notifierService.showError('Bạn chưa có học sinh nào, vui lòng thêm học sinh!');
          this._router.navigate(['/profile']);
        } else {
          var instanceId = this.listUserChildren[0].instanceId;
          this.getsChildCourse(instanceId);
          this.getChildFinishCourse(instanceId);
          this.getsChildCertificate(instanceId);
          this.getsChildExamHistory(instanceId);
          this.getsMyCourseHaventStudy(instanceId);
        }
      }
    });


  }

  getsMyCourseHaventStudy(instanceId) {
    this._courseService.getsChildCourseHaventStudy(instanceId).then(rs => {
      if (rs.success) {
        this.listMyCourseHaventStudy = rs.data;
      }
    })
  }

  getsChildCourse(instanceId) {
    this._courseService.getsChildStudyCourse(instanceId, CategoryLearningStatus.IsLearing).then(rs => {
      if (rs.success) {
        this.listMyLearningCourse = rs.data;
      }
    })
  }

  getChildFinishCourse(instanceId) {
    this._courseService.getsChildStudyCourse(instanceId, CategoryLearningStatus.IsFinish).then(rs => {
      if (rs.success) {
        this.listMyFinishCourse = rs.data;
      }
    })
  }

  getsChildCertificate(instanceId) {
    this._userCertificateService.getsChildCertificate(instanceId).then(rs => {
      if (rs.success) {
        this.listMyCertificate = rs.data;
      }
    })
  }

  getsChildExamHistory(instanceId) {
    this._examUserService.getsChildExamHistory(instanceId).then(rs => {
      if (rs.success) {
        this.listMyExamHistory = rs.data;
      }
    })
  }

  downloadCertificate(instanceId: string) {
    this._userCertificateService.downloadCertificate(instanceId);
  }
}
