import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/course`);
  }

  find(model: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/find`;
    return this._http
      .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  getsRandomByIdCategory(idCategory: number, num: number) {
    const queryString = `${this.serviceUri}/GetsRandomByIdCategory/${idCategory}/${num}`;
    return this.defaultGet(queryString);
  }

  getsHotCourse(idSysSchool: number, num: number) {
    const queryString = `${this.serviceUri}/GetsHotCourse/${idSysSchool}/${num}`;
    return this.defaultGet(queryString);
  }

  getsBestSeller(idSysSchool: number, num: number, includeAge: boolean) {
    const queryString = `${this.serviceUri}/GetsBestSeller/${idSysSchool}/${num}/${includeAge}`;
    return this.defaultGet(queryString);
  }

  getsLatestCourse(idSysSchool: number, num: number, includeAge: boolean) {
    const queryString = `${this.serviceUri}/GetsLatestCourse/${idSysSchool}/${num}/${includeAge}`;
    return this.defaultGet(queryString);
  }

  getsMyStudyCourse(idCategoryLearningStatus: number) {
    const queryString = `${this.serviceUri}/GetsMyStudyCourse/${idCategoryLearningStatus}`;
    return this.defaultGet(queryString);
  }

  getsMyStudyCourseWithUnfinishedTask() {
    const queryString = `${this.serviceUri}/GetsMyStudyCourseWithUnfinishedTask`;
    return this.defaultGet(queryString);
  }

  getInstructorInfo(idTenant: number, userId: number) {
    const queryString = `${this.serviceUri}/GetInstructorInfo/${idTenant}/${userId}`;
    return this.defaultGet(queryString);
  }

  getDetail(id: number) {
    const queryString = `${this.serviceUri}/GetDetail/${id}`;
    return this.defaultGet(queryString);
  }

  getsCourseReference(idCourse: number, num: number) {
    const queryString = `${this.serviceUri}/GetsCourseReference/${idCourse}/${num}`;
    return this.defaultGet(queryString);
  }

  getsCourseRoadmap(idCourse: number) {
    const queryString = `${this.serviceUri}/GetsCourseRoadmap/${idCourse}`;
    return this.defaultGet(queryString);
  }

  getsChildStudyCourse(instanceId, idCategoryLearningStatus: number) {
    const queryString = `${this.serviceUri}/GetsChildStudyCourse/${instanceId}/${idCategoryLearningStatus}`;
    return this.defaultGet(queryString);
  }

  getsByUserCategory() {
    const queryString = `${this.serviceUri}/GetsByUserCategory`;
    return this.defaultGet(queryString);
  }

  getsLastestView() {
    const queryString = `${this.serviceUri}/GetsLastestView`;
    return this.defaultGet(queryString);
  }

  getsMyCourseHaventStudy() {
    const queryString = `${this.serviceUri}/GetsMyCourseHaventStudy`;
    return this.defaultGet(queryString);
  }

  getsChildCourseHaventStudy(instanceId: string) {
    const queryString = `${this.serviceUri}/GetsChildCourseHaventStudy/${instanceId}`;
    return this.defaultGet(queryString);
  }

  getCountDashboard() {
    const queryString = `${this.serviceUri}/GetCountDashboard`;
    return this.defaultGet(queryString);
  }

}
