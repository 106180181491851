<div class="single-news-box-grid">
  <div routerLink="news-image">
    <a href="/document/detail/{{item.instanceId}}"><img src="{{item.imageUrl}}" alt=""></a>
  </div>
  <div class="news-content">
    <a routerLink="/document/detail/{{item.instanceId}}">
      <h3 class="title">{{item.title}}</h3>
    </a>
    <div class="description">{{item.subject}}</div>
    <div class="row">
      <div class="date-time md:col-6 sm:col-12">
        <div><i class="pi pi-calendar"></i><span class="pl-2">{{item.modified | date: 'dd/MM/yyyy'}}</span>
        </div>
      </div>
      <div class="news-rating md:col-6 sm:col-12" style="text-align: right">
        {{item.author}}
      </div>
      <!-- <div class="news-rating md:col-6 sm:col-12" style="text-align: right">
        <span class="rating mr-1">5</span>
        <ng-rating-bar [value]="5" [ratingCount]="4" colorActive="#F2A000" colorDefault="gray"
          [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
      </div> -->
    </div>

  </div>
</div>
