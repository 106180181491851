import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SvCoreUsersService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.coreEndPoint}/users`);
  }

  isGoogleOrFacebookLogin() {
    const apiUrl = `${this.serviceUri}/IsGoogleOrFacebookLogin`;
    return this.defaultGet(apiUrl);
  }

  getsChildren() {
    const apiUrl = `${this.serviceUri}/GetsChildren`;
    return this.defaultGet(apiUrl);
  }
}
