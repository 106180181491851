<div class="feedback-area mt-5" *ngIf="dataSource.length > 0">
  <div class="container">
    <div class="section-title">
      <h2>Nhận xét của học viên</h2>
      <!-- <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div> -->
    </div>
    <div class="feedback-slides-two">
      <owl-carousel-o [options]="feedbackSlides">
        <ng-container *ngFor="let item of dataSource">
          <ng-template carouselSlide>
            <app-comment-item-type-three [item]="item"></app-comment-item-type-three>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>