import { Component, Injector, OnInit } from '@angular/core';
import { TenantRegisterService } from '../../../services/tenant-register.service';
import { SecondPageEditBase } from 'sv-shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-section-register-tenant',
  templateUrl: './section-register-tenant.component.html',
  styleUrls: ['./section-register-tenant.component.scss']
})
export class SectionRegisterTenantComponent extends SecondPageEditBase implements OnInit {

  constructor(
    protected _tenantRegisterService: TenantRegisterService,
    protected _injector: Injector,
  ) {
    super(_tenantRegisterService, _injector);
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      phoneNumber: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

}
