import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-news-box-grid',
  templateUrl: './single-news-box-grid.component.html',
  styleUrls: ['./single-news-box-grid.component.scss']
})
export class SingleNewsBoxGridComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  @Input() item: any

}
