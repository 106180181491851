import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, BasicUserInfo, ComponentBase } from 'sv-shared';
import { DocumentService } from '../../../services/document.service';
import { ItemCommentService } from '../../../services/item-comment.service';
import { map, takeUntil } from 'rxjs/operators';
import { WorkshopService } from '../../../services/workshop.service';

@Component({
  selector: 'app-workshop-detail',
  templateUrl: './workshop-detail.component.html',
  styleUrls: ['./workshop-detail.component.scss']
})
export class WorkshopDetailComponent extends ComponentBase implements OnInit {
  itemDetail: any = {};
  id = 0;

  dataSource = [];
  dataCommentSource = [];
  totalCommentCount = 0;
  itemType = 2;
  pageIndexComment = 1;
  pageIndexCommentSize = 5;
  config: any = {};
  currentUser: BasicUserInfo = new BasicUserInfo();
  bodyHtml = '';
  rating = 5;

  currentTime = new Date();
  startTime = new Date();
  endTime = new Date();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _workshopService: WorkshopService,
    private _itemCommentService: ItemCommentService,
    private _router: Router,
    private _authenticationService: AuthenticationService,
  ) {
    super();
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.id = pid;
          this.getDetail(pid);
        } else {
          this._router.navigate(['/workshop']);

        }

      });
  }

  getDetail(id) {
    this._workshopService.getByInstanceId(id).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
        this.startTime = new Date(rs.data.startTime);
        this.endTime = new Date(rs.data.endTime);
        // this.getData();
        this.getsCommentData();
      }
    });
  }

  getData() {
    var searchModel = {
      keyword: '',
      status: 1,
      pageIndex: 1,
      pageSize: 6,
      orderCol: 'Id',
      isDesc: true,
    };
    this._workshopService.find(searchModel)
      .then(rs => {
        if (rs.success) {
          this.dataSource = rs.data.filter(x => (x.id - 0) != (this.id - 0));
        }
      });
  }

  getsCommentData() {
    var searchModel = {
      keyword: '',
      itemType: this.itemType,
      itemId: this.itemDetail.id,
      pageIndex: this.pageIndexComment,
      pageSize: this.pageIndexCommentSize,
    }
    this._itemCommentService.find(searchModel).then(rs => {
      if (rs.success) {
        this.dataCommentSource = rs.data;
        this.totalCommentCount = rs.totalRecord;
      }
    })
  }

  getFileType(fileName: string) {
    console.log(fileName);

    const arr = fileName.split('.');
    return arr[arr.length - 1];
  }

  getDocumentType(fileType: string) {
    if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
      return 'image';
    } else if ((/(doc|docx)$/i).test(fileType)) {
      return 'word';
    }
    else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
      return 'word';
    } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
      return 'spreadsheet';
    } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
      return 'presentation';
    }

    return '';
  }

  onDocumentReady = (event) => {
    console.log("Document is loaded");
  };

  onLoadComponentError = (errorCode, errorDescription) => {
    switch (errorCode) {
      case -1: // Unknown error loading component
        console.log(errorDescription);
        break;

      case -2: // Error load DocsAPI from http://documentserver/
        console.log(errorDescription);
        break;

      case -3: // DocsAPI is not defined
        console.log(errorDescription);
        break;
    }
  };

  addItemComment() {
    if (this.bodyHtml != '') {
      var model = {
        itemType: this.itemType,
        itemId: this.itemDetail.id,
        bodyHtml: this.bodyHtml,
        rating: this.rating,
        status: 1,
      }
      this._itemCommentService.post(model).then(rs => {
        if (rs.success) {
          this.bodyHtml = '';
          this.getsCommentData();
        }
      });
    }
  }
}
