import { Component, Injector, OnInit } from '@angular/core';
import { TenantsService } from '../../../services/tenants.service';
import { ComponentBase, SecondPageIndexBase } from 'sv-shared';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs';
import { CourseService } from '../../../services/course.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-author-detail',
  templateUrl: './author-detail.component.html',
  styleUrls: ['./author-detail.component.scss']
})
export class AuthorDetailComponent extends SecondPageIndexBase implements OnInit {

  dataSource = [];
  dataCommentSource = [];
  idTenant = 0;
  itemDetail = { id: 0, title: '', headline: '', description: '', logoUrl: '', email: '', phone: '', facebook: '' };
  isScroll = false;


  constructor(
    private _tenantsService: TenantsService,
    private _courseService: CourseService,
    protected _injector: Injector,
    private _titleTagService: Title,
  ) {
    super(_courseService, _injector);
  }

  ngOnInit() {
    this.pageSize = 12;
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.idTenant = pid;
          this.getDetail(pid);
          this.getData();
        }
      });
  }

  getDetail(id: number) {
    this._tenantsService.getById(id).then(res => {
      if (res.success) {
        this.itemDetail = res.data;
        this._titleTagService.setTitle(`${this._titleTagService.getTitle()} | ${res.data.title}`);
      }
    })
  }

  getData() {
    this.isLoading = true;
    var searchModel = {
      keyword: this.query,
      listIdCategory: [0],
      idSysSchool: 0,
      idCatLevel: 0,
      status: this.status,
      getModified: true,
      pageIndex: this.pageIndex,
      offset: (this.pageIndex - 1) * this.pageSize,
      pageSize: this.pageSize,
      orderCol: this.orderCol ? this.orderCol : 'Id',
      isDesc: this.isDesc,
    };
    this._courseService.find(searchModel)
      .then(rs => {
        this.dataSource = rs.data;
        this.totalRecord = rs.totalRecord;
        this.isLoading = false;
        this.isScroll = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
        this._notifierService.showHttpUnknowError();
      });
  }

  onGoPage($event) {
    this.pageIndex = $event;
    this.getData();
  }


}
