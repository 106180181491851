import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-course-box-list',
  templateUrl: './single-course-box-list.component.html',
  styleUrls: ['./single-course-box-list.component.scss']
})
export class SingleCourseBoxListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input() item: any
}
