import { Component, Injector, Input, OnInit } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { SecondPageIndexBase } from 'sv-shared';
import { CourseItemLiveService } from '../../../services/course-item-live.service';

@Component({
  selector: 'app-learning-space-v3-liveclass',
  templateUrl: './learning-space-v3-liveclass.component.html',
  styleUrls: ['./learning-space-v3-liveclass.component.scss']
})
export class LearningSpaceV3LiveclassComponent extends SecondPageIndexBase implements OnInit {

  @Input() idCourse: number;
  @Input() isTeacher: boolean;
  itemLiveIscomming = { id: 0, title: '', startTime: null, duration: 0, attendeePW: '', learningStatus: 0 };

  constructor(
    protected _courseItemLiveService: CourseItemLiveService,
    protected _injector: Injector,
  ) {
    super(_courseItemLiveService, _injector);
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        this.idCourse = pid;
        this.getData();
      });
  }


  getData() {
    this._courseItemLiveService.getsByIdCourse(this.idCourse).then(rs => {
      if (rs.success) {
        this.dataSource = rs.data.dsUpComming;
        this.dataSource = this.dataSource.concat(rs.data.dsFinished);
        if (rs.data.dsUpComming.length > 0) {
          this.itemLiveIscomming = rs.data.dsUpComming[0];
        }
      }
    });
  }

  goLiveClass() {
    this._courseItemLiveService.joinLiveClass(this.itemLiveIscomming.id, this.isTeacher).then(rs => {
      if (rs.success) {
        window.open(rs.data, "_blank");
      } else {
        this._notifierService.showWarning(rs.error);
      }
    })
  }
}

