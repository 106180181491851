<app-nav></app-nav>
<section class="course-detail-section ">
  <div class="container-fluid dark-background">
  </div>
  <div class="container course-detail-container">
    <div class="row">
      <div class="col-12 md:col-12 lg:col-8">
        <!-- page title -->
        <div class="row">
          <div class="col-12">
            <div class="page-title-area-course">
              <div class="container">
                <div class="page-title-course-content">
                  <ul>
                    <li><a routerLink="/">Trang chủ</a></li>
                    <li><a routerLink="/courses">Khoá học</a></li>
                    <li class="li-active">Chi tiết khoá học</li>
                  </ul>
                  <div class="course-bio">
                    <h2 class="mt-3">{{itemDetail.title}}</h2>
                    <p> {{itemDetail.subject}} </p>
                    <div class="flex align-items-center">
                      <button class="default-btn px-3">Bán chạy nhất<span></span></button>
                      <div class="ml-2">
                        <span class="rating-count mr-2">{{itemDetail.avgRatingStar}}</span>
                        <ng-rating-bar [value]="5" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
                          [styles]="{fontSize: '16px', margin: '0px',padding: '0px'}"></ng-rating-bar>
                        <span class="rating-people underline">({{itemDetail.countComment}})</span>
                        <span class="ml-2">{{itemDetail.countCourseUser}}</span>
                      </div>
                    </div>
                    <p class="mt-2 mb-1">Được tạo bởi <span class="author underline"><a style="color: white;"
                          routerLink="authors/detail/{{itemDetail.idTenant}}">{{itemDetail.displayNameTenant}}</a></span>
                    </p>
                    <div class="flex">
                      <div class="flex align-items-center">
                        <i class="pi pi-clock"></i><span class="ml-2 mr-5">Lần cập nhật gần đây nhất
                          {{itemDetail.modified | date: "dd/MM/yyyy"}}</span>
                      </div>
                      <!-- <div class="flex align-items-center">
                        <i class="pi pi-globe"></i><span class="ml-2">Vietnamese</span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12"> -->
          <div class="course-info course-info-mobile">
            <div class="course-info--introduction-asset">
              <div class="intro-asset--wrapper">
                <div class="intro-asset--asset">
                  <ng-container *ngIf="itemDetail.videoUrl == null || itemDetail.videoUrl == ''">
                    <div>
                      <span class="intro-asset--img-aspect">
                        <img src="assets/images/courses/course1.png" alt="">
                      </span>
                      <span class="intro-asset--overlay intro-asset--gradient"></span>
                      <span class="ud-play-overlay">
                        <button class="flex align-items-center justify-content-center">
                          <i class="pi pi-play"></i>
                        </button>
                      </span>
                      <span class="ud-heading-md intro-asset--overlay intro-asset--text">
                        Xem trước khóa học này
                      </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="itemDetail.videoUrl != null && itemDetail.videoUrl != ''">
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="sidebar-container--purchase-section p-3">
              <div class="buy-box grid">
                <div class="col-12 price">
                  <h2 *ngIf="itemDetail.price > 0">{{itemDetail.price | currency: "VND"}}</h2>
                  <h2 *ngIf="itemDetail.price == 0">Miễn phí</h2>
                </div>
                <div class="col-12 flex shopping-cart">
                  <ng-container *ngIf="isRegister">
                    <button class="add default-btn" (click)="goLearningSpace(itemDetail.instanceId)">Vào
                      học<span></span></button>
                  </ng-container>
                  <ng-container *ngIf="!isRegister">
                    <ng-container *ngIf="itemDetail.price > 0 || !(currentUser?.id > 0)">
                      <button class="add default-btn" (click)="addToShoppingCart()">
                        Thêm vào giỏ hàng<span></span>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="itemDetail.price == 0 && currentUser?.id > 0">
                      <button class="add default-btn" (click)="doRegister()">
                        Đăng ký<span></span>
                      </button>
                    </ng-container>
                  </ng-container>

                  <a class="btn-heart" _target="blank"
                    href="http://www.facebook.com/share.php?u=https://digicloudacademy.edu.vn/courses/detail/${itemDetail.id}">
                    <i class="pi pi-heart"></i></a>
                </div>
                <!-- <div class="col-12">
                  <button class="buy-now second-btn">Mua ngay<span></span></button>
                </div> -->
                <!-- <div class="col-12">
                  <p class="mb-0 text-center sure">Đảm bảo hoàn tiền trong 30 ngày</p>
                </div> -->
                <div class="col-12 includes">
                  <p class="mb-0">Khóa học này bao gồm:</p>
                  <ul>
                    <li class="flex align-items-center" *ngIf="courseItemCountInfo.countVideo > 0"><i
                        class="pi pi-align-justify"></i>
                      <span class="ml-2"> {{courseItemCountInfo.countVideo}} Học liệu</span>
                    </li>
                    <li class="flex align-items-center" *ngIf="courseItemCountInfo.countLearningTask > 0"><i
                        class="pi pi-file"></i>
                      <span class="ml-2">{{courseItemCountInfo.countLearningTask}} Nhiệm vụ học tập</span>
                    </li>
                    <li class="flex align-items-center" *ngIf="courseItemCountInfo.countTest > 0"><i
                        class="pi pi-comment"></i>
                      <span class="ml-2">{{courseItemCountInfo.countTest}} Bài trắc nghiệm</span>
                    </li>
                    <li class="flex align-items-center" *ngIf="courseItemCountInfo.countReference > 0"><i
                        class="pi pi-book"></i>
                      <span class="ml-2">{{courseItemCountInfo.countReference}} Tài nguyên học tập</span>
                    </li>
                  </ul>
                </div>
                <!-- <div class="col-12 options flex flex-wrap justify-content-center align-items-center ">
                  <span class="underline">Chia sẻ</span>
                  <span class="underline">Tặng khóa học này</span>
                  <span class="underline">Áp dụng coupon</span>
                </div> -->
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!-- what you will learn -->
        <div class="row mt-6">
          <!-- ShareThis BEGIN -->
          <div class="sharethis-inline-share-buttons"></div><!-- ShareThis END -->
          <div class="col-12">
            <p [innerHTML]="itemDetail.description"></p>
          </div>
        </div>
        <!-- course content -->
        <div class="row mt-3 course-content-container">
          <div class="col-12">
            <div class="">
              <h2>Nội dung khóa học</h2>
              <!-- <div class="mt-3 flex justify-content-between">
                <p class="summary">31 phần • 293 bài giảng • 56 giờ 53 phút tổng thời lượng</p>
                <p class="expandAll">Mở rộng tất cả các phần</p>
              </div> -->
            </div>
          </div>
          <div class="col-12">
            <div class="flex justify-content-center">
              <p-accordion class="w-full" [activeIndex]="0">
                <p-accordionTab>
                  <!-- <ng-template pTemplate="header">
                    <div class="col-12 flex align-items-center justify-content-between">
                      <span class="vertical-align-middle section-name">Giới thiệu khóa học</span>
                      <span class="vertical-align-middle section-time">6 bài giảng • 25 phút </span>
                    </div>
                  </ng-template> -->
                  <ng-template pTemplate="content">
                    <ng-container *ngFor="let item of listCourseItem">
                      <div class="grid">
                        <div class="col-8 flex align-items-center"><i class="pi pi-stop-circle"></i><span
                            class="ml-2 underline preview">{{item.label}}</span></div>
                        <!-- <div class="col-2 underline preview">Xem trước</div> -->
                        <div class="col-2 text-right">{{item.totalTime}}</div>
                      </div>
                      <ng-container *ngFor="let item1 of item.children">
                        <div class="grid">
                          <div class="col-8 flex align-items-center"><i class="pi pi-stop-circle"></i><span
                              class="ml-2 underline preview">{{item1.label}}</span></div>
                          <!-- <div class="col-2 underline preview">Xem trước</div> -->
                          <div class="col-2 text-right">{{item1.totalTime}}</div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </p-accordionTab>
              </p-accordion>
            </div>
          </div>
        </div>
        <!-- buy regulary -->
        <div class="row mt-3" *ngIf="comboDto?.id > 0">
          <div class="col-12">
            <div class="usually-buy p-3">
              <h2>Thường xuyên được mua</h2>
              <ng-container *ngFor="let item of comboDto?.courses">
                <app-course-item-type-three [item]="item"></app-course-item-type-three>
              </ng-container>
              <div class="grid flex align-items-center justify-content-between">
                <span class="col-12 lg:col-6 total-course-money">
                  Tổng: <b>{{totalComboPrice | currency: 'VND'}}</b> (Giảm: {{comboDto?.comboInfo?.promotionRate}}
                  %)</span>
                <button class="col-12 lg:col-6 default-btn px-2" (click)="addAllToShoppingCart()">Thêm tất cả vào giỏ
                  hàng<span></span></button>
              </div>
            </div>
          </div>
        </div>
        <!-- infor teacher -->
        <div class="row mt-3 infor-teacher-container">
          <div class="col-12">
            <h2>Giảng viên</h2>
            <h3 class="underline name">{{tenantInfo.title}}</h3>
            <h5>{{tenantInfo.headline}}</h5>
          </div>
          <div class="col-12 info">
            <div class="grid align-items-center">
              <div class="col-4 image-container">
                <img src="{{tenantInfo.logoUrl}}" alt="">
              </div>
              <div class="col-8">
                <!-- <p class="flex align-items-center"><i class="pi pi-comment"></i><span class="ml-2">4,9 xếp hạng giảng
                    viên</span></p>
                <p class="flex align-items-center"><i class="pi pi-phone"></i><span class="ml-2">1038 đánh giá</span>
                </p> -->
                <p class="flex align-items-center"><i class="pi pi-users"></i><span
                    class="ml-2">{{tenantInfo.countStudent}} Học viên</span>
                </p>
                <p class="flex align-items-center"><i class="pi pi-book"></i><span
                    class="ml-2">{{tenantInfo.countCourse}} Khóa học</span></p>

              </div>
            </div>
          </div>
          <div class="col-12 bio">
            <p [innerHTML]="tenantInfo.description"></p>
          </div>
        </div>
        <!-- comments -->
        <div class="row mt-3">
          <div class="col-12">
            <div class="comments-container px-2">
              <h2><i class="pi pi-star-fill"></i> {{itemDetail.avgRatingStar}} xếp hạng khóa học<span> •
                </span>{{itemDetail.countComment}} đánh giá</h2>
              <div class="row">
                <div class="review_item" *ngIf="isRegister && itemFeedback.id == 0">
                  <div class="rating-box mt-20">
                    <ng-rating-bar [(value)]="itemFeedback.rating" [styles]="{fontSize: '1.7rem'}" [ratingCount]="5">
                    </ng-rating-bar>
                  </div>
                  <p class="rvds10">
                    <p-editor [(ngModel)]="itemFeedback.bodyHtml" [style]="{'height':'150px'}"></p-editor>
                  </p>
                  <div class="row rpt100">
                    <div class="col-6 flex justify-content-end">
                      <p-captcha siteKey="6LfzQjooAAAAAJee0JvagiW69Pd1jANfEVmHNtzq"
                        (onResponse)="captchaResponse($event)">
                      </p-captcha>
                    </div>
                    <div class="col-6 flex justify-content-end">
                      <button class="default-btn px-3" (click)="addComment()"> Gửi đánh giá <span></span>
                      </button>
                    </div>
                  </div>
                </div>
                <ng-container *ngFor="let item of dataSource">
                  <div class="lg:col-12 md:col-12 sm:col-12">
                    <app-comment-item-type-one [item]="item"></app-comment-item-type-one>
                  </div>
                </ng-container>
              </div>
              <div class="row">
                <div class="col-12 flex justify-content-center">
                  <button class="second-btn p-3" (click)="onNextPage()" *ngIf="pageIndex <= totalRecord / pageSize">
                    Xem đánh giá khác ...<span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- other course -->
        <!-- <div class="row mt-3">
          <div class="col-12">
            <div class="other-couses-container">
              <h2>Các khóa học khác của<span class="ml-2 author-title">Hau Nguyen</span></h2>
              <div class="row">
                <ng-container *ngFor="let item of dataSource">
                  <div class="lg:col-4 md:col-6 sm:col-12">
                    <app-course-item-type-one [item]="item"></app-course-item-type-one>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- course-info -->
      <div class="col-12 md:col-12 lg:col-4">
        <div class="row">
          <!-- <div class="col-12"> -->
          <div class="course-info course-info-desktop">
            <div class="course-info--introduction-asset">
              <div class="intro-asset--wrapper">
                <div class="intro-asset--asset">
                  <ng-container *ngIf="itemDetail.videoUrl == null || itemDetail.videoUrl == ''">
                    <div>
                      <span class="intro-asset--img-aspect">
                        <img src="assets/images/courses/course1.png" alt="">
                      </span>
                      <span class="intro-asset--overlay intro-asset--gradient"></span>
                      <span class="ud-play-overlay">
                        <button class="flex align-items-center justify-content-center">
                          <i class="pi pi-play"></i>
                        </button>
                      </span>
                      <span class="ud-heading-md intro-asset--overlay intro-asset--text">
                        Xem trước khóa học này
                      </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="itemDetail.videoUrl != null && itemDetail.videoUrl != ''">
                    <div>
                      <video controls style="width: 443px;">
                        <source src="{{itemDetail.videoUrl}}" type="video/mp4" />
                        Browser not supported
                      </video>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="sidebar-container--purchase-section p-3">
              <div class="buy-box grid">
                <div class="col-12 price">
                  <h2 *ngIf="itemDetail.price > 0">{{itemDetail.price | currency: "VND"}}</h2>
                  <h2 *ngIf="itemDetail.price == 0">Miễn phí</h2>
                </div>
                <div class="col-12 flex shopping-cart">
                  <ng-container *ngIf="isRegister">
                    <button class="add default-btn" (click)="goLearningSpace(itemDetail.instanceId)">Vào
                      học<span></span></button>
                  </ng-container>
                  <ng-container *ngIf="!isRegister">
                    <ng-container *ngIf="itemDetail.price > 0 || !(currentUser?.id > 0)">
                      <button class="add default-btn" (click)="addToShoppingCart()">
                        Thêm vào giỏ hàng<span></span>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="itemDetail.price == 0 && currentUser?.id > 0">
                      <button class="add default-btn" (click)="doRegister()">
                        Đăng ký<span></span>
                      </button>
                    </ng-container>
                  </ng-container>
                  <a class="btn-heart" target="_blank"
                    href="http://www.facebook.com/share.php?u=https://digicloudacademy.edu.vn/courses/detail/${itemDetail.id}">
                    <svg xmlns="http://www.w3.org/2000/svg" id="facebook-logo-button" width="32" height="32"
                      viewBox="0 0 32 32">
                      <defs>
                        <style>
                          .cls-2 {
                            fill: #4267b2
                          }
                        </style>
                      </defs>
                      <g id="Group_1117" data-name="Group 1117">
                        <path id="Path_5321"
                          d="M16 0a16 16 0 1 0 16 16A16.018 16.018 0 0 0 16 0zm3.979 16.563h-2.6v9.278h-3.86v-9.278h-1.834v-3.279h1.834v-2.121a3.617 3.617 0 0 1 3.892-3.893l2.858.011v3.183H18.2a.785.785 0 0 0-.818.893v1.927h2.939z"
                          class="cls-2" data-name="Path 5321"></path>
                      </g>
                    </svg>
                  </a>
                </div>
                <!-- <div class="col-12">
                  <button class="buy-now second-btn">Mua ngay<span></span></button>
                </div> -->
                <!-- <div class="col-12">
                  <p class="mb-0 text-center sure">Đảm bảo hoàn tiền trong 30 ngày</p>
                </div> -->
                <div class="col-12 includes">
                  <p class="mb-0">Khóa học này bao gồm:</p>
                  <ul>
                    <li class="flex align-items-center" *ngIf="courseItemCountInfo.countVideo > 0"><i
                        class="pi pi-align-justify"></i>
                      <span class="ml-2"> {{courseItemCountInfo.countVideo}} Học liệu</span>
                    </li>
                    <li class="flex align-items-center" *ngIf="courseItemCountInfo.countLearningTask > 0"><i
                        class="pi pi-file"></i>
                      <span class="ml-2">{{courseItemCountInfo.countLearningTask}} Nhiệm vụ học tập</span>
                    </li>
                    <li class="flex align-items-center" *ngIf="courseItemCountInfo.countTest > 0"><i
                        class="pi pi-comment"></i>
                      <span class="ml-2">{{courseItemCountInfo.countTest}} Bài trắc nghiệm</span>
                    </li>
                    <li class="flex align-items-center" *ngIf="courseItemCountInfo.countReference > 0"><i
                        class="pi pi-book"></i>
                      <span class="ml-2">{{courseItemCountInfo.countReference}} Tài nguyên học tập</span>
                    </li>
                  </ul>
                </div>
                <!-- <div class="col-12 options flex flex-wrap justify-content-center align-items-center ">
                  <span class="underline">Chia sẻ</span>
                  <span class="underline">Tặng khóa học này</span>
                  <span class="underline">Áp dụng coupon</span>
                </div> -->
              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </div>
    </div>
  </div>
  <!-- course roadmap -->
  <div class="container" *ngIf=" listCourseRoadmap?.length > 0">
    <div class="col-12">
      <h2>Khóa học theo lộ trình</h2>
    </div>
    <div class="courses-most-study-container">
      <owl-carousel-o [options]="courseMostStudySides">
        <ng-container *ngFor="let item of listCourseRoadmap">
          <ng-template carouselSlide>
            <app-single-course-box-grid [item]="item" [isShowPopper]="false"></app-single-course-box-grid>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
  <!-- course references -->
  <div class="container" *ngIf=" listCourseReference?.length > 0">
    <div class="col-12">
      <h2>Khóa học liên quan</h2>
    </div>
    <div class="courses-most-study-container">
      <owl-carousel-o [options]="courseMostStudySides">
        <ng-container *ngFor="let item of listCourseReference">
          <ng-template carouselSlide>
            <app-single-course-box-grid [item]="item" [isShowPopper]="false"></app-single-course-box-grid>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>

  <div class="container" *ngIf=" listLatestViewed?.length > 0">
    <div class="col-12">
      <h2>Bạn mới xem</h2>
    </div>
    <div class="courses-most-study-container">
      <owl-carousel-o [options]="courseMostStudySides">
        <ng-container *ngFor="let item of listLatestViewed">
          <ng-template carouselSlide>
            <app-single-course-box-grid [item]="item" [isShowPopper]="false"></app-single-course-box-grid>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
  <div class="container" *ngIf=" listCourseLatest?.length > 0">
    <div class="col-12">
      <h2>Khóa học mới nhất</h2>
    </div>
    <div class="courses-most-study-container">
      <owl-carousel-o [options]="courseMostStudySides">
        <ng-container *ngFor="let item of listCourseLatest">
          <ng-template carouselSlide>
            <app-single-course-box-grid [item]="item" [isShowPopper]="false"></app-single-course-box-grid>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
  <div class="container" *ngIf=" listCourseBestSeller?.length > 0">
    <div class="col-12">
      <h2>Khóa học được mua nhiều nhất</h2>
    </div>
    <div class="courses-most-study-container">
      <owl-carousel-o [options]="courseMostStudySides">
        <ng-container *ngFor="let item of listCourseBestSeller">
          <ng-template carouselSlide>
            <app-single-course-box-grid [item]="item" [isShowPopper]="false"></app-single-course-box-grid>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</section>