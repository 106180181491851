import { Component, OnInit } from '@angular/core';
import { EInvoiceService } from '../../../services/e-invoice.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {

  dataSource = [];

  constructor(
    private _eInvoiceService: EInvoiceService,
  ) { }

  ngOnInit() {
    this._eInvoiceService.getsMyEinvoice().then(rs => {
      if (rs.success) {
        this.dataSource = rs.data;
      }
    });
  }

  getTitleIsPaid(isPaid: number) {
    switch (isPaid) {
      case 1:
        return 'Khởi tạo';
      case 2:
        return 'Chờ thanh toán';
      case 3:
        return 'Đã thanh toán';
      case 4:
        return 'Thanh toán thất bại';
      default:
        return '';
    }
  }

}
