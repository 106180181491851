import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'sv-shared';
import { ShoppingCartService } from '../services/shopping-cart.service';

@Component({
  selector: 'app-single-course-popper',
  templateUrl: './single-course-popper.component.html',
  styleUrls: ['./single-course-popper.component.scss']
})
export class SingleCoursePopperComponent implements OnInit {

  @Input() item: any

  constructor(
    private _shoppingCartService: ShoppingCartService,
    private _notifierService: NotifierService
  ) { }

  ngOnInit() {
  }

  addToShoppingCart(idCourse: number) {
    this._shoppingCartService.addCourses([idCourse]).then(rs => {
      if (rs.success) {
        if (rs.data > 0) {
          this._notifierService.showSuccess("Thêm vào giỏ hàng thành công");
        }
        else {
          this._notifierService.showWarning("Khóa học đã có trong giỏ hàng hoặc bạn đã đăng ký khóa học này");
        }
      }
    })
  }

}
