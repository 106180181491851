import { Component, OnInit } from '@angular/core';
import { SysSettingService } from '../../../services/sys-setting.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {

  itemDetail: any = { settingValue: '' }

  constructor(
    private _sysSettingService: SysSettingService
  ) { }

  ngOnInit() {
    this._sysSettingService.getByInstanceId('7A9E04FD-E869-4A4F-A32E-08D91530A2E7'.toLowerCase()).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
      }
    })
  }

}
