<div class="single-courses-box-list" [popper]="popperCourse" [popperTrigger]="'hover'" [popperPlacement]="'right'">
  <div class="courses-image">
    <a routerLink="/courses/detail/{{item.instanceId}}">
      <img src="{{item.imageUrl}}" alt="">
    </a>
  </div>
  <div class="courses-content">
    <a routerLink="/courses/detail/{{item.instanceId}}">
      <h3 class="title mb-0">{{item.title}}</h3>
    </a>
    <div class="description">
      {{item.headline}}
    </div>
    <a routerLink="/authors/detail/{{item.idTenant}}">
      <div class="author">{{item.displayNameTenant}}</div>
    </a>
    <div class="row">
      <div class="price-text md:col-6 sm:col-12">
        <div class="original-price" *ngIf="item.price > 0">{{item.price | currency: 'VND'}}</div>
        <div class="original-price" *ngIf="item.price == 0">Miễn phí</div>
        <!-- <div class="discount-price">2.099.000 đ </div> -->
      </div>
      <div class="courses-rating md:col-6 sm:col-12 ">
        <ng-rating-bar [value]="item.avgRatingStar ?? 5" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
          [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
        <span class="reviews">({{item.countComment ?? 0}})</span>
      </div>
    </div>
    <div class="courses-info"><span class="courses-time">Độ tuổi: {{{ageFrom: item.ageFrom, ageTo: item.ageTo} |
        age}}</span> · <span>{{item.titleCatLevel == null ? "Tất cả các trình độ": item.titleCatLevel}}</span></div>
  </div>
</div>

<popper-content #popperCourse>
  <app-single-course-popper [item]="item"></app-single-course-popper>
</popper-content>