<section class="register-to-be-partner container">
  <div class="section-title">
    <h2>Đăng ký để trở thành đối tác, giảng viên</h2>
    <!-- <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div> -->
  </div>
  <div class="row register-to-be-partner-container">
    <div class="col-12 md:col-6 lg:col-8 image-container ">
      <img src="assets/images/register/register.png" alt="">
    </div>
    <div
      class="col-12 md:col-12 lg:col-4 flex flex-column form justify-content-center align-items-center align-content-center">
      <img src="assets/images/logo/logo.png" alt="">
      <h5 class="text-center">Vui lòng nhập Email để hoàn tất!</h5>
      <form #formElement [formGroup]="formGroup">
        <div class="row p-fluid">
          <div class="field -required -error">
            <input type="number" class=" w-100 " pInputText placeholder="Số điện thoại"
              [(ngModel)]="itemDetail.phoneNumber" formControlName="phoneNumber" />
            <small class="text-red-500" *ngIf="formGroup.controls['phoneNumber'].invalid">
              Bạn cần nhập số điện thoại
            </small>
          </div>
          <div class="field -required -error">
            <input type="text" class=" w-100 " pInputText placeholder="Email" [(ngModel)]="itemDetail.email"
              formControlName="email" />
            <small class="text-red-500" *ngIf="formGroup.controls['phoneNumber'].invalid">
              Bạn cần nhập email
            </small>
          </div>
          <div class="field -required -error">
            <button class="default-btn w-100" (click)="save()">Đăng ký ngay<span></span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>