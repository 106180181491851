import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamUserLogService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.svmTestinEndpoint}/examuserlog`);
  }

  getsForExam(instanceId: string): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsForExam/${instanceId}`;
    return this.defaultGet(apiUrl);
  }

  updateLog(item: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/UpdateLog`;
    return this._http
      .post<ResponseResult>(apiUrl, item)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  getMyExamUser(instanceId: string): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetMyExamUser/${instanceId}`;
    return this.defaultGet(apiUrl);
  }

}
