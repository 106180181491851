import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-workshop-grid',
  templateUrl: './single-workshop-grid.component.html',
  styleUrls: ['./single-workshop-grid.component.scss']
})
export class SingleWorkshopGridComponent implements OnInit {

  @Input() item: any
  currentTime = new Date();
  startTime = new Date();
  endTime = new Date();
  constructor() { }

  ngOnInit() {
    this.startTime = new Date(this.item.startTime);
    this.endTime = new Date(this.item.endTime);
  }

}
