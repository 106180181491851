import { Component, OnInit } from '@angular/core';
import { SysSettingService } from '../../../services/sys-setting.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  itemDetail: any = { settingValue: '' }

  constructor(
    private _sysSettingService: SysSettingService
  ) { }

  ngOnInit() {
    this._sysSettingService.getByInstanceId('4C7E3BBB-2C01-4D87-B477-9F437374C441'.toLowerCase()).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
      }
    })
  }

}
