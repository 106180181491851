import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../../services/course.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-section-best-sellers-course',
  templateUrl: './section-best-sellers-course.component.html',
  styleUrls: ['./section-best-sellers-course.component.scss']
})
export class SectionBestSellersCourseComponent implements OnInit {

  dataSource = [];
  courseMostStudySides: OwlOptions = {
    loop: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    autoplayTimeout: 10000,
    margin: 30,
    nav: true,
    navText: [
      "<i class='pi pi-chevron-left'></i>",
      "<i class='pi pi-chevron-right'></i>"
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 4,
      }
    },
  }

  constructor(
    private _courseService: CourseService
  ) { }

  ngOnInit() {
    this.getsHotCourse();
  }

  getsHotCourse() {
    this._courseService.getsBestSeller(0, 6, false).then(rs => {
      if (rs.success) {
        this.dataSource = rs.data;
      }
    })
  }

}
