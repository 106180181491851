import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountdownComponent } from 'ngx-countdown';
import { map, takeUntil } from 'rxjs';
import { AuthenticationService, BasicUserInfo, ComponentBase, NotifierService } from 'sv-shared';
import { environment } from '../../../../environments/environment';
import { TestQuestionType } from '../../../config/enums';
import { ExamUser } from '../../../models/examUser';
import { TestQuestion } from '../../../models/testquestion';
import { ExamUserLogService } from '../../../services/exam-user-log.service';
import { ExamUserService } from '../../../services/exam-user.service';
import { ExamService } from '../../../services/exam.service';

@Component({
  selector: 'app-examing',
  templateUrl: './examing.component.html',
  styleUrls: ['./examing.component.scss']
})
export class ExamingComponent extends ComponentBase implements OnInit {
  isCollapsed = false;
  isCollapsedSmall = false;
  isShow = false;
  idExamUser = '';
  examDetail: any = {};
  @ViewChild('cd') public countdown: CountdownComponent;
  isDoingExam = 1;
  currentQuestionIndex = 0;
  totalQuestion = 0;
  currentQuestion: TestQuestion = new TestQuestion();
  itemExamUser: ExamUser = new ExamUser();
  config = { leftTime: 0 }
  isLoading = false;
  dataTest = [];
  itemDrag = {};
  indexDrag = -1;
  itemDrop = {};
  indexDrog = -1;
  dataReport = [];
  canDoExam = true;
  apiUploadUrl = `${environment.apiDomain.fileServiceEndPoint}/FsItemPublic/UploadExamUserLog`;
  testQuestionType = TestQuestionType;

  // countExamUser = 0;
  currentUser = new BasicUserInfo();
  constructor(
    protected _activatedRoute: ActivatedRoute,
    private _examService: ExamService,
    private _examUserService: ExamUserService,
    private _notifierService: NotifierService,
    private _examUserLogService: ExamUserLogService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
  ) {
    super();
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  ngOnInit() {

    if (this.currentUser?.userId > 0) {
      this._activatedRoute.params
        .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
        .subscribe(id => {
          this.idExamUser = id;
          this.getDetail(id);
        });
    } else {
      this._router.navigate(['/']);
    }

  }

  getDetail(id: '') {
    this._examUserService.getByInstanceId(id).then(rs => {
      if (rs.success) {
        this.itemExamUser = rs.data;
        this.getDetailExam(this.itemExamUser.idExam);
        this.config.leftTime = rs.data.countDown;

        sessionStorage.setItem('examUser', JSON.stringify(this.itemExamUser));
        if (rs.data.examStatus >= 3) {
          // this._notifierService.showWarning('Bạn đã hoàn thành bài thi');
        }
        else if (rs.data.countDown < 0) {
          this._notifierService.showWarning('Thời gian làm bài đã hết');
          sessionStorage.removeItem("examUser");
          if (this.itemExamUser.examStatus <= 2) {
            this.autoFinishExam();
          }
          return;
        }
        else {
          this.doingExam();
        }
      }
    });
  }

  getDetailExam(id: number) {
    this._examService.getById(id).then(rs => {
      if (rs.success) {
        this.examDetail = rs.data;
      }
    });
    // this._examUserService.countExamUser(id).then(rs => {
    //   if (rs.success) {
    //     this.countExamUser = rs.data;
    //   }
    // });
  }

  exitLearningSpace() {
    //this._router.navigate(['/']);
    window.location.href = '/';
  }

  doingExam() {
    this.isDoingExam = 2;
    this.isLoading = true;
    this.currentQuestionIndex = 0;
    this.config.leftTime = this.itemExamUser.countDown;
    this.apiUploadUrl = `${environment.apiDomain.fileServiceEndPoint}/FsItemPublic/UploadExamUserLog/${this.itemExamUser.id}`;
    if (this.countdown) {
      this.countdown.config = this.config;
      this.countdown.restart();
    }
    this._examUserLogService.getsForExam(this.itemExamUser.instanceId).then(rs => {
      if (rs.success) {
        this.dataTest = rs.data;
        this.totalQuestion = rs.data.length - 1;
        this.currentQuestion = this.dataTest[this.currentQuestionIndex];
      }
    })
  }

  goQuestion(i: number) {
    this.currentQuestionIndex = i;
    this.currentQuestion = this.dataTest[this.currentQuestionIndex];
    window.scrollTo({ top: 0 });
  }

  goPrevQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.goQuestion(this.currentQuestionIndex - 1);
    }

  }

  goNextQuestion() {
    if (this.currentQuestionIndex < this.totalQuestion) {
      this.goQuestion(this.currentQuestionIndex + 1);
    }
  }

  radioBtnClick(itemExamUserLogDto: any) {
    var model = {
      id: itemExamUserLogDto.id,
      answerText: itemExamUserLogDto.answerText
    };
    if (itemExamUserLogDto.idQuestionParent > 0) {
      this.checkDoSubQuestion(itemExamUserLogDto.idQuestionParent);
    }
    this._examUserLogService.updateLog(model);

  }

  async checkBoxChange(itemExamUserLogDto: any) {
    var dsAns = itemExamUserLogDto.testQuestionAnswer.sort((a, b) => {
      return a.id - b.id;
    });
    var ans = [];
    await dsAns.forEach(element => {
      if (element.isUserAnswer == true) {
        ans.push(element.id);
      }
    });
    var model = {
      id: itemExamUserLogDto.id,
      answerText: ans.join(",")
    };
    itemExamUserLogDto.answerText = model.answerText;
    if (itemExamUserLogDto.idQuestionParent > 0) {
      this.checkDoSubQuestion(itemExamUserLogDto.idQuestionParent);
    }
    this._examUserLogService.updateLog(model);
  }
  async onTextChange(itemExamUserLogDto: any) {
    var ans = [];
    await itemExamUserLogDto.testQuestionAnswer.forEach(element => {
      ans.push(element.answerText);
    });
    var model = {
      id: itemExamUserLogDto.id,
      answerText: ans.join(",")
    };
    itemExamUserLogDto.answerText = model.answerText;
    if (itemExamUserLogDto.idQuestionParent > 0) {
      this.checkDoSubQuestion(itemExamUserLogDto.idQuestionParent);
    }
    this._examUserLogService.updateLog(model);
  }

  checkDoSubQuestion(idQuestionParent: string) {
    var parentQuesion = this.dataTest.filter(x => x.id == idQuestionParent)[0];
    var dsChildQuestion = parentQuesion.dsSubQuestions;
    var dsNotAnswer = dsChildQuestion.filter(x => x.answerText == null || x.answerText == undefined || x.answerText == '');

    console.log(dsNotAnswer);
    if (dsNotAnswer.length > 0) {
      parentQuesion.answerText = null;
      var model = {
        id: parentQuesion.idPracticeUserLog,
        answerText: null
      };
      this._examUserLogService.updateLog(model);
    } else {
      parentQuesion.answerText = parentQuesion.id.toString();
      var model1 = {
        id: parentQuesion.idPracticeUserLog,
        answerText: parentQuesion.id.toString()
      };
      this._examUserLogService.updateLog(model1);
    }
  }

  onUploadInTesting(itemExamUserLogDto: any, event) {
    if (event.originalEvent.body.success) {
      var model = {
        id: itemExamUserLogDto.id,
        answerFileUrl: `${environment.apiDomain.fileServiceEndPoint}${event.originalEvent.body.data[0]}`,
        answerText: `${environment.apiDomain.fileServiceEndPoint}${event.originalEvent.body.data[0]}`,
      };
      itemExamUserLogDto.answerFileUrl = `${environment.apiDomain.fileServiceEndPoint}${event.originalEvent.body.data[0]}`;
      itemExamUserLogDto.answerText = `${environment.apiDomain.fileServiceEndPoint}${event.originalEvent.body.data[0]}`;
      if (itemExamUserLogDto.idQuestionParent > 0) {
        this.checkDoSubQuestion(itemExamUserLogDto.idQuestionParent);
      }
      this._examUserLogService.updateLog(model);
    }
  }

  autoFinishExam() {
    this._examUserService.finishExam(this.itemExamUser.instanceId).then(rs => {
      if (rs.success) {
        this.isDoingExam = 1;
        this.itemExamUser = rs.data;
        // this.showReport();
      }
    });
  }

  doFinishExam() {
    this._notifierService.showConfirm("Bạn chắc chắn muốn kết thúc bài kiểm tra?").then(rs => {
      this.autoFinishExam();
    });
  }

  countdownEvent(evt: any) {
    if (evt.action == 'done') {
      if (this.itemExamUser.status == 1 && this.config.leftTime > 0) {
        this.autoFinishExam();
      }
    }
  }

  dragStart(dsAnswers: any[], event) {
    this.indexDrag = dsAnswers.findIndex(x => x.id == event.id);
    this.itemDrag = event;
  }

  drop(itemExamUserLogDto, dsAnswers, event) {
    this.indexDrog = dsAnswers.findIndex(x => x.id == event.id);
    this.itemDrop = event;
    if (this.indexDrag >= 0 && this.indexDrog >= 0) {
      dsAnswers[this.indexDrag] = this.itemDrop;
      dsAnswers[this.indexDrog] = this.itemDrag;
    }
    itemExamUserLogDto.answerText = (dsAnswers.map(x => x.id)).join(',');
    //console.log(itemExamUserLogDto.answerText);

    var model = {
      id: itemExamUserLogDto.id,
      answerText: itemExamUserLogDto.answerText
    };

    if (itemExamUserLogDto.idQuestionParent > 0) {
      this.checkDoSubQuestion(itemExamUserLogDto.idQuestionParent);
    }
    this._examUserLogService.updateLog(model);
  }

  ViewExamUser() {
    this.isDoingExam = 3;
    this._examUserLogService.getMyExamUser(this.itemExamUser.instanceId).then(rs => {
      if (rs.success) {
        this.dataTest = rs.data;
        this.totalQuestion = rs.data.length - 1;
        this.currentQuestion = this.dataTest[this.currentQuestionIndex];
      }
    });
  }

}
