<div class="single-news-box-list">
  <div class="news-image">
    <a routerLink="/news/detail/{{item.instanceId}}">
      <img src="{{item.imageUrl}}" alt="">
    </a>
  </div>
  <div class="news-content">
    <a routerLink="/news/detail/{{item.instanceId}}">
      <h3 class="title mb-0">{{item.title}}</h3>
    </a>
    <div class="description">
      {{item.subject}}
    </div>
    <div class="row">
      <div class="md:col-6 sm:col-12">
        <div class="author">{{item.author}}</div>
      </div>
      <div class="date-time md:col-6 sm:col-12">
        <div>
          <i class="pi pi-calendar"></i><span class="pl-2">{{item.publishedDate | date: 'dd/MM/yyyy'}}</span>
        </div>
      </div>
      <!-- <div class="news-rating md:col-4 sm:col-12 ">
        <span class="rating mr-1">5</span>
        <ng-rating-bar [value]="item.ratingStar" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
          [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
      </div> -->
    </div>
    <!-- <button class="default-btn mb-2">Xem thêm<span></span></button> -->
  </div>
</div>
