<div class="ws-page-body-title h-mb-16"><span class="ws-img-icon ws-page-body-title__icon">
    <svg>
      <use href="\assets\images\icon\camera.svg#icon"></use>
    </svg></span>
  <div class="ws-page-body-title__text">Học trực tuyến</div>
</div>
<div class="ws-page-body-content h-overflow-y-auto h-100vh">
  <div class="ws-space-lesson-item -status-2" *ngIf="itemLiveIscomming.id > 0">
    <div class="ws-space-lesson-item-part__left-side">
      <div class="ws-avatar-with-icon -size-l ">
        <img class="ws-avatar-with-icon__img" src="\assets\images\blog_icon.svg" alt="Avatar">
      </div>
      <div class="ws-space-lesson-item__meta-block">
        <div class="ws-space-lesson-item__title">{{itemLiveIscomming.title}}</div>
        <div class="ws-space-lesson-item__event-time">
          <span class="ws-space-lesson-item__event-time-part">Bắt đầu lúc: {{itemLiveIscomming.startTime |
            date:'dd/MM/yyyy HH:mm' }}</span>
        </div>
        <div class="ws-space-lesson-item__excerpt">Kéo dài trong: {{itemLiveIscomming.duration }} ({{'phút' |
          translate}})</div>
      </div>
    </div>
    <div class="ws-space-lesson-item-part__right-side">
      <button class="ws-btn -size-2 ws-bgc--primary-2 ws-color--light"
        *ngIf="itemLiveIscomming.learningStatus == 2 && isTeacher != true && itemLiveIscomming.attendeePW != null"
        (click)="goLiveClass()">
        <div class="ws-btn__label">{{'Vào lớp' | translate}}
        </div>
      </button>
      <button class="ws-btn -size-2 ws-bgc--primary-2 ws-color--light" disabled
        *ngIf="itemLiveIscomming.learningStatus == 2 && isTeacher != true && itemLiveIscomming.attendeePW == null">
        <div class="ws-btn__label">{{'Giáo viên chưa vào lớp' | translate}}
        </div>
      </button>
      <button class="ws-btn -size-2 ws-bgc--primary-2 ws-color--light" *ngIf="isTeacher == true"
        (click)="goLiveClass()">
        <div class="ws-btn__label">{{'Vào lớp' | translate}}
        </div>
      </button>
    </div>
    <div class="ws-dot"></div>
  </div>
  <ng-container *ngFor="let item of dataSource">
    <div class="ws-space-lesson-item" [class.-status-3]="item.learningStatus == 1"
      [class.-status-2]="item.learningStatus != 1">
      <div class="ws-space-lesson-item-part__left-side">
        <div class="ws-avatar-with-icon -size-l ">
          <img class="ws-avatar-with-icon__img" src="\assets\images\blog_icon.svg" alt="Avatar">
        </div>
        <div class="ws-space-lesson-item__meta-block">
          <div class="ws-space-lesson-item__title">{{item.title}}</div>
          <div class="ws-space-lesson-item__event-time">
            <span class="ws-space-lesson-item__event-time-part">Bắt đầu lúc: {{item.startTime |
              date:'dd/MM/yyyy HH:mm' }}</span>
          </div>
          <div class="ws-space-lesson-item__excerpt">Kéo dài trong: {{item.duration }} ({{'phút' |
            translate}})</div>
        </div>
      </div>
      <div class="ws-dot"></div>
    </div>
  </ng-container>
</div>
