<div class="ws-page-container ws-page-layout--space--exam">
  <div class="ws-page-header-area">
    <div class="ws-page-header-action ws-page-header-menu-toggle ws-page-header-item h-hidden--lg-min">
      <span class="ws-img-icon ws-page-header-action__icon" (click)="isCollapsedSmall = !isCollapsedSmall">
        <svg>
          <use href="\assets\images\icon\app-menu.svg#icon"></use>
        </svg>
      </span>
    </div>
    <a class="ws-page-header-logo-wrapper ws-page-header-item h-hidden--lg-min">
      <img class="ws-page-header-logo" src="assets/images/logo.png" alt="Logo">
    </a>
    <div class="ws-page-header-title ws-page-header-item"><span class="ws-img-icon ws-page-header-title__icon">
        <svg>
          <use href="\assets\images\icon\book-o.svg#icon"></use>
        </svg>
      </span>
      <div class="ws-page-header-title__text">{{examDetail.title}}</div>
    </div>
    <div class="ws-page-header-user ws-page-header-item">
      <a href="#" class="header__btn" type="button" (click)="exitLearningSpace()">
        <img class="ws-page-header-user__avatar" src="\assets\images\icon\close-circle.svg" alt="User">
      </a>
    </div>
  </div>
  <div class="ws-page-body-area">
    <ng-container *ngIf="isDoingExam == 1">
      <ng-container *ngIf="itemExamUser.examStatus == 1">
        <div class="ws-page-body-content h-overflow-y-auto ws-l-content-container">
          <div class="ws-l-block-content-row">
            <div class="ws-l-block-content">
              <div class="ws-l-meta">
                <div class="ws-l-meta__key">Thời gian làm bài:</div>
                <div class="ws-l-meta__value">{{itemExam?.testTime}} phút</div>
              </div>
              <div class="ws-l-meta">
                <div class="ws-l-meta__key">Số điểm tối thiểu cần đạt được:</div>
                <div class="ws-l-meta__value">{{itemExam?.requiredMark}} / {{itemExam?.totalMark}}</div>
              </div>
            </div>
          </div>
          <div class="ws-l-main-icon-wrapper">
            <button class="ws-btn -size-4 -icon-size-2 ws-bgc--transparent"
              *ngIf="showCanDoTask && !isTeacher && courseUserLogItem.id == 0" (click)="doExam()">
              <span class="ws-img-icon ws-btn__icon ws-color--primary-2"><svg>
                  <use href="\assets\images\icon\rounded-arrow-right.svg#icon"></use>
                </svg></span>
              <div class="ws-btn__label">{{'Bắt đầu làm bài' | translate}}</div>
            </button>
            <button class="ws-btn -size-4 -icon-size-2 ws-bgc--transparent" *ngIf="!showCanDoTask">
              <div class="ws-btn__label">{{' Chưa đến hoặc đã hết thời gian làm bài' | translate}}
              </div>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="itemExamUser.examStatus >= 3">
        <div class="ws-page-body-content h-overflow-y-auto ws-l-content-container">
          <div class="ws-heading-1"><span class="ws-img-icon ws-heading-1__icon">
              <svg>
                <use href="\assets\images\icon\task-square.svg#icon"></use>
              </svg></span>
            <div class="ws-heading-1__text">Kết quả bài thi</div>
          </div>
          <div class="ws-l-main-icon-wrapper">
            <span class="ws-img-icon ws-l-main-icon ws-color--danger"
              *ngIf="itemExamUser.finalMark < itemExamUser.requiredMark">
              <svg>
                <use href="\assets\images\icon\close-circle.svg#icon"></use>
              </svg>
            </span>
            <span class="ws-img-icon ws-l-main-icon ws-color--success"
              *ngIf="itemExamUser.finalMark >= itemExamUser.requiredMark">
              <svg>
                <use href="\assets\images\icon\rounded-check.svg#icon"></use>
              </svg>
            </span>
          </div>
          <div class="ws-l-main-icon-wrapper">
            <div class="ws-1-meta" *ngIf="examDetail.option_ViewAnswer == true">
              <button class="ws-btn -size-3 ws-color--light ws-bgc--primary-2 ws-l-create-topic-btn"
                (click)="ViewExamUser()">
                <div class="ws-btn__label"> Xem lại bài thi </div>
              </button>
            </div>
          </div>
          <div class="ws-l-block-content-row">
            <div class="ws-l-block-content">
              <div class="ws-l-meta">
                <div class="ws-l-meta__key">Số câu trả lời đúng:</div>
                <div class="ws-l-meta__value">{{itemExamUser.numQTrue}}</div>
              </div>
              <div class="ws-l-meta">
                <div class="ws-l-meta__key">Số câu trả lời sai:</div>
                <div class="ws-l-meta__value">{{itemExamUser.numQFalse}}</div>
              </div>
              <div class="ws-l-meta">
                <div class="ws-l-meta__key">Số câu cần giáo viên chấm điểm:</div>
                <div class="ws-l-meta__value">{{itemExamUser.numQTeacher}}</div>
              </div>
              <div class="ws-l-meta">
                <div class="ws-l-meta__key">Số câu không trả lời:</div>
                <div class="ws-l-meta__value">{{itemExamUser.numQNoAns}}</div>
              </div>
            </div>
            <div class="ws-l-block-content">
              <div class="ws-l-meta">
                <div class="ws-l-meta__key">Điểm:</div>
                <div class="ws-l-meta__value">{{itemExamUser.finalMark}} /
                  {{itemExamUser.totalMark}}</div>
              </div>
              <div class="ws-l-meta">
                <div class="ws-l-meta__key">Điểm yêu cầu:</div>
                <div class="ws-l-meta__value">{{itemExamUser.requiredMark}} /
                  {{itemExamUser.totalMark}}</div>
              </div>
              <div class="ws-l-meta">
                <div class="ws-l-meta__key">Kết quả:</div>
                <div class="ws-l-meta__value" *ngIf="itemExamUser.finalMark >= itemExamUser.requiredMark">Đạt
                </div>
                <div class="ws-l-meta__value" *ngIf="itemExamUser.finalMark < itemExamUser.requiredMark">Không
                  đạt</div>
              </div>
              <div class="ws-1-meta" *ngIf="itemExamUser.teacherComment">
                <div class="ws-l-meta__key">Nhận xét của giáo viên:</div>
                <div class="ws-l-meta__value">
                  <p [innerHTML]="itemExamUser.teacherComment | safeHtml"></p>
                </div>
              </div>
              <div class="ws-1-meta"
                *ngIf="showCanDoTask && examDetail.option_NumReTest > 0 && !isTeacher && courseItemDetail.approvalMark != true && courseUserLogItem.id > 0 && courseUserLogItem.examStatus >= 3 && courseUserLogItem.finalMark < courseUserLogItem.passMark">
                <button class="ws-btn -size-3 ws-color--light ws-bgc--primary-2 ws-l-create-topic-btn"
                  (click)="doExam()">
                  <div class="ws-btn__label"> Làm lại </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isDoingExam == 2">
      <div class="ws-page-body-content ws-p-space-test">
        <div class="ws-p-space-test-main-area">
          <div class="ws-p-space-test-question-container">
            <div class="ws-p-space-test-question-note"> Câu {{currentQuestionIndex + 1}}.
            </div>
            <div class="ws-p-space-test-question-wrapper">
              <div class="ws-space-test-question">
                <div class="ws-space-test-question__title"
                  *ngIf="currentQuestion.idTestQuestionType != testQuestionType.GF"
                  [innerHTML]="currentQuestion.questionText"></div>
                <div class="ws-space-test-answer--type-choice" *ngIf="currentQuestion.idTestQuestionType == testQuestionType.MC
                                  || currentQuestion.idTestQuestionType == testQuestionType.TF
                                  || currentQuestion.idTestQuestionType == testQuestionType.TFNG
                                  || currentQuestion.idTestQuestionType == testQuestionType.YN">
                  <div class="ws-space-test-answer-part--type-choice"
                    [class.is-activated]="currentQuestion.answerText == ans.id"
                    *ngFor="let ans of currentQuestion.testQuestionAnswer">
                    <p-radioButton name="{{currentQuestion.id}}" value="{{ans.id}}"
                      [(ngModel)]="currentQuestion.answerText" inputId="{{ans.id}}"
                      (onClick)="radioBtnClick(currentQuestion)">
                    </p-radioButton>
                    <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                  </div>
                </div>

                <div class="ws-space-test-answer--type-choice"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.CL">
                  <div class="ws-space-test-answer-part--type-choice"
                    [class.is-activated]="currentQuestion.answerText != null && currentQuestion.answerText.indexOf(ans.id) !== -1 "
                    *ngFor="let ans of currentQuestion.testQuestionAnswer">
                    <p-checkbox name="{{ans.id}}" binary="true" (onChange)="checkBoxChange(currentQuestion)"
                      [(ngModel)]="ans.isUserAnswer">
                    </p-checkbox>
                    <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                  </div>
                </div>

                <div class="ws-space-test-answer--type-fill"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.GF">
                  <ng-container *ngFor="let item of currentQuestion.testQuestionAnswer; index as i">
                    <normal [innerHTML]="currentQuestion.dsQuestionText[i]"></normal>
                    <input type="text" class="mwText" [(ngModel)]="item.answerText"
                      (change)="onTextChange(currentQuestion)" />
                  </ng-container>
                  <normal [innerHTML]="currentQuestion.dsQuestionText[currentQuestion.testQuestionAnswer.length]">
                  </normal>
                </div>

                <div class="ws-space-test-answer--type-text-editor"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SA">
                  <p-editor [(ngModel)]="currentQuestion.answerText" [style]="{'height':'150px'}"
                    (onTextChange)="onTextEditorChange(currentQuestion)">
                  </p-editor>
                </div>

                <div class="ws-space-test-answer--type-sort-order"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SQ">
                  <ng-container *ngFor="let item of currentQuestion.testQuestionAnswer">
                    <div class="ws-space-test-answer-part--type-sort-order" pDraggable="items"
                      (onDragStart)="dragStart(currentQuestion.testQuestionAnswer, item)" pDroppable="items"
                      (onDrop)="drop(currentQuestion, currentQuestion.testQuestionAnswer, item)"
                      [innerHTML]="item.answerText">
                    </div>
                  </ng-container>
                </div>

                <div class="ws-space-test-answer--type-sort-pair"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.MA">
                  <div class="col-md-6 pd-2">
                    <div class="col-12 pt-0" *ngFor="let item of currentQuestion.testQuestionAnswer">
                      <div class="ws-space-test-answer-part--type-sort-pair " [innerHTML]="item.answerText">
                      </div>
                    </div>

                  </div>
                  <div class="col-md-6 pd-2">
                    <div class="col-12 pt-0" *ngFor="let item of currentQuestion.testQuestionAnswerMatching">
                      <div class="ws-space-test-answer-part--type-sort-pair is-activated-1" pDraggable="items"
                        (onDragStart)="dragStart(currentQuestion.testQuestionAnswerMatching, item)" pDroppable="items"
                        (onDrop)="drop(currentQuestion, currentQuestion.testQuestionAnswerMatching, item)"
                        [innerHTML]="item.matching">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ws-space-test-answer-part--type-upload"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.UP">
                  <div class="ws-heading-1 ws-space-test-answer--type-upload__heading">
                    <span class="ws-img-icon ws-heading-1__icon">
                      <svg>
                        <use href="\assets\images\icon\link-square.svg#icon"></use>
                      </svg></span>
                    <div class="ws-heading-1__text">File đính kèm</div>
                  </div>
                  <div class="ws-upload ws-space-test-answer--type-upload__field">
                    <a href="{{currentQuestion.answerFileUrl}}" target="_blank">{{currentQuestion.answerFileUrl
                      | fileName}}</a>
                  </div>
                  <div class="ws-upload ws-space-test-answer--type-upload__field">
                    <p-fileUpload mode="advanced" url="{{apiUploadUrl}}" [chooseLabel]="'Chọn file'"
                      [uploadLabel]="'Tải file'" [cancelLabel]="'Hủy tải lên'" name="file" [multiple]="false"
                      (onUpload)="onUploadInTesting(currentQuestion, $event)"
                      accept="image/*,.doc,.docx,.xls,.xlsx,ppt,pptx,.pdf,.rar,.zip" maxFileSize="1000000000">
                    </p-fileUpload>
                  </div>
                </div>
                <div class="ws-space-test-question" *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SB">
                  <div class="ws-space-test-question -sub-question"
                    *ngFor="let subItem of currentQuestion.dsSubQuestion; index as j">

                    <div class="ws-space-test-question__title" *ngIf="subItem.idTestQuestionType != testQuestionType.GF"
                      [innerHTML]="subItem.questionText">
                    </div>
                    <div class="ws-space-test-answer--type-choice" *ngIf="subItem.idTestQuestionType == testQuestionType.MC
                                  || subItem.idTestQuestionType == testQuestionType.TF
                                  || subItem.idTestQuestionType == testQuestionType.TFNG
                                  || subItem.idTestQuestionType == testQuestionType.YN">
                      <div class="ws-space-test-answer-part--type-choice"
                        [class.is-activated]="subItem.answerText == ans.id"
                        *ngFor="let ans of subItem.testQuestionAnswer">
                        <p-radioButton name="{{subItem.id}}" value="{{ans.id}}" [(ngModel)]="subItem.answerText"
                          inputId="{{ans.id}}" (onClick)="radioBtnClick(subItem)">
                        </p-radioButton>
                        <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                      </div>
                    </div>

                    <div class="ws-space-test-answer--type-choice"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.CL">
                      <div class="ws-space-test-answer-part--type-choice"
                        [class.is-activated]="subItem.answerText != null && subItem.answerText.indexOf(ans.id) !== -1 "
                        *ngFor="let ans of subItem.testQuestionAnswer">
                        <p-checkbox name="{{ans.id}}" binary="true" (onChange)="checkBoxChange(subItem)"
                          [(ngModel)]="ans.isUserAnswer">
                        </p-checkbox>
                        <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                      </div>
                    </div>

                    <div class="ws-space-test-answer--type-fill"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.GF">
                      <ng-container *ngFor="let item of subItem.testQuestionAnswer; index as i">
                        <normal [innerHTML]="subItem.dsQuestionText[i]"></normal>
                        <input type="text" class="mwText" [(ngModel)]="item.answerText"
                          (change)="onTextChange(subItem)" />
                      </ng-container>
                      <normal [innerHTML]="subItem.dsQuestionText[subItem.testQuestionAnswer.length]">
                      </normal>
                    </div>

                    <div class="ws-space-test-answer--type-text-editor"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.SA">
                      <p-editor [(ngModel)]="subItem.answerText" [style]="{'height':'150px'}"
                        (onTextChange)="onTextEditorChange(subItem)">
                      </p-editor>
                    </div>

                    <div class="ws-space-test-answer--type-sort-order"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.SQ">
                      <ng-container *ngFor="let item of subItem.testQuestionAnswer">
                        <div class="ws-space-test-answer-part--type-sort-order" pDraggable="items"
                          (onDragStart)="dragStart(subItem.testQuestionAnswer, item)" pDroppable="items"
                          (onDrop)="drop(subItem, subItem.testQuestionAnswer, item)" [innerHTML]="item.answerText">
                        </div>
                      </ng-container>
                    </div>

                    <div class="ws-space-test-answer--type-sort-pair"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.MA">
                      <div class="col-md-6 pd-2">
                        <div class="ws-space-test-answer-part--type-sort-pair "
                          *ngFor="let item of subItem.testQuestionAnswer" [innerHTML]="item.answerText">
                        </div>
                      </div>
                      <div class="col-md-6 pd-2">
                        <div class="ws-space-test-answer-part--type-sort-pair is-activated-1"
                          *ngFor="let item of subItem.testQuestionAnswerMatching">
                          <div class="col-12" pDraggable="items"
                            (onDragStart)="dragStart(subItem.testQuestionAnswerMatching, item)" pDroppable="items"
                            (onDrop)="drop(subItem, subItem.testQuestionAnswerMatching, item)"
                            [innerHTML]="item.matching">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="ws-space-test-answer-part--type-upload"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.UP">
                      <div class="ws-heading-1 ws-space-test-answer--type-upload__heading">
                        <span class="ws-img-icon ws-heading-1__icon">
                          <svg>
                            <use href="\assets\images\icon\link-square.svg#icon"></use>
                          </svg></span>
                        <div class="ws-heading-1__text">File đính kèm</div>
                      </div>
                      <div class="ws-upload ws-space-test-answer--type-upload__field">
                        <a href="{{subItem.answerFileUrl}}" target="_blank">{{subItem.answerFileUrl
                          | fileName}}</a>
                      </div>
                      <div class="ws-upload ws-space-test-answer--type-upload__field">
                        <p-fileUpload mode="advanced" url="{{apiUploadUrl}}" [chooseLabel]="'Chọn file'"
                          [uploadLabel]="'Tải file'" [cancelLabel]="'Hủy tải lên'" name="file" [multiple]="false"
                          (onUpload)="onUploadInTesting(subItem, $event)"
                          accept="image/*,.doc,.docx,.xls,.xlsx,ppt,pptx,.pdf,.rar,.zip" maxFileSize="1000000000">
                        </p-fileUpload>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
            <div class="ws-p-space-test-navigation">
              <button class="ws-btn -size-5 ws-p-space-test-navigation-btn" [disabled]="currentQuestionIndex <= 0"
                (click)="goPrevQuestion()"><span class="ws-img-icon ws-btn__icon">
                  <svg>
                    <use href="\assets\images\icon\square-arrow-left.svg#icon"></use>
                  </svg></span>
                <div class="ws-btn__label">Câu trước</div>
              </button>
              <button class="ws-btn -size-5 ws-p-space-test-navigation-btn"
                [disabled]="currentQuestionIndex >= totalQuestion" (click)="goNextQuestion()">
                <span class="ws-img-icon ws-btn__icon">
                  <svg>
                    <use href="\assets\images\icon\square-arrow-right.svg#icon"></use>
                  </svg></span>
                <div class="ws-btn__label">Câu sau</div>
              </button>
            </div>
          </div>
        </div>
        <div class="ws-p-space-test-side-area">
          <div class="ws-p-space-test-timer"><span class="ws-img-icon ws-p-space-test-timer__icon">
              <svg>
                <use href="\assets\images\icon\clock-2.svg#icon"></use>
              </svg></span>
            <div class="ws-p-space-test-timer__label">
              <countdown #cd [config]="config" (event)="countdownEvent($event)">
              </countdown>
            </div>
          </div>
          <div class="ws-p-space-test-question-btns">
            <ng-container *ngFor="let item of dataTest; let i = index">
              <div class="ws-p-space-test-question-btn-wrapper">
                <button class="ws-p-space-test-question-btn"
                  [class]="(currentQuestionIndex == i) ? '-val-3': (item.answerText !== null ? 'is-activated': '')"
                  (click)="goQuestion(i)">Câu {{i + 1}}</button>
              </div>

            </ng-container>
          </div>
          <div class="ws-p-space-test-glosses">
            <div class="ws-p-space-test-gloss">
              <div class="ws-p-space-test-gloss__value -val-3"></div>
              <div class="ws-p-space-test-gloss__text">Đang trả lời</div>
            </div>
            <div class="ws-p-space-test-gloss">
              <div class="ws-p-space-test-gloss__value -val-2"></div>
              <div class="ws-p-space-test-gloss__text">Đã trả lời</div>
            </div>
            <div class="ws-p-space-test-gloss">
              <div class="ws-p-space-test-gloss__value -val-1"></div>
              <div class="ws-p-space-test-gloss__text">Chưa trả lời</div>
            </div>
          </div>
          <div class="ws-p-space-test-submit-btn-wrapper h-text-center h-pb-20">
            <button class="ws-btn -size-5 ws-p-space-test-submit-btn" (click)="doFinishExam()">
              <div class="ws-btn__label">Nộp bài</div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isDoingExam == 3">
      <div class="ws-page-body-content ws-p-space-test">
        <div class="ws-p-space-test-main-area">
          <div class="ws-p-space-test-question-container">
            <div class="ws-p-space-test-question-note"> Câu {{currentQuestionIndex + 1}}.
            </div>
            <div class="ws-p-space-test-question-wrapper">
              <div class="ws-space-test-question">
                <div class="ws-space-test-question__title"
                  *ngIf="currentQuestion.idTestQuestionType != testQuestionType.GF"
                  [innerHTML]="currentQuestion.questionText"></div>
                <div class="ws-space-test-answer--type-choice" *ngIf="currentQuestion.idTestQuestionType == testQuestionType.MC
                                  || currentQuestion.idTestQuestionType == testQuestionType.TF
                                  || currentQuestion.idTestQuestionType == testQuestionType.TFNG
                                  || currentQuestion.idTestQuestionType == testQuestionType.YN">
                  <div class="ws-space-test-answer-part--type-choice"
                    [class.is-activated]="currentQuestion.answerText == ans.id"
                    *ngFor="let ans of currentQuestion.testQuestionAnswer">
                    <p-radioButton name="{{currentQuestion.id}}" value="{{ans.id}}"
                      [(ngModel)]="+currentQuestion.answerText" disabled>
                    </p-radioButton>
                    <span style="margin-left: 0.5rem;" [innerHTML]="ans.answerText"></span>
                  </div>
                  <div class="col-12 fw-600" style="font-weight: bold;">
                    <div class="col-md-2 pd-tb0">
                      Đáp án đúng:
                    </div>
                    <div class="col-1 col-md-10 pd-tb0">
                      <span class="row" *ngFor="let item of currentQuestion.dsCorrectAnswer"
                        [innerHTML]="item.answerText"></span>
                    </div>
                  </div>
                </div>

                <div class="ws-space-test-answer--type-choice"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.CL">
                  <div class="ws-space-test-answer-part--type-choice"
                    [class.is-activated]="currentQuestion.answerText != null && currentQuestion.answerText.indexOf(ans.id) !== -1 "
                    *ngFor="let ans of currentQuestion.testQuestionAnswer">
                    <p-checkbox disabled name="{{ans.id}}" binary="true" [(ngModel)]="ans.isUserAnswer">
                    </p-checkbox>
                    <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                  </div>
                  <div class="col-12 fw-600" style="font-weight: bold;">
                    <div class="col-2 pd-tb0">
                      Đáp án đúng:
                    </div>
                    <div class="col-10 row pd-tb0">
                      <span class="col-12" *ngFor="let item of currentQuestion.dsCorrectAnswer"
                        [innerHTML]="item.answerText"></span>
                    </div>
                  </div>
                </div>

                <div class="ws-space-test-answer--type-fill"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.GF">
                  <div class="col-12 fw-600" [innerHTML]="currentQuestion.questionText">
                  </div>
                  <div class="row fw-600" style="font-weight: bold;">
                    <div class="col-md-3 pd-tb0">
                      Học sinh trả lời:
                    </div>
                    <div class="col-md-9 pd-tb0" [innerHTML]="currentQuestion.answerText"></div>
                  </div>
                  <div class="row fw-600" style="font-weight: bold;">
                    <div class="col-md-3 pd-tb0">
                      Đáp án đúng:
                    </div>
                    <div class="col-md-9 pd-tb0" [innerHTML]="currentQuestion.correctAnswer"></div>
                  </div>
                </div>

                <div class="ws-space-test-answer--type-text-editor"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SA">
                  <div class="col-12">
                    <div class="ui-g-3 pd-tb0 fw-600" style="font-weight: bold;">
                      Học sinh trả lời:
                    </div>
                    <div class="col-md-9 col-md-9 pd-tb0" [innerHTML]="currentQuestion.answerText"></div>
                  </div>
                </div>

                <div class="ws-space-test-answer--type-sort-order"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SQ">
                  <div class="col-12 row fw-600" style="font-weight: bold;">
                    <div class="col-3 pd-tb0">
                      Học sinh trả lời:
                    </div>
                    <div class="col9 pd-tb0">
                      <div class="col-6 pd-2">
                        <div class="colmd-12" *ngFor="let item of currentQuestion.testQuestionAnswer"
                          [innerHTML]="item.answerText"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 row fw-600" style="font-weight: bold;">
                    <div class="col-3 pd-tb0">
                      Đáp án đúng:
                    </div>
                    <div class="col-md-9 pd-tb0">
                      <div class="col-6 pd-2">
                        <div class="col-md-12" *ngFor="let item of currentQuestion.dsCorrectAnswer"
                          [innerHTML]="item.answerText"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ws-space-test-answer--type-sort-pair"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.MA">
                  <div class="col-md-6 pd-2">
                    <div class="col-12 pt-0" *ngFor="let item of currentQuestion.testQuestionAnswer">
                      <div class="ws-space-test-answer-part--type-sort-pair " [innerHTML]="item.answerText">
                      </div>
                    </div>

                  </div>
                  <div class="col-md-6 pd-2">
                    <div class="col-12 pt-0" *ngFor="let item of currentQuestion.testQuestionAnswerMatching">
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="ui-g-3 pd-tb0">
                          Học sinh trả lời:
                        </div>
                        <div class="col-md-9 col-md-9 pd-tb0">
                          <div class="col-md-6 pd-2">
                            <div class="col-md-12" *ngFor="let item of currentQuestion.testQuestionAnswer"
                              [innerHTML]="item.answerText"></div>
                          </div>
                          <div class="col-md-6 pd-2">
                            <div class="col-md-12" *ngFor="let item of currentQuestion.testQuestionAnswerMatching"
                              [innerHTML]="item.matching">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="ui-g-3 pd-tb0">
                          Đáp án đúng:
                        </div>
                        <div class="col-md-9 col-md-9 pd-tb0">
                          <div class="col-md-6 pd-2">
                            <div class="col-md-12" *ngFor="let item of currentQuestion.dsCorrectAnswer"
                              [innerHTML]="item.answerText"></div>
                          </div>
                          <div class="col-md-6 pd-2">
                            <div class="col-md-12" *ngFor="let item of currentQuestion.dsCorrectAnswer"
                              [innerHTML]="item.matching">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ws-space-test-answer-part--type-upload"
                  *ngIf="currentQuestion.idTestQuestionType == testQuestionType.UP">
                  <div class="row">
                    <div class="ui-g-3 pd-tb0 fw-600" style="font-weight: bold;">
                      Học sinh trả lời:
                    </div>
                    <div class="col-md-9 col-md-9 pd-tb0">
                      <a href="{{currentQuestion.answerFileUrl}}">{{currentQuestion.answerFileUrl | fileName}}</a>
                    </div>
                  </div>
                </div>
                <div class="ws-space-test-question" *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SB">
                  <div class="ws-space-test-question -sub-question"
                    *ngFor="let subItem of currentQuestion.dsSubQuestion; index as j">

                    <div class="ws-space-test-question__title" *ngIf="subItem.idTestQuestionType != testQuestionType.GF"
                      [innerHTML]="subItem.questionText">
                    </div>
                    <div class="ws-space-test-answer--type-choice" *ngIf="subItem.idTestQuestionType == testQuestionType.MC
                                  || subItem.idTestQuestionType == testQuestionType.TF
                                  || subItem.idTestQuestionType == testQuestionType.TFNG
                                  || subItem.idTestQuestionType == testQuestionType.YN">
                      <div class="ws-space-test-answer-part--type-choice"
                        [class.is-activated]="subItem.answerText == ans.id"
                        *ngFor="let ans of subItem.testQuestionAnswer">
                        <p-radioButton name="{{subItem.id}}" value="{{ans.id}}" [(ngModel)]="subItem.answerText"
                          inputId="{{ans.id}}" disabled>
                        </p-radioButton>
                        <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                      </div>
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="col-md-2 pd-tb0">
                          Đáp án đúng:
                        </div>
                        <div class="col-1 col-md-10 pd-tb0">
                          <span class="row " *ngFor="let item of subItem.dsCorrectAnswer"
                            [innerHTML]="item.answerText"></span>
                        </div>
                      </div>
                    </div>

                    <div class="ws-space-test-answer--type-choice"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.CL">
                      <div class="ws-space-test-answer-part--type-choice"
                        [class.is-activated]="subItem.answerText != null && subItem.answerText.indexOf(ans.id) !== -1 "
                        *ngFor="let ans of subItem.testQuestionAnswer">
                        <p-checkbox name="{{ans.id}}" binary="true" disabled [(ngModel)]="+ans.isUserAnswer">
                        </p-checkbox>
                        <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                      </div>
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="col-md-2 pd-tb0">
                          Đáp án đúng:
                        </div>
                        <div class="col-1 col-md-10 pd-tb0">
                          <span class="row " *ngFor="let item of subItem.dsCorrectAnswer"
                            [innerHTML]="item.answerText"></span>
                        </div>
                      </div>
                    </div>

                    <div class="ws-space-test-answer--type-fill"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.GF">
                      <div class="row fw-600" [innerHTML]="subItem.questionText">
                      </div>
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="ui-g-3 pd-tb0">
                          Học sinh trả lời:
                        </div>
                        <div class="col-md-9 col-md-9 pd-tb0" [innerHTML]="subItem.answerText"></div>
                      </div>
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="col-md-2 pd-tb0">
                          Đáp án đúng:
                        </div>
                        <div class="col-1 col-md-10 pd-tb0" [innerHTML]="subItem.correctAnswer"></div>
                      </div>
                    </div>

                    <div class="ws-space-test-answer--type-text-editor"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.SA">
                      <div class="row">
                        <div class="ui-g-3 pd-tb0 fw-600" style="font-weight: bold;">
                          Học sinh trả lời:
                        </div>
                        <div class="col-md-9 col-md-9 pd-tb0" [innerHTML]="subItem.answerText"></div>
                      </div>
                    </div>

                    <div class="ws-space-test-answer--type-sort-order"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.SQ">
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="ui-g-3 pd-tb0">
                          Học sinh trả lời:
                        </div>
                        <div class="col-md-9 col-md-9 pd-tb0">
                          <div class="col-md-6 pd-2">
                            <div class="col-md-12" *ngFor="let subans of subItem.testQuestionAnswer"
                              [innerHTML]="subans.answerText"></div>
                          </div>
                        </div>
                      </div>
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="ui-g-3 pd-tb0">
                          Đáp án đúng:
                        </div>
                        <div class="col-md-9 col-md-9 pd-tb0">
                          <div class="col-md-6 pd-2">
                            <div class="col-md-12" *ngFor="let subans of subItem.dsCorrectAnswer"
                              [innerHTML]="subans.answerText"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="ws-space-test-answer--type-sort-pair"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.MA">
                      <div class="row fw-600" [innerHTML]="subItem.questionText">
                      </div>
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="ui-g-3 pd-tb0">
                          Học sinh trả lời:
                        </div>
                        <div class="col-md-9 col-md-9 pd-tb0">
                          <div class="col-md-6 pd-tb0">
                            <div class="col-md-12 pd-tb0" *ngFor="let item of subItem.testQuestionAnswer"
                              [innerHTML]="item.answerText"></div>
                          </div>
                          <div class="col-md-6 pd-tb0">
                            <div class="col-md-12 pd-tb0" *ngFor="let item of subItem.testQuestionAnswerMatching"
                              [innerHTML]="item.matching">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row fw-600" style="font-weight: bold;">
                        <div class="ui-g-3 pd-tb0">
                          Đáp án đúng:
                        </div>
                        <div class="col-md-9 col-md-9 pd-tb0">
                          <div class="col-md-6 pd-tb0">
                            <div class="col-md-12 pd-tb0" *ngFor="let item of subItem.dsCorrectAnswer"
                              [innerHTML]="item.answerText"></div>
                          </div>
                          <div class="col-md-6 pd-tb0">
                            <div class="col-md-12" *ngFor="let item of subItem.dsCorrectAnswer"
                              [innerHTML]="item.answerText">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="ws-space-test-answer-part--type-upload"
                      *ngIf="subItem.idTestQuestionType == testQuestionType.UP">
                      <div class="row fw-600" [innerHTML]="subItem.questionText">
                      </div>
                      <div class="row">
                        <div class="ui-g-3 pd-tb0 fw-600" style="font-weight: bold;">
                          Học sinh trả lời:
                        </div>
                        <div class="col-md-9 col-md-9 pd-tb0">
                          <a href="{{subItem.answerFileUrl}}">{{subItem.answerFileUrl |
                            fileName}}</a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div class="col-12" *ngIf="currentQuestion.questionExplain">
                <div class="col-12">
                  <h3>Giải thích</h3>
                  <p [innerHTML]="currentQuestion.questionExplain"></p>
                </div>
              </div>
            </div>
            <div class="ws-p-space-test-navigation">
              <button class="ws-btn -size-5 ws-p-space-test-navigation-btn" [disabled]="currentQuestionIndex <= 0"
                (click)="goPrevQuestion()"><span class="ws-img-icon ws-btn__icon">
                  <svg>
                    <use href="\assets\images\icon\square-arrow-left.svg#icon"></use>
                  </svg></span>
                <div class="ws-btn__label">Câu trước</div>
              </button>
              <button class="ws-btn -size-5 ws-p-space-test-navigation-btn"
                [disabled]="currentQuestionIndex >= totalQuestion" (click)="goNextQuestion()">
                <span class="ws-img-icon ws-btn__icon">
                  <svg>
                    <use href="\assets\images\icon\square-arrow-right.svg#icon"></use>
                  </svg></span>
                <div class="ws-btn__label">Câu sau</div>
              </button>
            </div>
          </div>
        </div>
        <div class="ws-p-space-test-side-area">
          <div class="ws-p-space-test-question-btns">
            <ng-container *ngFor="let item of dataTest; let i = index">
              <div class="ws-p-space-test-question-btn-wrapper">
                <button class="ws-p-space-test-question-btn"
                  [class]="(currentQuestionIndex == i) ? '-val-3': (item.answerText !== null ? 'is-activated': '')"
                  (click)="goQuestion(i)">Câu {{i + 1}}</button>
              </div>

            </ng-container>
          </div>

        </div>
      </div>
    </ng-container>
  </div>
</div>
