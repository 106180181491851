import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComboService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.financialEndpoint}/combo`);
  }

  find(model: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/Find`;
    return this.defaultPost(apiUrl, model);
  }

  getsByIdCourse(idCourse: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsByIdCourse/${idCourse}`;
    return this.defaultGet(apiUrl);
  }

  getByListCourse(models: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetByListCourse`;
    return this.defaultPost(apiUrl, models);
  }

}
