import { Component, Injector, OnInit } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { SecondPageEditBase, SvCorePublicService, UserService } from 'sv-shared';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { passwordPattern } from '../../../config/password.config';
import { GisRegionService } from '../../../services/gis-region.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent extends SecondPageEditBase implements OnInit {

  model: ModelSignUp = new ModelSignUp();
  returnUrl = '/profile/favourite';
  enableLogin = true;
  dsLanguage = [];
  socialUser: SocialUser;
  environment = environment;
  captchaValid = false;

  listProvince = [];
  listDistrict = [];
  listSchoolLevel = [
    { value: 1, label: 'Tiểu học' },
    { value: 2, label: 'Trung học cơ sở' },
    { value: 3, label: 'Trung học phổ thông' },
    { value: 4, label: 'Đại học/Cao đẳng' },
    { value: 5, label: 'Người đi làm' },
    { value: 6, label: 'Khác' },
  ];

  listUserType = [
    { value: 10, label: 'Học sinh' },
    { value: 11, label: 'Phụ huynh' },
  ];

  constructor(
    protected _injector: Injector,
    private _oauthService: OAuthService,
    private _svCorePublicService: SvCorePublicService,
    private _router: Router,
    private _socialAuthService: SocialAuthService,
    protected _userService: UserService,
    private _gisRegionService: GisRegionService,
    private _cookieService: CookieService,
  ) {
    super(null, _injector);
    this.formGroup = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      displayName: new FormControl('', [Validators.required]),
      passwordHash: new FormControl('', [Validators.required, Validators.pattern(passwordPattern)]),
      idProvince: new FormControl('', [Validators.required]),
      idDistrict: new FormControl('', [Validators.required]),
      typeId: new FormControl('', [Validators.required]),
      idSchoolLevel: new FormControl('', Validators.required),
    });
  }

  async ngOnInit() {
    this.getsProvince();
    this.getsDistrict();
    this.model.typeId = 10;
    this.model.idSchoolLevel = 2;
  }

  async getsProvince() {
    await this._gisRegionService.getsAllProvince().then(rs => {
      if (rs.success) {
        this.convertDataToOptions(this.listProvince, rs.data, 'title');

      }
    })
    this.model.idProvince = 9;
  }

  async getsDistrict() {
    this.listDistrict = [];
    await this._gisRegionService.getsDistrictByIdProvince(this.model.idProvince ?? 0).then(rs => {
      if (rs.success) {
        this.convertDataToOptions(this.listDistrict, rs.data, 'title');

      }
    })
    this.model.idDistrict = 73;
  }

  async doRegister() {
    if (this.formGroup.invalid || (this.model.typeId == 10 && !(this.model.idSchoolLevel))) {
      this._notifierService.showError(this._translateService.instant("Bạn cần nhập đầy đủ thông tin"));
      return;
    } else {
      this._notifierService.showWarning('Phần mềm đang thử chạy thử nghiệm nội bộ');
      return;
      if (this.captchaValid) {
        this._svCorePublicService.signIn(this.model).then(rs => {
          if (rs.success) {
            this._notifierService.showSuccess(this._translateService.instant("Đăng ký thành công"));
            this.goLogin();
          } else {

          } this._notifierService.showWarning(this._translateService.instant(rs.error));
        }, error => {
          this._notifierService.showWarning(this._translateService.instant(error.error.error));
        });
      }

    }

  }

  onFocus() {
    this.submitting = false;
  }

  goLogin() {
    //this._router.navigate(['/authentication/signin']);
    this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
      if (!rs) {
        var myHeaders = new HttpHeaders().set('DeviceId', this._cookieService.get(environment.cookieName)).set('ReCaptchaToken', this.model.reCaptchaToken).set('CheckCaptCha', 'false');

        this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(`${this.model.userName}$${this._cookieService.get(environment.cookieName)}`, this.model.passwordHash
          , myHeaders).then(result => {
            this._oauthService.setupAutomaticSilentRefresh();
            //this._notifierService.showSuccess(this._translateService.instant('LOGIN.MESSAGE_OK'));
            var svUser = this._authenticationService.getCurrentUser();
            setTimeout(() => {
              this.submitting = false;
              top.location.href = this.returnUrl;
            }, 500);

          });
      }
    });
  }


  signInWithGoogle(): void {
    this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
        if (!rs) {
          this.socialUser = x;
          var body = `grant_type=google_auth&scope=openid%20profile%20email&client_id=sv-client&id_token=${this.socialUser.idToken}`;
          this._userService.loginWithImplicit(body).then(rs => {
            localStorage.setItem('access_token', rs.access_token);
            this._oauthService.tryLogin();
            setTimeout(() => {
              this.submitting = false;
              top.location.href = this.returnUrl;
            }, 500);
          })
        }
      });

    });
  }

  signInWithFacebook(): void {
    this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {

      this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
        if (!rs) {
          this.socialUser = x;
          var body = `grant_type=facebook_auth&scope=openid%20profile%20email&client_id=sv-client&id_token=${this.socialUser.authToken}`;
          this._userService.loginWithImplicit(body).then(rs => {
            localStorage.setItem('access_token', rs.access_token);
            this._oauthService.tryLogin();
            setTimeout(() => {
              this.submitting = false;
              top.location.href = this.returnUrl;
            }, 500);
          })
        }
      });
    });
  }

  onChangeType() {
    if (this.model.typeId == 11) {
      this.model.idSchoolLevel = null;
    } else {
      this.model.idSchoolLevel = 2;
    }
  }

  captchaResponse(event) {
    this.captchaValid = true;
    this.model.reCaptchaToken = event.response;
  }

}


class ModelSignUp {
  userName: string;
  email: string;
  displayName: string;
  passwordHash: string;
  typeId: number;
  idProvince: number;
  idDistrict: number;
  idSchoolLevel: number;
  reCaptchaToken: string;
}
