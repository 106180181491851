import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.svotherEndpoint}/banner`);
  }

  getsAllByIdSchool(idSchool: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsAllByIdSchool/${idSchool}`;
    return this.defaultGet(apiUrl);
  }

}
