<section class="newsListEveryWeek" *ngIf="listNews.length > 0">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5">
      </div>
    </div>
    <div class="section-title">
      <h2>Tin tức - Bài viết mới nhất</h2>
      <!-- <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div> -->
    </div>
    <div class="row mt-2">
      <ng-container *ngFor="let item of listNews">
        <div class="md:col-6 sm:col-12 lg:col-6">
          <div class="single-teachers-box-list">
            <div class="teachers-image">
              <a routerLink="/news/detail/{{item.instanceId}}">
                <img src="{{item.imageUrl}}" alt="" style="width: 197px;">
              </a>
            </div>
            <div class="teachers-content">
              <a routerLink="/news/detail/{{item.instanceId}}">
                <h3 class="title">
                  {{item.title}}
                </h3>
              </a>
              <div class="description">
                {{item.subject}}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>