import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SecondPageEditBase, SvCorePublicService } from 'sv-shared';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends SecondPageEditBase implements OnInit {

  email = '';
  constructor(
    private _svCorePublicService: SvCorePublicService,
    protected _injector: Injector,
    private formBuilder: FormBuilder,
  ) {
    super(null, _injector);
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
  }

  onResetPassword() {
    if (this.formGroup.invalid) {
      this.showValidateMessage();
      this._notifierService.showWarning("Bạn chưa nhập email hoặc email không đúng định dạng !");
      return;
    }
    if (this.email) {
      var model = {
        email: this.email
      }
      this._svCorePublicService.resetPassword(model).then(rs => {
        if (rs.success) {
          this._notifierService.showSuccess("Chúng tôi đã gửi hướng dẫn thiết lập lại mật khẩu vào email của bạn!");
        }
      })
    } else {
      this._notifierService.showWarning("Bạn chưa nhập email")
    }
  }

}
