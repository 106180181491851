import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.financialEndpoint}/promotion`);
  }

  find(model: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/Find`;
    return this._http
      .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  getByCode(code: string): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetByCode/${code}`;
    return this.defaultGet(apiUrl);
  }

  getsAllActive(): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsAllActive`;
    return this.defaultGet(apiUrl);
  }

}
