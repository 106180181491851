<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li><a routerLink="/profile">Thông tin cá nhân</a></li>
        <li>Lịch sử thanh toán</li>
      </ul>
      <h2>Lịch sử thanh toán</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<div class="container mt-6 mb-6">
  <div class="row">
    <p-table [value]="dataSource" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
        <tr>
          <!-- <th style="width:100px">STT</th> -->
          <th>Mã hóa đơn</th>
          <th>Tổng số tiền thanh toán</th>
          <th>Trạng thái thanh toán</th>
          <th>Ngày tạo</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <!-- <td></td> -->
          <td>{{ rowData.code }}</td>
          <td>{{ rowData.totalPrice }}</td>
          <td>{{ getTitleIsPaid(rowData.isPaid) }}</td>
          <td>{{ rowData.paymentDate | date :'dd/MM/yyyy hh:mm:ss' }}</td>
        </tr>
        <tr>
          <td colspan="4">
            <ng-container *ngFor="let item of rowData.eInvoiceCourse">
              <div class="row">
                <a routerLink="/courses/detail/{{item.instanceId}}">{{ item.titleCourse }}</a>
              </div>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
