<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li><a routerLink="/profile">Thông tin cá nhân</a></li>
        <li>Bảng xếp hạng</li>
      </ul>
      <h2>Bảng xếp hạng</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<div class="container mt-6 mb-6">
  <div class="row">
    <div class="md:col-4 sm:col-12">
      <div class="row flex justify-content-center">
        <div class="col-12 mb-4 flex align-items-center justify-content-center">
          <img src="assets/images/medal.png" alt="medal">
        </div>
        <div class="col-12 mb-4 flex align-items-center justify-content-center">
          <img src="assets/images/user/user7.png" alt="medal">
        </div>
        <div class="flex justify-content-center rank-tag">
          <p-tag value="1/100" [rounded]="true" severity="success"></p-tag>
        </div>
        <div class="mb-4 text-center">
          <h5>Nguyễn Anh Tú</h5>
          <div class="email">tuna@abc.com</div>
        </div>
        <div class="mb-4 text-center">
          <button pButton class="primary-alt-btn w-20rem" routerLink="/profile">Thông tin tài hoản <span></span>
          </button>
        </div>
        <div class="mb-4 text-center">
          <button pButton class="default-btn w-20rem" routerLink="/change-password">Đổi mật khẩu <span></span> </button>
        </div>
      </div>
    </div>
    <div class="md:col-8 sm:col-12">
      <div class="row p-fluid">
        <div class="col-12">
          <div class="p-inputgroup">
            <input pInputText />
            <p-button icon="pi pi-search" styleClass="p-button-success border-noround"></p-button>
          </div>
        </div>
        <div class="col-12">
          <p-table [columns]="cols" [value]="listMembers" styleClass="p-datatable-striped" [scrollable]="true"
            responsiveLayout="stack" [tableStyle]="{'min-width': '50rem'}">
            <ng-template pTemplate="colgroup">
              <colgroup>
                <ng-container *ngFor="let col of cols">
                  <col [style.width]="col.width ? col.width : 'auto'" [hidden]="!col.visible">
                </ng-container>
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <ng-container *ngFor="let col of columns">
                  <th [hidden]="!col.visible" class="bg-rank" [class]="col.align">
                    <div class="h-overflow-break-word">{{col.header}}</div>
                  </th>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
              <tr>
                <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field" [class]="col.align">
                  <span *ngSwitchCase="'rank'" innerHTML="{{rowData.rank | rank}}">
                  </span>
                  <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
