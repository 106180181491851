<section class="courses-outstanding-section container" *ngIf="dataSource.length > 0">
  <div class="section-title">
    <h2>Các khóa học nổi bật</h2>
    <!-- <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div> -->
  </div>
  <div class="row">
    <ng-container *ngFor="let item of dataSource">
      <div class="lg:col-3 md:col-6 sm:col-12">
        <app-single-course-box-grid [item]="item"></app-single-course-box-grid>
      </div>
    </ng-container>
  </div>
</section>
