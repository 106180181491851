<ng-container *ngIf="isShowPopper">
  <div class="single-courses-box-grid" [popper]="popperCourse" [popperTrigger]="'hover'" [popperPlacement]="'right'">
    <div class="courses-image">
      <a routerLink="/courses/detail/{{item.instanceId}}">
        <img src="{{item.imageUrl}}" alt="">
      </a>
    </div>
    <div class="courses-content">
      <a routerLink="/courses/detail/{{item.instanceId}}">
        <h3 class="title">{{item.title}}</h3>
      </a>
      <a routerLink="/authors/detail/{{item.idTenant}}">
        <h5 class="author">{{item.displayNameTenant}}</h5>
      </a>
      <div class="row">
        <div class="price-text md:col-4 sm:col-12">
          <div class="original-price" *ngIf="item.price > 0">{{item.price | currency: 'VND'}}</div>
          <div class="original-price" *ngIf="item.price == 0">Miễn phí</div>
          <div class="discount-price">{{item.priceDiscount}}</div>
        </div>
        <div class="courses-rating md:col-8 sm:col-12">
          <ng-rating-bar [value]="5" [ratingCount]="item.avgRatingStar ?? 5" colorActive="#F2A000" colorDefault="gray"
            [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
          <span class="reviews">({{item.countComment ?? 0}})</span>
        </div>
      </div>
      <div class="courses-info"><span class="courses-time">Độ tuổi: {{{ageFrom: item.ageFrom, ageTo: item.ageTo} |
          age}}</span> · <span>{{item.titleCatLevel == null ? "Tất cả các trình độ": item.titleCatLevel}}</span></div>
    </div>
  </div>
  <popper-content #popperCourse>
    <app-single-course-popper [item]="item"></app-single-course-popper>
  </popper-content>
</ng-container>
<ng-container *ngIf="!isShowPopper">
  <div class="single-courses-box-grid">
    <div class="courses-image">
      <a routerLink="/courses/detail/{{item.instanceId}}">
        <img src="{{item.imageUrl}}" alt="">
      </a>
    </div>
    <div class="courses-content">
      <a routerLink="/courses/detail/{{item.instanceId}}">
        <h3 class="title">{{item.title}}</h3>
      </a>
      <a routerLink="/authors/detail/{{item.idTenant}}">
        <h5 class="author">{{item.displayNameTenant}}</h5>
      </a>
      <div class="row">
        <div class="price-text md:col-4 sm:col-12">
          <div class="original-price" *ngIf="item.price > 0">{{item.price | currency: 'VND'}}</div>
          <div class="original-price" *ngIf="item.price == 0">Miễn phí</div>
          <div class="discount-price">{{item.priceDiscount}}</div>
        </div>
        <div class="courses-rating md:col-8 sm:col-12">
          <ng-rating-bar [value]="5" [ratingCount]="item.avgRatingStar ?? 5" colorActive="#F2A000" colorDefault="gray"
            [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
          <span class="reviews">({{item.countComment ?? 0}})</span>
        </div>
      </div>
      <div class="courses-info"><span class="courses-time">Độ tuổi: {{{ageFrom: item.ageFrom, ageTo: item.ageTo} |
          age}}</span> · <span>{{item.titleCatLevel == null ? "Tất cả các trình độ": item.titleCatLevel}}</span></div>
    </div>
  </div>
</ng-container>