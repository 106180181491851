<div class="container pt-5 pb-5">
  <div class="row p-fluid">
    <div class="md:col-4 mt-8">
      <div class="col-12">
        <div style="text-align: center">
          <h2>Đặt lại mật khẩu?</h2>
          <p>Nhập đủ thông tin bên dưới để chúng tôi thiết lập lại cho bạn</p>
        </div>
        <div class="field">
          <span class="p-input-icon-left">
            <i class="pi pi-envelope"></i>
            <input pInputText type="password" placeholder="" [(ngModel)]="newPassword" />
          </span>
        </div>
        <div class="field">
          <button class="default-btn w-100 h-3rem" (click)="doResetPassword()">Đặt lại mật khẩu<span></span></button>
        </div>
      </div>
    </div>
    <div class="md:col-8">
      <img src="assets/images/sign-in.png" alt="image">
    </div>
  </div>
</div>
