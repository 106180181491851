import { Component, Input, OnInit } from '@angular/core';
import { CourseService } from '../../../services/course.service';

@Component({
  selector: 'app-section-hot-courses',
  templateUrl: './section-hot-courses.component.html',
  styleUrls: ['./section-hot-courses.component.scss']
})
export class SectionHotCoursesComponent implements OnInit {
  dataSource = [];
  @Input() idSysSchool: number = 0;
  constructor(
    private _courseService: CourseService
  ) { }

  ngOnInit() {
    this.getsHotCourse();
  }

  getsHotCourse() {
    this._courseService.getsHotCourse(this.idSysSchool, 8).then(rs => {
      if (rs.success) {
        this.dataSource = rs.data;
      }
    })
  }

}
