import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../../services/course.service';
import { CategoryLearningStatus } from '../../../config/enums';
import { Router } from '@angular/router';
import { UserCertificateService } from '../../../services/user-certificate.service';
import { ExamUserService } from '../../../services/exam-user.service';
import { AuthenticationService, BasicUserInfo } from 'sv-shared';

@Component({
  selector: 'app-learning-history',
  templateUrl: './learning-history.component.html',
  styleUrls: ['./learning-history.component.scss']
})
export class LearningHistoryComponent implements OnInit {

  listMyLearningCourse = [];
  listMyCertificate = [];
  listMyExamHistory = [];
  listMyFinishCourse = [];
  listMyCourseHaventStudy = [];

  currentUser = new BasicUserInfo();

  constructor(
    private _courseService: CourseService,
    private _userCertificateService: UserCertificateService,
    private _router: Router,
    private _examUserService: ExamUserService,
    private _authenticationService: AuthenticationService,
  ) {
    this.currentUser = this._authenticationService.getCurrentUser();
    if (!this.currentUser) {
      this._router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.getsMyCourse();
    this.getMyfinishCourse();
    this.getsMyCertificate();
    this.getsMyExamHistory();
    this.getsMyCourseHaventStudy();
  }

  getsMyCourseHaventStudy() {
    this._courseService.getsMyCourseHaventStudy().then(rs => {
      if (rs.success) {
        this.listMyCourseHaventStudy = rs.data;
      }
    })
  }

  getsMyCourse() {
    this._courseService.getsMyStudyCourse(CategoryLearningStatus.IsLearing).then(rs => {
      if (rs.success) {
        this.listMyLearningCourse = rs.data;
      }
    })
  }

  getMyfinishCourse() {
    this._courseService.getsMyStudyCourse(CategoryLearningStatus.IsFinish).then(rs => {
      if (rs.success) {
        this.listMyFinishCourse = rs.data;
      }
    })
  }

  getsMyCertificate() {
    this._userCertificateService.getsMyCertificate().then(rs => {
      if (rs.success) {
        this.listMyCertificate = rs.data;
      }
    })
  }

  getsMyExamHistory() {
    this._examUserService.getsMyExamHistory().then(rs => {
      if (rs.success) {
        this.listMyExamHistory = rs.data;
      }
    })
  }

  goLearningSpace(courseId) {
    this._router.navigate(['learning-space', courseId]);
  }
  downloadCertificate(instanceId: string) {
    this._userCertificateService.downloadCertificate(instanceId);
  }
}
