import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-document-list',
  templateUrl: './single-document-list.component.html',
  styleUrls: ['./single-document-list.component.scss']
})
export class SingleDocumentListComponent implements OnInit {

  @Input() item: any
  constructor() { }

  ngOnInit() {
  }

}
