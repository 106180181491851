<div class="ws-page-body-title"><span class="ws-img-icon ws-page-body-title__icon">
    <svg>
      <use href="\assets\images\icon\book-o.svg#icon"></use>
    </svg></span>
  <div class="ws-page-body-title__text">Nội dung học tập</div>
</div>
<div class="ws-page-body-content ws-l-page-body-content">
  <ng-container *ngFor="let node of courseItems">
    <ng-container *ngIf="node.idCourseItemType == 1">
      <div class="ws-space-learning-item ws-act--toggle-block--container">
        <div class="ws-space-learning-item__header ws-act--toggle-block--trigger">
          <span class="ws-img-icon ws-space-learning-item__main-icon">
            <svg>
              <use href="\assets\images\icon\menu-board.svg#icon"></use>
            </svg></span>
          <div class="ws-space-learning-item__name">{{node.label}}</div>
        </div>
        <ng-container *ngFor="let node1 of node.children">
          <ng-container *ngIf="node1.idCourseItemType == 1">
            <div class="ws-space-learning-item ws-act--toggle-block--container">
              <div class="ws-space-learning-item__header ws-act--toggle-block--trigger">
                <span class="ws-img-icon ws-space-learning-item__main-icon">
                  <svg>
                    <use href="\assets\images\icon\menu-board.svg#icon"></use>
                  </svg></span>
                <div class="ws-space-learning-item__name">{{node1.label}}</div>
              </div>
              <ng-container *ngFor="let node2 of node1.children">
                <ng-container *ngIf="node2.idCourseItemType == 1">
                  <div class="ws-space-learning-item ws-act--toggle-block--container">
                    <div class="ws-space-learning-item__header ws-act--toggle-block--trigger">
                      <span class="ws-img-icon ws-space-learning-item__main-icon">
                        <svg>
                          <use href="\assets\images\icon\menu-board.svg#icon">
                          </use>
                        </svg></span>
                      <div class="ws-space-learning-item__name">{{node2.label}}</div>
                    </div>
                    <ng-container *ngFor="let node3 of node2.children">

                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="node2.idCourseItemType != 1">
                  <div class="ws-space-learning-process-item cp" [class.-required]="!node2.isLearned"
                    [class.-completed]="node2.isLearned" *ngIf="node2.idCourseItemType != 1">
                    <div class="ws-space-learning-process-item-part-1" (click)=(viewCourseItem(node2))>
                      <span class="ws-img-icon ws-space-learning-process-item__main-icon">
                        <svg>
                          <use href="\assets\images\icon\video.svg#icon"></use>
                        </svg></span>
                      <div class="ws-space-learning-process-item__name">
                        {{node2.label}}</div>
                      <div class="ws-space-learning-process-item__status">
                        {{node2.requiredToComplete ? 'Bắt buộc hoàn thành' :
                        (node2.isLearned ? 'Đã hoàn thành' : 'Cần hoàn thành')}}
                      </div>
                    </div>
                    <div class="ws-space-learning-process-item-part-2" (click)=(viewCourseItem(node2))>
                      <div class="ws-progressbar -size-1 -thumb-size-2 ws-space-learning-process-item__progressbar">
                        <div class="ws-progressbar__slide" [style.width.%]="node2.learningRate">
                        </div>
                        <div class="ws-progressbar__thumb" [style.left.%]="node2.learningRate">
                          <!-- <div class="ws-progressbar__thumb-label">
                            {{node2.isLearned ?
                            '100%' :
                            '0%'}}</div> -->
                        </div>
                      </div>
                    </div>
                    <div class="ws-space-learning-process-item-part-3">
                      <div class="ws-learning-process-item__during" *ngIf="node2.totalTime > 0">
                        {{node2.totalTime}}
                        ({{'phút' | translate}})</div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="node1.idCourseItemType != 1">
            <div class="ws-space-learning-process-item cp" [class.-required]="!node1.isLearned"
              [class.-completed]="node1.isLearned" *ngIf="node1.idCourseItemType != 1">
              <div class="ws-space-learning-process-item-part-1" (click)=(viewCourseItem(node1))>
                <span class="ws-img-icon ws-space-learning-process-item__main-icon">
                  <svg>
                    <use href="\assets\images\icon\video.svg#icon"></use>
                  </svg></span>
                <div class="ws-space-learning-process-item__name">{{node1.label}}</div>
                <div class="ws-space-learning-process-item__status">
                  {{node1.requiredToComplete ? 'Bắt buộc hoàn thành' : (node1.isLearned ?
                  'Đã hoàn thành' : 'Cần hoàn thành')}}
                </div>
              </div>
              <div class="ws-space-learning-process-item-part-2" (click)=(viewCourseItem(node1))>
                <div class="ws-progressbar -size-1 -thumb-size-2 ws-space-learning-process-item__progressbar">
                  <div class="ws-progressbar__slide" [style.width.%]="node1.learningRate">
                  </div>
                  <div class="ws-progressbar__thumb" [style.left.%]="node1.learningRate">
                    <div class="ws-progressbar__thumb-label">{{node1.learningRate}}%</div>
                  </div>
                </div>
              </div>
              <div class="ws-space-learning-process-item-part-3">
                <div class="ws-learning-process-item__during" *ngIf="node1.totalTime > 0">
                  {{node1.totalTime}}
                  ({{'phút' | translate}})</div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="node.idCourseItemType != 1">
      <div class="ws-space-learning-process-item cp" [class.-required]="!node.isLearned"
        [class.-completed]="node.isLearned" *ngIf="node.idCourseItemType != 1">
        <div class="ws-space-learning-process-item-part-1" (click)=(viewCourseItem(node))>
          <span class="ws-img-icon ws-space-learning-process-item__main-icon">
            <svg>
              <use href="\assets\images\icon\video.svg#icon"></use>
            </svg></span>
          <div class="ws-space-learning-process-item__name">{{node.label}}</div>
          <div class="ws-space-learning-process-item__status">
            <!-- {{node.isLearned ? 'Đã hoàn thành' : 'Cần hoàn thành'}} -->
            {{node.requiredToComplete ? 'Bắt buộc hoàn thành' : (node.isLearned ? 'Đã hoàn thành'
            : 'Cần hoàn thành')}}
          </div>
        </div>
        <div class="ws-space-learning-process-item-part-2" (click)=(viewCourseItem(node))>
          <div class="ws-progressbar -size-1 -thumb-size-2 ws-space-learning-process-item__progressbar">
            <div class="ws-progressbar__slide" [style.width.%]="node.learningRate">
            </div>
            <div class="ws-progressbar__thumb" [style.left.%]="node.learningRate">
              <div class="ws-progressbar__thumb-label">{{node.learningRate}} %</div>
            </div>
          </div>
        </div>
        <div class="ws-space-learning-process-item-part-3">
          <div class="ws-learning-process-item__during" *ngIf="node.totalTime > 0">
            {{node.totalTime}}
            ({{'phút' | translate}})</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>