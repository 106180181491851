import { BaseModel } from './base';

export class ExamUser extends BaseModel {

  idExam: number;
  displayName: string;
  email: string;
  countDown: number;
  startTime: Date;
  endTime: Date;
  numQTrue: number;
  numQFalse: number;
  numQNoAns: number;
  instanceId: string;
  examStatus: number;
}
