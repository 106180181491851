import { Component, Injector, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { distinct, from, map, takeUntil } from 'rxjs';
import { SecondPageIndexBase } from 'sv-shared';
import { CategoryType } from '../../config/enums';
import { CategoriesService } from '../../services/categories.service';
import { CourseService } from '../../services/course.service';
import { NewsService } from '../../services/news.service';
import { SysSchoolService } from '../../services/sys-school.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { ExamService } from '../../services/exam.service';

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.scss']
})
export class ExamsComponent extends SecondPageIndexBase implements OnInit {


  selectedCategories = [];
  listCategories = [];
  listSubCategories = [];
  idSysSchool = 0;
  avgRatingStar = 0.0;


  isListView = true;
  isShowFilter = true;
  showCategories = true;
  showSubCategories = true;


  showRating = true;
  showLanguages = true;
  selectedValues = [];
  ingredient = 0;

  constructor(
    protected _examService: ExamService,
    protected _injector: Injector,
    protected _categoriesService: CategoriesService,
    private _sysSchoolService: SysSchoolService,
  ) {
    super(_examService, _injector);

  }
  async ngOnInit() {
    this.idSysSchool = this._sysSchoolService.getSelectedSchool();
    this.pageSize = 12;

    this._activatedRoute.queryParams.subscribe((params) => {
      let keyword = params['keyword'];
      if (keyword) {
        this.query = keyword;
      }
    });
    this._activatedRoute.queryParams.subscribe((params) => {
      let keyword = params['keyword'];
      if (keyword) {
        this.query = keyword;
        this.loadData();
        return;
      }
    });

    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.selectedCategories = [pid];
          this.loadData();
          return;
        }
      });
    this.loadData();
  }

  loadData() {
    this.getCategories();
    this.getData();
  }

  async getData() {
    this.dataSource = [];
    var searchModel = {
      keyword: this.query,
      idSysSchool: this.idSysSchool,
      listIdCategory: this.selectedCategories,
      avgRatingStar: this.avgRatingStar,
      status: 1,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      orderCol: this.orderCol ? this.orderCol : 'Id',
      isDesc: true,
      offset: (this.pageIndex - 1) * this.pageSize,
    };
    await this._examService.find(searchModel)
      .then(response => {
        this.dataSource = response.data;
        this.totalRecord = response.totalRecord;
      }, error => {
        this.isLoading = false;
        this._notifierService.showHttpUnknowError();
      });
  }

  changeOrderBy(orderCol: string) {
    this.isDesc = true;
    this.orderCol = orderCol;
    this.getData();
  }

  getCategories() {
    this._categoriesService.getTree(this.idSysSchool, CategoryType.Testing).then(rs => {
      if (rs.success) {
        this.listCategories = rs.data;
        rs.data.forEach(element => {
          element.children.forEach(item => {
            if (this.listSubCategories.find(x => x.id == item.id) == null) {
              this.listSubCategories.push(item);
            }
          });
        });
      }
    })
  }

  filterByKeyword(keyword: string) {
    this.query = keyword;
    this._router.navigateByUrl(`/courses?keyword=${keyword}`);
  }

  onGoPage($event) {
    this.pageIndex = $event;
    this.getData();
  }


}
