<div class="ws-page-container ws-page-layout--space" [class.-menu-expanded-in-large-screen]="!isCollapsed"
  [class.-menu-collapsed-in-large-screen]="isCollapsed" [class.-menu-expanded-in-small-screen]="!isCollapsedSmall"
  [class.-menu-collapsed-in-small-screen]="isCollapsedSmall">
  <div class="ws-page-menu-area">
    <div class="ws-page-menu-area-inner">
      <div class="ws-page-menu-logo-block">
        <span class="ws-img-icon ws-page-menu-toggle ws-page-menu-toggle-right">
          <svg>
            <use href="\assets\images\icon\square-arrow-right.svg#icon"></use>
          </svg>
        </span>
        <a class="ws-page-menu-logo-wrapper">
          <img class="ws-page-menu-logo" src="assets/images/logo/logo-white.png" alt="Logo">
        </a>
        <span class="ws-img-icon ws-page-menu-toggle ws-page-menu-toggle-left" (click)="isCollapsed = !isCollapsed">
          <svg class="-left">
            <use href="/assets/images/icon/square-arrow-left.svg#icon"></use>
          </svg>
          <svg class="-right">
            <use href="/assets/images/icon/square-arrow-right.svg#icon"></use>
          </svg>
        </span>
      </div>
      <div class="ws-page-menu-user-block">
        <img class="ws-page-menu-user__avatar" src="{{currentUser?.avatar | avatar}}" alt="User">
        <div class="ws-page-menu-user__name">{{currentUser?.displayName}}</div>
      </div>
      <div class="ws-page-menu-wrapper">
        <ul class="ws-page-menu">
          <li class="ws-page-menu-item ws-page-menu-item--level-1" [class.is-activated]="tabId == 6">
            <a class="ws-page-menu-item-link" (click)="goTab(6)">
              <span class="ws-img-icon ws-page-menu-item-link__main-icon">
                <svg>
                  <use href="\assets\images\icon\home.svg#icon"></use>
                </svg>
              </span>
              <span class="ws-page-menu-item-link__label">Trang chủ</span>
            </a>
          </li>
          <li class="ws-page-menu-item ws-page-menu-item--level-1" [class.is-activated]="tabId == 1"><a
              class="ws-page-menu-item-link" (click)="goTab(1)">
              <span class="ws-img-icon ws-page-menu-item-link__main-icon">
                <svg>
                  <use href="\assets\images\icon\book.svg#icon"></use>
                </svg></span><span class="ws-page-menu-item-link__label">Nội dung học tập</span>
            </a>
          </li>
          <li class="ws-page-menu-item ws-page-menu-item--level-1" [class.is-activated]="tabId == 5">
            <a class="ws-page-menu-item-link" (click)="goTab(5)">
              <span class="ws-img-icon ws-page-menu-item-link__main-icon">
                <svg>
                  <use href="\assets\images\icon\video.svg#icon"></use>
                </svg>
              </span>
              <span class="ws-page-menu-item-link__label">Học trực tuyến
              </span>
            </a>
          </li>
          <li class="ws-page-menu-item ws-page-menu-item--level-1 is-notified" [class.is-activated]="tabId == 3">
            <a class="ws-page-menu-item-link" (click)="goTab(3)">
              <span class="ws-img-icon ws-page-menu-item-link__main-icon">
                <svg>
                  <use href="\assets\images\icon\reply.svg#icon"></use>
                </svg></span>
              <span class="ws-page-menu-item-link__label">
                Trao đổi Thảo luận
              </span>
              <span class="ws-img-icon ws-page-menu-item-link__toggle-icon">
                <svg>
                  <use href="\assets\images\icon\arrow-right.svg#icon"></use>
                </svg>
              </span>
            </a>

          </li>
          <li class="ws-page-menu-item ws-page-menu-item--level-1 " [class.is-activated]="tabId == 7">
            <a class="ws-page-menu-item-link" (click)="goTab(7)">
              <span class="ws-img-icon ws-page-menu-item-link__main-icon">
                <svg>
                  <use href="\assets\images\icon\note-2.svg#icon"></use>
                </svg>
              </span>
              <span class="ws-page-menu-item-link__label">Tài nguyên lớp học</span><span
                class="ws-img-icon ws-page-menu-item-link__toggle-icon">
                <svg>
                  <use href="\assets\images\icon\arrow-right.svg#icon"></use>
                </svg>
              </span>
            </a>
          </li>
          <li class="ws-page-menu-item ws-page-menu-item--level-1 " [class.is-activated]="tabId == 2">
            <a class="ws-page-menu-item-link" (click)="goTab(2)">
              <span class="ws-img-icon ws-page-menu-item-link__main-icon">
                <svg>
                  <use href="\assets\images\icon\award-menu.svg#icon"></use>
                </svg></span><span class="ws-page-menu-item-link__label">Kết quả học tập</span>
            </a>
          </li>
        </ul>
      </div>
      <!-- <div class="ws-page-menu-support-block"><a class="ws-page-menu-support-link" href="#">
          <span class="ws-img-icon ws-page-menu-support-link__icon">
            <svg>
              <use href="\assets\images\icon\ear-phone.svg#icon"></use>
            </svg>
          </span>
          <span class="ws-page-menu-support-link__label">Hỗ trợ</span>
        </a>
      </div> -->
    </div>
  </div>
  <div class="ws-page-header-area">
    <div class="ws-page-header-action ws-page-header-menu-toggle ws-page-header-item h-hidden--lg-min">
      <span class="ws-img-icon ws-page-header-action__icon" (click)="isCollapsedSmall = !isCollapsedSmall">
        <svg>
          <use href="\assets\images\icon\app-menu.svg#icon"></use>
        </svg>
      </span>
    </div>
    <a class="ws-page-header-logo-wrapper ws-page-header-item h-hidden--lg-min">
      <img class="ws-page-header-logo" src="assets/images/logo.png" alt="Logo">
    </a>
    <div class="ws-page-header-title ws-page-header-item"><span class="ws-img-icon ws-page-header-title__icon">
        <svg>
          <use href="\assets\images\icon\book-o.svg#icon"></use>
        </svg>
      </span>
      <div class="ws-page-header-title__text">{{courseDetail.title}}</div>
    </div>
    <div class="ws-page-header-user ws-page-header-item">
      <a class="header__btn" type="button" (click)="exitLearningSpace()">
        <img class="ws-page-header-user__avatar" src="\assets\images\icon\close-circle.svg" alt="User">
      </a>
    </div>
  </div>
  <div class="ws-page-body-area">
    <ng-container *ngIf="tabId == 7">
      <app-learning-space-v3-resource></app-learning-space-v3-resource>
    </ng-container>
    <ng-container *ngIf="tabId == 6">
      <app-learning-space-v3-home [courseUserInfo]="courseUserInfo" [courseDetail]="courseDetail"
        (goAction)="goActionHome($event)">
      </app-learning-space-v3-home>
    </ng-container>
    <ng-container *ngIf="tabId == 5">
      <app-learning-space-v3-liveclass [idCourse]="idCourse" [isTeacher]="isTeacher">
      </app-learning-space-v3-liveclass>
    </ng-container>
    <ng-container *ngIf="tabId == 3">
      <app-learning-space-v3-forum [courseDetail]="courseDetail" [isTeacher]="isTeacher" [notifier]="topicNotifier">
      </app-learning-space-v3-forum>
    </ng-container>
    <ng-container *ngIf="tabId == 2">
      <app-learning-space-v3-result [courseDetail]="courseDetail"></app-learning-space-v3-result>
    </ng-container>
    <ng-container *ngIf="tabId == 1">
      <div class="ws-page-body-title"><span class="ws-img-icon ws-page-body-title__icon">
          <svg>
            <use href="\assets\images\icon\book-o.svg#icon"></use>
          </svg></span>
        <div class="ws-page-body-title__text">Nội dung học tập</div>
      </div>
      <div class="ws-page-body-content ws-l-page-body-content">
        <ng-container *ngFor="let node of courseItems">
          <ng-container *ngIf="node.idCourseItemType == 1">
            <div class="ws-space-learning-item ws-act--toggle-block--container">
              <div class="ws-space-learning-item__header ws-act--toggle-block--trigger">
                <span class="ws-img-icon ws-space-learning-item__main-icon">
                  <svg>
                    <use href="\assets\images\icon\menu-board.svg#icon"></use>
                  </svg></span>
                <div class="ws-space-learning-item__name">{{node.label}}</div>
              </div>
              <ng-container *ngFor="let node1 of node.children">
                <ng-container *ngIf="node1.idCourseItemType == 1">
                  <div class="ws-space-learning-item ws-act--toggle-block--container">
                    <div class="ws-space-learning-item__header ws-act--toggle-block--trigger">
                      <span class="ws-img-icon ws-space-learning-item__main-icon">
                        <svg>
                          <use href="\assets\images\icon\menu-board.svg#icon"></use>
                        </svg></span>
                      <div class="ws-space-learning-item__name">{{node1.label}}</div>
                    </div>
                    <ng-container *ngFor="let node2 of node1.children">
                      <ng-container *ngIf="node2.idCourseItemType == 1">
                        <div class="ws-space-learning-item ws-act--toggle-block--container">
                          <div class="ws-space-learning-item__header ws-act--toggle-block--trigger">
                            <span class="ws-img-icon ws-space-learning-item__main-icon">
                              <svg>
                                <use href="\assets\images\icon\menu-board.svg#icon">
                                </use>
                              </svg></span>
                            <div class="ws-space-learning-item__name">{{node2.label}}</div>
                          </div>
                          <ng-container *ngFor="let node3 of node2.children">

                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="node2.idCourseItemType != 1">
                        <div class="ws-space-learning-process-item cp" [class.-required]="!node2.isLearned"
                          [class.-completed]="node2.isLearned" *ngIf="node2.idCourseItemType != 1">
                          <div class="ws-space-learning-process-item-part-1" (click)=(beforeViewCourseItem(node2))>
                            <span class="ws-img-icon ws-space-learning-process-item__main-icon">
                              <svg>
                                <use href="\assets\images\icon\video.svg#icon"></use>
                              </svg></span>
                            <div class="ws-space-learning-process-item__name">
                              {{node2.label}}</div>
                            <div class="ws-space-learning-process-item__status">
                              {{node2.requiredToComplete ? 'Bắt buộc hoàn thành' :
                              (node2.isLearned ? 'Đã hoàn thành' : 'Cần hoàn thành')}}
                            </div>
                          </div>
                          <div class="ws-space-learning-process-item-part-2" (click)=(beforeViewCourseItem(node2))>
                            <div
                              class="ws-progressbar -size-1 -thumb-size-2 ws-space-learning-process-item__progressbar">
                              <div class="ws-progressbar__slide" [style.width.%]="node2.learningRate">
                              </div>
                              <div class="ws-progressbar__thumb" [style.left.%]="node2.learningRate">
                                <div class="ws-progressbar__thumb-label">
                                  {{node2.learningRate}}%
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="ws-space-learning-process-item-part-3">
                            <div class="ws-learning-process-item__during" *ngIf="node2.totalTime > 0">
                              {{node2.totalTime}}
                              ({{'phút' | translate}})</div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="node1.idCourseItemType != 1">
                  <div class="ws-space-learning-process-item cp" [class.-required]="!node1.isLearned"
                    [class.-completed]="node1.isLearned" *ngIf="node1.idCourseItemType != 1">
                    <div class="ws-space-learning-process-item-part-1" (click)=(beforeViewCourseItem(node1))>
                      <span class="ws-img-icon ws-space-learning-process-item__main-icon">
                        <svg>
                          <use href="\assets\images\icon\video.svg#icon"></use>
                        </svg></span>
                      <div class="ws-space-learning-process-item__name">{{node1.label}}</div>
                      <div class="ws-space-learning-process-item__status">
                        {{node1.requiredToComplete ? 'Bắt buộc hoàn thành' : (node1.isLearned ?
                        'Đã hoàn thành' : 'Cần hoàn thành')}}
                      </div>
                    </div>
                    <div class="ws-space-learning-process-item-part-2" (click)=(beforeViewCourseItem(node1))>
                      <div class="ws-progressbar -size-1 -thumb-size-2 ws-space-learning-process-item__progressbar">
                        <div class="ws-progressbar__slide" [style.width.%]="node1.learningRate">
                        </div>
                        <!-- <div class="ws-progressbar__thumb" [style.left.%]="node1.isLearned ? 100 : 0">
                          <div class="ws-progressbar__thumb-label">{{node1.isLearned ?
                            '100%' :
                            '0%'}}</div>
                        </div> -->
                      </div>
                    </div>
                    <div class="ws-space-learning-process-item-part-3">
                      <div class="ws-learning-process-item__during" *ngIf="node1.totalTime > 0">
                        {{node1.totalTime}}
                        ({{'phút' | translate}})</div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="node.idCourseItemType != 1">
            <div class="ws-space-learning-process-item cp" [class.-required]="!node.isLearned"
              [class.-completed]="node.isLearned" *ngIf="node.idCourseItemType != 1">
              <div class="ws-space-learning-process-item-part-1" (click)=(beforeViewCourseItem(node))>
                <span class="ws-img-icon ws-space-learning-process-item__main-icon">
                  <svg>
                    <use href="\assets\images\icon\video.svg#icon"></use>
                  </svg></span>
                <div class="ws-space-learning-process-item__name">{{node.label}}</div>
                <div class="ws-space-learning-process-item__status">
                  <!-- {{node.isLearned ? 'Đã hoàn thành' : 'Cần hoàn thành'}} -->
                  {{node.requiredToComplete ? 'Bắt buộc hoàn thành' : (node.isLearned ? 'Đã hoàn thành'
                  : 'Cần hoàn thành')}}
                </div>
              </div>
              <div class="ws-space-learning-process-item-part-2" (click)=(beforeViewCourseItem(node))>
                <div class="ws-progressbar -size-1 -thumb-size-2 ws-space-learning-process-item__progressbar">
                  <div class="ws-progressbar__slide" [style.width.%]="node.learningRate">
                  </div>
                  <div class="ws-progressbar__thumb" [style.left.%]="node.learningRate">
                    <div class="ws-progressbar__thumb-label">{{node.learningRate}}%</div>
                  </div>
                </div>
              </div>
              <div class="ws-space-learning-process-item-part-3">
                <div class="ws-learning-process-item__during" *ngIf="node.totalTime > 0">
                  {{node.totalTime}}
                  ({{'phút' | translate}})</div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="tabId > 10 && tabId < 20">
      <div class="ws-page-body-title"><span class="ws-img-icon ws-page-body-title__icon">
          <svg>
            <use href="\assets\images\icon\book-o.svg#icon"></use>
          </svg></span>
        <div class="ws-page-body-title__text">{{courseItemDetail.title}}</div>
      </div>
      <div class="ws-1-page-body-content-lecture ws-l-page-body-content">
        <div class="ws-l-main-area">
          <div class="ws-l-main-area-content">
            <!-- VIDEO -->
            <div class="col-lg-12 mb-30" *ngIf="tabId == 11">

              <div class="row justify-content-center">
                <div class="video embed-responsive embed-responsive-16by9 col-md-8">
                  <video id='sxmvideo' class="video-js vjs-default-skin vjs-16-9" controls>
                  </video>
                </div>
              </div>
            </div>
            <!-- SCORM -->
            <div class="col-md-12 mb-30" *ngIf="tabId == 14 && isViewScorm">
              <div class="d-flex justify-content-center">
                <ng-scorm-player [urlLaunchPage]="scormUrl" class="col-md-8">
                </ng-scorm-player>
                <button class="btn-close ws-btn -size-2 -icon-size-1 ws-bgc--primary-2 ws-color--light" type="submit"
                  (click)="viewFullScreen()" [hidden]="!isViewScormFullscreen">
                  <i class="pi pi-times"></i>
                </button>
              </div>
            </div>
            <!-- YOUTUBE -->
            <div class="col-lg-12 mb-30" *ngIf="courseItemDetail.idCourseItemType == 15">
              <iframe class="video embed-responsive embed-responsive-16by9 col-md-10"
                [src]="youtubeVideoId | safe: 'resourceUrl'" frameborder="0" allowfullscreen></iframe>
            </div>
            <!-- DOCUMENT -->
            <div class="col-lg-12 mb-30" *ngIf="tabId == 12" style="width: 100%; height: 100%; min-height: 800px;">
              <div id="file-viewer"></div>
            </div>
            <ng-container *ngIf="tabId <= 20">
              <ng-container *ngIf="courseItemDetail.contents">
                <div class="ws-heading-1 h-mb-12">
                  <span class="ws-img-icon ws-heading-1__icon">
                    <svg>
                      <use href="\assets\images\icon\link-square.svg#icon"></use>
                    </svg></span>
                  <div class="ws-heading-1__text">{{'Nội dung / giới thiệu' | translate}}</div>
                </div>
                <div class="h-flex-el h-flex-jc--c h-pt-28">
                  <p [innerHTML]="courseItemDetail.contents"></p>
                </div>
              </ng-container>
              <ng-container *ngIf="dsCourseItemAttactment.length > 0">
                <div class="ws-heading-1 h-mb-12">
                  <span class="ws-img-icon ws-heading-1__icon">
                    <svg>
                      <use href="\assets\images\icon\link-square.svg#icon"></use>
                    </svg></span>
                  <div class="ws-heading-1__text">{{'Tài liệu tham khảo' | translate}}</div>
                </div>
                <div class="ws-space-resource-category__body">
                  <ng-container *ngFor="let itemAt of dsCourseItemAttactment; index as i">

                    <div class="ws-space-resource-item">
                      <div class="ws-space-resource-item__left-side"><span
                          class="ws-img-icon ws-space-resource-item__main-icon">
                          <svg>
                            <use href="\assets\images\icon\word.svg#icon"></use>
                          </svg></span>
                        <div class="ws-space-resource-item__meta-wrapper">
                          <div class="ws-space-resource-item__name">
                            <a href="{{itemAt}}" target="_blank">{{i + 1}}. {{itemAt |
                              fileName}}</a>
                          </div>
                        </div>
                      </div>
                      <div class="ws-space-resource-item__right-side">
                        <a href="{{itemAt}}" target="_blank"
                          class="ws-btn -size-3 ws-space-resource-item__action -download"><span
                            class="ws-img-icon ws-btn__icon">
                            <svg>
                              <use href="\assets\images\icon\download.svg#icon"></use>
                            </svg></span>
                          <div class="ws-btn__label">Tải xuống</div>
                        </a>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>

          </div>
          <div class="ws-l-navigation-bar">
            <button class="ws-btn -size-4 -icon-size-2 ws-bgc--transparent" *ngIf="itemPrev.id > 0"
              (click)="beforeViewCourseItem(itemPrev)">
              <div class="ws-btn__label">Bài trước</div>
              <span class="ws-img-icon ws-btn__icon ws-color--primary-2">
                <svg>
                  <use href="\assets\images\icon\rounded-arrow-left.svg#icon"></use>
                </svg>
              </span>
            </button>
            <button class="ws-btn -size-4 -icon-size-2 ws-bgc--transparent" (click)="viewFullScreen()">
              <div class="ws-btn__label"> {{'Xem toàn màn hình' | translate}}</div><span
                class="ws-img-icon ws-btn__icon ws-color--primary-2">
                <svg>
                  <use href="\assets\images\icon\video.svg#icon"></use>
                </svg></span>
            </button>
            <button class="ws-btn -size-4 -icon-size-2 ws-bgc--transparent" *ngIf="isViewVideo" (click)="pauseVideo()">
              <ng-container *ngIf="!isPauseVideo">
                <div class="ws-btn__label"> {{'Tạm dừng' | translate}}</div>
                <span class="ws-img-icon ws-btn__icon ws-color--primary-2">
                  <svg>
                    <use href="\assets\images\icon\video.svg#icon"></use>
                  </svg>
                </span>
              </ng-container>
              <ng-container *ngIf="isPauseVideo">
                <div class="ws-btn__label"> {{'Xem tiếp' | translate}}</div>
                <span class="ws-img-icon ws-btn__icon ws-color--primary-2">
                  <svg>
                    <use href="\assets\images\icon\video-play.svg#icon"></use>
                  </svg>
                </span>
              </ng-container>
            </button>
            <button class="ws-btn -size-4 -icon-size-2 ws-bgc--transparent" *ngIf="itemNext.id > 0"
              (click)="beforeViewCourseItem(itemNext)">
              <span class="ws-img-icon ws-btn__icon ws-color--primary-2">
                <svg>
                  <use href="\assets\images\icon\rounded-arrow-right.svg#icon"></use>
                </svg>
              </span>
              <div class="ws-btn__label">Bài sau</div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tabId == 21 || tabId == 24">
      <div class="ws-page-body-title"><span class="ws-img-icon ws-page-body-title__icon">
          <svg>
            <use href="\assets\images\icon\book-o.svg#icon"></use>
          </svg></span>
        <div class="ws-page-body-title__text">{{courseItemDetail.title}}</div>
      </div>
      <ng-container *ngIf="!isDoingExam">
        <ng-container *ngIf="courseUserLogItem.id == 0">
          <div class="ws-page-body-content h-overflow-y-auto ws-l-content-container">
            <div class="ws-l-block-content-row">
              <!-- <div class="ws-l-block-content">
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Thời gian bắt đầu:</div>
                  <div class="ws-l-meta__value">
                    {{courseItemDetail.startTime | date:'dd/MM/yyyy HH:mm'}}</div>
                </div>
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Thời gian kết thúc:</div>
                  <div class="ws-l-meta__value">{{courseItemDetail.endTime | date:'dd/MM/yyyy HH:mm'}}
                  </div>
                </div>
              </div> -->
              <div class="ws-l-block-content">
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Thời gian làm bài:</div>
                  <div class="ws-l-meta__value">{{courseItemDetail.totalTime}} phút</div>
                </div>
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Số điểm tối thiểu cần đạt được:</div>
                  <div class="ws-l-meta__value">80%</div>
                </div>

              </div>
            </div>
            <div class="ws-l-main-icon-wrapper">
              <button class="ws-btn -size-4 -icon-size-2 ws-bgc--transparent"
                *ngIf="showCanDoTask && !isTeacher && courseUserLogItem.id == 0" (click)="doExam()">
                <span class="ws-img-icon ws-btn__icon ws-color--primary-2"><svg>
                    <use href="\assets\images\icon\rounded-arrow-right.svg#icon"></use>
                  </svg></span>
                <div class="ws-btn__label">{{'Bắt đầu làm bài' | translate}}</div>
              </button>
              <button class="ws-btn -size-4 -icon-size-2 ws-bgc--transparent" *ngIf="!showCanDoTask">
                <div class="ws-btn__label">{{' Chưa đến hoặc đã hết thời gian làm bài' | translate}}
                </div>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="courseUserLogItem.id > 0">
          <div class="ws-page-body-content h-overflow-y-auto ws-l-content-container">
            <div class="ws-heading-1"><span class="ws-img-icon ws-heading-1__icon">
                <svg>
                  <use href="\assets\images\icon\task-square.svg#icon"></use>
                </svg></span>
              <div class="ws-heading-1__text">Kết quả bài thi</div>
            </div>
            <div class="ws-l-main-icon-wrapper">
              <span class="ws-img-icon ws-l-main-icon ws-color--danger"
                *ngIf="courseUserLogItem.finalMark < courseUserLogItem.passMark">
                <svg>
                  <use href="\assets\images\icon\close-circle.svg#icon"></use>
                </svg>
              </span>
              <span class="ws-img-icon ws-l-main-icon ws-color--success"
                *ngIf="courseUserLogItem.finalMark >= courseUserLogItem.passMark">
                <svg>
                  <use href="\assets\images\icon\rounded-check.svg#icon"></use>
                </svg>
              </span>
            </div>
            <div class="ws-l-block-content-row">
              <div class="ws-l-block-content">
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Số câu trả lời đúng:</div>
                  <div class="ws-l-meta__value">{{courseUserLogItem.numQTrue}}</div>
                </div>
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Số câu trả lời sai:</div>
                  <div class="ws-l-meta__value">{{courseUserLogItem.numQFalse}}</div>
                </div>
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Số câu cần giáo viên chấm điểm:</div>
                  <div class="ws-l-meta__value">{{courseUserLogItem.numQTeacher}}</div>
                </div>
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Số câu không trả lời:</div>
                  <div class="ws-l-meta__value">{{courseUserLogItem.numQNoAns}}</div>
                </div>
              </div>
              <div class="ws-l-block-content">
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Điểm:</div>
                  <div class="ws-l-meta__value">{{courseUserLogItem.finalMark}} /
                    {{courseUserLogItem.totalMark}}</div>
                </div>
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Điểm yêu cầu:</div>
                  <div class="ws-l-meta__value">{{courseUserLogItem.passMark}} /
                    {{courseUserLogItem.totalMark}}</div>
                </div>
                <div class="ws-l-meta">
                  <div class="ws-l-meta__key">Kết quả:</div>
                  <div class="ws-l-meta__value" *ngIf="courseUserLogItem.finalMark >= courseUserLogItem.passMark">Đạt
                  </div>
                  <div class="ws-l-meta__value" *ngIf="courseUserLogItem.finalMark < courseUserLogItem.passMark">Không
                    đạt</div>
                </div>
                <div class="ws-1-meta">
                  &nbsp;
                </div>
                <div class="ws-1-meta"
                  *ngIf="showCanDoTask && courseItemDetail.options_Rework == true && !isTeacher && courseItemDetail.approvalMark != true && courseUserLogItem.id > 0 && courseUserLogItem.examStatus >= 3 && courseUserLogItem.finalMark < courseUserLogItem.passMark">
                  <button class="ws-btn -size-3 ws-color--light ws-bgc--primary-2 ws-l-create-topic-btn"
                    (click)="doExam()">
                    <div class="ws-btn__label"> Làm lại </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isDoingExam">
        <div class="ws-page-body-content ws-p-space-test">
          <div class="ws-p-space-test-main-area">
            <div class="ws-p-space-test-question-container">
              <div class="ws-p-space-test-question-note"> Câu {{currentQuestionIndex + 1}}.
              </div>
              <div class="ws-p-space-test-question-wrapper">
                <div class="ws-space-test-question">
                  <div class="ws-space-test-question__title"
                    *ngIf="currentQuestion.idTestQuestionType != testQuestionType.GF"
                    [innerHTML]="currentQuestion.questionText"></div>
                  <div class="ws-space-test-answer--type-choice" *ngIf="currentQuestion.idTestQuestionType == testQuestionType.MC
                                    || currentQuestion.idTestQuestionType == testQuestionType.TF
                                    || currentQuestion.idTestQuestionType == testQuestionType.TFNG
                                    || currentQuestion.idTestQuestionType == testQuestionType.YN">
                    <div class="ws-space-test-answer-part--type-choice"
                      [class.is-activated]="currentQuestion.answerText == ans.id"
                      *ngFor="let ans of currentQuestion.courseTestQuestionAnswer">
                      <p-radioButton name="{{currentQuestion.id}}" value="{{ans.id}}"
                        [(ngModel)]="currentQuestion.answerText" inputId="{{ans.id}}"
                        (onClick)="radioBtnClick(currentQuestion)">
                      </p-radioButton>
                      <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                    </div>
                  </div>

                  <div class="ws-space-test-answer--type-choice"
                    *ngIf="currentQuestion.idTestQuestionType == testQuestionType.CL">
                    <div class="ws-space-test-answer-part--type-choice"
                      [class.is-activated]="currentQuestion.answerText != null && currentQuestion.answerText.indexOf(ans.id) !== -1 "
                      *ngFor="let ans of currentQuestion.courseTestQuestionAnswer">
                      <p-checkbox name="{{ans.id}}" binary="true" (onChange)="checkBoxChange(currentQuestion)"
                        [(ngModel)]="ans.isUserAnswer">
                      </p-checkbox>
                      <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                    </div>
                  </div>

                  <div class="ws-space-test-answer--type-fill"
                    *ngIf="currentQuestion.idTestQuestionType == testQuestionType.GF">
                    <ng-container *ngFor="let item of currentQuestion.courseTestQuestionAnswer; index as i">
                      <normal [innerHTML]="currentQuestion.dsQuestionText[i]"></normal>
                      <input type="text" class="mwText" [(ngModel)]="item.answerText"
                        (change)="onTextChange(currentQuestion)" />
                    </ng-container>
                    <normal
                      [innerHTML]="currentQuestion.dsQuestionText[currentQuestion.courseTestQuestionAnswer.length]">
                    </normal>
                  </div>

                  <div class="ws-space-test-answer--type-text-editor"
                    *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SA">
                    <p-editor [(ngModel)]="currentQuestion.answerText" [style]="{'height':'150px'}"
                      (onTextChange)="onTextEditorChange(currentQuestion)">
                    </p-editor>
                  </div>

                  <div class="ws-space-test-answer--type-sort-order"
                    *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SQ">
                    <ng-container *ngFor="let item of currentQuestion.courseTestQuestionAnswer">
                      <div class="ws-space-test-answer-part--type-sort-order" pDraggable="items"
                        (onDragStart)="dragStart(currentQuestion.courseTestQuestionAnswer, item)" pDroppable="items"
                        (onDrop)="drop(currentQuestion, currentQuestion.courseTestQuestionAnswer, item)"
                        [innerHTML]="item.answerText">
                      </div>
                    </ng-container>
                  </div>

                  <div class="ws-space-test-answer--type-sort-pair"
                    *ngIf="currentQuestion.idTestQuestionType == testQuestionType.MA">
                    <div class="col-md-6 pd-2">
                      <div class="col-12 pt-0" *ngFor="let item of currentQuestion.courseTestQuestionAnswer">
                        <div class="ws-space-test-answer-part--type-sort-pair " [innerHTML]="item.answerText">
                        </div>
                      </div>

                    </div>
                    <div class="col-md-6 pd-2">
                      <div class="col-12 pt-0" *ngFor="let item of currentQuestion.courseTestQuestionAnswerMatching">
                        <div class="ws-space-test-answer-part--type-sort-pair is-activated-1" pDraggable="items"
                          (onDragStart)="dragStart(currentQuestion.courseTestQuestionAnswerMatching, item)"
                          pDroppable="items"
                          (onDrop)="drop(currentQuestion, currentQuestion.courseTestQuestionAnswerMatching, item)"
                          [innerHTML]="item.matching">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ws-space-test-answer-part--type-upload"
                    *ngIf="currentQuestion.idTestQuestionType == testQuestionType.UP">
                    <div class="ws-heading-1 ws-space-test-answer--type-upload__heading">
                      <span class="ws-img-icon ws-heading-1__icon">
                        <svg>
                          <use href="\assets\images\icon\link-square.svg#icon"></use>
                        </svg></span>
                      <div class="ws-heading-1__text">File đính kèm</div>
                    </div>
                    <div class="ws-upload ws-space-test-answer--type-upload__field">
                      <a href="{{currentQuestion.answerFileUrl}}" target="_blank">{{currentQuestion.answerFileUrl
                        | fileName}}</a>
                    </div>
                    <div class="ws-upload ws-space-test-answer--type-upload__field">
                      <p-fileUpload mode="advanced" url="{{apiUploadUrl}}" [chooseLabel]="'Chọn file'"
                        [uploadLabel]="'Tải file'" [cancelLabel]="'Hủy tải lên'" name="file" [multiple]="false"
                        (onUpload)="onUploadInTesting(currentQuestion, $event)"
                        accept="image/*,.doc,.docx,.xls,.xlsx,ppt,pptx,.pdf,.rar,.zip" maxFileSize="1000000000">
                      </p-fileUpload>
                    </div>
                  </div>
                  <div class="ws-space-test-question" *ngIf="currentQuestion.idTestQuestionType == testQuestionType.SB">
                    <div class="ws-space-test-question -sub-question"
                      *ngFor="let subItem of currentQuestion.dsSubQuestion; index as j">

                      <div class="ws-space-test-question__title"
                        *ngIf="subItem.idTestQuestionType != testQuestionType.GF" [innerHTML]="subItem.questionText">
                      </div>
                      <div class="ws-space-test-answer--type-choice" *ngIf="subItem.idTestQuestionType == testQuestionType.MC
                                    || subItem.idTestQuestionType == testQuestionType.TF
                                    || subItem.idTestQuestionType == testQuestionType.TFNG
                                    || subItem.idTestQuestionType == testQuestionType.YN">
                        <div class="ws-space-test-answer-part--type-choice"
                          [class.is-activated]="subItem.answerText == ans.id"
                          *ngFor="let ans of subItem.courseTestQuestionAnswer">
                          <p-radioButton name="{{subItem.id}}" value="{{ans.id}}" [(ngModel)]="subItem.answerText"
                            inputId="{{ans.id}}" (onClick)="radioBtnClick(subItem)">
                          </p-radioButton>
                          <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                        </div>
                      </div>

                      <div class="ws-space-test-answer--type-choice"
                        *ngIf="subItem.idTestQuestionType == testQuestionType.CL">
                        <div class="ws-space-test-answer-part--type-choice"
                          [class.is-activated]="subItem.answerText != null && subItem.answerText.indexOf(ans.id) !== -1 "
                          *ngFor="let ans of subItem.courseTestQuestionAnswer">
                          <p-checkbox name="{{ans.id}}" binary="true" (onChange)="checkBoxChange(subItem)"
                            [(ngModel)]="ans.isUserAnswer">
                          </p-checkbox>
                          <span style="margin-left: 0.5rem;" for="{{ans.id}}" [innerHTML]="ans.answerText"></span>
                        </div>
                      </div>

                      <div class="ws-space-test-answer--type-fill"
                        *ngIf="subItem.idTestQuestionType == testQuestionType.GF">
                        <ng-container *ngFor="let item of subItem.courseTestQuestionAnswer; index as i">
                          <normal [innerHTML]="subItem.dsQuestionText[i]"></normal>
                          <input type="text" class="mwText" [(ngModel)]="item.answerText"
                            (change)="onTextChange(subItem)" />
                        </ng-container>
                        <normal [innerHTML]="subItem.dsQuestionText[subItem.courseTestQuestionAnswer.length]">
                        </normal>
                      </div>

                      <div class="ws-space-test-answer--type-text-editor"
                        *ngIf="subItem.idTestQuestionType == testQuestionType.SA">
                        <p-editor [(ngModel)]="subItem.answerText" [style]="{'height':'150px'}"
                          (onTextChange)="onTextEditorChange(subItem)">
                        </p-editor>
                      </div>

                      <div class="ws-space-test-answer--type-sort-order"
                        *ngIf="subItem.idTestQuestionType == testQuestionType.SQ">
                        <ng-container *ngFor="let item of subItem.courseTestQuestionAnswer">
                          <div class="ws-space-test-answer-part--type-sort-order" pDraggable="items"
                            (onDragStart)="dragStart(subItem.courseTestQuestionAnswer, item)" pDroppable="items"
                            (onDrop)="drop(subItem, subItem.courseTestQuestionAnswer, item)"
                            [innerHTML]="item.answerText">
                          </div>
                        </ng-container>
                      </div>

                      <div class="ws-space-test-answer--type-sort-pair"
                        *ngIf="subItem.idTestQuestionType == testQuestionType.MA">
                        <div class="col-md-6 pd-2">
                          <div class="ws-space-test-answer-part--type-sort-pair "
                            *ngFor="let item of subItem.courseTestQuestionAnswer" [innerHTML]="item.answerText">
                          </div>
                        </div>
                        <div class="col-md-6 pd-2">
                          <div class="ws-space-test-answer-part--type-sort-pair is-activated-1"
                            *ngFor="let item of subItem.courseTestQuestionAnswerMatching">
                            <div class="col-12" pDraggable="items"
                              (onDragStart)="dragStart(subItem.courseTestQuestionAnswerMatching, item)"
                              pDroppable="items"
                              (onDrop)="drop(subItem, subItem.courseTestQuestionAnswerMatching, item)"
                              [innerHTML]="item.matching">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="ws-space-test-answer-part--type-upload"
                        *ngIf="subItem.idTestQuestionType == testQuestionType.UP">
                        <div class="ws-heading-1 ws-space-test-answer--type-upload__heading">
                          <span class="ws-img-icon ws-heading-1__icon">
                            <svg>
                              <use href="\assets\images\icon\link-square.svg#icon"></use>
                            </svg></span>
                          <div class="ws-heading-1__text">File đính kèm</div>
                        </div>
                        <div class="ws-upload ws-space-test-answer--type-upload__field">
                          <a href="{{subItem.answerFileUrl}}" target="_blank">{{subItem.answerFileUrl
                            | fileName}}</a>
                        </div>
                        <div class="ws-upload ws-space-test-answer--type-upload__field">
                          <p-fileUpload mode="advanced" url="{{apiUploadUrl}}" [chooseLabel]="'Chọn file'"
                            [uploadLabel]="'Tải file'" [cancelLabel]="'Hủy tải lên'" name="file" [multiple]="false"
                            (onUpload)="onUploadInTesting(subItem, $event)"
                            accept="image/*,.doc,.docx,.xls,.xlsx,ppt,pptx,.pdf,.rar,.zip" maxFileSize="1000000000">
                          </p-fileUpload>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>
              <div class="ws-p-space-test-navigation">
                <button class="ws-btn -size-5 ws-p-space-test-navigation-btn" [disabled]="currentQuestionIndex <= 0"
                  (click)="goPrevQuestion()"><span class="ws-img-icon ws-btn__icon">
                    <svg>
                      <use href="\assets\images\icon\square-arrow-left.svg#icon"></use>
                    </svg></span>
                  <div class="ws-btn__label">Câu trước</div>
                </button>
                <button class="ws-btn -size-5 ws-p-space-test-navigation-btn"
                  [disabled]="currentQuestionIndex >= totalQuestion" (click)="goNextQuestion()">
                  <span class="ws-img-icon ws-btn__icon">
                    <svg>
                      <use href="\assets\images\icon\square-arrow-right.svg#icon"></use>
                    </svg></span>
                  <div class="ws-btn__label">Câu sau</div>
                </button>
              </div>
            </div>
          </div>
          <div class="ws-p-space-test-side-area">
            <div class="ws-p-space-test-timer"><span class="ws-img-icon ws-p-space-test-timer__icon">
                <svg>
                  <use href="\assets\images\icon\clock-2.svg#icon"></use>
                </svg></span>
              <div class="ws-p-space-test-timer__label">
                <countdown #cd [config]="config" (event)="countdownEvent($event)">
                </countdown>
              </div>
            </div>
            <div class="ws-p-space-test-question-btns">
              <ng-container *ngFor="let item of dataTest; let i = index">
                <div class="ws-p-space-test-question-btn-wrapper">
                  <button class="ws-p-space-test-question-btn"
                    [class]="(currentQuestionIndex == i) ? '-val-3': (item.answerText !== null ? 'is-activated': '')"
                    (click)="goQuestion(i)">Câu {{i + 1}}</button>
                </div>

              </ng-container>
            </div>
            <div class="ws-p-space-test-glosses">
              <div class="ws-p-space-test-gloss">
                <div class="ws-p-space-test-gloss__value -val-3"></div>
                <div class="ws-p-space-test-gloss__text">Đang trả lời</div>
              </div>
              <div class="ws-p-space-test-gloss">
                <div class="ws-p-space-test-gloss__value -val-2"></div>
                <div class="ws-p-space-test-gloss__text">Đã trả lời</div>
              </div>
              <div class="ws-p-space-test-gloss">
                <div class="ws-p-space-test-gloss__value -val-1"></div>
                <div class="ws-p-space-test-gloss__text">Chưa trả lời</div>
              </div>
            </div>
            <div class="ws-p-space-test-submit-btn-wrapper h-text-center h-pb-20">
              <button class="ws-btn -size-5 ws-p-space-test-submit-btn" (click)="doFinishExam()">
                <div class="ws-btn__label">Nộp bài</div>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
