<app-nav></app-nav>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li><a routerLink="/authors">Giáo viên - Đối tác</a></li>
        <li class="li-active">Chi tiết Giáo viên - Đối tác</li>
      </ul>
      <h2>Chi tiết Giáo viên - Đối tác</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<section class="partnerAndTeacherDetail mt-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>Giới thiệu chi tiết</h2>
      </div>
      <div class="col-12 lg:col-4 image-container">
        <img src="{{itemDetail.logoUrl}}" alt="">
      </div>
      <div class="col-12 lg:col-4 bio-container">
        <h4>{{itemDetail.title}}</h4>
        <p class="subject" [innerText]="itemDetail.headline"></p>
        <p [innerHTML]="itemDetail.description"></p>
        <div class="features flex flex-wrap justify-content-between">
          <div class="flex align-items-center ">
            <div>
              <i class="pi pi-user"></i>
              <span class="ml-1 mr-2">8.2k</span>
            </div>
            <div>
              <span class="mr-1">4.5</span>
              <ng-rating-bar [value]="1" [ratingCount]="1" colorActive="#F2A000" colorDefault="gray"
                [styles]="{fontSize: '16px', margin: '0px',padding: '0px'}"></ng-rating-bar>
            </div>
            <div>
              <span class="ml-2">25+</span>
              <span class="ml-1">Khoá học</span>
            </div>
          </div>
          <div class="social-icons flex gap-2 align-items-center">
            <!-- <i class="pi pi-facebook"></i>
            <i class="pi pi-linkedin"></i>
            <i class="pi pi-telegram"></i>
            <i class="pi pi-twitter"></i> -->
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4 contacts-container">
        <h2>Liên hệ ngay</h2>
        <p class="flex align-items-center" *ngIf="itemDetail.email"><i class="pi pi-comment"></i>
          <span class="ml-2">Email: <a href="mailto:{{itemDetail.email}}">{{itemDetail.email}}</a></span>
        </p>
        <p class="flex align-items-center" *ngIf="itemDetail.phone"><i class="pi pi-phone"></i><span class="ml-2">Phone:
            {{itemDetail.phone}}</span></p>
        <p class="flex align-items-center" *ngIf="itemDetail.facebook"><i class="pi pi-bookmark
          "></i><span class="ml-2">Fanpage: <a href="{{itemDetail.facebook}}">{{itemDetail.facebook}}</a></span></p>
      </div>
    </div>
    <ng-container *ngIf="dataSource.length> 0">
      <div class="row mt-5">
        <div class="col-12">
          <h2>Khoá học của tôi</h2>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let item of dataSource">
          <div class="lg:col-3 md:col-6 sm:col-12">
            <app-course-item-type-one [item]="item"></app-course-item-type-one>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <sv-paging [pageIndex]="pageIndex" [totalRecord]="totalRecord" [pageSize]="pageSize" [pagesToShow]="4"
          *ngIf="totalRecord > 0" [loading]="isLoading" (onGoPage)="onGoPage($event)" (onPrePage)="onPrePage()"
          (onNextPage)="onNextPage()">
        </sv-paging>
      </div>
    </ng-container>
    <ng-container *ngIf="dataCommentSource.length > 0">
      <div class="row">
        <div class="col-12">
          <h2>Nhận xét của học viên</h2>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let item of dataCommentSource">
          <div class="lg:col-4 md:col-6 sm:col-12">
            <app-comment-item-type-one [item]="item"></app-comment-item-type-one>
          </div>
        </ng-container>
      </div>
      <div class="row mb-5">
        <div class="col-12 flex justify-content-center">
          <button class="second-btn showAll">Hiện tất cả đánh giá<span></span></button>
        </div>
      </div>
    </ng-container>
  </div>
</section>
