import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BasicUserInfo, SecondPageEditBase, UserService } from 'sv-shared';
import { GisRegionService } from '../../../services/gis-region.service';
import { Router } from '@angular/router';
import { SysSchoolService } from '../../../services/sys-school.service';
import { CategoriesService } from '../../../services/categories.service';
import { CategoryType } from '../../../config/enums';
import { UserCategoryService } from '../../../services/user-category.service';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends SecondPageEditBase implements OnInit {

  listGender = [{ label: 'Nam', value: 1 }, { label: 'Nữ', value: 2 }, { label: 'Khác', value: 3 }]
  listProvince = [];
  listDistrict = [];
  listSysSchool: any[] = [];
  selectedCategories = [];

  listSchoolLevel = [
    { value: 1, label: 'Tiểu học' },
    { value: 2, label: 'Trung học cơ sở' },
    { value: 3, label: 'Trung học phổ thông' },
    { value: 4, label: 'Đại học/Cao đẳng' },
    { value: 5, label: 'Người đi làm' },
    { value: 6, label: 'Khác' },
  ];
  listUserCategory = [];

  currentUser = new BasicUserInfo();

  constructor(
    protected _userService: UserService,
    protected _injector: Injector,
    private _gisRegionService: GisRegionService,
    private _router: Router,
    private _sysSchoolService: SysSchoolService,
    private _categoriesService: CategoriesService,
    private _userCategoryService: UserCategoryService,

  ) {
    super(_userService, _injector);

  }

  async ngOnInit() {
    if (!this.currentUser) {
      this._router.navigate(['/']);
    }
    this.getsProvince();
    this.getsDistrict();
    this.formGroup = new FormGroup({
      userName: new FormControl({ value: '', disabled: true }, [Validators.required],),
      displayName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      dateOfBirth: new FormControl(''),
      gender: new FormControl(''),
      avatar: new FormControl(''),
      phoneNumber: new FormControl(''),
      idProvince: new FormControl('', [Validators.required]),
      idDistrict: new FormControl('', [Validators.required]),
      idSchoolLevel: new FormControl('',),
    });

    this._userService.getMyInfo().then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
        this.itemDetail.dateOfBirth = this.itemDetail.dateOfBirth ? new Date(this.itemDetail.dateOfBirth) : null;
      }
    })
    await this.getsUserCategory();
    this.getsCategories();
  }

  async getsUserCategory() {
    await this._userCategoryService.getsMyFavoriteCategory().then(rs => {
      if (rs.success) {
        this.listUserCategory = rs.data;
      }
    })
  }

  getsCategories() {
    var arrSchool = this._sysSchoolService.getsSchool();
    if (arrSchool.length > 0) {
      arrSchool.forEach(item => {
        this._categoriesService.getTree(item.id, 1, CategoryType.Courses).then(rs1 => {
          if (rs1.success && rs1.data.length > 0) {
            this.listSysSchool.push({
              schoolInfo: item,
              listCategory: rs1.data
            })
            if (this.listUserCategory.length > 0) {
              rs1.data.forEach(item1 => {
                this.getSelectedItem(item1);
              });
            }
          }
        });
      });
    }
  }

  private getSelectedItem(node: TreeNode) {
    if (this.listUserCategory.findIndex(x => x == node.data) > -1) {
      this.selectedCategories.push(node);
    }
    if (node.children) {
      node.children.forEach(childNode => {
        this.getSelectedItem(childNode);
      });
    }
  }


  async getsProvince() {
    await this._gisRegionService.getsAllProvince().then(rs => {
      if (rs.success) {
        this.convertDataToOptions(this.listProvince, rs.data, 'title');
      }
    })
  }

  async getsDistrict() {
    await this._gisRegionService.getsDistrictByIdProvince(this.itemDetail.idProvince ?? 0).then(rs => {
      if (rs.success) {
        this.convertDataToOptions(this.listDistrict, rs.data, 'title');
      }
    })
  }

  doUpdateInfo() {

    this.submitting = true;
    if (this.formGroup.invalid) {
      this.showValidateMessage();
      this.scrollToTop();
      this.submitting = false;
      return;
    }
    this._userService.updateMyInfo(this.itemDetail).then(rs => {
      this.submitting = false;
      if (rs.success) {
        this._notifierService.showUpdateDataSuccess();
      } else {
        this._notifierService.showUpdateDataFailed();
      }
    })

    this._userCategoryService.updateMyFavoriteCategory(this.selectedCategories.map(x => x.data)).then(rs => {
    })

  }

}
