import { DropdownModule } from "primeng/dropdown"
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TabViewModule } from 'primeng/tabview';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ClickOutsideModule } from "ng-click-outside";
import { NgxPopperjsModule } from 'ngx-popperjs';
import { AppRoutingModule } from './app.routes';
import { AppComponent } from './app.component';
import { AccordionModule } from 'primeng/accordion';
import { NgRatingBarModule } from 'ng-rating-bar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BadgeModule } from 'primeng/badge';
import { SlideMenuModule } from 'primeng/slidemenu';
import { DragDropModule } from 'primeng/dragdrop';
import { CaptchaModule } from 'primeng/captcha';
import { ImageModule } from 'primeng/image';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialAuthService,
} from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { EditorModule } from 'primeng/editor';
import { CountdownModule } from 'ngx-countdown';
import { DocumentEditorModule } from "@onlyoffice/document-editor-angular";
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './layouts/common/header/header.component';
import { FooterComponent } from './layouts/common/footer/footer.component';
import { SignInComponent } from './pages/account/sign-in/sign-in.component';
import { SignUpComponent } from './pages/account/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './pages/account/forgot-password/forgot-password.component';
import { StudentDashboardComponent } from './pages/account/student-dashboard/student-dashboard.component';
import { NavComponent } from './layouts/common/nav/nav.component';
import { ProfileComponent } from './pages/account/profile/profile.component';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';
import { NewsDetailComponent } from './pages/news/newsDetail/newsDetail.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { RankingComponent } from './pages/account/ranking/ranking.component';
import { RankPipe } from './pipes/rank.pipe';
import { SchoolComponent } from './pages/school/school.component';
import { TopCategoriesComponent } from './pages/school/top-categories/top-categories.component';
import { FeaturesStyleTwoComponent } from './pages/school/features-style-two/features-style-two.component';
import { CourseDetailComponent } from './pages/courses/course-detail/course-detail.component';
import { SingleNewsBoxGridComponent } from './shared/single-news-box-grid/single-news-box-grid.component';
import { SingleTeacherBoxListComponent } from './components/single-teacher-box-list/single-teacher-box-list.component';
import { SingleTeacherBoxGridComponent } from './components/single-teacher-box-grid/single-teacher-box-grid.component';
import { SingleCourseBoxGridComponent } from './shared/single-course-box-grid/single-course-box-grid.component';
import { NewsItemTypeOneComponent } from './shared/news-item-type-one/news-item-type-one.component';
import { CourseItemTypeOneComponent } from './components/course-item-type-one/course-item-type-one.component';
import { CourseItemTypeTwoComponent } from './components/course-item-type-two/course-item-type-two.component';
import { CourseItemTypeThreeComponent } from './components/course-item-type-three/course-item-type-three.component';
import { CourseItemTypeFourComponent } from './components/course-item-type-four/course-item-type-four.component';
import { CommentItemTypeOneComponent } from './components/comment-item-type-one/comment-item-type-one.component';
import { CommentItemTypeTwoComponent } from './components/comment-item-type-two/comment-item-type-two.component';
import { CommentItemTypeThreeComponent } from './shared/comment-item-type-three/comment-item-type-three.component';
import { environment } from '../environments/environment';
import { AuthGuardService } from './services/auth-guard.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader, SendAccessTokenInterceptor, SvCorePublicService, SvSharedModule, UserService } from 'sv-shared';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { provideOAuthClient, OAuthModule } from 'angular-oauth2-oidc';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SvLocalizationModule } from './localization/svlocalization.module';
import { SectionSchoolsComponent } from './pages/home/section-schools/section-schools.component';
import { SingleCourseBoxListComponent } from './shared/single-course-box-list/single-course-box-list.component';
import { SectionHotCoursesComponent } from './pages/home/section-hot-courses/section-hot-courses.component';
import { SectionFeedbackComponent } from './pages/home/section-feedback/section-feedback.component';
import { SectionBestSellersCourseComponent } from './pages/home/section-best-sellers-course/section-best-sellers-course.component';
import { SectionRegisterTenantComponent } from './pages/home/section-register-tenant/section-register-tenant.component';
import { SectionHotCategoriesComponent } from './pages/home/section-hot-categories/section-hot-categories.component';
import { SectionBannerComponent } from './pages/home/section-banner/section-banner.component';
import { SectionTopNewsComponent } from './pages/school/section-top-news/section-top-news.component';
import { SectionRandomTenantComponent } from './pages/school/section-random-tenant/section-random-tenant.component';
import { NewsComponent } from './pages/news/news.component';
import { AgePipe } from './pipes/age.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { AvatarPipe } from './pipes/avatar.pipe';
import { LearningSpaceV3Component } from './pages/learning-space-v3/learning-space-v3.component';
import { LearningSpaceV3HomeComponent } from './pages/learning-space-v3/learning-space-v3-home/learning-space-v3-home.component';
import { LearningSpaceV3CourseItemComponent } from './pages/learning-space-v3/learning-space-v3-course-item/learning-space-v3-course-item.component';
import { LearningSpaceV3LiveclassComponent } from './pages/learning-space-v3/learning-space-v3-liveclass/learning-space-v3-liveclass.component';
import { LearningSpaceV3ForumComponent } from './pages/learning-space-v3/learning-space-v3-forum/learning-space-v3-forum.component';
import { LearningSpaceV3ResultComponent } from './pages/learning-space-v3/learning-space-v3-result/learning-space-v3-result.component';
import { LearningSpaceV3ResourceComponent } from './pages/learning-space-v3/learning-space-v3-resource/learning-space-v3-resource.component';
import { NgScormPlayerModule } from 'ng-scorm-player';
import { SvScormPlayerModule } from 'sv-scorm-player';
import { LearningLayoutComponent } from './layouts/learning-layout/learning-layout.component';
import { CourseItemListComponent } from './pages/learning-space-v3/course-item-list/course-item-list.component';
import { LearningHistoryComponent } from './pages/account/learning-history/learning-history.component';
import { SingleCoursePopperComponent } from './single-course-popper/single-course-popper.component';
import { AdminRedirectComponent } from './pages/admin-redirect/admin-redirect.component';
import { AuthorsComponent } from './pages/authors/authors.component';
import { AuthorDetailComponent } from './pages/authors/author-detail/author-detail.component';
import { PagingComponent } from './shared/paging/paging.component';
import { SafePipe } from './pipes/safe.pipe';
import { ScormTestComponent } from './pages/scorm-test/scorm-test.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DocumentDetailComponent } from './pages/documents/document-detail/document-detail.component';
import { SingleNewsBoxListComponent } from './shared/single-news-box-list/single-news-box-list.component';
import { SingleDocumentListComponent } from './shared/single-document-list/single-document-list.component';
import { SingleDocumentGridComponent } from './shared/single-document-grid/single-document-grid.component';
import { DocumentItemTypeOneComponent } from './shared/document-item-type-one/document-item-type-one.component';
import { ExamsComponent } from './pages/exams/exams.component';
import { ExamDetailComponent } from './pages/exams/exam-detail/exam-detail.component';
import { SingleExamBoxGridComponent } from './shared/single-exam-box-grid/single-exam-box-grid.component';
import { SingleExamBoxListComponent } from './shared/single-exam-box-list/single-exam-box-list.component';
import { ExamingComponent } from './pages/exams/examing/examing.component';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { WorkshopComponent } from './pages/workshop/workshop.component';
import { WorkshopDetailComponent } from './pages/workshop/workshop-detail/workshop-detail.component';
import { SingleWorkshopGridComponent } from './shared/single-workshop-grid/single-workshop-grid.component';
import { ShoppingCartChildrenComponent } from './pages/shopping-cart-children/shopping-cart-children.component';
import { ChildrenComponent } from './pages/account/children/children.component';
import { LearningHistoryChildrenComponent } from './pages/account/learning-history-children/learning-history-children.component';
import { FavouriteCategoryComponent } from './pages/account/favourite-category/favourite-category.component';
import { ErrorComponent } from './pages/error/error.component';
import { AboutUsComponent } from './pages/aboutus/about-us/about-us.component';
import { PrivacyComponent } from './pages/aboutus/privacy/privacy.component';
import { PaymentComponent } from './pages/aboutus/payment/payment.component';
import { RefundComponent } from './pages/aboutus/refund/refund.component';
import { ShippingComponent } from './pages/aboutus/shipping/shipping.component';
import { ResetPasswordComponent } from './pages/account/reset-password/reset-password.component';
import { PaymentHistoryComponent } from './pages/account/payment-history/payment-history.component';
import { DocumentDetailAppComponent } from './pages/documents/document-detail-app/document-detail-app.component';
import { DocumentDetailApp2Component } from './pages/documents/document-detail-app2/document-detail-app2.component';

export function createTranslateLoader(corePublicService: SvCorePublicService) {
  return new MultiTranslateHttpLoader(corePublicService);
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    CountUpModule,
    StickyNavModule,
    TabsModule,
    NgxScrollTopModule,
    LightboxModule,
    AccordionModule,
    FormsModule,
    LightgalleryModule,
    ButtonModule,
    DropdownModule,
    BadgeModule,
    InputTextModule,
    NgRatingBarModule,
    CheckboxModule,
    ChartModule,
    SlideMenuModule,
    CalendarModule,
    RadioButtonModule,
    TableModule,
    TagModule,
    TabViewModule,
    OverlayPanelModule,
    ClickOutsideModule,
    NgxPopperjsModule,
    SvLocalizationModule.forRoot({ locale_id: 'vi' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [SvCorePublicService]
      }
    }),
    SvSharedModule.forRoot({ environment: environment }),
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiDomain.gateway],
        sendAccessToken: false
      }
    }),
    EditorModule,
    SvScormPlayerModule.forChild({ debug: true }),
    CountdownModule,
    DragDropModule,
    DocumentEditorModule,
    CaptchaModule,
    ImageModule,
  ],
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    HeaderComponent,
    FooterComponent,

    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    StudentDashboardComponent,
    LearningHistoryComponent,
    LearningHistoryChildrenComponent,
    SingleCoursePopperComponent,

    HomeComponent,
    SectionBannerComponent,
    SectionSchoolsComponent,
    SectionHotCoursesComponent,
    SectionFeedbackComponent,
    SectionBestSellersCourseComponent,
    SectionRegisterTenantComponent,
    SectionHotCategoriesComponent,
    SectionTopNewsComponent,
    SectionRandomTenantComponent,

    NewsComponent,
    NavComponent,

    ProfileComponent,
    ChangePasswordComponent,
    NewsDetailComponent,
    CoursesComponent,
    RankingComponent,
    ChildrenComponent,

    RankPipe,
    AgePipe,
    AvatarPipe,
    TimeAgoPipe,
    SafePipe,
    SafeHtmlPipe,
    SchoolComponent,
    TopCategoriesComponent,
    FeaturesStyleTwoComponent,
    AdminRedirectComponent,

    AboutUsComponent,
    CourseDetailComponent,

    SingleNewsBoxListComponent,
    SingleNewsBoxGridComponent,

    SingleTeacherBoxListComponent,
    SingleTeacherBoxGridComponent,

    SingleCourseBoxListComponent,
    SingleCourseBoxGridComponent,

    NewsItemTypeOneComponent,

    CourseItemTypeOneComponent,
    CourseItemTypeTwoComponent,
    CourseItemTypeThreeComponent,
    CourseItemTypeFourComponent,

    CommentItemTypeOneComponent,
    CommentItemTypeTwoComponent,
    CommentItemTypeThreeComponent,

    LearningLayoutComponent,
    LearningSpaceV3Component,
    LearningSpaceV3HomeComponent,
    LearningSpaceV3CourseItemComponent,
    LearningSpaceV3LiveclassComponent,
    LearningSpaceV3ForumComponent,
    LearningSpaceV3ResultComponent,
    LearningSpaceV3ResourceComponent,
    CourseItemListComponent,
    ShoppingCartComponent,
    ShoppingCartChildrenComponent,

    DocumentsComponent,
    DocumentDetailComponent,
    SingleDocumentListComponent,
    SingleDocumentGridComponent,
    DocumentItemTypeOneComponent,

    AuthorsComponent,
    AuthorDetailComponent,
    PagingComponent,
    ScormTestComponent,
    PrivacyComponent,
    ExamsComponent,
    ExamDetailComponent,
    SingleExamBoxGridComponent,
    SingleExamBoxListComponent,
    ExamingComponent,
    WorkshopComponent,
    WorkshopDetailComponent,
    SingleWorkshopGridComponent,
    FavouriteCategoryComponent,
    ErrorComponent,
    PaymentComponent,
    RefundComponent,
    ShippingComponent,
    ResetPasswordComponent,
    PaymentHistoryComponent,
    DocumentDetailAppComponent,
    DocumentDetailApp2Component,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SendAccessTokenInterceptor,
      multi: true
    },
    MessageService, ConfirmationService, DatePipe, UserService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.socialLoginProvider.google),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.socialLoginProvider.facebook),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    SocialAuthService, AuthGuardService, CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
