import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from 'sv-shared';
import { CourseService } from '../../../services/course.service';
import { map, takeUntil } from 'rxjs';
import { CourseItemService } from '../../../services/course-item.service';
import { CourseUserService } from '../../../services/course-user.service';
import { CourseUserFeedbackService } from '../../../services/course-user-feedback.service';
import { ShoppingCartService } from '../../../services/shopping-cart.service';
import { TenantsService } from '../../../services/tenants.service';
import { ComboService } from '../../../services/combo.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent extends SecondPageIndexBase implements OnInit {

  courseItemCountInfo: any = {};
  listCourseItem: [];
  idCourse = 0;

  itemDetail: any = {};
  tenantInfo = { id: 0, title: '', headline: '', description: '', logoUrl: '', email: '', phone: '', facebook: '', countStudent: 0, countCourse: 0 };

  isRegister = false;
  itemCourseUser: any = { id: 0, status: 1, }
  itemFeedback = { id: 0, bodyHtml: '', idCourse: 0, userIdStudent: 0, rating: 5, status: 1 };

  comboDto: any = {};
  totalComboPrice = 0;

  listCourseReference: any = [];
  listCourseRoadmap: any = [];
  listLatestViewed: any = [];

  listCourseLatest: any = [];
  listCourseBestSeller: any = [];

  captchaIsValid = false;

  courseMostStudySides: OwlOptions = {
    loop: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    autoplayTimeout: 10000,
    margin: 30,
    nav: true,
    navText: [
      "<i class='pi pi-chevron-left'></i>",
      "<i class='pi pi-chevron-right'></i>"
    ],
    responsive: {
      0: {
        items: 2,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 4,
      }
    },
  }

  constructor(
    protected _injector: Injector,
    private _courseService: CourseService,
    private _coureItemService: CourseItemService,
    private _courseItemService: CourseItemService,
    private _courseUserService: CourseUserService,
    private _courseUserFeedbackService: CourseUserFeedbackService,
    private _shoppingCartService: ShoppingCartService,
    private _tenantsService: TenantsService,
    private _comboService: ComboService,
    private _titleTagService: Title,
  ) {
    super(null, _injector);
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.idCourse = pid;
          this.getDetail(pid);
          this.getsCourseItems();
          this.getDataComment();

          this.getsLastestView();
        }

      });
  }

  getDetail(id) {
    this._courseService.getByInstanceId(id).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
        this.getTenant(rs.data.idTenant);

        this.getsCourseReference(rs.data.id);
        this.getsCourseRoadmap(rs.data.id);
        this.getsCourseLatest(rs.data.idSysSchool);
        this.getsCourseBestSeller(rs.data.idSysSchool);

        this._coureItemService.getCourseItemCountInfo(rs.data.id).then(rs1 => {
          if (rs1.success) {
            this.courseItemCountInfo = rs1.data;
          }
        });

        this.getsCourseInCombo(rs.data.id);

        this._titleTagService.setTitle(`${this._titleTagService.getTitle()} | ${rs.data.title}`);

      }
    });

    if (this.currentUser?.userId > 0) {
      // Kiểm tra xem đã đăng ký chưa
      this._courseUserService.checkRegistered(this.idCourse).then(rs => {
        if (rs.success) {
          if (rs.data != null) {
            this.isRegister = true;
            this.itemCourseUser = rs.data;

            //Nếu đăng ký rồi thì kiểm tra xem đã đánh giá khóa học chưa
            this._courseUserFeedbackService.getByUserId(this.idCourse).then(response => {
              if (response.success) {
                if (response.data != null) {
                  this.itemFeedback = response.data;
                }

              }
            })
          }
        }
      });
    }
  }


  getTenant(id) {
    this._tenantsService.getById(id).then(rs => {
      if (rs.success) {
        this.tenantInfo = rs.data;
      }
    });
  }

  getsCourseItems() {
    this._courseItemService.getTree(this.idCourse, 1).then(rs => {
      if (rs.success) {
        this.listCourseItem = rs.data;
      }
    })
  }

  addComment() {
    if (this.captchaIsValid && this.itemFeedback.bodyHtml != '') {
      this.itemFeedback.idCourse = this.idCourse;
      this.itemFeedback.userIdStudent = this.currentUser.userId;
      this._courseUserFeedbackService.post(this.itemFeedback).then(rs => {
        if (rs.success) {
          this.itemFeedback.id = rs.data;
          this._notifierService.showInsertDataSuccess();
        }
      })
    }
  }

  getDataComment() {
    this.dataSource = [];
    var model = {
      instanceId: this.idCourse,
      keyword: this.query,
      status: this.status,
      offset: (this.pageIndex - 1) * this.pageSize,
      pageSize: this.pageSize,
      orderCol: this.orderCol,
      isDesc: this.isDesc
    }
    this._courseUserFeedbackService.find(model)
      .then(response => {
        this.dataSource = response.data;

        if (response.totalRecord || response.totalRecord === 0) {
          this.totalRecord = response.totalRecord;
        }
        this.isLoading = false;
      }, error => {
        //this._notifierService.showHttpUnknowError();
        this.isLoading = false;
      });
  }

  onSearchFeedBack() {
    this.pageIndex = 1;
    this.getDataComment();
  }

  addToShoppingCart() {
    this._shoppingCartService.addCourses([this.idCourse]).then(rs => {
      if (rs.success) {
        if (rs.data > 0) {
          this._notifierService.showSuccess("Thêm vào giỏ hàng thành công");
        }
        else {
          this._notifierService.showWarning("Khóa học đã có trong giỏ hàng hoặc bạn đã đăng ký khóa học này");
        }
      }
    })
  }

  doRegister() {
    this._courseUserService.registerCourse(this.idCourse).then(rs => {
      if (rs.success) {
        this._notifierService.showSuccess(this._translateService.instant('Đăng ký thành công !'));
        this.getDetail(this.idCourse);
      }
    });
  }

  goLearningSpace(instanceId: string) {
    this._router.navigateByUrl(`/learning-space/${instanceId}`);
  }

  getsCourseInCombo(idCourse: number) {
    this._comboService.getsByIdCourse(idCourse).then(rs => {
      if (rs.success) {
        this.comboDto = rs.data;
        this.totalComboPrice = (100 - rs.data.comboInfo.promotionRate) * rs.data.courses.reduce((sum, item) => sum + item.price, 0) / 100;
      }
    });
  }

  addAllToShoppingCart() {
    this._shoppingCartService.addCourses(this.comboDto.courses.map(({ id }) => id)).then(rs => {
      if (rs.success) {
        if (rs.data > 0) {
          this._notifierService.showSuccess("Thêm vào giỏ hàng thành công");
        }
        else {
          this._notifierService.showWarning("Khóa học đã có trong giỏ hàng hoặc bạn đã đăng ký khóa học này");
        }
      }
    })
  }

  getsCourseReference(idCourse: number) {
    this._courseService.getsCourseReference(idCourse, 10).then(rs => {
      if (rs.success) {
        this.listCourseReference = rs.data;
      }
    });
  }

  getsCourseRoadmap(idCourse: number) {
    this._courseService.getsCourseRoadmap(idCourse).then(rs => {
      if (rs.success) {
        this.listCourseRoadmap = rs.data;
      }
    });
  }

  getsLastestView() {
    this._courseService.getsLastestView().then(rs => {
      if (rs.success) {
        this.listLatestViewed = rs.data;
      }
    });
  }

  getsCourseLatest(idSysSchool) {
    this._courseService.getsLatestCourse(idSysSchool, 10, true).then(rs => {
      if (rs.success) {
        this.listCourseLatest = rs.data;
      }
    });
  }

  getsCourseBestSeller(idSysSchool) {
    this._courseService.getsBestSeller(idSysSchool, 10, true).then(rs => {
      if (rs.success) {
        this.listCourseBestSeller = rs.data;
      }
    });
  }

  captchaResponse(event) {
    if (event) {
      this.captchaIsValid = true;
    }
  }

}
