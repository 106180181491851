import { Component, OnInit } from '@angular/core';
import { SysSettingService } from '../../../services/sys-setting.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  itemDetail: any = { settingValue: '' }

  constructor(
    private _sysSettingService: SysSettingService
  ) { }

  ngOnInit() {
    this._sysSettingService.getByInstanceId('E0DC3490-EE16-418C-B0EB-5FC3910095BC'.toLowerCase()).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
      }
    })
  }
}
