<div class="single-comment-box">
  <hr>
  <div class="flex justify-content-between align-items-center comment-title">
    <div class="flex align-items-center">
      <div class="comment-image">
        <img src="{{item.avatar | avatar}}" alt="">
      </div>
      <div class="ml-2">
        <div class="name">{{item.modifiedByName}}</div>
        <div>
          <ng-rating-bar [value]="item.rating" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
            [styles]="{fontSize: '16px', margin: '0px',padding: '0px'}"></ng-rating-bar>
          <span class="ml-1">{{item.modified | timeAgo}}</span>
        </div>
      </div>
    </div>
    <i class="pi pi-ellipsis-v"></i>
  </div>
  <div class="comment-description">
    <p [innerHTML]="item.bodyHtml"></p>
    <!-- <div class="useful">
      <span class="mr-2">Bạn thấy hữu ích?</span>
      <i class="pi pi-thumbs-up"></i>
    </div> -->
  </div>
</div>