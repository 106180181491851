<div class="flex align-items-center single-comment-box-type-two">
  <div class="comment-image">
    <img src="{{item.avatar | avatar}}" alt="">
  </div>
  <div class="ml-2 comment-description">
    <p class="name mb-0">{{item.displayName}}</p>
    <ng-rating-bar [value]="5" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray" disabled="true"
      [styles]="{fontSize: '16px', margin: '0px',padding: '0px'}"></ng-rating-bar>
    <p class="reviews mb-0">{{item.bodyHtml}}</p>
  </div>
</div>
