import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  transform(value: { ageFrom: number, ageTo: number }): any {
    if (value.ageFrom && value.ageTo) {
      return `${value.ageFrom} - ${value.ageTo}`
    }
    else if (value.ageFrom && !value.ageTo) {
      return `${value.ageFrom}+`;
    }
    else if (!value.ageFrom && value.ageTo) {
      return `${value.ageTo}-`;
    }
    else {
      return 'tất cả';
    }
  }
}
