<div class="container pt-5 pb-5">
  <div class="row p-fluid">
    <div class="md:col-4">
      <div class="col-12">
        <h2>Đăng nhập</h2>
        <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p> -->
        <form #formElement [formGroup]="formGroup">
          <div class="field">
            <input pInputText type="text" placeholder="Tài khoản" formControlName="username"
              [(ngModel)]="model.username" />
          </div>
          <div class="field">
            <input pInputText placeholder="Mật khẩu" type="password" formControlName="password"
              [(ngModel)]="model.password" />
          </div>
          <div class="field">
            <p-captcha siteKey="6LfzQjooAAAAAJee0JvagiW69Pd1jANfEVmHNtzq"
              (onResponse)="captchaResponse($event)"></p-captcha>
          </div>
          <div class="flex justify-content-center mb-3">
            <div class="align-items-center justify-content-center">
              <a routerLink="/authentication/forgot-password">Quên mật khẩu</a>
            </div>
          </div>
          <div class="field">
            <button class="default-btn w-100 h-3rem" (click)="doSignIn()">Đăng nhập<span></span></button>
          </div>
          <div class="col-12">
            <p-button icon="pi pi-google" styleClass="p-button-rounded p-button-text" label="Đăng nhập bằng Google"
              (onClick)="signInWithGoogle()">
            </p-button>
            <p-button icon="pi pi-facebook" styleClass="p-button-rounded p-button-text" label="Đăng nhập bằng Facebook"
              (onClick)="signInWithFacebook()">
            </p-button>
          </div>
        </form>
      </div>
      <div class="flex justify-content-center">
        <div class="align-items-center justify-content-center">
          Bạn chưa có tài khoản? <a routerLink="/authentication/signup">Đăng ký</a>
        </div>
      </div>
    </div>
    <div class="md:col-8">
      <img src="assets/images/sign-in.png" alt="image">
    </div>
  </div>
</div>