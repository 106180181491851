import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';
import { ForgotPasswordComponent } from './pages/account/forgot-password/forgot-password.component';
import { ProfileComponent } from './pages/account/profile/profile.component';
import { SignInComponent } from './pages/account/sign-in/sign-in.component';
import { SignUpComponent } from './pages/account/sign-up/sign-up.component';
import { StudentDashboardComponent } from './pages/account/student-dashboard/student-dashboard.component';
import { CourseDetailComponent } from './pages/courses/course-detail/course-detail.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { NewsDetailComponent } from './pages/news/newsDetail/newsDetail.component';
import { RankingComponent } from './pages/account/ranking/ranking.component';
import { SchoolComponent } from './pages/school/school.component';
import { HomeComponent } from './pages/home/home.component';
import { NewsComponent } from './pages/news/news.component';
import { LearningLayoutComponent } from './layouts/learning-layout/learning-layout.component';
import { LearningSpaceV3Component } from './pages/learning-space-v3/learning-space-v3.component';
import { LearningHistoryComponent } from './pages/account/learning-history/learning-history.component';
import { AdminRedirectComponent } from './pages/admin-redirect/admin-redirect.component';
import { AuthorsComponent } from './pages/authors/authors.component';
import { AuthorDetailComponent } from './pages/authors/author-detail/author-detail.component';
import { ScormTestComponent } from './pages/scorm-test/scorm-test.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DocumentDetailComponent } from './pages/documents/document-detail/document-detail.component';
import { ExamsComponent } from './pages/exams/exams.component';
import { ExamDetailComponent } from './pages/exams/exam-detail/exam-detail.component';
import { ExamingComponent } from './pages/exams/examing/examing.component';
import { WorkshopComponent } from './pages/workshop/workshop.component';
import { WorkshopDetailComponent } from './pages/workshop/workshop-detail/workshop-detail.component';
import { ShoppingCartChildrenComponent } from './pages/shopping-cart-children/shopping-cart-children.component';
import { ChildrenComponent } from './pages/account/children/children.component';
import { LearningHistoryChildrenComponent } from './pages/account/learning-history-children/learning-history-children.component';
import { FavouriteCategoryComponent } from './pages/account/favourite-category/favourite-category.component';
import { ErrorComponent } from './pages/error/error.component';
import { AboutUsComponent } from './pages/aboutus/about-us/about-us.component';
import { PrivacyComponent } from './pages/aboutus/privacy/privacy.component';
import { PaymentComponent } from './pages/aboutus/payment/payment.component';
import { ResetPasswordComponent } from './pages/account/reset-password/reset-password.component';
import { RefundComponent } from './pages/aboutus/refund/refund.component';
import { ShippingComponent } from './pages/aboutus/shipping/shipping.component';
import { PaymentHistoryComponent } from './pages/account/payment-history/payment-history.component';
import { DocumentDetailAppComponent } from './pages/documents/document-detail-app/document-detail-app.component';
import { DocumentDetailApp2Component } from './pages/documents/document-detail-app2/document-detail-app2.component';


const routes: Routes = [
  {
    path: '', component: DefaultLayoutComponent,
    children: [
      { path: '', component: HomeComponent, data: { title: 'Trang chủ' } },
      { path: 'authentication/signin', component: SignInComponent, data: { title: 'Đăng nhập' } },
      { path: 'authentication/signup', component: SignUpComponent, data: { title: 'Đăng ký' } },
      { path: 'authentication/forgot-password', component: ForgotPasswordComponent, data: { title: 'Quên mật khẩu' } },
      { path: 'authentication/reset-password/:instanceId/:code', component: ResetPasswordComponent, data: { title: 'Đặt lại mật khẩu' } },
      { path: 'profile/dashboard', component: StudentDashboardComponent, data: { title: 'Trang chủ học viên' } },
      { path: 'profile', component: ProfileComponent, data: { title: 'Thông tin cá nhân' } },
      { path: 'profile/change-password', component: ChangePasswordComponent, data: { title: 'Đổi mật khẩu' } },
      { path: 'profile/ranking', component: RankingComponent, data: { title: 'Xêp shangj' } },
      { path: 'profile/learning-history', component: LearningHistoryComponent, data: { title: 'Lịch sử học tập' } },
      { path: 'profile/children', component: ChildrenComponent, data: { title: 'Con' } },
      { path: 'child/learning-history', component: LearningHistoryChildrenComponent, data: { title: 'Lịch sử học tập của con' } },
      { path: 'profile/favourite', component: FavouriteCategoryComponent, data: { title: 'Chuyên mục yêu thích' } },
      { path: 'profile/payment-history', component: PaymentHistoryComponent, data: { title: 'Lịch sử thanh toán' } },

      { path: 'courses', component: CoursesComponent, data: { title: 'Khóa học' } },
      { path: 'courses/:id', component: CoursesComponent, data: { title: 'Chi tiết khóa học' } },
      { path: 'courses/detail/:id', component: CourseDetailComponent, data: { title: 'Chi tiết khóa học' } },
      { path: 'about', component: AboutUsComponent, data: { title: 'Giới thiệu' } },
      { path: 'privacy/sercurity', component: PrivacyComponent, data: { title: 'Chính sách bảo mật' } },
      { path: 'privacy/payment', component: PaymentComponent, data: { title: 'Chính sách thanh toán' } },
      { path: 'privacy/refund', component: RefundComponent, data: { title: 'Chính sách đổi, hủy và hoàn trả' } },
      { path: 'privacy/shipping', component: ShippingComponent, data: { title: 'Chính sách giao hàng' } },
      { path: 'school', component: SchoolComponent, data: { title: 'Trường học' } },

      { path: 'news', component: NewsComponent, data: { title: 'Tin tức' } },
      { path: 'news/:id', component: NewsComponent, data: { title: 'Tin theo chuyên mục' } },
      { path: 'news/detail/:id', component: NewsDetailComponent, data: { title: 'Chi tiết tin tức' } },
      { path: 'shopping-cart', component: ShoppingCartComponent, data: { title: 'Giỏ hàng' } },
      { path: 'shopping-cart/children', component: ShoppingCartChildrenComponent, data: { title: 'Giỏ hàng của con' } },

      { path: 'school/:id', component: SchoolComponent, data: { title: 'Trường học' } },

      { path: 'authors', component: AuthorsComponent, data: { title: 'Tác giả' } },
      { path: 'authors/detail/:id', component: AuthorDetailComponent, data: { title: 'Đổi mật khẩu' } },
      { path: 'document', component: DocumentsComponent, data: { title: 'Tài liệu' } },
      { path: 'document/:id', component: DocumentsComponent, data: { title: 'Tài liệu theo chuyên mục' } },
      { path: 'document/detail/:id', component: DocumentDetailComponent, data: { title: 'Chi tiết tài liệu' } },

      { path: 'exam', component: ExamsComponent, data: { title: 'Đợt thi' } },
      { path: 'exam/:id', component: ExamsComponent, data: { title: 'Đợt thi theo chuyên mục' } },
      { path: 'exam/detail/:id', component: ExamDetailComponent, data: { title: 'Chi tiết đợt thi' } },
      { path: 'workshop', component: WorkshopComponent, data: { title: 'Hội thảo' } },
      { path: 'workshop/detail/:id', component: WorkshopDetailComponent, data: { title: 'Chi tiết hội thảo' } },
    ],
  },
  {
    path: 'examing', component: LearningLayoutComponent,
    children: [
      { path: ':id', component: ExamingComponent, data: { title: 'Thi' } }
    ]
  },
  {
    path: 'learning-space', component: LearningLayoutComponent,
    children: [
      { path: ':id', component: LearningSpaceV3Component, data: { title: 'Không gian khóa học' } }
    ]
  },
  { path: 'scorm-test/:id', component: ScormTestComponent, data: { title: 'SCORM' } },
  { path: 'admin', children: [{ path: '**', component: AdminRedirectComponent }] },
  {
    path: 'app', children: [
      { path: 'document-detail/:id', component: DocumentDetailAppComponent, data: { title: 'Chi tiết tài liệu' } },
      { path: 'course-attachment-detail/:id', component: DocumentDetailApp2Component, data: { title: 'Chi tiết tài liệu' } },
    ]
  },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
