export enum TestQuestionType {
  MC = 1,
  CL = 2,
  GF = 3,
  SA = 4,
  SB = 5,
  SQ = 6,
  MA = 7,
  UP = 8,
  TF = 9,
  TFNG = 10,
  YN = 11,
  SP = 12
}

export enum CategoryType {
  Courses = 1,
  News = 2,
  Document = 3,
  Testing = 4,
}

export enum CourseItemType {
  Group = 1,
  HocLieu_Video = 2,
  NhiemVu_Test = 3,
}

export enum UserTypeId {
  CanBoGiaoVien = 1,
  HocVien = 2,
}


export enum EnumTrangThaiTestExamUser {
  ChuaThi = 1,
  DangThi = 2,
  KetThucThi = 3,
  ChamXong = 4,
  HuyBaiThi = 5,
}

export enum CategoryLearningStatus {
  IsLearing = 2,
  IsFinish = 1,
}
