import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'avatar'
})
export class AvatarPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (!value) {
            return "assets/images/avatar-default.png"
        }
        return value;
    }

}
