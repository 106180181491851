import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {

  listMembers = [
    { displayName: 'Nguyễn Văn A', rank: 1, point: 10000 },
    { displayName: 'Nguyễn Văn A', rank: 2, point: 10000 },
    { displayName: 'Nguyễn Văn A', rank: 3, point: 10000 },
    { displayName: 'Nguyễn Văn A', rank: 4, point: 10000 },
    { displayName: 'Nguyễn Văn A', rank: 5, point: 10000 },
    { displayName: 'Nguyễn Văn A', rank: 6, point: 10000 },
    { displayName: 'Nguyễn Văn A', rank: 7, point: 10000 },
    { displayName: 'Nguyễn Văn A', rank: 8, point: 10000 },
    { displayName: 'Nguyễn Văn A', rank: 9, point: 10000 },
    { displayName: 'Nguyễn Văn A', rank: 10, point: 10000 },
  ];

  cols = [
    { field: 'rank', header: 'Xếp hạng', visible: true, width: '20%', align: 'text-center' },
    { field: 'displayName', header: 'Họ và tên', visible: true, width: 'auto', align: 'text-center' },
    { field: 'point', header: 'Điểm', visible: true, width: '20%', align: 'text-right' },
  ];

  constructor() { }

  ngOnInit() {

  }

}
