<div class="single-courses-box-list">
  <div class="courses-image">
    <a routerLink="/exam/detail/{{item.instanceId}}">
      <img src="{{item.imageUrl}}" alt="">
    </a>
  </div>
  <div class="courses-content">
    <a routerLink="/exam/detail/{{item.instanceId}}">
      <h3 class="title mb-0">{{item.title}}</h3>
    </a>
    <div class="description">
      {{item.headline}}
    </div>
    <a routerLink="/authors/detail/{{item.idTenant}}">
      <div class="author">{{item.displayNameTenant}}</div>
    </a>
    <div class="row">
      <div class="price-text md:col-6 sm:col-12">
        <div class="original-price">{{item.price | currency: "VND"}} </div>
        <!-- <div class="discount-price">2.099.000 đ </div> -->
      </div>
    </div>
  </div>
</div>
