import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BannerService } from '../../../services/banner.service';

@Component({
  selector: 'app-section-banner',
  templateUrl: './section-banner.component.html',
  styleUrls: ['./section-banner.component.scss']
})
export class SectionBannerComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      }
    },
  }


  dataSource = [];
  @Input() idSysSchool = 0;

  constructor(
    private _bannerService: BannerService
  ) { }

  ngOnInit() {
    this.getsBanner();
  }

  getsBanner() {
    this._bannerService.getsAllByIdSchool(this.idSysSchool).then(res => {
      if (res.success) {
        this.dataSource = res.data;
      }
    })
  }

}
