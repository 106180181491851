<app-nav></app-nav>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li class="li-active">Giỏ hàng của con</li>
      </ul>
      <h2>{{title}}</h2>
      <!-- <p>{{itemDetail?.subject}} </p> -->
    </div>
  </div>
</div>
<section class="newsDetail mt-5">
  <div class="container" *ngIf="!showPayResult">
    <div class="row">
      <div class="col-8 pr-5">
        <ng-container *ngFor="let item of dataSource">
          <div class="single-courses-box-list">
            <div class="courses-image">
              <a routerLink="/courses/detail/{{item.instanceId}}">
                <img src="{{item.imageUrl}}" alt="">
              </a>
            </div>
            <div class="courses-content">
              <a routerLink="/courses/detail/{{item.instanceId}}">
                <h3 class="title mb-0">{{item.title}}</h3>
              </a>
              <div class="description">
                {{item.headline}}
              </div>
              <a routerLink="/authors/detail/{{item.idTenant}}">
                <div class="author">{{item.displayNameTenant}}</div>
              </a>
              <div class="row">
                <div class="price-text md:col-6 sm:col-12">
                  <div class="original-price">{{item.price | currency: "VND"}} </div>
                  <!-- <div class="discount-price">2.099.000 đ </div> -->
                </div>
                <div class="courses-rating md:col-6 sm:col-12 ">
                  <ng-rating-bar [value]="item.avgRatingStar ?? 5" [ratingCount]="5" colorActive="#F2A000"
                    colorDefault="gray" [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
                  <span class="reviews">({{item.countComment ?? 0}})</span>
                </div>
              </div>
              <div class="courses-info"><span class="courses-time">
                  Độ tuổi: {{{ageFrom: item.ageFrom, ageTo: item.ageTo} |
                  age}}</span> · <span>{{item.titleCatLevel == null ? "Tất cả các trình độ": item.titleCatLevel}}</span>
              </div>
              <button class="third-btn" (click)="deleteCourse(item.id)">Xóa<span></span></button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-4">
        <div class="row">
          <label>Tổng tiền khóa học</label>
          <h4>{{totalPriceCourse | currency: 'VND'}}</h4>
        </div>
        <ng-container *ngIf="comboInfo.id == 0">
          <ng-container *ngIf="listAllPromotion.length > 0">
            <div class="row">
              <label>Khuyến mại có thể sử dụng</label>
              <div class="p-inputgroup">
                <ng-container *ngFor="let item of listAllPromotion">
                  <p-tag icon="pi pi-check" value="{{item.code}} : {{item.promotionRate}} %" severity="success"
                    class="cursor-pointer" (click)="addPromotionCode(item)"></p-tag>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedPromotion?.id > 0">
            <div class="row">
              <label>Khuyến mại đã chọn</label>
              <div class="row mt-2 mb-2">
                <p-tag class="cursor-pointer" icon="pi pi-times cursor-pointer" *ngIf="selectedPromotion?.id > 0"
                  value="{{selectedPromotion.code}} : {{selectedPromotion.promotionRate}} %" severity="danger"
                  (click)="deletePromotionCode()"></p-tag>
              </div>
              <div class="row mt-2 mb-2" *ngIf="selectedPromotion?.id > 0">
                <label>Tổng tiền khuyến mại</label>
                <h4>{{totalPricePromotion | currency: 'VND'}}</h4>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="comboInfo.id > 0">
          <label>Khuyến mại combo</label>
          <div class="row mt-2 mb-2">
            <p-tag class="cursor-pointer" icon="pi pi-times cursor-pointer"
              value="{{comboInfo.title}} : giảm giá {{comboInfo.promotionRate}} %" severity="danger"></p-tag>
          </div>
        </ng-container>
        <div class="row mt-2 mb-2">
          <label>Số tiền cần thanh toán</label>
          <h4>{{(totalPriceCourse - totalPricePromotion) | currency: 'VND'}}</h4>
        </div>

        <div class="row">
          <ng-container *ngIf="currentUser.typeId != 10 ">
            <button class="default-btn mb-2" (click)="processPayment()" [disabled]="dataSource.length == 0">
              Thanh toán (VNPAY)<span></span>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

</section>
