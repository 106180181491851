import { Component, OnInit } from '@angular/core';
import { UsersTrackingTimeService } from '../../services/users-tracking-time.service';
import { AuthenticationService, BasicUserInfo } from 'sv-shared';

@Component({
  selector: 'app-learning-layout',
  templateUrl: './learning-layout.component.html',
  styleUrls: ['./learning-layout.component.scss']
})
export class LearningLayoutComponent implements OnInit {
  currentUser = new BasicUserInfo();
  // timerId: any = null;

  constructor(
    private _usersTrackingTimeService: UsersTrackingTimeService,
    private _authenticationService: AuthenticationService,
  ) {
    document.getElementById('theme').setAttribute('href', './assets/learning-space-v3/main.css');
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  ngOnInit() {
    // if (this.currentUser?.userId > 0) {

    // this.timerId = setInterval(() => {
    //   this._usersTrackingTimeService.ping().then(rs => { console.log(rs); });
    // }, 120000);

    // }
  }

}
