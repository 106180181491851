<div class="news-item-type-one">
  <div class="news-image">
    <a routerLink="/news/detail/{{item.instanceId}}">
      <img src="{{item.imageUrl}}" alt="">
    </a>
  </div>
  <div class="news-content ml-2">
    <a routerLink="/news/detail/{{item.instanceId}}">
      <h4 class="title">
        {{item.title}}
      </h4>
    </a>
    <div class="description">
      {{item.subject}}
    </div>
  </div>
</div>
