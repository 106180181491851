import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, map, takeUntil } from 'rxjs';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss']
})
export class SchoolComponent implements OnInit {

  public _unsubscribeAll: Subject<any>;
  idSysSchool = 0;

  constructor(
    private _activatedRoute: ActivatedRoute,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        this.idSysSchool = pid;
      });

  }

}
