import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SysSchoolService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.coreEndPoint}/sysschool`);
  }

  getSelectedSchool(): number {
    if (sessionStorage.getItem('idSysSchool')) {
      return parseInt(sessionStorage.getItem('idSysSchool'));
    } else {
      var schools = JSON.parse(sessionStorage.getItem('schools'));
      var idSysSchool = schools[0].id;
      sessionStorage.setItem('idSysSchool', idSysSchool.toString());
      return idSysSchool;
    }
  }

  getsSchool() {
    var schools = sessionStorage.getItem('schools');
    if (schools) {
      return JSON.parse(schools);
    } else {
      this.getsAll().then(res => {
        if (res.success) {
          sessionStorage.setItem('schools', JSON.stringify(res.data));
          return res.data;
        }
      })
    }
  }
  setSelectedSysSchool(idSysSchool: number) {
    sessionStorage.setItem('idSysSchool', idSysSchool.toString());
  }
}
