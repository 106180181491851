import { Component, OnInit } from '@angular/core';
import { TenantsService } from '../../../services/tenants.service';

@Component({
  selector: 'app-section-random-tenant',
  templateUrl: './section-random-tenant.component.html',
  styleUrls: ['./section-random-tenant.component.scss']
})
export class SectionRandomTenantComponent implements OnInit {
  listTenants = [];

  constructor(
    private _tenantsService: TenantsService,
  ) { }

  ngOnInit() {
    this._tenantsService.getsRandomTenant(4).then((rs) => {
      if (rs.success) {
        this.listTenants = rs.data;
      }
    })
  }

}
