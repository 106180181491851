<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li>Dashboard học viên</li>
      </ul>
      <h2>Dashboard học viên</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<div class="container mt-6 mb-6">
  <div class="row">
    <h3>Thống kê</h3>
  </div>
  <div class="row">
    <div class="md:col-3">
      <div class="col-12">
        <div class="flex overview-box box-1">
          <div class="box-icon flex align-items-center justify-content-center mr-2"><i class="pi pi-clone"></i></div>
          <div class="box-title">
            Chương trình đào tạo
            <div class="box-count">0</div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="flex overview-box box-2">
          <div class="box-icon flex align-items-center justify-content-center mr-2"><i class="pi pi-clone"></i></div>
          <div class="box-title">
            Khóa học của tôi
            <div class="box-count">{{countInfo.countCourse}}</div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="flex overview-box box-3">
          <div class="box-icon flex align-items-center justify-content-center mr-2"><i class="pi pi-clone"></i></div>
          <div class="box-title">
            Chứng chỉ
            <div class="box-count">{{countInfo.countCertificate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:col-9">
      <div class="row p-fluid">
        <div class="md:col-9">
          <h5>Thống kê thời lượng sử dụng</h5>
        </div>
        <div class="md:col-3 float-end">
          <p-dropdown [options]="listChartOptions" [(ngModel)]="days"></p-dropdown>
        </div>
      </div>
      <div class="col-12" style="height: 300px;position: relative; ">
        <p-chart type="line" [data]="dataChart" [options]="options" [height]="90"></p-chart>
      </div>
    </div>
  </div>
</div>
<div class=" container mt-6 mb-6">
  <div class="row">
    <div class="lg:col-6 md:col-12 pr-4">
      <div class="row">
        <div class="course-box">
          <div class="section-header">
            <h3>Lịch sử học tập</h3>
            <a routerLink="/profile/learning-history" class="all-link">Xem tất cả</a>
          </div>
          <div class="section-body pr-1">
            <ng-container *ngFor="let item of listMyLearningCourse">
              <article class="item flex">
                <a routerLink="/courses/detail/{{item.instanceId}}" class="thumb">
                  <span class="fullimage" style="background-image: url({{item.imageUrl}});"></span>
                </a>
                <div class="info flex-1">
                  <h4 class="title usmall">
                    <a routerLink="/courses/detail/{{item.instanceId}}">
                      {{item.title}}
                    </a>
                  </h4>
                  <span><i class="pi pi-clock mr-2"></i>{{item.startDate | date:'dd/MM/yyyy'}}</span>
                </div>
                <div class="progress-bar flex" class="bg-{{getPercentComplete(item)}}"></div>
              </article>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:col-6 md:col-12">
      <div class="row">
        <div class="course-box">
          <div class="section-header">
            <h3>Top 5 khóa học phổ biến</h3>
            <a routerLink="/courses" class="all-link">Xem tất cả</a>
          </div>
          <div class="section-body pr-1">
            <ng-container *ngFor="let item of listHotCourse">
              <article class="item flex">
                <a routerLink="/courses/detail/{{item.instanceId}}" class="thumb">
                  <span class="fullimage" style="background-image: url({{item.imageUrl}});"></span>
                </a>
                <div class="info flex-1">
                  <h4 class="title usmall">
                    <a routerLink="/courses/detail/{{item.instanceId}}">
                      {{item.title}}
                    </a>
                  </h4>
                  <span>Độ tuổi: {{{ageFrom: item.ageFrom, ageTo: item.ageTo} | age}} -
                    {{item.titleCatLevel == null ? "Tất cả các trình độ": item.titleCatLevel}}
                  </span>
                </div>
              </article>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="listMyExamHistory?.length > 0">
  <div class="container mt-6 mb-6">
    <div class="container">
      <h3>Lịch sử thi</h3>
      <div class="row">
        <p-table [value]="listMyExamHistory" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5">
          <ng-template pTemplate="header">
            <tr>
              <th>Đợt thi</th>
              <th>Thời gian bắt đầu</th>
              <th>Thời gian kết thúc</th>
              <th>Số câu trả lời đúng</th>
              <th>Số câu trả lời sai</th>
              <th>Số câu không trả lời</th>
              <th>Tổng điểm</th>
              <th>Điểm yêu cầu</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td>{{ rowData.titleExam }}</td>
              <td>{{ rowData.startTime | date :'dd/MM/yyyy hh:mm' }}</td>
              <td>{{ rowData.endTime | date :'dd/MM/yyyy hh:mm' }}</td>
              <td>{{ rowData.numQTrue }}</td>
              <td>{{ rowData.numQFalse }}</td>
              <td>{{ rowData.numQNoAns }}</td>
              <td>{{ rowData.finalMark ?? 0 }} / {{rowData.totalMark}}</td>
              <td>{{ rowData.requiredMark }} / {{rowData.totalMark}}</td>

            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="listCourseByUserCategory?.length > 0">
  <div class="container mt-6 mb-6">
    <div class="container">
      <h3>Khóa học mới</h3>
      <div class="row">
        <ng-container *ngFor="let item of listCourseByUserCategory">
          <div class="lg:col-3 md:col-6 sm:col-12">
            <app-single-course-box-grid [item]="item"></app-single-course-box-grid>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<div class="container mt-6 mb-6">
  <div class="container">
    <h3>Khóa học được mua nhiều nhất</h3>
    <div class="row">
      <ng-container *ngFor="let item of listBestSellerCourse">
        <div class="lg:col-3 md:col-6 sm:col-12">
          <app-single-course-box-grid [item]="item"></app-single-course-box-grid>
        </div>
      </ng-container>

    </div>
  </div>
</div>
<!-- <div class=" container mt-6 mb-6">
  <div class="row">
    <div class="lg:col-6 md:col-12 pr-4">
      <div class="row">
        <div class="course-box" style="height: 480px;">
          <div class="section-header">
            <h3>Xếp hạng thi đua</h3>
          </div>
          <div class="section-body pr-1">
            <table class="table-rank">
              <thead>
                <tr>
                  <th style="width: 15%;">HẠNG</th>
                  <th>HỌC VIÊN</th>
                  <th style="width: 15%;" class="text-center">ĐIỂM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <div class="client-info d-flex align-items-center">
                      <img src="assets/images/user/user1.jpg" alt="image" class="rounded-circle">
                      <div class="title">
                        <h3>Nguyễn Anh Tú</h3>
                        <span>Đăng ký từ ngày 12/09/2019</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <img src="assets/images/icons/medal-star.png" alt="image"> <br />
                    <span>15000</span>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <div class="client-info d-flex align-items-center">
                      <img src="assets/images/user/user1.jpg" alt="image" class="rounded-circle">
                      <div class="title">
                        <h3>Nguyễn Anh Tú</h3>
                        <span>Đăng ký từ ngày 12/09/2019</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">12993</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <div class="client-info d-flex align-items-center">
                      <img src="assets/images/user/user1.jpg" alt="image" class="rounded-circle">
                      <div class="title">
                        <h3>Nguyễn Anh Tú</h3>
                        <span>Đăng ký từ ngày 12/09/2019</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">12993</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <div class="client-info d-flex align-items-center">
                      <img src="assets/images/user/user1.jpg" alt="image" class="rounded-circle">
                      <div class="title">
                        <h3>Nguyễn Anh Tú</h3>
                        <span>Đăng ký từ ngày 12/09/2019</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">12993</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:col-6 md:col-12">
      <div class="row">
        <div class="course-box" style="height: 480px;">
          <div class="section-header">
            <h3>Huy hiệu gần đây</h3>
          </div>
          <div class="section-body pr-1">
            <article class="item flex">
              <a class="thumb">
                <span class="fullimage" style="background-color: #2D368E;">
                  <img src="assets/images/icons/medal-1.png" alt="img">
                </span>
              </a>
              <div class="info flex-1">
                <h4 class="title usmall">
                  Bền bỉ (Cấp độ Gold)
                </h4>
                <span class="mt-0 mb-0"><i class="pi pi-clock mr-2"></i>Đạt được ngày 04/04/2023</span>
                <span class="mt-0 mb-0">224 người</span>
              </div>
            </article>
            <article class="item flex">
              <a class="thumb">
                <span class="fullimage" style="background-color: #2D368E;">
                  <img src="assets/images/icons/medal-1.png" alt="img">
                </span>
              </a>
              <div class="info flex-1">
                <h4 class="title usmall">
                  Bền bỉ (Cấp độ Gold)
                </h4>
                <span class="mt-0 mb-0"><i class="pi pi-clock mr-2"></i>Đạt được ngày 04/04/2023</span>
                <span class="mt-0 mb-0">224 người</span>
              </div>
            </article>
            <article class="item flex">
              <a class="thumb">
                <span class="fullimage" style="background-color: #FFBD00;">
                  <img src="assets/images/icons/medal-2.png" alt="img">
                </span>
              </a>
              <div class="info flex-1">
                <h4 class="title usmall">
                  Bền bỉ (Cấp độ Silver)
                </h4>
                <span class="mt-0 mb-0"><i class="pi pi-clock mr-2"></i>Đạt được ngày 04/04/2023</span>
                <span class="mt-0 mb-0">224 người</span>
              </div>
            </article>
            <article class="item flex">
              <a class="thumb">
                <span class="fullimage" style="background-color: #CFCFCF;">
                  <img src="assets/images/icons/medal-2.png" alt="img">
                </span>
              </a>
              <div class="info flex-1">
                <h4 class="title usmall">
                  Bền bỉ (Cấp độ Silver)
                </h4>
                <span class="mt-0 mb-0"><i class="pi pi-clock mr-2"></i>Đạt được ngày 04/04/2023</span>
                <span class="mt-0 mb-0">224 người</span>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
