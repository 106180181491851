<div class="single-courses-box-grid">
  <div class="courses-image">
    <a routerLink="/exam/detail/{{item.instanceId}}">
      <img src="{{item.imageUrl}}" alt="">
    </a>
  </div>
  <div class="courses-content">
    <a routerLink="/exam/detail/{{item.instanceId}}">
      <h3 class="title">{{item.title}}</h3>
    </a>
    <a routerLink="/authors/detail/{{item.idTenant}}">
      <h5 class="author">{{item.displayNameTenant}}</h5>
    </a>
    <div class="row">
      <div class="price-text md:col-4 sm:col-12">
        <div class="original-price">{{item.price | currency: 'VND'}}</div>
      </div>
    </div>
  </div>
</div>
