import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VnPayLogService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.financialEndpoint}/vnpaylog`);
  }

  createPayRequest(model): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/CreatePayRequest`;
    return this._http
      .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  updatePaymentResponse(model): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/UpdatePaymentResponse`;
    return this._http
      .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  createChildPayRequest(instanceId: string, model): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/CreateChildPayRequest/${instanceId}`;
    return this._http
      .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

}
