import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SysSetting } from '../models/sys-setting';

@Injectable({
  providedIn: 'root'
})
export class SysSettingService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.coreEndPoint}/SysSetting`);
  }

  updateSettings(model: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/UpdateSettings`;
    return this._http
      .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  getByCode(code: string): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetByCode?code=${code}`;
    return this.defaultGet(apiUrl);
  }

  getsForClient(): Promise<[]> {

    const storageItem = sessionStorage.getItem('sysSetting');
    const listSettings = <[]>JSON.parse(storageItem);

    if (listSettings && listSettings.length > 0) {
      return new Promise((resolve, reject) => resolve(listSettings));
    } else {
      return new Promise((resolve, reject) => {
        const apiUrl = `${this.serviceUri}/GetsForClient`;
        this._http.get<ResponseResult>(apiUrl).pipe(
          shareReplay(this.REPLAY_COUNT),
          retry(this.RETRY_COUNT),
          catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)),
          map(repsonse => <[]>repsonse.data)
        ).toPromise().then(success => resolve(success)).catch(err => {
          reject(err);
        });
      });
    }
  }

}
