import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-comment-item-type-three',
  templateUrl: './comment-item-type-three.component.html',
  styleUrls: ['./comment-item-type-three.component.scss']
})
export class CommentItemTypeThreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input() item: any

}
