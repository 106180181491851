import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, map, takeUntil } from 'rxjs';
import { AuthenticationService, BasicUserInfo } from 'sv-shared';
import { ItemCommentService } from '../../../services/item-comment.service';
import { NewsService } from '../../../services/news.service';

@Component({
  selector: 'app-newsDetail',
  templateUrl: './newsDetail.component.html',
  styleUrls: ['./newsDetail.component.scss']
})
export class NewsDetailComponent implements OnInit {
  _unsubscribeAll: Subject<any>;
  itemDetail: { title: '', subject: '', bodyHtml: '', idCategory: 0, id: 0, author: '', publishedDate: '' };
  id = 0;

  dataSource = [];
  dataCommentSource = [];
  totalCommentCount = 0;
  itemType = 1;
  pageIndexComment = 1;
  pageIndexCommentSize = 5;
  currentUser: BasicUserInfo = new BasicUserInfo();
  bodyHtml = '';
  rating = 5;

  captchaIsValid = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _newsService: NewsService,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _itemCommentService: ItemCommentService,
    private _titleTagService: Title,
  ) {
    this._unsubscribeAll = new Subject();
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.id = pid;
          this.getDetail(pid);
        } else {
          this._router.navigate(['/news']);
        }

      });
  }

  getDetail(instanceId) {
    this._newsService.getByInstanceId(instanceId).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
        this.getData();
        this.getsCommentData();
        this._titleTagService.setTitle(`${this._titleTagService.getTitle()} | ${rs.data.title}`);
      }
    });
  }

  getData() {
    var searchModel = {
      keyword: '',
      listIdCategory: [this.itemDetail.idCategory],
      status: 1,
      pageIndex: 1,
      pageSize: 6,
      orderCol: 'Id',
      isDesc: true,
    };
    this._newsService.find(searchModel)
      .then(rs => {
        if (rs.success) {
          this.dataSource = rs.data.filter(x => (x.id - 0) != (this.id - 0));
        }

      });
  }

  getsCommentData() {
    var searchModel = {
      keyword: '',
      itemType: this.itemType,
      itemId: this.itemDetail.id,
      pageIndex: this.pageIndexComment,
      pageSize: this.pageIndexCommentSize,
    }
    this._itemCommentService.find(searchModel).then(rs => {
      if (rs.success) {
        this.dataCommentSource = rs.data;
        this.totalCommentCount = rs.totalRecord;
      }
    })
  }

  addItemComment() {
    if (this.bodyHtml != '' && this.captchaIsValid) {
      var model = {
        itemType: this.itemType,
        itemId: this.itemDetail.id,
        bodyHtml: this.bodyHtml,
        rating: this.rating,
        status: 1,
      }
      this._itemCommentService.post(model).then(rs => {
        if (rs.success) {
          this.bodyHtml = '';
          this.getsCommentData();
        }
      });
    }
  }

  captchaResponse(event) {
    if (event) {
      this.captchaIsValid = true;
    }
  }

}
