import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.coreEndPoint}/categories`);
  }
  getTree(idSchool: number, idCategoryType: number, status: number = 1) {
    const queryString = `${this.serviceUri}/GetTree/${idSchool}/${idCategoryType}/${status}`;
    return this.defaultGet(queryString);
  }

  getTreeTableByIdParent(idSchool: number, idCategoryType: number, idParent: number) {
    const queryString = `${this.serviceUri}/GetTreeTableByIdParent/${idSchool}/${idCategoryType}/${idParent}`;
    return this.defaultGet(queryString);
  }

  getsByIdParent(idParent: number) {
    const queryString = `${this.serviceUri}/GetsByIdParent/${idParent}`;
    return this.defaultGet(queryString);
  }

  getsHotByIdSysSchool(idSysSchool: number) {
    const queryString = `${this.serviceUri}/GetsHotByIdSysSchool/${idSysSchool}`;
    return this.defaultGet(queryString);
  }

}
