<app-nav></app-nav>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li class="li-active">Hội thảo</li>
      </ul>
      <h2>Hội thảo</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<section class="newsList-section mt-5">
  <div class="container">
    <div class="row filter-nav flex align-items-center">
      <div class="col-3">
        <!-- <p-button icon="{{isShowFilter ? 'pi pi-times': 'pi pi-sliders-h'}}" label="Bộ lọc" styleClass="p-button-text"
          (onClick)="isShowFilter = ! isShowFilter"></p-button> -->
      </div>
      <div class="col-9 flex justify-content-end filter-nav--buttons">
        <button class="second-btn p-2 mr-2" (click)="changeOrderBy('id')">Mới nhất<span></span></button>
        <button class="second-btn p-2 mr-2" (click)="changeOrderBy('viewCount')">Xem nhiều nhất<span></span></button>
      </div>
    </div>
    <div class="row">
      <div class="{{isShowFilter ? 'col-12 md:col-12 lg:col-12' : 'col-12 md:col-12 lg:col-12 search-result-wrapper'}}">
        <div class="row">
          <ng-container *ngFor="let item of dataSource">
            <div class="{{isShowFilter ? 'lg:col-3 md:col-3 sm:col-12' : 'lg:col-3 md:col-6 sm:col-12'}} ">
              <app-single-workshop-grid [item]="item"></app-single-workshop-grid>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>