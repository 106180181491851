<app-nav></app-nav>
<section class="course-detail-section ">
  <div class="container-fluid dark-background">
  </div>
  <div class="container course-detail-container">
    <div class="row">
      <div class="col-12 md:col-12 lg:col-8">
        <!-- page title -->
        <div class="row">
          <div class="col-12">
            <div class="page-title-area-course">
              <div class="container">
                <div class="page-title-course-content">
                  <ul>
                    <li><a routerLink="/">Trang chủ</a></li>
                    <li><a routerLink="/courses">Đợt thi</a></li>
                    <li class="li-active">Chi tiết đợt thi</li>
                  </ul>
                  <div class="course-bio">
                    <h2 class="mt-3">{{itemDetail.title}}</h2>
                    <p> {{itemDetail.subject}} </p>
                    <div class="flex align-items-center">
                      <button class="default-btn px-3" *ngIf="itemDetail.enableRegister != true">
                        Không cho phép đăng ký<span></span></button>
                      <button class="default-btn px-3" *ngIf="itemDetail.enableRegister == true">
                        Học sinh có thể đăng ký<span></span></button>
                    </div>
                    <p class="mt-2 mb-1">Được tạo bởi <span class="author underline"><a style="color: white;"
                          routerLink="authors/detail/{{itemDetail.idTenant}}">{{itemDetail.displayNameTenant}}</a></span>
                    </p>
                    <div class="flex">
                      <div class="flex align-items-center">
                        <i class="pi pi-clock"></i>
                        <span class="ml-2 mr-5">
                          Thời gian thi {{itemDetail.startTime | date: "dd/MM/yyyy"}} đến {{itemDetail.endTime | date:
                          "dd/MM/yyyy"}}
                        </span>
                      </div>
                      <div class="flex align-items-center">
                        <i class="pi pi-clock"></i>
                        <span class="ml-2 mr-5">
                          Thời gian làm bài: {{itemDetail.testTime}} phút
                        </span>
                      </div>
                      <!-- <div class="flex align-items-center">
                        <i class="pi pi-globe"></i><span class="ml-2">Vietnamese</span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12"> -->
          <div class="course-info course-info-mobile">
            <div class="course-info--introduction-asset">
              <div class="intro-asset--wrapper">
                <div class="intro-asset--asset">
                  <div>
                    <span class="intro-asset--img-aspect">
                      <img src="{{itemDetail.imageUrl}}" alt="">
                    </span>
                    <span class="intro-asset--overlay intro-asset--gradient"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebar-container--purchase-section p-3">
              <div class="buy-box grid">
                <div class="col-12 price">
                  <h2>{{itemDetail.price | currency: "VND"}}</h2>
                </div>
                <div class="col-12 flex shopping-cart">
                  <ng-container *ngIf="isRegister">
                    <ng-container *ngIf="itemExamUser.examStatus <= 2">
                      <button class="add default-btn" (click)="goExaming()">
                        Vào thi<span></span>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="itemExamUser.examStatus >= 3">
                      <button class="add default-btn" (click)="goTestResult()">
                        Kết quả thi<span></span>
                      </button>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!isRegister ">
                    <ng-container *ngIf="itemDetail.enableRegister == true  && itemDetail.price == 0 && canDoExam">
                      <button class="add default-btn" (click)="doRegister()">
                        Đăng ký thi<span></span>
                      </button>
                    </ng-container>
                  </ng-container>
                  <button class="btn-heart"><i class="pi pi-heart"></i></button>
                </div>
                <div class="col-12 flex shopping-cart"
                  *ngIf="isRegister && itemDetail.option_NumReTest > 0 && countExamUser <= itemDetail.option_NumReTest">
                  <button class="add default-btn" (click)="doRegister2()">
                    Thi lại<span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!-- what you will learn -->
        <div class="row mt-6">
          <!-- ShareThis BEGIN -->
          <div class="sharethis-inline-share-buttons"></div><!-- ShareThis END -->
          <div class="col-12">
            <p [innerHTML]="itemDetail.bodyHtml"></p>
          </div>
        </div>
      </div>
      <!-- course-info -->
      <div class="col-12 md:col-12 lg:col-4">
        <div class="row">
          <!-- <div class="col-12"> -->
          <div class="course-info course-info-desktop">
            <div class="course-info--introduction-asset">
              <div class="intro-asset--wrapper">
                <div class="intro-asset--asset">
                  <ng-container *ngIf="itemDetail.videoUrl == null || itemDetail.videoUrl == ''">
                    <div>
                      <span class="intro-asset--img-aspect">
                        <img src="{{itemDetail.imageUrl}}" alt="">
                      </span>
                      <span class="intro-asset--overlay intro-asset--gradient"></span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="itemDetail.videoUrl != null && itemDetail.videoUrl != ''">
                    <div>
                      <video controls style="width: 443px;">
                        <source src="{{itemDetail.videoUrl}}" type="video/mp4" />
                        Browser not supported
                      </video>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="sidebar-container--purchase-section p-3">
              <div class="buy-box grid">
                <div class="col-12 price">
                  <h2>{{itemDetail.price | currency: 'VND'}}</h2>
                </div>
                <div class="col-12 flex shopping-cart">
                  <ng-container *ngIf="isRegister">
                    <ng-container *ngIf="itemExamUser.examStatus <= 2">
                      <button class="add default-btn" (click)="goExaming()">
                        Vào thi<span></span>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="itemExamUser.examStatus >= 3">
                      <button class="add default-btn" (click)="goTestResult()">
                        Kết quả thi<span></span>
                      </button>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!isRegister">
                    <ng-container *ngIf="itemDetail.enableRegister == true && itemDetail.price == 0 && canDoExam">
                      <button class="add default-btn" (click)="doRegister()">
                        Đăng ký thi<span></span>
                      </button>
                    </ng-container>
                  </ng-container>
                  <button class="btn-heart"><i class="pi pi-heart"></i></button>
                </div>
                <div class="col-12 flex shopping-cart"
                  *ngIf="isRegister && itemDetail.option_NumReTest > 0 && countExamUser <= itemDetail.option_NumReTest">
                  <button class="add default-btn" (click)="doRegister2()">
                    Thi lại<span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->

        </div>
      </div>
    </div>
  </div>
</section>
