import { Component, Injector, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { distinct, from, map, takeUntil } from 'rxjs';
import { SecondPageIndexBase } from 'sv-shared';
import { CategoryType } from '../../config/enums';
import { CategoriesService } from '../../services/categories.service';
import { CourseService } from '../../services/course.service';
import { NewsService } from '../../services/news.service';
import { SysSchoolService } from '../../services/sys-school.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent extends SecondPageIndexBase implements OnInit {


  selectedCategories = [];
  listCategories = [];
  listSubCategories = [];
  idSysSchool = 0;
  avgRatingStar = 0.0;

  listBestSellerCource = [];
  totalBestSellerCoursePrice = 0;
  listLatestCourse = [];

  isListView = true;
  isShowFilter = true;
  showCategories = true;
  showSubCategories = true;


  showRating = true;
  showLanguages = true;
  selectedValues = [];
  ingredient = 0;

  courseMostStudySides: OwlOptions = {
    loop: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    autoplayTimeout: 10000,
    margin: 30,
    nav: true,
    navText: [
      "<i class='pi pi-chevron-left'></i>",
      "<i class='pi pi-chevron-right'></i>"
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  }

  constructor(
    protected _courseService: CourseService,
    protected _injector: Injector,
    protected _categoriesService: CategoriesService,
    private _sysSchoolService: SysSchoolService,
    private _shoppingCartService: ShoppingCartService,
  ) {
    super(_courseService, _injector);

  }
  async ngOnInit() {
    this.pageSize = 12;
    this.idSysSchool = this._sysSchoolService.getSelectedSchool();
    this._activatedRoute.queryParams.subscribe((params) => {
      let keyword = params['keyword'];
      if (keyword) {
        this.query = keyword;
        this.loadData();
        return;
      }
    });
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.selectedCategories = [pid];

          this.loadData();
          return;
        }
      });
    this.loadData();
  }

  loadData() {
    this.getCategories();
    this.getData();
    this.getsBestSeller();
    this.getsLatestCourse();
  }

  async getData() {
    this.dataSource = [];
    var searchModel = {
      keyword: this.query,
      idSysSchool: this.idSysSchool,
      listIdCategory: this.selectedCategories,
      avgRatingStar: this.avgRatingStar,
      status: 1,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      orderCol: this.orderCol ? this.orderCol : 'Id',
      isDesc: true,
      offset: (this.pageIndex - 1) * this.pageSize,
    };
    await this._courseService.find(searchModel)
      .then(response => {
        this.dataSource = response.data;
        this.totalRecord = response.totalRecord;
      }, error => {
        this.isLoading = false;
        this._notifierService.showHttpUnknowError();
      });
  }

  changeOrderBy(orderCol: string) {
    this.isDesc = true;
    this.orderCol = orderCol;
    this.getData();
  }

  getCategories() {
    this._categoriesService.getTree(this.idSysSchool, CategoryType.Courses).then(rs => {
      if (rs.success) {
        this.listCategories = rs.data;
        rs.data.forEach(element => {
          element.children.forEach(item => {
            if (this.listSubCategories.find(x => x.id == item.id) == null) {
              this.listSubCategories.push(item);
            }
          });
        });
      }
    })
  }

  getsBestSeller() {
    this._courseService.getsBestSeller(this.idSysSchool, 3, true).then(rs => {
      if (rs.success) {
        this.listBestSellerCource = rs.data;
        this.totalBestSellerCoursePrice = rs.data.reduce((sum, current) => sum + current.price, 0);
      }
    })
  }

  getsLatestCourse() {
    this._courseService.getsLatestCourse(this.idSysSchool, 5, false).then(rs => {
      if (rs.success) {
        this.listLatestCourse = rs.data;
      }
    })
  }

  filterByKeyword(keyword: string) {
    this.query = keyword;
    this._router.navigateByUrl(`/courses?keyword=${keyword}`);
  }

  onGoPage($event) {
    this.pageIndex = $event;
    this.getData();
  }
  addAllToShoppingCart() {
    this._shoppingCartService.addCourses(this.listBestSellerCource.map(({ instanceId }) => instanceId)).then(rs => {
      if (rs.success) {
        if (rs.data > 0) {
          this._notifierService.showSuccess("Thêm vào giỏ hàng thành công");
        }
        else {
          this._notifierService.showWarning("Khóa học đã có trong giỏ hàng hoặc bạn đã đăng ký khóa học này");
        }
      }
    })
  }

}
