import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { SecondPageIndexBase } from 'sv-shared';
import { CourseItemLiveService } from '../../../services/course-item-live.service';
import { CourseTopicService } from '../../../services/course-topic.service';
import { CourseUserLogService } from '../../../services/course-user-log.service';
import { CourseUserService } from '../../../services/course-user.service';

@Component({
  selector: 'app-learning-space-v3-home',
  templateUrl: './learning-space-v3-home.component.html',
  styleUrls: ['./learning-space-v3-home.component.scss']
})
export class LearningSpaceV3HomeComponent extends SecondPageIndexBase implements OnInit {

  idCourse = 0;
  isScroll = false;
  @Input() courseUserInfo = { id: 0, idCourse: 0, status: 2, percentLearningStatus: 0, learningStatus: 0 };
  arrLessonUnFinish = [];
  arrLessonFinished = [];

  dsItemLiveClassUpComing = [];
  dsCourseTopic = [];
  @Input() courseDetail = { id: 0, instanceId: '', description: '' }


  @Output() goAction = new EventEmitter<any>();

  constructor(
    protected _courseuserService: CourseUserService,
    protected _injector: Injector,
    private _courseUserLogService: CourseUserLogService,
    private _courseItemLiveService: CourseItemLiveService,
    private _courseTopicService: CourseTopicService,
  ) {
    super(_courseuserService, _injector);
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        this.idCourse = pid;
        this.getData();
        this.getDetail();
      });
  }

  getDetail() {
    this._courseUserLogService.getsLessonUnFinish(this.idCourse).then(rs => {
      if (rs.success) {
        this.arrLessonUnFinish = rs.data;
      }
    });
    this._courseUserLogService.getsLessonFinished(this.idCourse).then(rs => {
      if (rs.success) {
        this.arrLessonFinished = rs.data;
      }
    });
    this._courseItemLiveService.getsUpComing(this.idCourse).then(rs => {
      if (rs.success) {
        this.dsItemLiveClassUpComing = rs.data;
      }
    });
    this._courseTopicService.getsTopItem(this.idCourse, 5).then(rs => {
      if (rs.success) {
        this.dsCourseTopic = rs.data;
      }
    })
  }

  goActionMethod(actionIndex: number, id: number, idCourseItemType: number = 0, instanceId: '') {
    this.goAction.next({ actionIndex: actionIndex, id: id, idCourseItemType: idCourseItemType, instanceId: instanceId });
  }

  goActionMethod2(event: any) {
    this.goAction.next({ actionIndex: event.actionIndex, id: event.id, idCourseItemType: event.idCourseItemType, instanceId: event.instanceId });
  }


  getData() {
    if (this.isScroll == false) {
      this.dataSource = [];
    }

    var searchModel = {
      keyword: this.query,
      status: this.status,
      idCourse: this.idCourse,
      idOrganization: 0,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      offset: (this.pageIndex - 1) * this.pageSize,
      orderCol: this.orderCol ? this.orderCol : 'JoinTime',
      isDesc: this.isDesc,
    };
    this._courseuserService.find(searchModel)
      .then(rs => {
        if (this.isScroll == false) {
          this.dataSource = rs.data;
        } else {
          this.dataSource.push(...rs.data);
        }
        this.totalRecord = rs.totalRecord;
        this.isLoading = false;
        this.isScroll = false;
      }, error => {
        this.isLoading = false;
        this._notifierService.showHttpUnknowError();
      });

    this.resetBulkSelect();
  }

  onScroll() {
    this.isScroll = true;
    this.pageIndex += 1;
    this.getData();
  }
}



