<header class="header-section style-1">
  <div class="header-top flex align-items-center justify-content-center">
    <div class="header-top-noti">
      <span>
        Hotline: <a href="tel:{{phoneNumber}}">{{phoneNumber}}</a>
      </span>
    </div>
  </div>
  <div class="navbar-area" (clickOutside)="closeCategoryFilter()">
    <div class="flex align-items-center logo">
      <a routerLink="/"><img src="assets/images/logo/logo.png" alt="" /></a>
    </div>
    <div class="flex align-items-center greeting ml-3">
      <button class="transparent-btn" [popperShowOnStart]="false" [popper]="propperCategory" [popperTrigger]="click"
        [popperHideOnClickOutside]="true" [popperPlacement]="'bottom-end'">Chuyên mục<span></span>
      </button>
    </div>
    <popper-content #propperCategory>
      <div class="categories-box">
        <div class="flex justify-content-between categories-checkbox-group">
          <ng-container *ngFor="let item of listCategoryType">
            <p-checkbox name="groupname" value="{{item.id}}" label="{{item.title}}"
              [(ngModel)]="categoryTypeSelectedValues" (onChange)="onChangeCategoryType(item.id)">
            </p-checkbox>
          </ng-container>
        </div>
        <div class="categories-menu">
          <ul>
            <ng-container *ngFor="let item of listCategory">
              <li (mouseenter)="onMouseEnter(item)">
                <a (click)="goSchool(item.data)">
                  <i class="pi pi-user"></i>
                  <span>{{item.label}}</span>
                  <i class="pi pi-caret-right"></i>
                </a>
                <ng-container *ngIf="item.children.length > 0">
                  <div class="category-detail">
                    <!-- <h3>{{item.label}}</h3> -->
                    <ul>
                      <ng-container *ngFor="let cat of item.children">

                        <li>
                          <a (click)="goCategoryLink(cat)"><span>{{cat.label}}</span>
                            <i class="pi pi-caret-right" *ngIf="cat.children.length >0"></i>
                          </a>
                          <ul class="subject-detail">
                            <ng-container *ngFor="let cat1 of cat.children">
                              <li>
                                <a (click)="goCategoryLink(cat1)"><span>{{cat1.label}}</span>
                                  <i class="pi pi-caret-right" *ngIf="cat1.children.length >0"></i>
                                </a>
                                <ul class="subject-children-detail">
                                  <li *ngFor="let cat2 of cat1.children">
                                    <a (click)="goCategoryLink(cat2)">{{cat2.label}}</a>
                                  </li>
                                </ul>
                              </li>
                            </ng-container>
                          </ul>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </ng-container>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </popper-content>
    <div class="flex-auto flex align-items-center search-box-container">
      <div class="search-box flex align-items-center">
        <img src="assets/images/icons/search_icon.png" alt="">
        <input type="text" [placeholder]="placeholderTitle" [(ngModel)]="keyword" (keyup.enter)="onSearch()" />
      </div>
    </div>
    <div class="flex align-items-center shopping-cart">
      <button class="navbar-btn" routerLink="/shopping-cart">
        <i class="pi pi-shopping-cart" pBadge [value]="listCourseShoppingCart.length" severity="danger"></i>
      </button>
    </div>
    <div class="flex align-items-center button" *ngIf="currentUser?.userId <= 0">
      <button class="second-btn mr-2" routerLink="/authentication/signin">Đăng nhập <span></span></button>
      <button class="default-btn" routerLink="/authentication/signup">Đăng ký<span></span></button>
    </div>
    <div class="flex align-items-center mr-2" *ngIf="currentUser?.userId > 0">
      <button class="navbar-btn mr-2" (click)="toggleRightSidebar()">
        <i class="pi pi-bell" pBadge [value]="totalUnRead" severity="danger"></i>
      </button>
      <div [popper]="propperUser" [popperTrigger]="'click'" [popperPlacement]="'bottom'">
        <p-button icon="pi pi-user" styleClass="border-noround"></p-button>
      </div>
    </div>
  </div>
  <div class="navbar-area-mobile">
    <div class="navbar">
      <button class="navbar-btn" (click)="toggleCategoriesBoxMobile()">
        <i class="pi pi-align-justify"></i>
      </button>
      <div *ngIf="categoriesBoxMobileVisible" class="categories-mobile-box">

        <div class=" flex justify-content-center">

        </div>
      </div>
      <div class="header--button-spacer"></div>
      <div class="narbar-middle">
        <a routerLink="/">
          <img src="assets/images/logo/logo.png" alt="" style="width: 167px;" />
        </a>
      </div>
      <button class="navbar-btn" (click)="showSearchBar = true"><i class="pi pi-search"></i></button>
      <button class="navbar-btn" routerLink="/authentication/signin" *ngIf="currentUser?.userId <= 0"><i
          class="pi pi-user"></i></button>
      <div [popper]="propperUser" [popperTrigger]="'click'" [popperPlacement]="'bottom'"
        *ngIf="currentUser?.userId > 0">
        <p-button icon="pi pi-user" styleClass="border-noround"></p-button>
      </div>
    </div>
  </div>
  <div class="header-bottom"></div>
</header>
<div class="header--search-bar" *ngIf="showSearchBar">
  <div class="flex p-2 p-fluid">
    <div class="flex flex-1">
      <div class="field p-input-icon-left">
        <i class="pi pi-search"></i>
        <input pInputText type="text" />
      </div>
    </div>
    <div class="flex">
      <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-danger p-button-text"
        (click)="showSearchBar = !showSearchBar"></p-button>
    </div>
  </div>
</div>
<popper-content #propperUser>
  <div class="ud-header-menu">
    <a href="/profile" class="user-profile-dropdown--user-section">
      <div aria-hidden="true" class="ud-avatar" data-purpose="display-initials" style="width: 3rem; height: 3rem;">
      </div>
      <div class="user-profile-dropdown--user-details">
        <div>
          <div class="ud-heading-md">{{currentUser?.displayName}}</div>
          <div class="user-profile-dropdown--email">{{currentUser?.email}}</div>
        </div>
      </div>
    </a>
    <ng-container *ngIf="currentUser?.typeId == 11">
      <ul class="ud-unstyled-list">
        <li> <a routerLink="/shopping-cart/children">
            <i class="pi pi-shopping-cart"></i> &nbsp;Giỏ hàng của con</a>
        </li>
        <li> <a routerLink="/child/learning-history">
            <i class="pi pi-id-card"></i> &nbsp;Lịch sử học tập của con</a>
        </li>
      </ul>
    </ng-container>
    <ul class="ud-unstyled-list ">
      <li> <a routerLink="/profile/dashboard">
          <i class="pi pi-table"></i> &nbsp;Dashboard học viên</a></li>
      <li> <a routerLink="/profile/learning-history">
          <i class="pi pi-id-card"></i> &nbsp;Khóa học của tôi</a></li>
      <li> <a routerLink="/profile/payment-history">
          <i class="pi pi-dollar"></i> &nbsp;Lịch sử thanh toán</a></li>
    </ul>
    <ul class="ud-unstyled-list  border-top">
      <li> <a href="javascript: void(0)" (click)="doLogOut()">
          <i class="pi pi-user-minus"></i> &nbsp;Đăng xuất</a></li>
    </ul>
  </div>
</popper-content>

<div class="sv-right-sidebar-area" [ngClass]="{
                  '-show': showDropdown.rightSidebar,
                  '-hide': !showDropdown.rightSidebar
                  }" *ngIf="dataSource.length > 0">
  <div class="sv-right-sidebar-tabs-container">
    <div class="sv-right-sidebar-tab-item -activated" (click)="readAll($event)">
      {{'Đọc tất cả' | translate}}
    </div>

    <!-- <div class="__tab-item" [class.activated]="currentSidebarTab === SidebarTabs.ACTIVITY"
              (click)="currentSidebarTab = SidebarTabs.ACTIVITY">
              {{'USERINFO_TAB_ACTIVITY' | translate}}
          </div> -->
  </div>

  <div class="sv-right-sidebar-panel-container">
    <div class="sv-notification-container" [hidden]="currentSidebarTab !== SidebarTabs.NOTIFICATION">
      <!-- <app-notification></app-notification> -->
      <div class="sv-notification-container">
        <ng-template ngFor let-item [ngForOf]="dataSource">
          <div class="sv-notification-item -unseen" [class.unseen]="!item.isRead">
            <div class="sv-notification-item__icon pi" [ngClass]="{
                                      'pi-bell': item.type === 1,
                                      'pi-bell': item.type === 2,
                                      'pi-bell': item.type === 3,
                                      'pi-bell': item.type === 4 || item.type === null || item.type === undefined
                                  }" (click)="readItem(item)"></div>

            <div class="sv-notification-item__title" (click)="goItemLink(item)">{{item.title}}</div>

            <div class="sv-notification-item__time">{{item.created | date:'dd/MM/yyyy HH:mm'}}</div>
          </div>
        </ng-template>
      </div>
    </div>

    <!-- <div class="__activity-panel" [hidden]="currentSidebarTab !== SidebarTabs.ACTIVITY">
              <div class="py-8 px-16">
                  {{'USERINFO_LIST_ACTIVITY' | translate}}
              </div>
          </div> -->
  </div>
</div>
