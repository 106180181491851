import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BaseService, ExportService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserCertificateService extends BaseService {
  constructor(http: HttpClient, injector: Injector, private _exportService: ExportService) {
    super(http, injector, `${environment.apiDomain.svmTestinEndpoint}/usercertificate`);
  }

  getsMyCertificate(): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsMyCertificate`;
    return this.defaultGet(apiUrl);
  }


  downloadCertificate(instanceId: string) {
    const url = `${this.serviceUri}/DownloadCertificate/${instanceId}`;
    return this._http
      .post(`${url}`, null, { responseType: 'blob' as 'json' })
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
      .subscribe(res => {
        this._exportService.saveAsPdfFile(res, 'certificate');
      });
  }

  getsChildCertificate(instanceId: string): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsChildCertificate/${instanceId}`;
    return this.defaultGet(apiUrl);
  }

}
