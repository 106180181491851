<div class="ws-page-body-content ws-l-page-body-content">
  <div class="ws-l-main-area h-overflow-y-auto h-100vh">
    <ng-container *ngIf="arrLessonUnFinish.length == 0 && arrLessonFinished.length == 0">
      <app-course-item-list (goAction)="goActionMethod2($event)"></app-course-item-list>
    </ng-container>
    <div class="ws-l-learning-process-container ws-l-grid-row">
      <div class="ws-l-learning-process-block-wrapper" *ngIf="arrLessonUnFinish.length > 0">
        <div class="ws-l-learning-process-block -status-1">
          <div class="ws-l-learning-process-block__header"><span
              class="ws-img-icon ws-l-learning-process-block__header-icon">
              <svg>
                <use href="\assets\images\icon\book-o.svg#icon"></use>
              </svg></span>
            <div class="ws-l-learning-process-block__title">Bài đang học</div>
          </div>
          <div class="ws-l-learning-process-block__body">
            <div class="ws-l-group-process-item-1">
              <ng-container *ngFor="let item of arrLessonUnFinish; index as i">
                <div class="ws-step-bar">
                  <div class="ws-step-bar__number">{{i + 1}}</div>
                  <div class="ws-step-bar__content">
                    <div class="ws-l-learning-process-item-1">
                      <div class="ws-l-learning-process-item-1__title">{{item.titleCourseItem}}
                      </div>
                      <div class="ws-l-learning-process-item-1__progressbar-wrapper">
                        <div
                          class="ws-progressbar -size-1 -thumb-size-1 ws-l-learning-process-item-1__progressbar -style-danger">
                          <div class="ws-progressbar__slide"
                            [style.width.%]="item.idCourseItemType == 11 ? item.learningRate: 50">
                          </div>
                          <div class="ws-progressbar__thumb"
                            [style.left.%]="item.idCourseItemType == 11 ? item.learningRate: 50"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="ws-l-learning-process-block__btn-wrapper">
              <button class="ws-btn -size-2 -icon-size-1 ws-bgc--primary-2 ws-color--light"
                (click)="goActionMethod(3,arrLessonUnFinish[0].idCourseItem, arrLessonUnFinish[0].idCourseItemType, arrLessonUnFinish[0].instanceId)">
                <div class="ws-btn__label">Học tiếp</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="ws-l-learning-process-block-wrapper" *ngIf="arrLessonFinished.length > 0">
        <div class="ws-l-learning-process-block -status-2">
          <div class="ws-l-learning-process-block__header"><span
              class="ws-img-icon ws-l-learning-process-block__header-icon">
              <svg>
                <use href="\assets\images\icon\book-o.svg#icon"></use>
              </svg></span>
            <div class="ws-l-learning-process-block__title">Những bài đã hoàn thành</div>
          </div>
          <div class="ws-l-learning-process-block__body">
            <ng-container *ngFor="let item of arrLessonFinished; index as i">
              <div class="ws-l-group-process-item-2">
                <div class="ws-step-bar">
                  <div class="ws-step-bar__number">{{i + 1}}</div>
                  <div class="ws-step-bar__content">
                    <div class="ws-l-learning-process-item-2">
                      <div class="ws-l-learning-process-item-1__title">{{item.titleCourseItem}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="ws-l-learning-progressbar-container">
      <div class="ws-progressbar -size-2 -thumb-size-4 ws-l-learning-progressbar"
        [class]="courseUserInfo.learningStatus == 1 ? '-style-success' : '-style-danger'">
        <div class="ws-progressbar__slide" [style.width.%]="courseUserInfo.percentLearningStatus"></div>
        <div class="ws-progressbar__thumb" [style.left.%]="courseUserInfo.percentLearningStatus">
          <div class="ws-progressbar__thumb-label">{{courseUserInfo.percentLearningStatus}}</div>
        </div>
      </div>
    </div>
    <div class="ws-l-overview-row ws-l-grid-row">
      <div class="ws-l-overview-block-wrapper" *ngIf="dsCourseTopic.length > 0">
        <div class="ws-l-overview-block">
          <div class="ws-l-overview-block__header"><span class="ws-img-icon ws-l-overview-block__header-icon">
              <svg>
                <use href="\assets\images\icon\notification-o.svg#icon"></use>
              </svg></span>
            <div class="ws-l-overview-block__title">Thảo luận mới nhất</div>
            <button class="ws-btn -size-1 ws-l-overview-block__btn">
              <div class="ws-btn__label" (click)="goActionMethod(1, 0, '')">Tất cả</div><span
                class="ws-img-icon ws-btn__icon">
                <svg>
                  <use href="\assets\images\icon\arrow-right.svg#icon"></use>
                </svg></span>
            </button>
          </div>

          <div class="ws-l-overview-block__body">
            <ng-container *ngFor="let item of dsCourseTopic">
              <div class="ws-space-notification-class-item-2 -status-1">
                <div class="ws-avatar-with-icon -size-s "><img class="ws-avatar-with-icon__img"
                    src="\assets\images\avatar\avatar-placeholder-2.png" alt="Avatar"><span
                    class="ws-img-icon ws-avatar-with-icon__icon">
                    <svg>
                      <use href="\assets\images\icon\clipboard-list.svg#icon"></use>
                    </svg></span>
                </div>
                <div class="ws-space-notification-class-item-2__meta-block">
                  <div class="ws-space-notification-class-item-2__title">
                    <a (click)="goActionMethod(1, item.id)">
                      {{item.title}}
                    </a>

                  </div>
                  <div class="ws-space-notification-class-item-2__date-time">
                    {{item.repliedDate | date:'dd/MM/yyyy HH:mm' }}
                  </div>
                </div>
                <div class="ws-dot"></div>
              </div>
            </ng-container>
          </div>

        </div>
      </div>
      <div class="ws-l-overview-block-wrapper" *ngIf="dsItemLiveClassUpComing.length > 0">

        <div class="ws-l-overview-block">
          <div class="ws-l-overview-block__header"><span class="ws-img-icon ws-l-overview-block__header-icon">
              <svg>
                <use href="\assets\images\icon\camera.svg#icon"></use>
              </svg></span>
            <div class="ws-l-overview-block__title">Học trực tuyến sắp diễn ra</div>
            <button class="ws-btn -size-1 ws-l-overview-block__btn">
              <div class="ws-btn__label" (click)="goActionMethod(2, 0)">Tất cả</div><span
                class="ws-img-icon ws-btn__icon">
                <svg>
                  <use href="\assets\images\icon\arrow-right.svg#icon"></use>
                </svg></span>
            </button>
          </div>
          <ng-container *ngFor="let item of dsItemLiveClassUpComing">
            <div class="ws-l-overview-block__body">
              <div class="ws-space-lesson-item-2 -status-2">
                <div class="ws-avatar-with-icon -size-s "><img class="ws-avatar-with-icon__img"
                    src="\assets\images\blog_icon.svg" alt="Avatar">
                </div>
                <div class="ws-space-lesson-item-2__meta-block">
                  <div class="ws-space-lesson-item-2__title">
                    <a (click)="goActionMethod(2, item.id)">
                      {{item.title}}
                    </a>
                  </div>
                  <div class="ws-space-lesson-item-2__event-time"><span class="ws-space-lesson-item-2__event-time-part">
                      {{item.startTime |
                      date:'dd/MM/yyyy HH:mm' }}</span><span>&nbsp;-&nbsp;</span><span
                      class="ws-space-lesson-item-2__event-time-part">
                      {{item.duration }} ({{'phút' |
                      translate}})
                    </span>
                  </div>
                </div>
                <!-- <div class="ws-space-lesson-item-2__action-wrapper">
                                    <button class="ws-btn -size-1 ws-space-lesson-item-2__action">
                                        <div class="ws-btn__label">Xem thêm</div>
                                    </button>
                                </div> -->
              </div>
            </div>
          </ng-container>
        </div>

      </div>
    </div>
  </div>
  <div class="ws-l-sidebar-area h-overflow-y-auto h-100vh">
    <div class="ws-l-sidebar-header">
      <div class="ws-l-sidebar-header-content"><span class="ws-img-icon ws-l-sidebar-header__main-icon">
          <svg>
            <use href="\assets\images\icon\profile-2user.svg#icon"></use>
          </svg></span>
        <div class="ws-l-sidebar-header__title">Danh sách lớp</div>

      </div>
    </div>
    <div class="ws-l-sidebar-body">
      <div class="ws-l-account-container">
        <ng-container *ngFor="let item of dataSource; index as i">
          <div class="ws-l-account-item is-activated">
            <div class="ws-l-account-item__avatar"><img class="ws-l-account-item__avatar-img"
                src="{{item.avatar | avatar}}" alt="Avatar">
              <div class="ws-l-account-item__status"></div>
            </div>
            <div class="ws-l-account-item__user-name">{{item.displayName}}</div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col-md-12" *ngIf="pageIndex <= totalRecord / pageSize">
      <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"></div>
      <div class="main-loader mt-50" *ngIf="isLoading">
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>

  </div>
</div>
