import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseItemService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseitem`);
  }


  getTree(idCourse: number, status: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetTree/${idCourse}/${status}`;
    return this.defaultGet(apiUrl);
  }

  getTreeWihUserLog(idCourse: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetTreeWihUserLog/${idCourse}`;
    return this.defaultGet(apiUrl);
  }

  getByCode(code: string): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetByCode/${code}`;
    return this.defaultGet(apiUrl);
  }

  getCourseItemCountInfo(idCourse: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetCourseItemCountInfo/${idCourse}`;
    return this.defaultGet(apiUrl);
  }

  getTreeTableByIdCourse(idCourse): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetTreeTableByIdCourse/${idCourse}`;
    return this.defaultGet(apiUrl);
  }

  getDetailForLearn(id: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetDetailForLearn/${id}`;
    return this.defaultGet(apiUrl);
  }


  checkAllBeforeIsLearned(idCourse: number, itemId: number) {
    const queryString = `${this.serviceUri}/CheckAllBeforeIsLearned/${idCourse}/${itemId}`;
    return this.defaultGet(queryString);
  }

}
