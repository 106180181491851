import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseTopicService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/coursetopic`);
  }

  find(model: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/find`;
    return this._http
      .post<ResponseResult>(`${apiUrl}`, model).pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  updatePin(id: number, pin: boolean): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/UpdatePin/${id}/${pin}`;
    return this._http
      .post<ResponseResult>(apiUrl, null)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }
  getsTopItem(idCourse: number, count: number) {
    const apiUrl = `${this.serviceUri}/getsTopItem/${idCourse}/${count}`;
    return this.defaultGet(apiUrl);
  }

}
