import { Component, OnInit } from '@angular/core';
import { SysSchoolService } from '../../../services/sys-school.service';

interface Option {
  name: string;
  id: string;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  listSysSchool: [];
  idSysSchool = 0;
  selectedOption: Option;

  constructor(
    private _sysSchoolService: SysSchoolService
  ) { }

  ngOnInit() {
    this.listSysSchool = this._sysSchoolService.getsSchool();
    this.idSysSchool = this._sysSchoolService.getSelectedSchool();
  }

  onChangeSysSchool() {
    this._sysSchoolService.setSelectedSysSchool(this.idSysSchool);
    window.location.reload();
  }

}



