<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="lg:col-3 md:col-6 sm:col-6">
        <div class="single-footer-widget">
          <a routerLink="/" class="logo"><img src="assets/images/logo/logo2.png" alt="logo"></a>
          <p></p>
          <ul class="app-link">
            <li>
              <a href="http://online.gov.vn/Home/WebDetails/110676" target="_blank" class="app"><img
                  alt="chứng nhận bộ công thương" src="assets/images/logo-bct.png"></a>
            </li>
            <li><a
                href="itms-services://?action=download-manifest&url=https://digicloudacademy.edu.vn/assets/ios/app.plist"
                class="app" target="_blank"><img src="assets/images/logo/ios.png" alt="logo"></a></li>
            <li><a href="https://play.google.com/store/apps/details?id=com.chikei.digicloud" class="app"
                target="_blank"><img src="assets/images/logo/android.png" alt="logo"></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="lg:col-4 md:col-6 sm:col-6">
        <div class="single-footer-widget pl-5">
          <ul class="footer-links-list">
            <li><a routerLink="/about">
                <h3>Về chúng tôi</h3>
              </a></li>
            <li>
              {{companyTitle}}
            </li>
            <li>
              {{companyMst}}
            </li>
            <li>
              {{companyAddress}}
            </li>
            <li><a href="tel:{{phoneNumber}}"><i class="bx bx-phone-call"></i> SĐT: {{phoneNumber}}</a></li>
            <li><a href="mailto::{{email}}"><i class="bx bx-envelope"></i> Email: {{email}}</a></li>
          </ul>
        </div>
      </div>
      <div class="lg:col-2 md:col-6 sm:col-6">
        <div class="single-footer-widget">
          <h3>Cộng đồng</h3>
          <ul class="footer-links-list">
            <li><a routerLink="{{linkFacebook}}"><i class="bx bxl-facebook"></i> Facebook</a></li>
            <li><a routerLink="{{linkYoutube}}"><i class="bx bxl-youtube"></i> Youtube</a></li>
          </ul>
        </div>
      </div>
      <div class="lg:col-3 md:col-6 sm:col-6">
        <div class="single-footer-widget">
          <h3>Chính sách</h3>
          <ul class="footer-contact-info">
            <li><i class='bx bx-lock'></i><a href="/privacy/sercurity">Chính sách bảo mật thông tin</a></li>
            <li><i class='bx bx-cart-alt'></i><a href="/privacy/payment">Chính sách thanh toán</a></li>
            <li><i class='bx bx-reset'></i><a href="/privacy/refund">Chính sách đổi, hủy và hoàn trả</a></li>
            <li><i class='bx bx-directions'></i><a href="/privacy/shipping">Chính sách giao hàng</a></li>
          </ul>
        </div>

      </div>
    </div>

  </div>
  <div class="footer-bottom-area">
    <div class="flex justify-content-center">
      <p>© DigiCloud all right reversed</p>
    </div>
  </div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</footer>
