<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li><a routerLink="/profile">Thông tin cá nhân</a></li>
        <li>Bạn quan tâm tới nội dung nào?</li>
      </ul>
      <h2>Bạn quan tâm tới nội dung nào?</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<div class="container mt-6 mb-6">
  <div class="row">
    <div class="md:col-4">
      <div class="profile">
        <div class="profile-image mb-4">
          <img src="assets/images/user/user9.png" alt="avatar">
        </div>
        <div class="flex justify-content-center rank-tag">
          <p-tag value="1/100" [rounded]="true" severity="success"></p-tag>
        </div>
        <div class="mb-4 text-center">
          <h5>{{itemDetail.displayName}}</h5>
          <div class="email">{{itemDetail.Email}}</div>
        </div>
        <div class="mb-4 text-center">
          <button pButton class="primary-alt-btn w-20rem" routerLink="/profile">Thông tin cá nhân
            <span></span>
          </button>
        </div>
      </div>
    </div>
    <div class="md:col-8">
      <div class="row p-fluid">
        <ng-container *ngIf="listSysSchool.length > 0">
          <ng-container *ngFor="let item of listSysSchool">
            <div class="col-6 digi">
              <label>{{item.schoolInfo.title}}</label>
              <p-tree [value]="item.listCategory" selectionMode="checkbox" class="w-full md:w-30rem"
                [(selection)]="selectedCategories">
              </p-tree>
            </div>
          </ng-container>
        </ng-container>
        <div class="col-12 flex align-items-center justify-content-center">
          <div class="field">
            <button pButton class="default-btn w-10rem" (click)="doUpdateInfo()">Cập nhật <span></span> </button>
            &nbsp; &nbsp;
            <button pButton class="third-btn w-10rem" (click)="goDashboard()">Bỏ qua <span></span> </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
