import { Component, OnInit } from '@angular/core';
import { SysSettingService } from '../../../services/sys-setting.service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit {

  itemDetail: any = { settingValue: '' }

  constructor(
    private _sysSettingService: SysSettingService
  ) { }

  ngOnInit() {
    this._sysSettingService.getByInstanceId('DB807863-403A-4096-98C9-6A5490F599A5'.toLowerCase()).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
      }
    })
  }

}
