<section class="courses-most-study-section">
  <div class="container">
    <div class="section-title">
      <h2>Các khóa được nhiều người học nhất</h2>
      <!-- <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div> -->
    </div>
    <div class="courses-most-study-container">
      <owl-carousel-o [options]="courseMostStudySides">
        <ng-container *ngFor="let item of dataSource">
          <ng-template carouselSlide>
            <app-single-course-box-grid [item]="item" [isShowPopper]="false"></app-single-course-box-grid>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</section>