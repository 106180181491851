import { Component, OnInit } from '@angular/core';
import { BasicUserInfo, UserService } from 'sv-shared';
import { SvCoreUsersService } from '../../../services/svcore-users.service';

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss']
})
export class ChildrenComponent implements OnInit {

  currentUser = new BasicUserInfo();
  itemDetail: any = {};
  listChildren: any[] = [];

  cols = [
    { field: 'userName', header: 'Tài khoản', visible: true, width: '20%', align: 'text-center' },
    { field: 'displayName', header: 'Họ và tên', visible: true, width: 'auto', align: 'text-center' },
    { field: 'action', header: 'Thao tác', visible: true, width: '20%', align: 'text-right' },
  ];

  constructor(
    protected _userService: UserService,
    private _svCoreUsersService: SvCoreUsersService,
  ) {

  }

  ngOnInit() {
    this._userService.getMyInfo().then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
        this.itemDetail.dateOfBirth = this.itemDetail.dateOfBirth ? new Date(this.itemDetail.dateOfBirth) : null;
      }
    });
    this.getsMyChildren();
  }

  getsMyChildren() {
    this._svCoreUsersService.getsChildren().then(rs => {
      if (rs.success) {
        this.listChildren = rs.data;
      }
    })
  }

  onDeleteChildren(item) {
    // this._userService.deleteChildren(item.id).then(rs => {
    //   if (rs.success) {
    //     this.listChildren = this.listChildren.filter(x => x.id !== item.id);
    //   }
    // })
  }

}
