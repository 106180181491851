import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GisRegionService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.coreEndPoint}/gisregion`);
  }

  getsDistrictByIdProvince(idProvince: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsDistrictByIdProvince/${idProvince}`;
    return this.defaultGet(apiUrl);
  }

  getsAllProvince(): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsAllProvince`;
    return this.defaultGet(apiUrl);
  }

}
