import { BaseModel } from './base';

export class CourseUserLog extends BaseModel {
  id: number;
  idCourse: number;
  idCourseItem: number;
  userId: number;
  finalMark: number;
  timeIn: Date;
  timeOut: Date;
  status: number;
  answerText: string;
  idItemData: number;
  idItemDataDetail: number;
  idCourseItemType: number;
  courseUserLog_Attactment = [];
  totalTime: number;
  countDown: number;
  numQTrue: number;
  numQFalse: number;
  numQNoAns: number;
  teacherComment: string;
  totalMark: number;
  passMark; number;
  scormResult: string;
  isLearned: boolean;
  learningTime: number;
  idCourseNavigation: any;
  constructor(_id: number, _answerText: string) {
    super();
    this.id = _id;
    this.answerText = _answerText;
  }
}
