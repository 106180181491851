import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BaseService, ExportService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseUserService extends BaseService {
  constructor(http: HttpClient, injector: Injector, private _exportService: ExportService) {
    super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseuser`);
  }

  find(searchModel: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/Gets`;
    return this._http
      .post<ResponseResult>(apiUrl, searchModel)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }



  checkRegistered(idCourse: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/CheckRegistered/${idCourse}`;
    return this._http
      .post<ResponseResult>(apiUrl, null)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  registerCourse(idCourse: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/RegisterCourse/${idCourse}`;
    return this._http
      .post<ResponseResult>(apiUrl, null)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  getMyDetail(idCourse: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetMyDetail/${idCourse}`;
    return this.defaultGet(apiUrl);
  }

}
