import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { AuthenticationService, BasicUserInfo, NotificationService, NotifierService } from 'sv-shared';
import { PromotionService } from '../../services/promotion.service';
import { EInvoiceService } from '../../services/e-invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { ComboService } from '../../services/combo.service';
import { VnPayLogService } from '../../services/vn-pay-log.service';
import { SvCoreUsersService } from '../../services/svcore-users.service';


@Component({
  selector: 'app-shopping-cart-children',
  templateUrl: './shopping-cart-children.component.html',
  styleUrls: ['./shopping-cart-children.component.scss']
})
export class ShoppingCartChildrenComponent implements OnInit {

  dataSource = [];
  totalPriceCourse = 0;
  totalPricePromotion = 0;

  txtPromotionCode = '';
  selectedPromotion: any = {};

  listAllPromotion = [];
  comboInfo: any = { id: 0, promotionRate: 0 };

  showPayResult = false;
  title = "Giỏ hàng";
  currentUser: BasicUserInfo = new BasicUserInfo();

  listUserChildren = [];

  constructor(
    private _shoppingCartService: ShoppingCartService,
    private _notifierService: NotifierService,
    private _promotionService: PromotionService,
    private _vnPayLogService: VnPayLogService,
    private _router: Router,
    private _cookieService: CookieService,
    private _comboService: ComboService,
    private _activatedRoute: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _svCoreUsersService: SvCoreUsersService
  ) {
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  async ngOnInit() {
    if (this.currentUser.typeId == 10) {
      this._router.navigate(['/profile/dashboard']);
    }

    this._svCoreUsersService.getsChildren().then(rs => {
      if (rs.success) {
        this.listUserChildren = rs.data;
        if (rs.data.length == 0) {
          this._notifierService.showError('Bạn chưa có học sinh nào, vui lòng thêm học sinh!');
          this._router.navigate(['/profile']);
        } else {
          this.getsChildrenShoppingCart(rs.data[0].userId);
          this.title = "Giỏ hàng của con";
        }
      }
    });

  }

  getsChildrenShoppingCart(userId: number) {
    this.dataSource = [];
    this._shoppingCartService.getsChildrenShoppingCart(userId).then(rs => {
      if (rs.success) {
        this.dataSource = rs.data;
        this.totalPriceCourse = rs.data.reduce((acc, item) => acc + Number(item.price), 0);
        //console.log(this.totalPriceCourse);
        this.getComboInfo();
      }
    });
  }

  async addPromotionCode(item) {
    if (this.selectedPromotion?.id > 0) {
      this.listAllPromotion = this.listAllPromotion.filter(x => x.id != item.id);
      this.listAllPromotion.push(Object.assign({}, this.selectedPromotion))
      this.selectedPromotion = item;
    } else {
      this.listAllPromotion = this.listAllPromotion.filter(x => x.id != item.id);
      this.selectedPromotion = item;
    }
    this.totalPricePromotion = this.totalPriceCourse * Number(this.selectedPromotion.promotionRate) / 100;
  }
  async deletePromotionCode() {
    this.listAllPromotion.push(this.selectedPromotion);
    this.selectedPromotion = {};
  }

  deleteCourse(id: number) {
    this._shoppingCartService.deleteChildrenCourses(this.listUserChildren[0].userId, [id]).then(rs => {
      if (rs.success) {
        this.getsChildrenShoppingCart(this.listUserChildren[0].userId);
        this.getComboInfo();
      }
    });
  }

  processPayment() {
    var paymentModel = {
      courses: this.dataSource,
      promotion: this.selectedPromotion,
      invoice: {
        totalPriceCourse: this.totalPriceCourse,
        totalPricePromotion: this.totalPricePromotion,
        isPaid: 2,
        status: 1
      },
      cookieId: this._cookieService.get(environment.cookieName)
    };
    this._vnPayLogService.createChildPayRequest(this.listUserChildren[0].instanceId, paymentModel).then(rs => {
      if (rs.success) {
        if (rs.data) {
          window.location.href = rs.data;
        } else {
          this._notifierService.showSuccess('Thanh toán thành công!');
          this._router.navigate(['/profile/dashboard']);
        }
      }
      else {
        this._notifierService.showError('Thanh toán thất bại, xin vui lòng thư lại sau!');
      }
    });
  }

  getsPromotion() {
    this._promotionService.getsAllActive().then(rs => {
      if (rs.success) {
        this.listAllPromotion = rs.data;
      }
    });
  }

  getComboInfo() {
    this._comboService.getByListCourse(this.dataSource).then(rs => {
      if (rs.success) {
        this.comboInfo = rs.data;
        this.totalPricePromotion = this.totalPriceCourse * Number(this.comboInfo.promotionRate) / 100;
      } else {
        this.comboInfo = { id: 0, promotionRate: 0 };
        this.getsPromotion();
      }
    })
  }

}
