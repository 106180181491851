import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../../services/feedback.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-section-feedback',
  templateUrl: './section-feedback.component.html',
  styleUrls: ['./section-feedback.component.scss']
})
export class SectionFeedbackComponent implements OnInit {

  dataSource = [];

  feedbackSlides: OwlOptions = {
    loop: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    autoplayTimeout: 5000,
    margin: 30,
    nav: true,
    navText: [
      "<i class='pi pi-chevron-left'></i>",
      "<i class='pi pi-chevron-right'></i>"
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 3,
      }
    },
  }

  constructor(
    private _feedbackService: FeedbackService,
  ) { }

  ngOnInit() {
    this.getsFeedback();
  }
  getsFeedback() {
    this._feedbackService.getsAll().then(res => {
      if (res.success) {
        this.dataSource = res.data;
      }
    })
  }

}
