import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CourseItemService } from '../../../services/course-item.service';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { ComponentBase } from 'sv-shared';

@Component({
  selector: 'app-course-item-list',
  templateUrl: './course-item-list.component.html',
  styleUrls: ['./course-item-list.component.scss']
})
export class CourseItemListComponent extends ComponentBase implements OnInit {

  courseItems = [];
  idCourse = 0;

  @Output() goAction = new EventEmitter<any>();

  constructor(
    protected _courseItemService: CourseItemService,
    private _activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(idCourse => {
        this.idCourse = idCourse;
        this.getsCourseItem();
      });
  }

  getsCourseItem() {
    this._courseItemService.getTree(this.idCourse, 1).then(rs => {
      if (rs.success) {
        this.courseItems = rs.data;
        this.courseItems.forEach(node => {
          this.expandRecursive(node, true);
        });
      }
    })
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  goActionMethod(actionIndex: number, id: number, idCourseItemType: number = 0,) {
    this.goAction.next({ actionIndex: actionIndex, id: id, idCourseItemType: idCourseItemType });
  }

  viewCourseItem(item: any) {
    this.goAction.next({ actionIndex: 3, id: item.data, idCourseItemType: item.idCourseItemType, instanceId: item.instanceId });
  }


}
