<section class="feature-topic-by-category-section">
  <div class="container feature-topic-by-category-container">
    <div class="section-title">
      <h2>Chủ đề nổi bật theo thể loại</h2>
      <!-- <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div> -->
    </div>
    <div class="row list-categories">
      <ng-container *ngFor="let item of dataSource">
        <div class="col-12 md:col-6 lg:col-3 category-item">
          <div class="item">
            <a (click)="goCategoryLink(item)">
              <h5>{{item.title}}</h5>
            </a>
            <p>{{item.countCourses}} khóa học</p>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="grid see-more">
      <div class="col-12 flex justify-content-center align-content-center align-items-center">
        <button class="second-btn" routerLink="/courses">Khám phá thêm chủ đề<span></span></button>
      </div>
    </div>
  </div>
</section>