<div class="ws-page-body-title">
    <span class="ws-img-icon ws-page-body-title__icon">
        <svg>
            <use href="\assets\images\icon\note-2-o.svg#icon"></use>
        </svg>
    </span>
    <div class="ws-page-body-title__text">Tài nguyên lớp học</div>
</div>

<div class="ws-page-body-content h-overflow-y-auto">
    <ng-container *ngFor="let item of dataSource">
        <div class="ws-l-resource-category-container">
            <div class="ws-space-resource-category">
                <div class="ws-heading-2 ws-space-resource-category__header">
                    <span class="ws-img-icon ws-heading-2__icon">
                        <svg>
                            <use href="\assets\images\icon\paperclip-2.svg#icon"></use>
                        </svg>
                    </span>
                    <div class="ws-heading-2__text">{{item.title}}
                    </div>
                </div>
                <div class="ws-space-resource-category__body">
                    <ng-container *ngFor="let itemAt of item.courseItemAttachment; index as i">
                        <div class="ws-space-resource-item">
                            <div class="ws-space-resource-item__left-side"><span
                                    class="ws-img-icon ws-space-resource-item__main-icon">
                                    <svg>
                                        <use href="\assets\images\icon\word.svg#icon"></use>
                                    </svg></span>
                                <div class="ws-space-resource-item__meta-wrapper">
                                    <div class="ws-space-resource-item__name">
                                        <a href="{{itemAt.fileViewUrl}}" target="_blank">
                                            {{i + 1}}. {{itemAt.fileViewUrl | fileName}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="ws-space-resource-item__right-side">
                                <a href="{{itemAt.fileViewUrl}}" target="_blank"
                                    class="ws-btn -size-3 ws-space-resource-item__action -download">
                                    <span class="ws-img-icon ws-btn__icon">
                                        <svg>
                                            <use href="\assets\images\icon\download.svg#icon"></use>
                                        </svg>
                                    </span>
                                    <div class="ws-btn__label">Tải xuống</div>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>
