import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../../services/course.service';
import { CategoryLearningStatus } from '../../../config/enums';
import { UsersTrackingTimeService } from '../../../services/users-tracking-time.service';
import { ExamUserService } from '../../../services/exam-user.service';
import { AuthenticationService, BasicUserInfo } from 'sv-shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.scss']
})
export class StudentDashboardComponent implements OnInit {

  data: any;
  options: {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: '#2E3191'
        }
      }
    },

  };
  listMyLearningCourse = [];
  listHotCourse = [];
  listBestSellerCourse = [];
  listCourseByUserCategory = [];
  dataChart = [];
  days = 7;
  listMyExamHistory = [];

  listChartOptions = [{ label: '7 Ngày vừa qua', value: 7 }, { label: '30 Ngày vừa qua', value: 30 }]

  currentUser = new BasicUserInfo();
  countInfo = { countCourse: 0, countCertificate: 0 };
  constructor(
    private _courseService: CourseService,
    private _usersTrackingTimeService: UsersTrackingTimeService,
    private _examUserService: ExamUserService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
  ) {
    this.currentUser = this._authenticationService.getCurrentUser();
    if (!this.currentUser) {
      this._router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.getsMyCourse();
    this.getsHotCourse();
    this.getsBestSeller();
    this.getsUserTrackingTime();
    this.getsMyExamHistory();
    this.getsByUserCategory();
    this.getCountDashboard();
  }

  getsMyCourse() {
    this._courseService.getsMyStudyCourse(CategoryLearningStatus.IsLearing).then(rs => {
      if (rs.success) {
        this.listMyLearningCourse = rs.data;
      }
    })
  }

  getsHotCourse() {
    this._courseService.getsHotCourse(0, 3).then(rs => {
      if (rs.success) {
        this.listHotCourse = rs.data;
      }
    })
  }

  getsBestSeller() {
    this._courseService.getsBestSeller(0, 4, true).then(rs => {
      if (rs.success) {
        this.listBestSellerCourse = rs.data;
      }
    })
  }

  getsByUserCategory() {
    this._courseService.getsByUserCategory().then(rs => {
      if (rs.success) {
        this.listCourseByUserCategory = rs.data;
      }
    })
  }

  getsUserTrackingTime() {
    this._usersTrackingTimeService.getChartMyTrackingTime(this.days).then(rs => {
      if (rs.success) {
        this.dataChart = rs.data;
      }
    })
  }
  getsMyExamHistory() {
    this._examUserService.getsMyExamHistory().then(rs => {
      if (rs.success) {
        this.listMyExamHistory = rs.data;
      }
    })
  }

  getPercentComplete(course) {
    var tmp = Math.round((course.countCourseUserLog ?? 0) * 100 / (course.countCourseItem ?? 1));
    return tmp > 100 ? 100 : tmp > 1 ? tmp : 1;
  }

  getCountDashboard() {
    this._courseService.getCountDashboard().then(rs => {
      if (rs.success) {
        this.countInfo = rs.data;
      }
    })
  }
}
