import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { ComponentBase } from 'sv-shared';
import { CourseItemAttachmentService } from '../../../services/course-item-attachment.service';

@Component({
  selector: 'app-learning-space-v3-resource',
  templateUrl: './learning-space-v3-resource.component.html',
  styleUrls: ['./learning-space-v3-resource.component.scss']
})
export class LearningSpaceV3ResourceComponent extends ComponentBase implements OnInit {

  idCourse: number = 0;
  dataSource = [];
  constructor(
    private _courseItemAttachmentService: CourseItemAttachmentService,
    private _activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(idCourse => {
        this.idCourse = idCourse;
        this.getData(idCourse);
      });
  }

  getData(idCourse: number) {
    this._courseItemAttachmentService.getsByIdCourse(idCourse).then(rs => {
      if (rs.success) {
        this.dataSource = rs.data;
      }
    })


  }

}
