<section class="banner-area">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let item of dataSource">
      <ng-template carouselSlide>
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="banner-content">
                <h1 style="color: #f03c23;">{{item.title}}</h1>
                <h1>{{item.subTitle}}</h1>
                <p>{{item.description}}</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="banner-image">
                <img src="{{item.imageUrl}}" alt="image">
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</section>