import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgScormPlayerService, ScormResult } from 'ng-scorm-player';
import { map, takeUntil } from 'rxjs';
import { ComponentBase } from 'sv-shared';
import { CourseItemModel } from '../../models/courseitem';
import { CourseUserLogService } from '../../services/course-user-log.service';

@Component({
  selector: 'app-scorm-test',
  templateUrl: './scorm-test.component.html',
  styleUrls: ['./scorm-test.component.scss']
})
export class ScormTestComponent extends ComponentBase implements OnInit {

  @Input() scormUrl: string = '';
  @Input() courseItemDetail: CourseItemModel = new CourseItemModel();
  @Input() courseUserLogItem: any = {};
  constructor(
    private player: NgScormPlayerService,
    private _courseUserLogService: CourseUserLogService,
    private _activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(id => {
        if (id == 1) {
          this.scormUrl = 'https://digicloudacademy.edu.vn/gw/svfssystem/tenant/1/Temp/Course template/res/index.html';
        } else {
          this.scormUrl = 'http://localhost:12032/assets/scorm/B%C3%80I%2000.%20GI%E1%BB%9AI%20THI%E1%BB%86U%20KH%C3%93A%20H%E1%BB%8CC/res/index.html';
        }
      });

    this.player.initializeEvent.subscribe(val => { console.log('initializeEvent:', val); });
    this.player.setValueEvent.subscribe(val => { console.log('setValueEvent:', val); });
    this.player.getValueEvent.subscribe(val => { console.log('getValueEvent:', val); });
    this.player.finishEvent.subscribe(val => { console.log('finishEvent:', val); });
    this.player.commitEvent.subscribe(val => { console.log('commitEvent:', val); });


    //   var scormResult = new ScormResult();
    //   if (this.courseUserLogItem != null && this.courseUserLogItem.id > 0) {
    //     if (this.courseUserLogItem.scormResult) {
    //       scormResult = JSON.parse(this.courseUserLogItem.scormResult) as ScormResult;
    //     }
    //   } else {
    //     // Tạo log
    //     // this.isUpdateLog = true;
    //     // await this.updateCourseUserLog();
    //     if (this.courseUserLogItem.scormResult) {
    //       scormResult = JSON.parse(this.courseUserLogItem.scormResult) as ScormResult;
    //     }
    //   }

    //   this.scormUrl = this.courseItemDetail.fileViewUrl;
    //   this.player.initializeEvent.subscribe(val => {
    //     if (scormResult) {
    //       this.player.runtimeData = scormResult.runtimeData;
    //     }
    //   });

    //   this.player.commitEvent.subscribe(val => {
    //     this.courseUserLogItem.scormResult = JSON.stringify(val);
    //     sessionStorage.setItem('scorm', JSON.stringify(val));
    //     if (val.runtimeData['cmi.completion_status'] == 'completed'
    //       || val.runtimeData['cmi.success_status'] == 'passed'
    //       || val.runtimeData['cmi.core.success_status'] == 'completed'
    //       || val.runtimeData['cmi.core.success_status'] == 'passed') {
    //       this.courseUserLogItem.isLearned = true;
    //     }
    //     this.updateScormResult();
    //   });

    //   this.player.finishEvent.subscribe(val => {
    //     this.courseUserLogItem.scormResult = JSON.stringify(val);
    //     this.courseUserLogItem.isLearned = true;
    //     this.updateScormResult();
    //   });

    // }

    // updateScormResult() {
    //   console.log('updateScormResult');
    //   this._courseUserLogService.updateScormResult(this.courseUserLogItem);
    // }
  }


}
