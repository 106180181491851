<div class="container pt-5 pb-5">
  <div class="row p-fluid">
    <div class="md:col-4 mt-8">
      <div class="col-12">
        <div style="text-align: center">
          <h2>Bạn quên mật khẩu?</h2>
          <p>Nhập đủ thông tin bên dưới để chúng tôi gửi cho bạn</p>
        </div>
        <form #formElement [formGroup]="formGroup">
          <div class="field">
            <span class="p-input-icon-left">
              <i class="pi pi-envelope"></i>
              <input pInputText type="text" placeholder="Nhập Email" [(ngModel)]="email" formControlName="email" />
            </span>
          </div>
        </form>
        <div class="field">
          <button class="default-btn w-100 h-3rem" (click)="onResetPassword()">Gửi code<span></span></button>
        </div>
      </div>
    </div>
    <div class="md:col-8">
      <img src="assets/images/sign-in.png" alt="image">
    </div>
  </div>
</div>
