import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-item-type-two',
  templateUrl: './course-item-type-two.component.html',
  styleUrls: ['./course-item-type-two.component.scss']
})
export class CourseItemTypeTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input() item: any
}
