<div class="single-courses-box-list">
  <div class="courses-image">
    <a routerLink="/courses/detail/{{item.instanceId}}">
      <img src="{{item.imageUrl}}" alt="">
    </a>
  </div>
  <div class="courses-content">
    <a routerLink="/courses/detail/{{item.instanceId}}">
      <h3 class="title mb-0">{{item.title}}</h3>
    </a>
    <a routerLink="/authors/detail/{{item.idTenant}}">
      <div class="author">{{item.displayNameTenant}}</div>
    </a>
    <div class="row">
      <div class="courses-rating md:col-6 sm:col-12 ">
        <span class="rating mr-1 ml-1">5</span>
        <ng-rating-bar [value]="item.avgRatingStar ?? 5" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
          [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
        <span class="reviews">({{item.countComment ?? 0}})</span>
      </div>
      <div class="price-text md:col-6 sm:col-12">
        <div class="original-price" *ngIf="item.price > 0">{{item.price | currency: 'VND'}}</div>
        <div class="original-price" *ngIf="item.price == 0">Miễn phí</div>
      </div>
    </div>
    <button class="default-btn mb-2">Cho vào giỏ hàng<span></span></button>
  </div>
</div>