import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from 'sv-shared';
import { NewsService } from '../../services/news.service';
import { CategoriesService } from '../../services/categories.service';
import { CategoryType } from '../../config/enums';
import { map, takeUntil } from 'rxjs';
import { SysSchoolService } from '../../services/sys-school.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent extends SecondPageIndexBase implements OnInit {

  selectedCategories = [];
  listCategories = [];
  listSubCategories = [];
  itemDetail: any = { ten: 'Thông báo' };
  idSysSchool = 0;

  isListView = true;
  isShowFilter = true;
  showCategories = true;
  showSubCategories = true;

  selectedValues: string[] = [];
  ingredient: string;
  showRating = true;
  showVideoTime = true;

  showLanguages = false;


  constructor(
    protected _newsService: NewsService,
    protected _injector: Injector,
    protected _categoriesService: CategoriesService,
    private _sysSchoolService: SysSchoolService,
  ) {
    super(_newsService, _injector);
    this.idSysSchool = this._sysSchoolService.getSelectedSchool();
  }
  async ngOnInit() {
    this.pageSize = 10;
    this.getCategories();

    this._activatedRoute.queryParams.subscribe((params) => {
      let keyword = params['keyword'];
      if (keyword) {
        this.query = keyword;
      }
    });
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.selectedCategories = [pid];
        }

      });
    await this.getData();
  }

  async getData() {
    this.dataSource = [];
    var searchModel = {
      keyword: this.query,
      idSysSchool: this.idSysSchool,
      listIdCategory: this.selectedCategories,
      status: 1,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      offset: (this.pageIndex - 1) * this.pageSize,
      orderCol: this.orderCol ? this.orderCol : 'Id',
      isDesc: true,
    };
    await this._newsService.find(searchModel)
      .then(response => {
        this.dataSource = response.data;
        this.totalRecord = response.totalRecord;
      }, error => {
        this.isLoading = false;
        this._notifierService.showHttpUnknowError();
      });
  }

  changeOrderBy(orderCol: string) {
    this.isDesc = true;
    this.orderCol = orderCol;
    this.getData();
  }

  getCategories() {
    this._categoriesService.getTree(this.idSysSchool, CategoryType.News).then(rs => {
      if (rs.success) {
        this.listCategories = rs.data;
        rs.data.forEach(element => {
          this.listSubCategories = this.listSubCategories.concat(element.children);
        });
      }
    })
  }

}
