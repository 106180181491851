import { Component, Input, OnInit } from '@angular/core';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { NotifierService } from 'sv-shared';

@Component({
  selector: 'app-single-course-box-grid',
  templateUrl: './single-course-box-grid.component.html',
  styleUrls: ['./single-course-box-grid.component.scss']
})
export class SingleCourseBoxGridComponent implements OnInit {

  @Input() item: any
  @Input() isShowPopper: boolean = true;
  constructor(

  ) { }

  ngOnInit() {
  }


  onShown() {

  }

}
