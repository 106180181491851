import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamUserService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.svmTestinEndpoint}/examuser`);
  }

  registerExam(model: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/RegisterExam`;
    return this._http
      .post<ResponseResult>(apiUrl, model)
      .pipe(catchError(err => this.handleError(err, this._injector)))
      .toPromise();
  }

  finishExam(instanceId: string): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/FinishExam/${instanceId}`;
    return this._http
      .post<ResponseResult>(apiUrl, null)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  checkRegistered(idExam: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/CheckRegistered/${idExam}`;
    return this._http
      .post<ResponseResult>(apiUrl, null)
      .pipe(catchError(err => this.handleError(err, this._injector)))
      .toPromise();
  }

  doingExam(model: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/DoingExam`;
    return this._http
      .post<ResponseResult>(apiUrl, model)
      .pipe(catchError(err => this.handleError(err, this._injector)))
      .toPromise();
  }

  countExamUser(id: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/CountExamUser/${id}`;
    return this._http
      .post<ResponseResult>(apiUrl, null)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  getsMyExamHistory(): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsMyExamHistory`;
    return this.defaultGet(apiUrl);
  }

  getsChildExamHistory(instanceId): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsChildExamHistory/${instanceId}`;
    return this.defaultGet(apiUrl);
  }

}
