import { Component, OnInit } from '@angular/core';
import { CategoriesService } from '../../../services/categories.service';
import { SysSchoolService } from '../../../services/sys-school.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-section-hot-categories',
  templateUrl: './section-hot-categories.component.html',
  styleUrls: ['./section-hot-categories.component.scss']
})
export class SectionHotCategoriesComponent implements OnInit {

  dataSource = [];
  constructor(
    private _categoriesService: CategoriesService,
    private _sysSchoolService: SysSchoolService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.getCategories();

  }
  getCategories() {
    this._categoriesService.getsHotByIdSysSchool(0).then(rs => {
      if (rs.success) {
        this.dataSource = rs.data;
      }
    })
  }

  async goCategoryLink(item: any) {
    console.log(item);
    if (item.idSchool) {
      await this._sysSchoolService.setSelectedSysSchool(item.idSchool);
    }
    this._router.navigateByUrl(`/courses/${item.id}`);
  }

}
