import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.svotherEndpoint}/news`);
  }
  find(searchModel: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/Find`;
    return this._http
      .post<ResponseResult>(apiUrl, searchModel)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  getsTop(idSysSchool: number, count: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsTop/${idSysSchool}/${count}`;
    return this.defaultGet(apiUrl);
  }

  getPrevNextItem(id: any): Promise<ResponseResult> {
    const url = `${this.serviceUri}/GetPrevNextItem/${id}`;
    return this.defaultGet(url);
  }

}
