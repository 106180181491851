<div class="container pt-5 pb-5">
  <div class="row p-fluid">
    <div class="md:col-4">
      <div class="col-12">
        <h2>Đăng ký</h2>
        <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p> -->
        <form #formElement [formGroup]="formGroup">
          <div class="field -required -error">
            <label>Loại tài khoản</label>
            <p-dropdown class="sv-form-item__input sv-dropdown" panelStyleClass="sv-dropdown-panel"
              (onChange)="onChangeType()" formControlName="typeId" [options]="listUserType" [autoDisplayFirst]="false"
              dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="model.typeId" [filter]="true"
              filterBy="label,value">
            </p-dropdown>
            <small class="text-red-500" *ngIf="formGroup.controls['typeId'].invalid"> Bạn chưa chọn loại tài khoản
            </small>
          </div>
          <div class="field -required -error">
            <span class="p-input-icon-left">
              <i class="pi pi-user"></i>
              <input type="text" pInputText placeholder="Tài khoản" formControlName="userName"
                [(ngModel)]="model.userName" />
            </span>
            <small class="text-red-500" *ngIf="formGroup.controls['userName'].invalid"> Bạn chưa nhập tên tài
              khoản</small>
            <small class="text-500" *ngIf="formGroup.controls['userName'].valid && model.typeId == 10"> Tài khoản của
              phụ huynh sẽ được tự
              động tạo khi bạn đăng ký tài khoản học sinh là: ph_{{model.userName}}
            </small>
          </div>
          <div class="field -required -error">
            <span class="p-input-icon-left">
              <i class="pi pi-user"></i>
              <input type="text" pInputText placeholder="Họ và tên" formControlName="displayName"
                [(ngModel)]="model.displayName" />
            </span>
            <small class="text-red-500" *ngIf="formGroup.controls['displayName'].invalid"> Bạn chưa nhập họ và
              tên</small>
          </div>
          <div class="field -required -error">
            <span class="p-input-icon-left">
              <i class="pi pi-envelope"></i>
              <input type="text" pInputText placeholder="Email" formControlName="email" [(ngModel)]="model.email" />
            </span>
            <small class="text-red-500" *ngIf="formGroup.controls['email'].invalid"> Bạn chưa nhập email hoặc email
              không đúng định dạng</small>
          </div>
          <div class="field -required -error">
            <span class="p-input-icon-left">
              <i class="pi pi-lock"></i>
              <input pInputText type="password" placeholder="Mật khẩu" formControlName="passwordHash"
                [(ngModel)]="model.passwordHash" />
            </span>
            <small class="text-red-500" *ngIf="formGroup.controls['passwordHash'].invalid"> Mật khẩu có độ dài ít nhất
              8 ký tự bao gồm cả chữ hoa, chữ thường, số và ký tự đặc biệt </small>
          </div>
          <div class="field -required -error">
            <label>Tỉnh/thành phố</label>
            <p-dropdown panelStyleClass="sv-dropdown-panel" [options]="listProvince" [autoDisplayFirst]="false"
              formControlName="idProvince" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
              [(ngModel)]="model.idProvince" [filter]="true" (onChange)="getsDistrict()" filterBy="label,value">
            </p-dropdown>
            <small class="text-red-500" *ngIf="formGroup.controls['idProvince'].invalid"> Bạn chưa chọn Tỉnh / Thành phố
            </small>
          </div>
          <div class="field -required -error">
            <label>Quận / Huyện</label>
            <p-dropdown class="sv-form-item__input sv-dropdown" panelStyleClass="sv-dropdown-panel"
              formControlName="idDistrict" [options]="listDistrict" [autoDisplayFirst]="false"
              dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="model.idDistrict" [filter]="true"
              filterBy="label,value">
            </p-dropdown>
            <small class="text-red-500" *ngIf="formGroup.controls['idDistrict'].invalid"> Bạn chưa chọn Quận / Huyện
            </small>
          </div>
          <ng-container *ngIf="model.typeId == 10">
            <div class="field -required -error">
              <label>Cấp học</label>
              <p-dropdown class="sv-form-item__input sv-dropdown" panelStyleClass="sv-dropdown-panel"
                formControlName="idSchoolLevel" [options]="listSchoolLevel" [autoDisplayFirst]="false"
                dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="model.idSchoolLevel" [filter]="true"
                filterBy="label,value">
              </p-dropdown>
              <small class="text-red-500" *ngIf="formGroup.controls['idSchoolLevel'].invalid"> Bạn chưa chọn cấp học
              </small>
            </div>
          </ng-container>
          <div class="field">
            <p-captcha siteKey="6LfzQjooAAAAAJee0JvagiW69Pd1jANfEVmHNtzq"
              (onResponse)="captchaResponse($event)"></p-captcha>
          </div>
          <div class="field mt-5">
            <button class="default-btn w-100 h-3rem" (click)="doRegister()">Đăng ký<span></span></button>
          </div>
        </form>
      </div>
      <div class="flex justify-content-center">
        <div class="align-items-center justify-content-center">
          Nếu bạn đã có tài khoản? <a routerLink="/authentication/signin">Đăng nhập</a>
        </div>
      </div>
    </div>
    <div class="md:col-8">
      <img src="assets/images/sign-up.png" alt="image">
    </div>
  </div>
</div>