import { BaseModel } from './base';

export class TestQuestion extends BaseModel {

  idCourseUserLog: number;
  idQuestionParent: number;
  idTestQuestionType: number;
  idCourseTestQuestionParent: number;
  answerText: string;
  mark: number;
  questionText: string;
  courseTestQuestionAnswer = [];
  courseTestQuestionAnswerMatching = [];
  dsSubQuestion = [];
}
