<app-nav></app-nav>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li class="li-active">Khoá học</li>
      </ul>
      <h2>Khoá học</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<section class="coursesList-section mt-5">
  <div class="container">
    <div class="row filter-nav flex align-items-center">
      <div class="col-3">
        <p-button icon="{{isShowFilter ? 'pi pi-times': 'pi pi-sliders-h'}}" label="Bộ lọc" styleClass="p-button-text"
          (onClick)="isShowFilter = ! isShowFilter"></p-button>
      </div>
      <div class="col-9 flex justify-content-end filter-nav--buttons">
        <button class="mx-3 no-border-btn" *ngIf="!isListView" (click)="isListView = !isListView"><i
            class="pi pi-list"></i></button>
        <button class="mr-3 no-border-btn" *ngIf="isListView" (click)="isListView = !isListView"><i
            class="pi pi-th-large"></i></button>
        <button class="second-btn p-2 mr-2" (click)="changeOrderBy('id')">Mới nhất<span></span></button>
        <button class="second-btn p-2 mr-2" (click)="changeOrderBy('viewCount')">Xem nhiều nhất<span></span></button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 md:col-12 lg:col-3" *ngIf="isShowFilter">
        <div class="filter-container mt-2 mr-3">
          <div class="flex flex-column">
            <div class="flex flex-wrap justify-content-between title">
              <h5>Xếp hạng</h5>
              <i (click)="showRating = !showRating"
                [ngClass]="showRating ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
            </div>
            <div *ngIf="showRating" class="content rating">
              <div class="flex align-items-center">
                <div class="flex-initial flex">
                  <p-radioButton value="4.5" [(ngModel)]="avgRatingStar" (onClick)="getData()">
                  </p-radioButton>
                  <div class="mr-2"></div>
                  <ng-rating-bar [value]="4.5" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
                    [styles]="{fontSize: '20px', margin: '0px',padding: '0px'}"></ng-rating-bar>
                </div>
                <div class="flex-1 flex justify-content-center">
                  >= 4.5
                </div>
                <div class="flex flex-row-reverse">
                  <!-- (3960) -->
                </div>
              </div>
              <div class="flex align-items-center">
                <div class="flex-initial flex">
                  <p-radioButton value="4" [(ngModel)]="avgRatingStar" (onClick)="getData()">
                  </p-radioButton>
                  <div class="mr-2"></div>
                  <ng-rating-bar [value]="4" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
                    [styles]="{fontSize: '20px', margin: '0px',padding: '0px'}"></ng-rating-bar>
                </div>
                <div class="flex-1 flex justify-content-center">
                  >= 4.0
                </div>
                <div class="flex flex-row-reverse">
                  <!-- (3960) -->
                </div>
              </div>
              <div class="flex align-items-center">
                <div class="flex-initial flex">
                  <p-radioButton value="3.5" [(ngModel)]="avgRatingStar" (onClick)="getData()">
                  </p-radioButton>
                  <div class="mr-2"></div>
                  <ng-rating-bar [value]="3.5" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
                    [styles]="{fontSize: '20px', margin: '0px',padding: '0px'}"></ng-rating-bar>
                </div>
                <div class="flex-1 flex justify-content-center">
                  >= 3.5
                </div>
                <div class="flex flex-row-reverse">
                  <!-- (3960) -->
                </div>
              </div>
              <div class="flex align-items-center">
                <div class="flex-initial flex">
                  <p-radioButton value="3" [(ngModel)]="avgRatingStar" (onClick)="getData()">
                  </p-radioButton>
                  <div class="mr-2"></div>
                  <ng-rating-bar [value]="3" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
                    [styles]="{fontSize: '20px', margin: '0px',padding: '0px'}"></ng-rating-bar>
                </div>
                <div class="flex-1 flex justify-content-center">
                  >= 3
                </div>
                <div class="flex flex-row-reverse">
                  <!-- (3960) -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-container mt-2 mr-3">
          <div class="flex flex-column" *ngIf="listCategories.length > 0">
            <div class="flex flex-wrap justify-content-between title">
              <h5>Chuyên mục</h5>
              <i (click)="showCategories = !showCategories" class="cursor-pointer"
                [ngClass]="showCategories ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
            </div>
            <div *ngIf="showCategories" class="content rating">
              <ng-container *ngFor="let item of listCategories">
                <div class="my-2 flex">
                  <p-checkbox name="group1" value="{{item.data}}" [(ngModel)]="selectedCategories" class="mr-2"
                    (onChange)="getData()" inputId="ny"></p-checkbox>
                  <label for="ny">{{item.label}}</label>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="filter-container mt-2 mr-3">
          <div class="flex flex-column" *ngIf="listSubCategories.length > 0">
            <div class="flex flex-wrap justify-content-between title">
              <h5>Chuyên mục con</h5>
              <i (click)="showSubCategories = !showSubCategories" class="cursor-pointer"
                [ngClass]="showSubCategories ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
            </div>
            <div *ngIf="showSubCategories" class="content languages ">

              <ng-container *ngFor="let item of listSubCategories">
                <div class="my-2 flex">
                  <p-checkbox name="group1" value="{{item.data}}" [(ngModel)]="selectedCategories" class="mr-2"
                    (onChange)="getData()" inputId="ny"></p-checkbox>
                  <label for="ny">{{item.label}}</label>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="{{isShowFilter ? 'col-12 md:col-12 lg:col-9' : 'col-12 md:col-12 lg:col-12 search-result-wrapper'}}">
        <div class="row" *ngIf="isListView">
          <ng-container *ngFor="let item of dataSource">
            <app-single-course-box-list [item]="item"></app-single-course-box-list>
          </ng-container>
        </div>

        <div class="row" *ngIf="!isListView">
          <ng-container *ngFor="let item of dataSource">
            <div class="{{isShowFilter ? 'lg:col-4 md:col-6 sm:col-12' : 'lg:col-3 md:col-6 sm:col-12'}} ">
              <app-single-course-box-grid [item]="item"></app-single-course-box-grid>
            </div>
          </ng-container>

        </div>
        <div class="row">
          <sv-paging [pageIndex]="pageIndex" [totalRecord]="totalRecord" [pageSize]="pageSize" [pagesToShow]="4"
            *ngIf="totalRecord > 0" [loading]="isLoading" (onGoPage)="onGoPage($event)" (onPrePage)="onPrePage()"
            (onNextPage)="onNextPage()">
          </sv-paging>
        </div>
        <div class="row mt-3" *ngIf="listBestSellerCource.length > 0">
          <div class="col-12">
            <div class="usually-buy p-3">
              <h2>Thường xuyên được mua</h2>
              <ng-container *ngFor="let item of listBestSellerCource">
                <app-course-item-type-three [item]="item"></app-course-item-type-three>
              </ng-container>
              <div class="grid flex align-items-center justify-content-between">
                <span class="col-12 lg:col-6 total-course-money">Tổng: <b>
                    {{totalBestSellerCoursePrice | currency: "VND"}}</b></span>
                <button class="col-12 lg:col-6 default-btn px-2" (click)="addAllToShoppingCart()">Thêm tất cả vào giỏ
                  hàng<span></span></button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3" *ngIf="listLatestCourse.length > 0">
          <div class="col-12">
            <div class="container">
              <h2 class="mb-2">Các khóa học thịnh hành và mới</h2>
              <div class="courses-most-study-container">
                <owl-carousel-o [options]="courseMostStudySides">
                  <ng-container *ngFor="let item of listLatestCourse">
                    <ng-template carouselSlide>
                      <app-course-item-type-two [item]="item"></app-course-item-type-two>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-5 align-items-left search-section">
          <div class="col-12">
            <h2>Tìm kiếm có liên quan</h2>
          </div>
          <div class="col-12 flex justify-content-between flex-wrap">
            <span (click)="filterByKeyword('Starters')">Starters</span>
            <span (click)="filterByKeyword('Movers')">Movers</span>
            <span (click)="filterByKeyword('Flyers')">Flyers</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
