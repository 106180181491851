<div class="ws-l-page-body-content h-100vh">
  <div class="ws-page-body-title"><span class="ws-img-icon ws-page-body-title__icon">
      <svg>
        <use href="\assets\images\icon\award.svg#icon"></use>
      </svg></span>
    <div class="ws-page-body-title__text">Thông tin học tập</div>
  </div>
  <div class="ws-page-body-content">
    <div class="ws-l-result-item">
      <div class="ws-l-result-item-title">Thông tin học tập</div>
      <div class="ws-l-result-item-date"><span class="ws-img-icon ws-l-result-item-date__icon">
          <svg>
            <use href="\assets\images\icon\sticky-note.svg#icon"></use>
          </svg></span>
        <div class="ws-l-result-item-date__text">
          Ngày tham gia: {{courseUserInfoExt.joinTime | date:'dd/MM/yyyy HH:mm'}} - Lần học gần nhất:
          {{courseUserInfoExt.lastActiveTime | date:'dd/MM/yyyy HH:mm'}}
        </div>
      </div>
      <div class="ws-l-result-item-separator"></div>
      <div class="ws-l-result-item-process-container">
        <div class="ws-l-result-item-process-item">
          <div class="ws-l-result-item-process-item-part-1"><span
              class="ws-img-icon ws-l-result-item-process-item__main-icon">
              <svg>
                <use href="\assets\images\icon\camera.svg#icon"></use>
              </svg></span>
            <div class="ws-l-result-item-process-item__name">Số lượng bài giảng đã học </div>
          </div>
          <div class="ws-l-result-item-process-item-part-2">
            <div class="ws-progressbar -size-1 -thumb-size-1 -style-success ws-l-result-item-process-item__progressbar">
              <div class="ws-progressbar__slide"
                [style.width.%]="courseUserInfoExt.countCourseItemFinished * 100 / courseUserInfoExt.countCourseItem">
              </div>
              <div class="ws-progressbar__thumb"
                [style.left.%]="courseUserInfoExt.countCourseItemFinished * 100 / courseUserInfoExt.countCourseItem">
              </div>
            </div>
          </div>
          <div class="ws-l-result-item-process-item-part-3">
            <div class="ws-l-result-item-process-item__progress-detail">
              {{courseUserInfoExt.totalCourseUserLogView}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ws-l-main-progress-area" *ngIf="courseUserInfoExt.learningStatus == 1">
      <div class="ws-l-main-progressbar-wrapper">
        <div class="ws-progressbar -size-2 -thumb-size-3 -style-success ws-l-main-progressbar">
          <div class="ws-progressbar__slide" style="width:100%;"></div>
          <div class="ws-progressbar__thumb" style="left:100%;">
            <div class="ws-progressbar__thumb-label">100%</div>
          </div>
        </div>
        <div class="ws-l-main-progress-status-1-wrapper">
          <div class="ws-btn -size-2 ws-l-main-progress-status-1 -icon-size-1 ws-bgc--success ws-color--light">
            <span class="ws-img-icon ws-btn__icon">
              <svg>
                <use href="\assets\images\icon\rounded-check.svg#icon"></use>
              </svg></span>
            <div class="ws-btn__label">{{courseUserInfoExt.titleLearningStatus}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ws-l-main-progress-area" *ngIf="courseUserInfoExt.learningStatus != 1">
      <div class="ws-l-main-progressbar-wrapper">
        <div class="ws-progressbar -size-2 -thumb-size-3 -style-danger ws-l-main-progressbar">
          <div class="ws-progressbar__slide" style="width:50%;"></div>
          <div class="ws-progressbar__thumb" style="left:50%;">
            <!-- <div class="ws-progressbar__thumb-label">50%</div> -->
          </div>
        </div>
        <div class="ws-l-main-progress-status-1-wrapper">
          <div class="ws-btn -size-2 ws-l-main-progress-status-1 -icon-size-1 ws-bgc--danger ws-color--light">
            <span class="ws-img-icon ws-btn__icon">
              <svg>
                <use href="\assets\images\icon\rounded-check.svg#icon"></use>
              </svg></span>
            <div class="ws-btn__label">{{courseUserInfoExt.titleLearningStatus}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ws-page-body-title"><span class="ws-img-icon ws-page-body-title__icon">
      <svg>
        <use href="\assets\images\icon\award.svg#icon"></use>
      </svg></span>
    <div class="ws-page-body-title__text">Kết quả học tập</div>
  </div>
  <div class="ws-page-body-content" style="overflow-y: scroll; height: 400px;">
    <div class="invoice_table">
      <div class="table-responsive-md">
        <table class="table table-borderless">
          <thead class="p-datatable-thead">
            <tr>
              <th style="width: 40%;">Tiêu đề</th>
              <th>Thời gian truy cập</th>
              <th style="width: 20%;">Điểm / Tỷ lệ hoàn thành</th>
              <th>Đạt</th>
            </tr>
          </thead>
          <tbody class="p-datatable-tbody">
            <ng-container *ngFor="let item of courseItemsExt">
              <tr>
                <td>
                  <div class="user_dt_trans">
                    <p>{{item.label}}</p>
                  </div>
                </td>
                <td>
                  <div class="user_dt_trans">
                    {{item.learningTime * 1000 | date:'mm:ss' }}
                  </div>
                </td>
                <td>
                  <ng-container *ngIf="item.idCourseItemType < 20">
                    {{item.learningRate ?? 0}} %
                  </ng-container>
                  <ng-container *ngIf="item.idCourseItemType > 20">
                    <p [class.approval]="item.approvalMark">
                      {{item.finalMark}} ({{item.passMark}} / {{item.totalMark}})
                    </p>
                    <ul *ngIf="item.dsCourseUserLog?.length > 1">
                      <li *ngFor="let log of item.dsCourseUserLog; index as i">
                        {{i + 1}}. {{log.timeIn
                        |
                        date:'dd/MM/yyyy HH:mm'}} - {{log.finalMark}}</li>
                    </ul>
                  </ng-container>
                </td>
                <td>
                  <p *ngIf="item.idCourseItemType > 10 && item.idCourseItemType < 20">
                    <p-inputSwitch [(ngModel)]="item.isLearned" disabled></p-inputSwitch>
                  </p>
                  <p *ngIf="item.idCourseItemType > 20">
                    <p-inputSwitch [(ngModel)]="item.finalMark >= item.passMark" disabled>
                    </p-inputSwitch>
                  </p>
                </td>
              </tr>
              <ng-container *ngFor="let child1 of item.children">
                <tr>
                  <td>
                    <div class="user_dt_trans">
                      <p>{{child1.label}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="user_dt_trans">
                      {{child1.learningTime * 1000 | date:'mm:ss' }}
                    </div>
                  </td>
                  <td>
                    <ng-container *ngIf="child1.idCourseItemType < 20">
                      {{child1.learningRate ?? 0}} %
                    </ng-container>
                    <ng-container *ngIf="child1.idCourseItemType > 20">
                      <p [class.approval]="child1.approvalMark">
                        {{child1.finalMark}} ({{child1.passMark}} / {{child1.totalMark}})
                      </p>
                      <ul *ngIf="child1.dsCourseUserLog?.length > 1">
                        <li *ngFor="let log of child1.dsCourseUserLog; index as i">
                          {{i + 1}}. {{log.timeIn
                          |
                          date:'dd/MM/yyyy HH:mm'}} - {{log.finalMark}}</li>
                      </ul>
                    </ng-container>
                  </td>
                  <td>
                    <p *ngIf="child1.idCourseItemType > 10 && child1.idCourseItemType < 20">
                      <p-inputSwitch [(ngModel)]="child1.isLearned" disabled></p-inputSwitch>
                    </p>
                    <p *ngIf="child1.idCourseItemType > 20">
                      <p-inputSwitch [(ngModel)]="child1.finalMark >= child1.passMark" disabled>
                      </p-inputSwitch>
                    </p>
                  </td>
                </tr>
                <ng-container *ngFor="let child2 of child1.children">
                  <tr>
                    <td>
                      <div class="user_dt_trans">
                        <p>{{child2.label}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="user_dt_trans">
                        {{child2.learningTime * 1000 | date:'mm:ss' }}
                      </div>
                    </td>

                    <td>
                      <ng-container *ngIf="child2.idCourseItemType < 20">
                        {{child2.learningRate ?? 0}} %
                      </ng-container>
                      <ng-container *ngIf="child2.idCourseItemType > 20">
                        <p [class.approval]="child2.approvalMark">
                          {{child2.finalMark}} ({{child2.passMark}} / {{child2.totalMark}})
                        </p>
                        <ul *ngIf="child2.dsCourseUserLog?.length > 1">
                          <li *ngFor="let log of child2.dsCourseUserLog; index as i">
                            {{i + 1}}. {{log.timeIn
                            |
                            date:'dd/MM/yyyy HH:mm'}} - {{log.finalMark}}</li>
                        </ul>
                      </ng-container>
                    </td>
                    <td>
                      <p *ngIf="child2.idCourseItemType > 10 && child2.idCourseItemType < 20">
                        <p-inputSwitch [(ngModel)]="child2.isLearned" disabled></p-inputSwitch>
                      </p>
                      <p *ngIf="child2.idCourseItemType > 20">
                        <p-inputSwitch [(ngModel)]="child2.finalMark >= child2.passMark" disabled>
                        </p-inputSwitch>
                      </p>
                    </td>
                  </tr>
                  <ng-container *ngFor="let child3 of child2.children">
                    <tr>
                      <td>
                        <div class="user_dt_trans">
                          <p>{{child3.label}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="user_dt_trans">
                          <p *ngIf="child3.idCourseItemType > 10">
                            <p-inputSwitch [(ngModel)]="child3.isLearned" disabled>
                            </p-inputSwitch>

                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="user_dt_trans">
                          <p *ngIf="child3.idCourseItemType > 10 && child3.idCourseItemType < 20">
                            {{child3.totalTimeCourseUserLogDetail}}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="user_dt_trans">
                          <p *ngIf="child3.idCourseItemType > 10 && child3.idCourseItemType < 20">
                            {{child3.totalTime}}
                          </p>
                        </div>
                      </td>
                      <td>
                        <p *ngIf="child3.idCourseItemType > 20" [class.approval]="child3.approvalMark">
                          {{child3.finalMark}} ({{child3.passMark}} / {{child3.totalMark}})
                        </p>
                        <ul *ngIf="child3.dsCourseUserLog?.length > 1">
                          <li *ngFor="let log of child3.dsCourseUserLog; index as i">{{i +
                            1}}.
                            {{log.timeIn |
                            date:'dd/MM/yyyy HH:mm'}} - {{log.finalMark}}</li>
                        </ul>
                      </td>
                      <td>
                        <p *ngIf="child3.idCourseItemType > 10 && child3.idCourseItemType < 20">
                          <p-inputSwitch [(ngModel)]="child3.isLearned" disabled>
                          </p-inputSwitch>
                        </p>
                        <p *ngIf="child3.idCourseItemType > 20">
                          <p-inputSwitch [(ngModel)]="child3.finalMark >= child3.passMark" disabled>
                          </p-inputSwitch>
                        </p>
                      </td>
                    </tr>
                    <ng-container *ngFor="let child4 of child3.children">
                      <tr>
                        <td>
                          <div class="user_dt_trans">
                            <p>{{child4.label}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="user_dt_trans">
                            <p *ngIf="child4.idCourseItemType > 10">
                              <p-inputSwitch [(ngModel)]="child4.isLearned" disabled>
                              </p-inputSwitch>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div class="user_dt_trans">
                            <p *ngIf="child4.idCourseItemType > 10 && child4.idCourseItemType < 20">
                              {{child4.totalTimeCourseUserLogDetail}}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div class="user_dt_trans">
                            <p *ngIf="child4.idCourseItemType > 10 && child4.idCourseItemType < 20">
                              {{child4.totalTime}}
                            </p>
                          </div>
                        </td>
                        <td>
                          <p *ngIf="child4.idCourseItemType > 20" [class.approval]="child4.approvalMark">
                            {{child4.finalMark}} ({{child4.passMark}} /
                            {{child4.totalMark}})
                          </p>
                          <ul *ngIf="child4.dsCourseUserLog?.length > 1">
                            <li *ngFor="let log of child4.dsCourseUserLog; index as i">{{i +
                              1}}.
                              {{log.timeIn |
                              date:'dd/MM/yyyy HH:mm'}} - {{log.finalMark}}</li>
                          </ul>
                        </td>
                        <td>
                          <p *ngIf="child4.idCourseItemType > 10 && child4.idCourseItemType < 20">
                            <p-inputSwitch [(ngModel)]="child4.isLearned" disabled>
                            </p-inputSwitch>
                          </p>
                          <p *ngIf="child4.idCourseItemType > 20">
                            <p-inputSwitch [(ngModel)]="child4.finalMark >= child4.passMark" disabled>
                            </p-inputSwitch>
                          </p>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>