<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

<p-toast styleClass="sv-toast -style-inline" position="bottom-right" key="success-warn"></p-toast>

<p-toast styleClass="sv-toast" position="bottom-right" key="general-warning"></p-toast>

<p-toast styleClass="sv-toast" [style]="{marginTop: '60px'}" position="top-right" key="general-info"></p-toast>

<p-toast styleClass="sv-toast" [style]="{marginTop: '60px'}" position="top-right" key="error"></p-toast>

<p-confirmDialog [autoZIndex]="true"></p-confirmDialog>