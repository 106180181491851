<div class="ws-page-body-title"><span class="ws-img-icon ws-page-body-title__icon">
    <svg>
      <use href="\assets\images\icon\camera.svg#icon"></use>
    </svg></span>
  <div class="ws-page-body-title__text">Diễn đàn Trao đổi/Thảo luận</div>
</div>
<div class="ws-page-body-content ws-l-page-body-content h-100vh">
  <ng-container *ngIf="!showTopic">
    <div class="ws-l-create-topic-btn-wrapper">
      <button class="ws-btn -size-3 ws-color--light ws-bgc--primary-2 ws-l-create-topic-btn" *ngIf="!showAdd"
        (click)="onShowAddTopic()">
        <div class="ws-btn__label">Tạo chủ đề</div>
      </button>
    </div>
    <ng-container *ngIf="showAdd">
      <div class="ws-heading-1 ws-l-create-topic-heading">
        <span class="ws-img-icon ws-heading-1__icon">
          <svg>
            <use href="\assets\images\icon\rounded-plus.svg#icon"></use>
          </svg>
        </span>
        <div class="ws-heading-1__text">Tạo chủ đề mới</div>
      </div>
      <div class="ws-l-text-editor">
        <form #formElement [formGroup]="formGroup">
          <div class="ui-g row">
            <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
            </validation-summary>
          </div>
          <div class="row p-fluid">
            <div class="ui-g-12 ui-lg-12">
              <div class="sv-form-item -required -error">
                <div class="sv-form-item__label" for="title">{{'Tiêu đề' | translate}}</div>
                <div class="sv-form-item__input-wrapper -type-text-field">
                  <input pInputText type="text" [(ngModel)]="itemDetail.title" formControlName="title">
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="sv-form-item -required -error">
                <div class="sv-form-item__label" for="bodyHtml">Nội dung</div>
                <div class="sv-form-item__input-wrapper">
                  <div class="col-md-12">
                    <p-editor [(ngModel)]="itemDetail.bodyHtml" [style]="{'height':'200px', 'width': '100%'}"
                      formControlName="bodyHtml">
                    </p-editor>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="ws-l-create-topic-btn-wrapper">
        <button class="ws-btn -size-3 ws-color--light ws-bgc--primary-2 ws-l-create-topic-btn" (click)="addTopic()">
          <div class="ws-btn__label">Tạo chủ đề</div>
        </button>
      </div>
    </ng-container>
    <div class="ws-l-topic-container-header">
      <div class="ws-l-topic-container-header__left-side">Chủ đề</div>
      <div class="ws-l-topic-container-header__right-side">Thảo luận</div>
    </div>
    <div class="ws-l-topic-container">
      <ng-container *ngFor="let item of dataSource; index as i">
        <div class="ws-space-topic-item">
          <div class="ws-space-topic-item-part-1">
            <span class="ws-space-topic-item__icon">
              <a *ngIf="item.pin == true" (click)="updatePin(item, true)">
                <i class="bx bx-unlink"></i>
              </a>
              <a *ngIf="item.pin != true" (click)="updatePin(item, false)">
                <i class="ci ci-unlink bx bx-link-alt"></i>
              </a>
            </span>
            <div class="ws-space-topic-item__title">
              <a (click)="viewTopicDetail(item.id)">
                {{item.title}}
              </a>
            </div>
          </div>

          <div class="ws-space-topic-item-part-3">
            <div class="ws-space-topic-item-part-3__inner">
              <div class="ws-space-topic-item__comment-count">{{item.replyCount ?? 0}}</div>
              <div class="ws-space-topic-item__date">{{item.modified | timeAgo}}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-md-12" *ngIf="pageIndex <= totalRecord / pageSize">
        <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"></div>
        <div class="main-loader mt-50" *ngIf="isLoading">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showTopic">
    <div class="ws-heading-2 ws-l-heading">
      <span class="ws-img-icon ws-heading-2__icon">
        <svg>
          <use href="\assets\images\icon\paperclip-2.svg#icon"></use>
        </svg>
      </span>
      <div class="ws-heading-2__text">{{itemTopic.title}}</div>
      <button class="ws-l-heading__btn" (click)="viewTopics()" pTooltip="Trở về">
        <span class="ws-img-icon ws-l-heading__btn-icon">
          <svg>
            <use href="\assets\images\icon\square-arrow-left.svg#icon"></use>
          </svg>
        </span>
      </button>
    </div>

    <div class="ws-page-body-content ws-l-page-body-content">
      <div class="ws-space-comment-item">
        <div class="ws-space-comment-item__body">
          <div class="ws-space-comment-item__dot"></div>
          <img class="ws-space-comment-item__avatar" src="{{itemTopic.avatar | avatar}}" alt="Avatar">
          <button class="ws-space-comment-item__dots-btn"><span
              class="ws-img-icon ws-space-comment-item__dots-btn-icon">
              <svg>
                <use href="\assets\images\icon\hoz-dots.svg#icon"></use>
              </svg></span>
          </button>
          <div class="ws-space-comment-item__content-block">
            <div class="ws-space-comment-item__user-name">{{itemTopic.modifiedByName}}</div>
            <div class="ws-space-comment-item__date-time">
              <div class="ws-space-comment-item__date-time-text">
                {{itemTopic.modified | date:'dd/MM/yyyy HH:mm'}}
              </div><span class="ws-img-icon ws-space-comment-item__date-time-icon">
                <svg>
                  <use href="\assets\images\icon\global.svg#icon"></use>
                </svg></span>
            </div>
            <div class="ws-space-comment-item__message">
              {{itemTopic.title}}
              <p [innerHTML]="itemTopic.bodyHtml"></p>
            </div>
          </div>
        </div>
        <div class="ws-space-comment-item__footer">
          <div class="ws-space-comment-item__action -view">
            <span class="ws-img-icon ws-space-comment-item__action-icon">
              <svg>
                <use href="\assets\images\icon\eye.svg#icon"></use>
              </svg>
            </span>
            <div class="ws-space-comment-item__action-text">{{itemTopic.viewCount ?? 0}}</div>
          </div>
          <button class="ws-space-comment-item__action -like">
            <span class="ws-img-icon ws-space-comment-item__action-icon">
              <svg>
                <use href="\assets\images\icon\heart.svg#icon"></use>
              </svg>
            </span>
            <div class="ws-space-comment-item__action-text">{{itemTopic.replyCount ?? 0}}</div>
          </button>
          <button class="ws-btn -size-2 -icon-size-1 ws-space-comment-item__reply-btn" (click)="addPost()"
            *ngIf="!showAddPost">
            <span class="ws-img-icon ws-btn__icon">
              <svg>
                <use href="\assets\images\icon\back.svg#icon"></use>
              </svg></span>
            <div class="ws-btn__label">Trả lời</div>
          </button>
        </div>
        <div class="ws-space-comment-item__reply-container" *ngIf="showAddPost">
          <div class="ws-space-comment-item -sub-comment">
            <div class="ws-space-comment-item__body">
              <div class="ws-space-comment-item__content-block" style="width:100%">
                <p-editor [(ngModel)]="itemPost.bodyHtml" [style]="{'height':'200px', 'width': '100%'}">
                </p-editor>
              </div>
            </div>
            <div class="ws-space-comment-item__footer">
              <button class="ws-btn -size-2 -icon-size-1 ws-bgc--primary-1 ws-space-comment-item__reply-btn"
                (click)="cancelPost()">
                <span class="ws-img-icon ws-btn__icon">
                  <svg>
                    <use href="\assets\images\icon\close-circle.svg#icon"></use>
                  </svg></span>
                <div class="ws-btn__label">Hủy</div>
              </button>
              <button class="ws-btn -size-2 -icon-size-1 ws-space-comment-item__reply-btn" (click)="savePost()">
                <span class="ws-img-icon ws-btn__icon">
                  <svg>
                    <use href="\assets\images\icon\back.svg#icon"></use>
                  </svg></span>
                <div class="ws-btn__label">Tạo</div>
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let itemTopic of dataPost">
          <div class="ws-space-comment-item__reply-container">
            <div class="ws-space-comment-item -sub-comment">
              <div class="ws-space-comment-item__body">
                <div class="ws-space-comment-item__dot"></div>
                <img class="ws-space-comment-item__avatar" src="{{itemTopic.avatar | avatar}}" alt="Avatar">
                <div class="ws-space-comment-item__content-block">
                  <div class="ws-space-comment-item__user-name">{{itemTopic.modifiedByName}}</div>
                  <div class="ws-space-comment-item__date-time">
                    <div class="ws-space-comment-item__date-time-text"> {{itemTopic.modified |
                      date:'dd/MM/yyyy HH:mm'}}</div>
                    <span class="ws-img-icon ws-space-comment-item__date-time-icon">
                      <svg>
                        <use href="\assets\images\icon\global.svg#icon"></use>
                      </svg></span>
                  </div>
                  <div class="ws-space-comment-item__message">
                    <p [innerHTML]="itemTopic.bodyHtml"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </ng-container>
</div>