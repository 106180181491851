import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { map, takeUntil } from 'rxjs/operators';
import { AuthenticationService, ComponentBase, BasicUserInfo } from 'sv-shared';
import { CourseItemService } from '../../../services/course-item.service';
import { CourseUserService } from '../../../services/course-user.service';

@Component({
  selector: 'app-learning-space-v3-result',
  templateUrl: './learning-space-v3-result.component.html',
  styleUrls: ['./learning-space-v3-result.component.scss']
})
export class LearningSpaceV3ResultComponent extends ComponentBase implements OnInit {

  courseItemsExt: TreeNode[] = [
    {
      'label': 'Nội dung khóa học',
      'data': '-1',
      'expandedIcon': 'fa-folder-open',
      'collapsedIcon': 'fa-folder',
      'children': [],
      'type': '1'
    }];
  // idCourse = 0;
  currentUser: BasicUserInfo = new BasicUserInfo();
  courseUserInfoExt: any = {};

  @Input() courseDetail = { id: 0, instanceId: '', description: '' }

  constructor(
    private _courseUserService: CourseUserService,
    private _activatedRoute: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _courseItemService: CourseItemService,
  ) {
    super();
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(idCourse => {
        //this.idCourse = idCourse;

      });

    this.viewLearningHistory();
  }

  viewLearningHistory() {
    this._courseUserService.getMyDetail(this.courseDetail.id).then(rs => {
      if (rs.success) {
        this.courseUserInfoExt = rs.data;
        this.courseUserInfoExt.joinTime = new Date(this.courseUserInfoExt.joinTime);
        this.courseUserInfoExt.lastAccessTime = new Date(this.courseUserInfoExt.lastAccessTime);

        console.log(this.courseUserInfoExt.joinTime);

      }
    });
    this._courseItemService.getTreeWihUserLog(this.courseDetail.id).then(rs => {
      if (rs.success) {
        this.courseItemsExt = rs.data;
      }
    })
  }

}
