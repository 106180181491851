<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li><a routerLink="/profile">Thông tin cá nhân</a></li>
        <li>Lịch sử học tập của con</li>
      </ul>
      <h2>Lịch sử học tập của con</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<div class="container mt-6 mb-6">
  <div class="row">
    <p-tabView>
      <p-tabPanel header="Khóa chưa học">
        <div class="row">
          <ng-container *ngFor="let item of listMyCourseHaventStudy">
            <div class="lg:col-3 md:col-6 sm:col-12">
              <div class="single-courses-box-grid">
                <div class="courses-image">
                  <a routerLink="/courses/detail/{{item.instanceId}}">
                    <img src="{{item.imageUrl}}" alt="">
                  </a>
                </div>
                <div class="courses-content">
                  <a routerLink="/courses/detail/{{item.instanceId}}">
                    <h3 class="title">{{item.title}}</h3>
                  </a>
                  <a routerLink="/authors/detail/{{item.idTenant}}">
                    <h5 class="author">{{item.displayNameTenant}}</h5>
                  </a>
                  <div class="row">
                    <div class="price-text md:col-4 sm:col-12">
                      <div class="original-price">{{item.price | currency: 'VND'}}</div>
                      <div class="discount-price">{{item.priceDiscount}}</div>
                    </div>
                    <div class="courses-rating md:col-8 sm:col-12">
                      <ng-rating-bar [value]="5" [ratingCount]="item.avgRatingStar ?? 5" colorActive="#F2A000"
                        colorDefault="gray" [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
                      <span class="reviews">({{item.countComment ?? 0}})</span>
                    </div>
                  </div>
                  <div class="courses-info"><span class="courses-time">Độ tuổi: {{{ageFrom: item.ageFrom, ageTo:
                      item.ageTo} |
                      age}}</span> · <span>{{item.titleCatLevel == null ? "Tất cả các trình độ":
                      item.titleCatLevel}}</span></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Khóa đang học">
        <div class="row">
          <ng-container *ngFor="let item of listMyLearningCourse">
            <div class="lg:col-3 md:col-6 sm:col-12">
              <div class="single-courses-box-grid">
                <div class="courses-image">
                  <a routerLink="/courses/detail/{{item.instanceId}}">
                    <img src="{{item.imageUrl}}" alt="">
                  </a>
                </div>
                <div class="courses-content">
                  <a routerLink="/courses/detail/{{item.instanceId}}">
                    <h3 class="title">{{item.title}}</h3>
                  </a>
                  <a routerLink="/authors/detail/{{item.idTenant}}">
                    <h5 class="author">{{item.displayNameTenant}}</h5>
                  </a>
                  <div class="row">
                    <div class="price-text md:col-4 sm:col-12">
                      <div class="original-price">{{item.price | currency: 'VND'}}</div>
                      <div class="discount-price">{{item.priceDiscount}}</div>
                    </div>
                    <div class="courses-rating md:col-8 sm:col-12">
                      <ng-rating-bar [value]="5" [ratingCount]="item.avgRatingStar ?? 5" colorActive="#F2A000"
                        colorDefault="gray" [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
                      <span class="reviews">({{item.countComment ?? 0}})</span>
                    </div>
                  </div>
                  <div class="courses-info"><span class="courses-time">Độ tuổi: {{{ageFrom: item.ageFrom, ageTo:
                      item.ageTo} |
                      age}}</span> · <span>{{item.titleCatLevel == null ? "Tất cả các trình độ":
                      item.titleCatLevel}}</span></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Khóa đã hoàn thành">
        <div class="row">
          <ng-container *ngFor="let item of listMyFinishCourse">
            <div class="lg:col-3 md:col-6 sm:col-12">
              <div class="single-courses-box-grid">
                <div class="courses-image">
                  <a routerLink="/courses/detail/{{item.instanceId}}">
                    <img src="{{item.imageUrl}}" alt="">
                  </a>
                </div>
                <div class="courses-content">
                  <a routerLink="/courses/detail/{{item.instanceId}}">
                    <h3 class="title">{{item.title}}</h3>
                  </a>
                  <a routerLink="/authors/detail/{{item.idTenant}}">
                    <h5 class="author">{{item.displayNameTenant}}</h5>
                  </a>
                  <div class="row">
                    <div class="price-text md:col-4 sm:col-12">
                      <div class="original-price">{{item.price | currency: 'VND'}}</div>
                      <div class="discount-price">{{item.priceDiscount}}</div>
                    </div>
                    <div class="courses-rating md:col-8 sm:col-12">
                      <ng-rating-bar [value]="5" [ratingCount]="item.avgRatingStar ?? 5" colorActive="#F2A000"
                        colorDefault="gray" [styles]="{fontSize: '20px', margin: '5px',padding: '0px'}"></ng-rating-bar>
                      <span class="reviews">({{item.countComment ?? 0}})</span>
                    </div>
                  </div>
                  <div class="courses-info"><span class="courses-time">Độ tuổi: {{{ageFrom: item.ageFrom, ageTo:
                      item.ageTo} |
                      age}}</span> · <span>{{item.titleCatLevel == null ? "Tất cả các trình độ":
                      item.titleCatLevel}}</span></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Lịch sử thi">
        <div class="row">
          <p-table [value]="listMyExamHistory" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="5">
            <ng-template pTemplate="header">
              <tr>
                <th>Đợt thi</th>
                <th>Thời gian bắt đầu</th>
                <th>Thời gian kết thúc</th>
                <th>Số câu trả lời đúng</th>
                <th>Số câu trả lời sai</th>
                <th>Số câu không trả lời</th>
                <th>Tổng điểm</th>
                <th>Điểm yêu cầu</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.titleExam }}</td>
                <td>{{ rowData.startTime | date :'dd/MM/yyyy hh:mm' }}</td>
                <td>{{ rowData.endTime | date :'dd/MM/yyyy hh:mm' }}</td>
                <td>{{ rowData.numQTrue }}</td>
                <td>{{ rowData.numQFalse }}</td>
                <td>{{ rowData.numQNoAns }}</td>
                <td>{{ rowData.finalMark ?? 0 }} / {{rowData.totalMark}}</td>
                <td>{{ rowData.requiredMark }} / {{rowData.totalMark}}</td>

              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Chứng nhận / Chứng chỉ">
        <div class="row">
          <ng-container *ngFor="let item of listMyCertificate; index as i">
            <div class="row mb-5">
              <div class="col-12">
                <h2>{{i+1}}. {{item.titleCertificate}}</h2>
              </div>
              <div class="col-12 text-center">
                <div
                  style="width: 1000px; height: 700px; background-repeat: no-repeat; background-size: 97% 100%; background-image: url({{item.idCertificateNavigation.imageUrl}})">
                  <div [style.padding-top.px]="item.idCertificateNavigation.displayNamePositionTop"
                    [style.padding-left.px]="item.idCertificateNavigation.displayNamePositionLeft"
                    [style.font-size.px]="item.idCertificateNavigation.displayNameFont"
                    [style.color]="item.idCertificateNavigation.displayNameColor">
                    {{item.displayName}}
                  </div>
                  <div [style.padding-top.px]="item.idCertificateNavigation.dobPositionTop"
                    [style.padding-left.px]="item.idCertificateNavigation.dobPositionLeft"
                    [style.font-size.px]="item.idCertificateNavigation.dobFont"
                    [style.color]="item.idCertificateNavigation.dobColor">
                    Ngày sinh: {{item.dateOfBirth | date: 'dd/MM/yyyy'}}
                  </div>
                  <div [style.padding-top.px]="item.idCertificateNavigation.timePositionTop"
                    [style.padding-left.px]="item.idCertificateNavigation.timePositionLeft"
                    [style.font-size.px]="item.idCertificateNavigation.timeFont"
                    [style.color]="item.idCertificateNavigation.timeColor">
                    {{item.modified | date: 'dd/MM/yyyy'}}
                  </div>
                </div>
              </div>
              <div class="col-12 text-center">
                <button class="default-btn px-3" (click)="downloadCertificate(item.instanceId)">Tải chứng
                  chỉ<span></span></button>
              </div>
            </div>
          </ng-container>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>