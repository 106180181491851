<div class=" container">
  <div class="section-title">
    <h2>Đội ngũ giáo viên</h2>
    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua.</p> -->
  </div>
  <div class="row mt-2">
    <ng-container *ngFor="let item of listTenants">
      <div class="md:col-6 sm:col-12 lg:col-6">
        <div class="single-teachers-box-list">
          <div class="teachers-image">
            <a href="#">
              <img src="{{item.logoUrl}}" alt="" style="height:180px;">
            </a>
          </div>
          <div class="teachers-content" style="width: 100%;">
            <a href="#">
              <h3 class="title">
                {{item.title}}
              </h3>
            </a>
            <div class="description">
              {{item.description}}
            </div>
            <div class="row">
              <div class="md:col-4 sm:col-6"><i class="pi pi-user mr-2"></i>8.2k</div>
              <div class="md:col-4 sm:col-6"> <i class="pi pi-star-fill" style="color: #F2A000;"></i> 4.5</div>
              <div class="md:col-4 sm:col-6">25+ Khóa học</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>