import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SysFbLinkService } from '../../../services/sys-fblink.service';
import { SysSchoolService } from '../../../services/sys-school.service';
import { SysSettingService } from '../../../services/sys-setting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  listSettings = [];
  phoneNumber = '';
  email = '';
  linkFacebook = '';
  linkYoutube = '';
  companyTitle = '';
  companyMst = '';
  companyAddress = '';

  constructor(
    private _sysSettingService: SysSettingService,
    private route: ActivatedRoute,
    private _sysFbLinkService: SysFbLinkService,
    private router: Router
  ) { }

  ngOnInit() {
    this._sysSettingService.getsForClient().then(rs => {
      this.listSettings = rs;
      this.phoneNumber = this.listSettings.find(x => x.code === 'Info.Phone').settingValue;
      this.email = this.listSettings.find(x => x.code === 'Info.Email').settingValue;
      this.linkFacebook = this.listSettings.find(x => x.code === 'Info.Facebook').settingValue;
      this.linkYoutube = this.listSettings.find(x => x.code === 'Info.Youtube').settingValue;
      this.companyTitle = this.listSettings.find(x => x.code === 'Info.CompanyTitle').settingValue;
      this.companyMst = this.listSettings.find(x => x.code === 'Info.CompanyMst').settingValue;
      this.companyAddress = this.listSettings.find(x => x.code === 'Info.CompanyAddress').settingValue;
    })

    if (this.route.snapshot.queryParamMap.get('fbclid')) {
      var model = {
        fbclid: this.route.snapshot.queryParamMap.get('fbclid'),
        digiUrl: this.router.url
      }
      this._sysFbLinkService.post(model).then(rs => {
        console.log(rs);
      })
    }
  }

}
