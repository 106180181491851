import { Component, OnInit } from '@angular/core';
import { SysSettingService } from '../../../services/sys-setting.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  itemDetail: any = { settingValue: '' }

  constructor(
    private _sysSettingService: SysSettingService
  ) { }

  ngOnInit() {
    this._sysSettingService.getByInstanceId('75FEAF89-2A9F-47D4-BCFC-467F4F138338'.toLowerCase()).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
      }
    })
  }

}
