import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rank'
})
export class RankPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    if (value >= 1 && value <= 3) {
      return `<img src="assets/images/icons/rank${value}.png">`;
    }
    return `${value}`;
  }

}
