<app-section-banner [idSysSchool]="idSysSchool"></app-section-banner>
<div style="background: #f7f9fa; margin-top: 50px;">
  <!-- <div class="container">
    <div class="banner-inner-area">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-banner-box">
            <div class="icon">
              <i class="flaticon-brain-process"></i>
            </div>
            <h3>10,000 Online Courses</h3>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-banner-box">
            <div class="icon">
              <i class="flaticon-people"></i>
            </div>
            <h3>Experts Teachers</h3>

          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-banner-box">
            <div class="icon">
              <i class="flaticon-world"></i>
            </div>
            <h3>Lifetime Access</h3>

          </div>
        </div>
      </div>
    </div>
    <div class="features-area pt-8 pb-5">
      <app-features-style-two></app-features-style-two>
    </div>
  </div> -->
</div>

<app-section-hot-courses [idSysSchool]="idSysSchool"></app-section-hot-courses>
<!-- <app-top-categories></app-top-categories> -->

<app-section-random-tenant></app-section-random-tenant>
<app-section-top-news [idSysSchool]="idSysSchool"></app-section-top-news>