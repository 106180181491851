<app-nav></app-nav>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li><a routerLink="/document">Tài liệu</a></li>
        <li class="li-active">Chi tiết tài liệu</li>
      </ul>
      <h2>{{itemDetail?.title}}</h2>
      <p>{{itemDetail?.subject}} </p>
    </div>
  </div>
</div>
<section class="newsDetail mt-5">
  <div class="container">
    <div class="row">
      <div class="col-12 pr-5">
        <div class="row">
          <div class="col-12">
            <document-editor id="onlyoffice-editor" documentServerUrl="https://docapi.mlearning.space" [config]="config"
              [events_onDocumentReady]="onDocumentReady" [onLoadComponentError]="onLoadComponentError">
            </document-editor>

            <div id="fileViewContent">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="col-12 flex justify-content-between align-items-center author">
              <div class="flex align-items-center">
                <div class="ml-2">
                  <h4>{{itemDetail?.author}}</h4>
                  <span>{{itemDetail?.modified | date: 'dd/MM/yyyy'}}</span>
                </div>
              </div>
              <div>
                <!-- ShareThis BEGIN -->
                <div class="sharethis-inline-share-buttons"></div><!-- ShareThis END -->
              </div>
            </div>
            <hr class="mt-5">
            <ng-container *ngIf="currentUser.userId  > 0">
              <div class="col-12 flex comment-box flex-wrap">
                <div class="flex align-items-center mr-5">
                  <!-- <i class="pi pi-eye"></i>
                  <span class="ml-2">{{itemDetail.viewCount}}</span> -->
                  <ng-rating-bar [(value)]="rating" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
                    [styles]="{fontSize: '16px', margin: '0px',padding: '0px'}"></ng-rating-bar>
                </div>
                <div class="flex flex-auto align-items-center">
                  <input type="text" placeholder="Viết một bình luận..." [(ngModel)]="bodyHtml">
                </div>
                <div class="flex align-items-center mr-5">
                  <i class="pi pi-comments"></i>
                  <span class="ml-2">{{totalCommentCount}} bình luận</span>
                </div>
                <!-- <div class="flex align-items-center">
                  <i class="pi pi-heart"></i>
                  <span class="ml-2">50</span>
                </div> -->
              </div>
              <div class="row">
                <div class="col-6 flex justify-content-end">
                  <p-captcha siteKey="6LfzQjooAAAAAJee0JvagiW69Pd1jANfEVmHNtzq" (onResponse)="captchaResponse($event)">
                  </p-captcha>
                </div>
                <div class="col-6 flex justify-content-end">
                  <button class="default-btn px-3 mr-2" (click)="addItemComment()">Bình luận<span></span></button>
                </div>
              </div>
              <div class="col-12 comments-list">
                <div class="row">
                  <ng-container *ngFor="let item of dataCommentSource">
                    <div class="col-12">
                      <app-comment-item-type-two [item]="item"></app-comment-item-type-two>
                    </div>
                  </ng-container>
                </div>

                <!-- <div class="row">
                  <div class="col-12 flex justify-content-end">
                    <button class="no-border-btn ">Xem thêm</button>
                  </div>
                </div> -->
              </div>
            </ng-container>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="row">
                <h2>Tài liệu mới nhất</h2>
              </div>
              <ng-container *ngFor="let item of dataSource">
                <div class="row">
                  <div class="col-4">
                    <a routerLink="/document/detail/{{item.instanceId}}">
                      <img src="{{item.imageUrl}}" alt="">
                    </a>
                  </div>
                  <div class="col-8">
                    <a routerLink="/document/detail/{{item.instanceId}}">
                      <h4 class="document-title">
                        {{item.title}}
                      </h4>
                    </a>
                    <div class="description">
                      {{item.subject}}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
