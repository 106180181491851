<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li><a routerLink="/profile">Thông tin cá nhân</a></li>
        <li>Đổi mật hẩu</li>
      </ul>
      <h2>Đổi mật khẩu</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<div class="container mt-6 mb-6">
  <div class="row">
    <div class="md:col-4">
      <div class="profile">
        <div class="profile-image mb-4">
          <img src="assets/images/user/user9.png" alt="avatar">
        </div>
        <div class="flex justify-content-center rank-tag">
          <p-tag value="1/100" [rounded]="true" severity="success"></p-tag>
        </div>
        <div class="mb-4 text-center">
          <h5>{{itemDetail.displayName}}</h5>
          <div class="email">{{itemDetail.Email}}</div>
        </div>
        <div class="mb-4 text-center">
          <button pButton class="default-btn w-20rem" routerLink="/profile">{{'Thông tin cá nhân' | translate}}
            <span></span> </button>
        </div>
        <div class="mb-4 text-center">
          <button pButton class="primary-alt-btn w-20rem" routerLink="/ranking">{{'Bảng xếp hạng' | translate}}
            <span></span> </button>
        </div>
      </div>
    </div>
    <div class="md:col-8">
      <form #formElement [formGroup]="formGroup">
        <div class="row p-fluid">
          <div class="md:col-6">
            <div class="field -required -error" *ngIf="!isGoogleOrFacebookLogin">
              <label>{{'Mật khẩu cũ' | translate}}</label>
              <input type="password" pInputText [(ngModel)]="itemDetail.oldPassword" formControlName="oldPassword" />
              <small class="text-red-500" *ngIf="formGroup.controls['oldPassword'].invalid"> Bạn chưa nhập mật
                khẩu cũ</small>
            </div>
            <div class="field">
              <label>Mật khẩu mới</label>
              <input type="password" pInputText [(ngModel)]="itemDetail.newPassword" formControlName="newPassword" />
              <small class="text-red-500" *ngIf="formGroup.controls['newPassword'].invalid"> Mật khẩu mới độ dài ít nhất
                8 ký tự bao gồm cả chữ hoa, chữ thường, số và ký tự đặc biệt </small>
            </div>
            <div class="field">
              <button pButton class="default-btn w-20rem" (click)="doChangePassword()">Cập nhật <span></span> </button>
            </div>
          </div>
          <div class="md:col-6">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
