<div class="course-propper">
  <div class="course-details-content">
    <a routerLink="/courses/detail/{{item.instanceId}}">
      <h5 class="title">{{item.title}}</h5>
    </a>
    <div class="row">
      <div class="col-12">
        <p [innerHTML]="item.titleContent"></p>
      </div>
      <div class="price-text md:col-6 sm:col-12">
        <div class="original-price">{{item.price | currency: 'VND'}}</div>
        <div class="discount-price">{{item.priceDiscount}}</div>
      </div>
      <div class="courses-rating md:col-6 sm:col-12">
        <span class="author">{{item.displayNameTenant}}</span>
      </div>
    </div>
    <div class="row">
      <button class="default-btn" (click)="addToShoppingCart(item.instanceId)">Cho vào giỏ hàng</button>
    </div>
  </div>
</div>
