import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-news-box-list',
  templateUrl: './single-news-box-list.component.html',
  styleUrls: ['./single-news-box-list.component.scss']
})
export class SingleNewsBoxListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  @Input() item: any
}
