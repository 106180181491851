import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-item-type-one',
  templateUrl: './course-item-type-one.component.html',
  styleUrls: ['./course-item-type-one.component.scss']
})
export class CourseItemTypeOneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  @Input() item: any
}
