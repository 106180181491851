import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SecondPageEditBase, SvCorePublicService, UserService } from 'sv-shared';
import { OAuthService } from 'angular-oauth2-oidc';

import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent extends SecondPageEditBase implements OnInit {


  environment = environment;
  model: ModelDangNhap = new ModelDangNhap();
  error = false;
  returnUrl: string;
  socialUser: SocialUser;
  reCaptchaToken = '';
  captchaValid = false;

  constructor(
    private _router: ActivatedRoute,
    protected _injector: Injector,
    private formBuilder: FormBuilder,
    private _oauthService: OAuthService,
    private _svCorePublicService: SvCorePublicService,
    private _socialAuthService: SocialAuthService,
    protected _userService: UserService,
    private _cookieService: CookieService,
  ) {
    super(null, _injector);
    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
      remember: [''],
    });
  }

  ngOnInit() {
    this.returnUrl = this._router.queryParams['returnUrl'] || '/profile/dashboard';
    this._oauthService.requireHttps = false;
    if (this._authenticationService.isLoggedIn() && this._oauthService.hasValidAccessToken()) {
      // top.location.href = this.returnUrl;
    } else {
      localStorage.clear();
      sessionStorage.clear();
    }
    this.model.remember = true;
  }

  doSignIn() {
    if (this.formGroup.invalid) {
      this.showValidateMessage();
      return;
    }
    else {
      if (this.captchaValid) {
        this.submitting = true;
        this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
          if (!rs) {
            var myHeaders = new HttpHeaders().set('DeviceId', this._cookieService.get(environment.cookieName)).set('ReCaptchaToken', this.reCaptchaToken);

            this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(`${this.model.username}$${this._cookieService.get(environment.cookieName)}`
              , this.model.password
              , myHeaders).then(result => {
                this._oauthService.setupAutomaticSilentRefresh();
                // this.authorizeService.savePermission(result);

                this._notifierService.showSuccess(this._translateService.instant('LOGIN.MESSAGE_OK'));
                // this.app.getMenu();
                var svUser = this._authenticationService.getCurrentUser();
                setTimeout(() => {
                  this.submitting = false;
                  top.location.href = this.returnUrl;
                }, 500);

              },
                error => {
                  console.log(error.error.error_description);
                  this._notifierService.showWarning(error.error.error_description);
                  setTimeout(() => {
                    this.submitting = false;
                  }, 500);
                }
              );
          }
        });
      }
    }
  }

  signInWithGoogle(): void {
    this._notifierService.showWarning('Phần mềm đang thử chạy thử nghiệm nội bộ');
    return;
    this._socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
        if (!rs) {
          this.socialUser = x;
          var body = `grant_type=google_auth&scope=openid%20profile%20email&client_id=sv-client&id_token=${this.socialUser.idToken}`;
          this._userService.loginWithImplicit(body).then(rs => {
            localStorage.setItem('access_token', rs.access_token);
            this._oauthService.tryLogin();
            setTimeout(() => {
              this.submitting = false;
              top.location.href = this.returnUrl;
            }, 500);
          })
        }
      });

    });
  }

  signInWithFacebook(): void {
    this._notifierService.showWarning('Phần mềm đang thử chạy thử nghiệm nội bộ');
    return;
    this._socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
      this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
        if (!rs) {
          this.socialUser = x;
          var body = `grant_type=facebook_auth&scope=openid%20profile%20email&client_id=sv-client&id_token=${this.socialUser.authToken}`;
          this._userService.loginWithImplicit(body).then(rs => {
            localStorage.setItem('access_token', rs.access_token);
            this._oauthService.tryLogin();
            setTimeout(() => {
              this.submitting = false;
              top.location.href = this.returnUrl;
            }, 500);
          })
        }
      });
    });
  }

  captchaResponse(event) {
    this.reCaptchaToken = event.response;
    this.captchaValid = true;
  }

}


class ModelDangNhap {
  username: string;
  password: string;
  remember: boolean;
  email: string;
}
