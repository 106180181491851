<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li class="li-active">Chính sách bảo mật thông tin</li>
      </ul>
      <h2>CHÍNH SÁCH BẢO MẬT THÔNG TIN</h2>
      <p></p>
    </div>
  </div>
</div>
<section class="general-introduction-section">
  <div class="container mt-5 mb-5">
    <p [innerHTML]="itemDetail.settingValue"></p>
  </div>
</section>
