import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { NotificationService, SignalRService, BasicUserInfo, AuthenticationService, UserService } from 'sv-shared';
import { environment } from '../../../../environments/environment';
import { SysSchoolService } from '../../../services/sys-school.service';
import { CategoryTypeService } from '../../../services/category-type.service';
import { CategoriesService } from '../../../services/categories.service';
import { ShoppingCartService } from '../../../services/shopping-cart.service';
import { CookieService } from 'ngx-cookie-service';
import { UsersTrackingTimeService } from '../../../services/users-tracking-time.service';
import { Subscription } from 'rxjs';
import { SysSettingService } from '../../../services/sys-setting.service';


enum SidebarTabs {
  NOTIFICATION,
  ACTIVITY
}

interface Category {
  name: string;
  id: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  listSysSchool = [];
  listCategoryType = [];
  idCategoryType = 1;
  categoryTypeSelectedValues: string[] = ['1'];
  listCategory = [];

  showSearchBar = false;

  categoriesBoxVisible = false;

  categoriesBoxMobileVisible = false;


  showDropdownMenu = false;
  click = 'click'

  listCourseShoppingCart = [];

  currentUser = new BasicUserInfo();
  userId: string;
  totalUnRead = 0;
  dataSource = [];

  keyword = '';
  timerId: any = null;
  routeSubscription!: Subscription;

  placeholderTitle = 'Tìm kiếm khóa học';
  typeId = 1;


  showDropdown = {
    subsystem: false,
    userSetting: false,
    rightSidebar: false
  };
  SidebarTabs = SidebarTabs;
  currentSidebarTab: SidebarTabs = SidebarTabs.NOTIFICATION;

  listSettings = [];
  phoneNumber = '';
  email = '';

  constructor(
    private _router: Router,
    private _signalRService: SignalRService,
    private _notificationService: NotificationService,
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _sysSchoolService: SysSchoolService,
    private _categoryTypeService: CategoryTypeService,
    private _categoriesService: CategoriesService,
    private _activatedRoute: ActivatedRoute,
    private _shoppingCartService: ShoppingCartService,
    private _cookieService: CookieService,
    private _usersTrackingTimeService: UsersTrackingTimeService,
    private _sysSettingService: SysSettingService
  ) {
    this.currentUser = this._authenticationService.getCurrentUser();
    if (this.currentUser?.userId > 0) {
      this._signalRService.initNotificationHub();
    }

    this._signalRService.initCommonHub();

  }

  async ngOnInit() {

    this._activatedRoute.queryParams.subscribe((params) => {
      let keyword = params['keyword'];
      if (keyword) {
        this.keyword = keyword;
      }
    });

    this._sysSettingService.getsForClient().then(rs => {
      this.listSettings = rs;
      this.phoneNumber = this.listSettings.find(x => x.code === 'Info.Phone').settingValue;
      this.email = this.listSettings.find(x => x.code === 'Info.Email').settingValue;
    })

    this.routeSubscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var url = window.location.href;
        if (url.indexOf('courses') !== -1) {
          this.placeholderTitle = 'Tìm kiếm khóa học';
          this.typeId = 1;
        } else if (url.indexOf('news') !== -1) {
          this.placeholderTitle = 'Tìm kiếm tin tức';
          this.typeId = 2;
        } else if (url.indexOf('document') !== -1) {
          this.placeholderTitle = 'Tìm kiếm tài liệu';
          this.typeId = 3;
        } else if (url.indexOf('exam') !== -1) {
          this.placeholderTitle = 'Tìm kiếm cuộc thi';
          this.typeId = 4;
        } else {
          this.placeholderTitle = 'Tìm kiếm';
          this.typeId = 1;
        }
      }
    });


    await this.getsCategoryType();
    await this.getsSchool();
    this.onChangeCategoryType(1);

    if (this.currentUser?.userId > 0) {
      // var deviceId = this._cookieService.get(environment.cookieName);
      // if (deviceId != this.currentUser.deviceId) {
      //   this.doLogOut();
      // }

      this.userId = this.currentUser.userId.toString();
      this._signalRService.start(
        environment.signalrConfig.topic.notificationCreated,
        'NotificationHub',
        this.notifyTrigger.bind(this)
      );

      this.refreshNotification();

      this.timerId = setInterval(() => {
        this._usersTrackingTimeService.ping().then(rs => { console.log(rs); });
      }, 120000);

    }

    this._signalRService.start(
      environment.signalrConfig.topic.shoppingCartCreated,
      'CommonHub',
      this.commonHubTrigger.bind(this)
    );

    this.getShoppingCart();
  }

  toggleCategoriesBox() {
    this.categoriesBoxVisible = !this.categoriesBoxVisible;
  }

  toggleCategoriesBoxMobile() {
    this.categoriesBoxMobileVisible = !this.categoriesBoxMobileVisible;
  }


  doLogOut() {
    // this._userService.logOut().then(rs => { });
    // this.auth.logout();
    localStorage.clear();
    sessionStorage.clear();
    top.location.href = "/";
  }

  // user setting
  //-------------------

  closeUserSettingDropdown(event) {
    this.showDropdownMenu = false;
  }

  toggleUserSettingDropdown() {
    this.showDropdownMenu = !this.showDropdownMenu;
  }


  async goCategoryLink(item: any) {
    console.log(item);
    this.categoriesBoxVisible = false;
    if (item.idSchool) {
      await this._sysSchoolService.setSelectedSysSchool(item.idSchool);
    }
    switch (this.idCategoryType) {
      case 1:
        this._router.navigateByUrl(`/courses/${item.data}`);
        break;
      case 2:
        this._router.navigateByUrl(`/news/${item.data}`);
        break;
      case 3:
        this._router.navigateByUrl(`/document/${item.data}`);
        break;
      case 4:
        this._router.navigateByUrl(`/exam/${item.data}`);
        break;
    }
  }

  closeCategoryFilter() {
    this.categoriesBoxVisible = false;
  }


  //#region notification
  closeRightSidebar(event) {
    this.showDropdownMenu = false;
  }

  toggleRightSidebar() {
    this.showDropdown.rightSidebar = !this.showDropdown.rightSidebar;
  }


  notifyTrigger(data: any) {
    console.log(data);
    if (data !== null) {
      if (data.userId !== null) {
        if (data.userId.indexOf(',' + this.userId + ',') !== -1) {
          console.log('refreshNotification:  ' + this.userId);
          this.refreshNotification();
        }
      } else {
        console.log('refreshNotification:  All');
        this.refreshNotification();
      }
    }
  }

  refreshNotification() {
    var model = {
      keyword: '',
      offset: 0,
      pageSize: 10,
      isRead: 2,
    }
    this._notificationService.getsMyNotification(model)
      .then(result => {
        if (result.success) {
          this.dataSource = result.data;
          this.totalUnRead = result.totalRecord;
          sessionStorage.setItem('numOfNotifiUnRead', this.totalUnRead.toString());
        }

      });
  }

  readAll(event) {
    event.preventDefault();
    event.stopPropagation();

    this._notificationService.readAll().then(rs => {
      this.dataSource.forEach(element => {
        if (element.read != null) {
          element.read.push({ userId: this.userId });
        } else {
          element.read = [{ userId: this.userId }];
        }
      });
    });

    this.totalUnRead = 0;
  }

  readItem(item) {
    item.isRead = true;
    if (this.totalUnRead > 0) {
      this.totalUnRead -= 1;
    }
    this._notificationService.readMessage(item.id).then(rs => {
    });
  }
  //#endregion

  async getsCategoryType() {
    await this._categoryTypeService.getsAll().then(res => {
      if (res.success) {
        this.listCategoryType = res.data;
      }
    })
  }

  async getsSchool() {
    var schools = sessionStorage.getItem('schools');
    if (schools) {
      this.listSysSchool = JSON.parse(schools);

    } else {
      await this._sysSchoolService.getsAll().then(res => {
        if (res.success) {
          this.listSysSchool = res.data;
          sessionStorage.setItem('schools', JSON.stringify(res.data));
        }
      })
    }
  }

  onChangeCategoryType(typeId: number) {
    this.idCategoryType = typeId;
    this.categoryTypeSelectedValues = [`${typeId}`];
    this.listCategory = [];
    this.listSysSchool.forEach(school => {
      this.listCategory.push({ label: school.title, data: school.id, type: this.idCategoryType, children: [] });
    });

  }

  onMouseEnter(item: any) {
    if (item.children.length == 0) {
      this._categoriesService.getTree(item.data, this.idCategoryType, 1).then(res => {
        if (res.success) {
          item.children = res.data;
        }
      });
    }
  }

  goSchool(id: number) {
    this._router.navigate(['school', id]);
  }

  onSearch() {
    switch (this.typeId) {
      case 1:
        this._router.navigateByUrl(`/courses?keyword=${this.keyword}`);
        break;
      case 2:
        this._router.navigateByUrl(`/news?keyword=${this.keyword}`);
        break;
      case 3:
        this._router.navigateByUrl(`/document?keyword=${this.keyword}`);
        break;
      case 4:
        this._router.navigateByUrl(`/exam?keyword=${this.keyword}`);
        break;
      default:
        this._router.navigateByUrl(`/courses?keyword=${this.keyword}`);
        break;
    }

  }

  commonHubTrigger(body: any) {
    var digiCookie = this._cookieService.get(environment.cookieName);
    console.log(body.message);
    if (body.message.typeId === 1) {
      if (body.message.cookieId === digiCookie) {
        console.log(`reaload shopping cart:  ${digiCookie}`);
        this.getShoppingCart();
      }
    }
    // else {
    //   if (body.message.cookieId !== digiCookie) {
    //     this.doLogOut();
    //   }
    // }
  }

  getShoppingCart() {
    this._shoppingCartService.getsMyShoppingCart().then(rs => {
      if (rs.success) {
        this.listCourseShoppingCart = rs.data;
      }
    });
  }

}

