<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li>Thông tin cá nhân</li>
      </ul>
      <h2>Thông tin cá nhân</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p> -->
    </div>
  </div>
</div>
<div class="container mt-6 mb-6">
  <div class="row">
    <div class="md:col-4">
      <div class="profile">
        <div class="profile-image mb-4">
          <img src="assets/images/user/user9.png" alt="avatar">
        </div>
        <div class="flex justify-content-center rank-tag">
          <p-tag value="1/100" [rounded]="true" severity="success"></p-tag>
        </div>
        <div class="mb-4 text-center">
          <h5>{{itemDetail.displayName}}</h5>
          <div class="email">{{itemDetail.Email}}</div>
        </div>
        <div class="mb-4 text-center">
          <button pButton class="primary-alt-btn w-20rem" routerLink="/profile/change-password">Đổi mật khẩu
            <span></span>
          </button>
        </div>
        <!-- <ng-container *ngIf="itemDetail.typeId == 11">
          <div class="mb-4 text-center">
            <button pButton class="default-btn w-20rem" routerLink="/profile/children">Quản lý khoản của con
              <span></span>
            </button>
          </div>
        </ng-container> -->
        <!-- <div class="mb-4 text-center">
          <button pButton class="default-btn w-20rem" routerLink="/profile/ranking">Bảng xếp hạng <span></span>
          </button>
        </div> -->
      </div>
    </div>
    <div class="md:col-8">
      <h4>Thông tin cá nhân</h4>
      <div class="row p-fluid">
        <form #formElement [formGroup]="formGroup">
          <div class="row">
            <div class="md:col-6">
              <div class="field -required -error">
                <label>{{'Tài khoản' | translate}}</label>
                <input type="text" pInputText [disabled]="true" [(ngModel)]="itemDetail.userName"
                  formControlName="userName" />
              </div>
              <div class="field -required -error">
                <label>{{'Họ & tên' | translate}}</label>
                <input type="text" pInputText [(ngModel)]="itemDetail.displayName" formControlName="displayName" />
              </div>
              <div class="field">
                <label>Ngày sinh</label>
                <p-calendar formControlName="dateOfBirth" [(ngModel)]="itemDetail.dateOfBirth" appendTo="body"
                  dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"></p-calendar>
              </div>
              <div class="field -required -error">
                <label>Tỉnh/thành phố</label>
                <p-dropdown panelStyleClass="sv-dropdown-panel" [options]="listProvince" [autoDisplayFirst]="false"
                  formControlName="idProvince" dropdownIcon="ci ci-chevron-down-1" appendTo="body"
                  [(ngModel)]="itemDetail.idProvince" [filter]="true" (onChange)="getsDistrict()"
                  filterBy="label,value">
                </p-dropdown>
              </div>
              <ng-container *ngIf="itemDetail.typeId == 10">
                <div class="field -required -error">
                  <label>Cấp học</label>
                  <p-dropdown class="sv-form-item__input sv-dropdown" panelStyleClass="sv-dropdown-panel"
                    formControlName="idSchoolLevel" [options]="listSchoolLevel" [autoDisplayFirst]="false"
                    dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idSchoolLevel"
                    [filter]="true" filterBy="label,value">
                  </p-dropdown>
                </div>
              </ng-container>
            </div>
            <div class="md:col-6">
              <div class="field">
                <label>Giới tính</label>
                <p-dropdown [options]="listGender" [(ngModel)]="itemDetail.gender" formControlName="gender">
                </p-dropdown>
              </div>
              <div class="field">
                <label>Email</label>
                <input type="email" pInputText [(ngModel)]="itemDetail.email" formControlName="email" />
              </div>
              <div class="field">
                <label>Số điện thoại</label>
                <input type="text" pInputText [(ngModel)]="itemDetail.phoneNumber" formControlName="phoneNumber" />
              </div>
              <div class="field -required -error">
                <label>Quận / Huyện</label>
                <p-dropdown class="sv-form-item__input sv-dropdown" panelStyleClass="sv-dropdown-panel"
                  formControlName="idDistrict" [options]="listDistrict" [autoDisplayFirst]="false"
                  dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="itemDetail.idDistrict"
                  [filter]="true" filterBy="label,value">
                </p-dropdown>
              </div>
            </div>
            <!-- <div class="col-12 flex align-items-center justify-content-center">
              <div class="field">
                <button pButton class="default-btn w-20rem" (click)="doUpdateInfo()">Cập nhật <span></span> </button>
              </div>
            </div> -->
          </div>
        </form>
        <ng-container *ngIf="listSysSchool.length > 0">
          <div class="row">
            <h4>Bạn quan tâm tới nội dung nào?</h4>
            <ng-container *ngFor="let item of listSysSchool">
              <div class="col-6 digi">
                <label>{{item.schoolInfo.title}}</label>
                <p-tree [value]="item.listCategory" selectionMode="checkbox" class="w-full md:w-30rem"
                  [(selection)]="selectedCategories">
                </p-tree>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div class="col-12 flex align-items-center justify-content-center">
          <div class="field">
            <button pButton class="default-btn w-20rem" (click)="doUpdateInfo()">Cập nhật <span></span> </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
