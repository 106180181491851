import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseItemLiveService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseitemlive`);
  }

  getsByIdCourse(idCourse: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsByIdCourse/${idCourse}`;
    return this.defaultGet(apiUrl);
  }

  joinLiveClass(idCourse: number, isTeacher: boolean): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/JoinLiveClass/${idCourse}/${isTeacher}`;
    return this.defaultGet(apiUrl);
  }

  getsUpComing(idCourse: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsUpComing/${idCourse}`;
    return this.defaultGet(apiUrl);
  }
}
