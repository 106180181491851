import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, BasicUserInfo, ComponentBase } from 'sv-shared';
import { DocumentService } from '../../../services/document.service';
import { map, takeUntil } from 'rxjs';
import { IConfig } from '@onlyoffice/document-editor-angular';
import { ItemCommentService } from '../../../services/item-comment.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent extends ComponentBase implements OnInit {
  itemDetail: any = {};
  id = 0;

  dataSource = [];
  dataCommentSource = [];
  totalCommentCount = 0;
  itemType = 2;
  pageIndexComment = 1;
  pageIndexCommentSize = 5;
  config: any = {};
  currentUser: BasicUserInfo = new BasicUserInfo();
  bodyHtml = '';
  rating = 5;
  captchaIsValid = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _documentService: DocumentService,
    private _itemCommentService: ItemCommentService,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _titleTagService: Title,
  ) {
    super();
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.id = pid;
          this.getDetail(pid);
        } else {
          this._router.navigate(['/news']);

        }

      });
  }

  getDetail(id) {
    this._documentService.getByInstanceId(id).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
        const fileType = this.getFileType(rs.data.fileViewUrl);
        const documentType = this.getDocumentType(fileType);
        var view = document.getElementById("fileViewContent").parentElement.style;
        view.height = "800px";
        view.margin = "-15px";
        this._titleTagService.setTitle(`${this._titleTagService.getTitle()} | ${rs.data.title}`);

        this.config = {
          'height': '100%',
          'width': '100%',
          'documentType': `${documentType}`,
          'editorConfig': {
            'mode': 'view',
          },
          'document': {
            'permissions': {
              'comment': false,
              'download': true,
              'edit': false,
              'print': true,
              'review': false,
              'reader': true
            },
            'fileType': `${fileType}`,
            'title': `${rs.data.title}`,
            'url': `${rs.data.fileViewUrl}`,
            'key': `${rs.data.instanceId}`,
          }
        };
        this.getData();
        this.getsCommentData();
      }
    });
  }

  getData() {
    var searchModel = {
      keyword: '',
      listIdCategory: [this.itemDetail.idCategory],
      status: 1,
      pageIndex: 1,
      pageSize: 6,
      orderCol: 'Id',
      isDesc: true,
    };
    this._documentService.find(searchModel)
      .then(rs => {
        if (rs.success) {
          this.dataSource = rs.data.filter(x => (x.id - 0) != (this.id - 0));
        }
      });
  }

  getsCommentData() {
    var searchModel = {
      keyword: '',
      itemType: this.itemType,
      itemId: this.itemDetail.id,
      pageIndex: this.pageIndexComment,
      pageSize: this.pageIndexCommentSize,
    }
    this._itemCommentService.find(searchModel).then(rs => {
      if (rs.success) {
        this.dataCommentSource = rs.data;
        this.totalCommentCount = rs.totalRecord;
      }
    })
  }

  getFileType(fileName: string) {
    console.log(fileName);

    const arr = fileName.split('.');
    return arr[arr.length - 1];
  }

  getDocumentType(fileType: string) {
    if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
      return 'image';
    } else if ((/(doc|docx)$/i).test(fileType)) {
      return 'word';
    }
    else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
      return 'word';
    } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
      return 'spreadsheet';
    } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
      return 'presentation';
    }

    return '';
  }

  onDocumentReady = (event) => {
    console.log("Document is loaded");
  };

  onLoadComponentError = (errorCode, errorDescription) => {
    switch (errorCode) {
      case -1: // Unknown error loading component
        console.log(errorDescription);
        break;

      case -2: // Error load DocsAPI from http://documentserver/
        console.log(errorDescription);
        break;

      case -3: // DocsAPI is not defined
        console.log(errorDescription);
        break;
    }
  };

  addItemComment() {
    if (this.bodyHtml != '' && this.captchaIsValid) {
      var model = {
        itemType: this.itemType,
        itemId: this.itemDetail.id,
        bodyHtml: this.bodyHtml,
        rating: this.rating,
        status: 1,
      }
      this._itemCommentService.post(model).then(rs => {
        if (rs.success) {
          this.bodyHtml = '';
          this.getsCommentData();
        }
      });
    }
  }

  captchaResponse(event) {
    if (event) {
      this.captchaIsValid = true;
    }
  }
}
