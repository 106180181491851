import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseItemAttachmentService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/courseitemattachment`);
  }

  getsByIdCourseItem(idCourseItem: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/getsByIdCourseItem/${idCourseItem}`;
    return this.defaultGet(apiUrl);
  }

  getsByIdCourse(idCourse: number): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/GetsByIdCourse/${idCourse}`;
    return this.defaultGet(apiUrl);
  }
}
