<app-nav></app-nav>
<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Trang chủ</a></li>
        <li><a routerLink="/document">Tài liệu</a></li>
        <li class="li-active">Chi tiết tài liệu</li>
      </ul>
      <h2>{{itemDetail?.title}}</h2>
      <p>{{itemDetail?.subject}} </p>
    </div>
  </div>
</div>
<section class="newsDetail mt-5">
  <div class="container">
    <div class="row">
      <!-- ShareThis BEGIN -->
      <div class="sharethis-inline-share-buttons"></div><!-- ShareThis END -->
      <div class="col-12 pr-5">
        <div class="row">
          <div class="col-12">
            <div [innerHTML]="itemDetail?.bodyHtml | safeHtml"></div>
          </div>
          <ng-container *ngIf="startTime > currentTime">
          </ng-container>
          <ng-container *ngIf="startTime <= currentTime && currentTime <= endTime">
            <div class="col-12 " *ngIf="itemDetail.onlineLink">
              <h3>
                <a href="{{itemDetail.onlineLink}}" target="_blank">Tham gia ngay</a>
              </h3>
            </div>
          </ng-container>
          <ng-container *ngIf="endTime < currentTime">
            <div class="col-12 flex justify-content-center" *ngIf="itemDetail?.fileViewUrl">
              <video controls style="width: 800px;">
                <source src="{{itemDetail.fileViewUrl}}" type="video/mp4" />
                Browser not supported
              </video>
            </div>
          </ng-container>

        </div>
        <div class="row">
          <div class="col-8">
            <div class="col-12 flex justify-content-between align-items-center author">
              <div class="flex align-items-center">
                <div class="ml-2">
                  <!-- <h4>{{itemDetail?.author}}</h4> -->
                  <span>{{itemDetail?.startTime | date: 'dd/MM/yyyy HH:mm'}} -
                    {{itemDetail?.endTime | date: 'dd/MM/yyyy HH:mm'}}</span>
                </div>
              </div>
              <div>
                <button class=" px-4 py-2"><i class="pi pi-facebook"></i></button>
                <button class="px-4 py-2"><i class="pi pi-twitter"></i></button>
              </div>
            </div>
            <hr class="mt-5">
            <ng-container *ngIf="currentUser?.userId  > 0">
              <div class="col-12 flex comment-box flex-wrap">
                <div class="flex align-items-center mr-5">
                  <!-- <i class="pi pi-eye"></i>
                  <span class="ml-2">{{itemDetail.viewCount}}</span> -->
                  <ng-rating-bar [(value)]="rating" [ratingCount]="5" colorActive="#F2A000" colorDefault="gray"
                    [styles]="{fontSize: '16px', margin: '0px',padding: '0px'}"></ng-rating-bar>
                </div>
                <div class="flex flex-auto align-items-center">
                  <input type="text" placeholder="Viết một bình luận..." [(ngModel)]="bodyHtml">
                </div>
                <div class="flex align-items-center mr-5">
                  <i class="pi pi-comments"></i>
                  <span class="ml-2">{{totalCommentCount}} bình luận</span>
                </div>
                <!-- <div class="flex align-items-center">
                  <i class="pi pi-heart"></i>
                  <span class="ml-2">50</span>
                </div> -->
              </div>
              <div class="col-12 flex justify-content-end">
                <button class="default-btn px-3 mr-2" (click)="addItemComment()">Bình luận<span></span></button>
              </div>
              <div class="col-12 comments-list">
                <div class="row">
                  <ng-container *ngFor="let item of dataCommentSource">
                    <div class="col-12">
                      <app-comment-item-type-two [item]="item"></app-comment-item-type-two>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- <div class="col-4">
            <div class="row">
              <div class="row">
                <h2>Tài liệu mới nhất</h2>
              </div>
              <ng-container *ngFor="let item of dataSource">
                <div class="row">
                  <div class="col-4">
                    <a routerLink="/document/detail/{{item.instanceId}}">
                      <img src="{{item.imageUrl}}" alt="">
                    </a>
                  </div>
                  <div class="col-8">
                    <a routerLink="/document/detail/{{item.instanceId}}">
                      <h4 class="document-title">
                        {{item.title}}
                      </h4>
                    </a>
                    <div class="description">
                      {{item.subject}}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
