import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { AuthenticationService, BasicUserInfo, NotificationService, NotifierService } from 'sv-shared';
import { PromotionService } from '../../services/promotion.service';
import { EInvoiceService } from '../../services/e-invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { ComboService } from '../../services/combo.service';
import { VnPayLogService } from '../../services/vn-pay-log.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  dataSource = [];
  totalPriceCourse = 0;
  totalPricePromotion = 0;

  txtPromotionCode = '';
  selectedPromotion: any = {};

  listAllPromotion = [];
  comboInfo: any = { id: 0, promotionRate: 0 };

  showPayResult = false;
  title = "Giỏ hàng";
  currentUser: BasicUserInfo = new BasicUserInfo();

  constructor(
    private _shoppingCartService: ShoppingCartService,
    private _notifierService: NotifierService,
    private _promotionService: PromotionService,
    private _vnPayLogService: VnPayLogService,
    private _router: Router,
    private _cookieService: CookieService,
    private _comboService: ComboService,
    private _activatedRoute: ActivatedRoute,
    private _authenticationService: AuthenticationService
  ) {
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  ngOnInit() {


    this._activatedRoute.queryParams.subscribe((params) => {
      let vnp_SecureHash = params['vnp_SecureHash'];
      let vnp_PayDate = params['vnp_PayDate'];
      let vnp_TxnRef = params['vnp_TxnRef'];
      let vnp_ResponseCode = params['vnp_ResponseCode'];
      let vnp_TransactionStatus = params['vnp_TransactionStatus'];

      if (vnp_SecureHash) {
        this.showPayResult = true;
        this.title = "Kết quả thanh toán";
        if (vnp_ResponseCode == '00' && vnp_TransactionStatus == '00') {
          this.updatePaymentResponse(vnp_TxnRef, vnp_PayDate);
        } else {
          this._notifierService.showError('Thanh toán thất bại, xin vui lòng thư lại sau!');
          this.showPayResult = false;
          this.getShoppingCart();
          this.title = "Giỏ hàng";
        }

      } else {
        this.showPayResult = false;
        this.getShoppingCart();
        this.title = "Giỏ hàng";
      }
    });

  }

  getShoppingCart() {
    this.dataSource = [];
    this._shoppingCartService.getsMyShoppingCart().then(rs => {
      if (rs.success) {
        this.dataSource = rs.data;
        this.totalPriceCourse = rs.data.reduce((acc, item) => acc + Number(item.price), 0);
        //console.log(this.totalPriceCourse);
        this.getComboInfo();
      }
    });
  }

  async addPromotionCode(item) {
    if (this.selectedPromotion?.id > 0) {
      this.listAllPromotion = this.listAllPromotion.filter(x => x.id != item.id);
      this.listAllPromotion.push(Object.assign({}, this.selectedPromotion))
      this.selectedPromotion = item;
    } else {
      this.listAllPromotion = this.listAllPromotion.filter(x => x.id != item.id);
      this.selectedPromotion = item;
    }
    this.totalPricePromotion = this.totalPriceCourse * Number(this.selectedPromotion.promotionRate) / 100;
  }
  async deletePromotionCode() {
    this.listAllPromotion.push(this.selectedPromotion);
    this.selectedPromotion = {};
  }

  deleteCourse(id) {
    this._shoppingCartService.deleteCourses([id]).then(rs => {
      if (rs.success) {
        this.getShoppingCart();
        this.getComboInfo();
      }
    });
  }

  processPayment() {
    var paymentModel = {
      courses: this.dataSource,
      promotion: this.selectedPromotion,
      invoice: {
        totalPriceCourse: this.totalPriceCourse,
        totalPricePromotion: this.totalPricePromotion,
        isPaid: 2,
        status: 1
      },
      cookieId: this._cookieService.get(environment.cookieName)
    };
    this._vnPayLogService.createPayRequest(paymentModel).then(rs => {
      if (rs.success) {
        if (rs.data) {
          window.location.href = rs.data;
        } else {
          this._notifierService.showSuccess('Thanh toán thành công!');
          this._router.navigate(['/profile/dashboard']);
        }
      }
      else {
        this._notifierService.showError('Thanh toán thất bại, xin vui lòng thư lại sau!');
      }
    });
  }

  updatePaymentResponse(vnp_TxnRef: string, vnp_PayDate: string) {
    var model = {
      vnp_TxnRef: vnp_TxnRef,
      vnp_PayDate: vnp_PayDate,
      cookieId: this._cookieService.get(environment.cookieName)
    }
    this._vnPayLogService.updatePaymentResponse(model).then(rs => {
      if (rs.success) {
        this._notifierService.showSuccess('Thanh toán thành công!');
        this._router.navigate(['/profile/dashboard']);
      } else {
        this.showPayResult = false;
        this.getShoppingCart();
        this.title = "Giỏ hàng";
      }
    });

  }

  getsPromotion() {
    this._promotionService.getsAllActive().then(rs => {
      if (rs.success) {
        this.listAllPromotion = rs.data;
      }
    });
  }

  getComboInfo() {
    this._comboService.getByListCourse(this.dataSource).then(rs => {
      if (rs.success) {
        this.comboInfo = rs.data;
        this.totalPricePromotion = this.totalPriceCourse * Number(this.comboInfo.promotionRate) / 100;
      } else {
        this.comboInfo = { id: 0, promotionRate: 0 };
        this.getsPromotion();
      }
    })
  }

}
