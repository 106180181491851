import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase, UserService } from 'sv-shared';
import { passwordPattern } from '../../../config/password.config';
import { SvCoreUsersService } from '../../../services/svcore-users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends SecondPageEditBase implements OnInit {

  formGroup: FormGroup = new FormGroup({});
  isGoogleOrFacebookLogin: boolean = false;

  constructor(
    protected _userService: UserService,
    protected _injector: Injector,
    private _svCoreUsersService: SvCoreUsersService,
    private _router: Router,
  ) {
    super(_userService, _injector);

  }

  ngOnInit() {
    if (!this.currentUser) {
      this._router.navigate(['/']);
    }
    this.formGroup = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.pattern(passwordPattern)]),
    });

    this._userService.getMyInfo().then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
      }
    })
    this.checkIsGoogleOrFacebookLogin();
  }

  checkIsGoogleOrFacebookLogin() {
    this._svCoreUsersService.isGoogleOrFacebookLogin().then(rs => {
      if (rs.success) {
        this.isGoogleOrFacebookLogin = rs.data;
        if (rs.data == true) {
          this.formGroup.controls['oldPassword'].disable();
        }
      }
    });
  }

  doChangePassword() {
    this.submitting = true;
    if (this.formGroup.invalid) {
      this.submitting = false;
      return;
    }
    this._userService.changePassword(this.itemDetail)
      .then(res => {
        if (res.success) {
          this._notifierService.showUpdateDataSuccess();
          this.onAfterSave();
          this.submitting = false;
        } else {
          this._notifierService.showError(this._translateService.instant(res.error));
        }

      }, error => {
        this._notifierService.showError("Mật khẩu cũ không đúng");
        this.submitting = false;
      });
  }

  onAfterSave() {
    this.checkIsGoogleOrFacebookLogin();
  }

}
