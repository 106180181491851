import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;
import { OAuthInfoEvent, OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './config/oidc-auth.config';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { UserService } from 'sv-shared';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { ShoppingCartService } from './services/shopping-cart.service';

import { DeviceUUID } from 'device-uuid';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from './services/canonical.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent {
  location: any;
  routerSubscription: any;

  constructor(private router: Router,
    private translate: TranslateService,
    private _oauthService: OAuthService,
    private _userService: UserService,
    private _cookieService: CookieService,
    private _metaTagService: Meta,
    private _titleTagService: Title,
    private _canonicalService: CanonicalService,
    private _activatedRoute: ActivatedRoute,
  ) {

    this.configureWithNewConfigApi();
  }

  ngOnInit() {
    this.recallJsFuntions();
    var digiCookie = this._cookieService.get(environment.cookieName);
    // console.log(digiCookie);
    var du = new DeviceUUID().parse();
    var dua = [
      du.language,
      du.platform,
      du.os,
      du.cpuCores,
      du.isAuthoritative,
      du.silkAccelerated,
      du.isKindleFire,
      du.isDesktop,
      du.isMobile,
      du.isTablet,
      du.isWindows,
      du.isLinux,
      du.isLinux64,
      du.isMac,
      du.isiPad,
      du.isiPhone,
      du.isiPod,
      du.isSmartTV,
      du.pixelDepth,
      du.isTouchScreen
    ];
    // console.log(du.hashMD5(dua.join(':')));

    if (digiCookie == '' || digiCookie == null || digiCookie == undefined) {
      // console.log('set cookie');
      this._cookieService.set(environment.cookieName, du.hashMD5(dua.join(':')), 365, '/');
    }

    this._metaTagService.addTags([
      { charset: 'utf-8' },
      { name: 'og:title', content: environment.appMetadata.meta.title },
      { name: 'og:description', content: environment.appMetadata.meta.description },
      { name: 'og:url', content: environment.appMetadata.meta.url },
      { name: 'og:site_name', content: environment.appMetadata.meta.title },
    ]);
    this._canonicalService.setCanonicalURL();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this._activatedRoute);
      rt.data.subscribe(data => {
        this._titleTagService.setTitle(`${environment.appMetadata.meta.title} | ${data.title ?? ''}`)
      });
    });

    // this.router.config.forEach(route => {
    //   this.printRoutes(route);
    // });
  }

  printRoutes(route) {
    if (route.children) {
      route.children.forEach(child => {
        this.printRoutes(child);
      });
    } else {
      console.log(route.path);
    }
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private configureWithNewConfigApi() {
    this._oauthService.setStorage(localStorage);
    this._oauthService.configure(authConfig);
    this._oauthService.tokenValidationHandler = new JwksValidationHandler();

    if (!this._oauthService.hasValidAccessToken()) {

      this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
        if (!rs) {
          // this._oauthService.initImplicitFlow(`${environment.clientDomain.appDomain}`);
        } else {
          this._userService.returnPromises();

        }
      });
    } else {
      this._userService.returnPromises();
      this._oauthService.loadDiscoveryDocument();
    }

    // Optional
    //this._oauthService.setupAutomaticSilentRefresh();
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
