import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-item-type-three',
  templateUrl: './course-item-type-three.component.html',
  styleUrls: ['./course-item-type-three.component.scss']
})
export class CourseItemTypeThreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input() item: any

}
