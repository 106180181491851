import { Component, Input, OnInit } from '@angular/core';
import { NewsService } from '../../../services/news.service';

@Component({
  selector: 'app-section-top-news',
  templateUrl: './section-top-news.component.html',
  styleUrls: ['./section-top-news.component.scss']
})
export class SectionTopNewsComponent implements OnInit {

  listNews = [];

  @Input() idSysSchool = 0;

  constructor(
    private _newsService: NewsService
  ) { }

  ngOnInit() {
    this.getsTopNews();
  }

  getsTopNews() {
    this._newsService.getsTop(this.idSysSchool, 8).then(rs => {
      if (rs.success) {
        this.listNews = rs.data;
      }
    })
  }

}
