import { Component, Injector, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs';
import { ComponentBase, SecondPageEditBase } from 'sv-shared';
import { ExamUser } from '../../../models/examUser';
import { ExamUserService } from '../../../services/exam-user.service';
import { ExamService } from '../../../services/exam.service';

@Component({
  selector: 'app-exam-detail',
  templateUrl: './exam-detail.component.html',
  styleUrls: ['./exam-detail.component.scss']
})
export class ExamDetailComponent extends SecondPageEditBase implements OnInit {

  itemDetail: any = {};
  instanceIdExam = '';
  isRegister = false;
  isDoingExam = false;
  canDoExam = true;
  itemExamUser: ExamUser = new ExamUser();
  countExamUser = 0;
  constructor(
    protected _injector: Injector,
    private _examService: ExamService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _examUserService: ExamUserService,
    private _titleTagService: Title,
  ) {
    super(null, _injector);
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.instanceIdExam = pid;
          this.getDetail(pid);
        }
      });
  }

  async getDetail(instanceIdExam: any) {

    await this._examService.getByInstanceId(instanceIdExam).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
        this.itemExamUser.idExam = rs.data.id;
        this._titleTagService.setTitle(`${this._titleTagService.getTitle()} | ${rs.data.title}`);
        var sessionItem = JSON.parse(sessionStorage.getItem('examUser'));
        if (sessionItem !== null && sessionItem.idExam != rs.data.id) {
          sessionStorage.removeItem('examUser');
        }

        if (this.itemDetail.startTime) {
          if (new Date(this.itemDetail.startTime) > new Date()) {
            this.canDoExam = false;
            this._notifierService.showWarning('Đợt thi chưa bắt đầu');
            return;
          }
        }
        if (this.itemDetail.endTime) {
          if (new Date(this.itemDetail.endTime) < new Date()) {
            this.canDoExam = false;
            this._notifierService.showWarning('Đợt thi đã kết thúc');
            return;
          }
        }
      }
    });

    if (sessionStorage.getItem('examUser')) {
      var item = JSON.parse(sessionStorage.getItem('examUser'));
      this.itemExamUser = item;
      if (item.countDown > 0 && item.examStatus == 1) {
        this.goExaming();
      }
    }

    // CheckRegistered
    this._examUserService.checkRegistered(this.itemDetail.instanceId).then(rs => {
      if (rs.success) {
        if (rs.data.id > 0) {
          this.isRegister = true;
          this.itemExamUser = rs.data;
          this.countExamUser = rs.totalRecord;
        }
      }
    });

  }

  doRegister() {
    if (this.currentUser?.userId > 0) {
      this._examUserService.registerExam(this.itemExamUser).then(rs => {
        if (rs.success) {
          this.itemExamUser = rs.data;
          this.isRegister = true;
          this._notifierService.showSuccess('Đăng ký thành công');

          this._examUserService.doingExam(rs.data).then(rsx => {
            if (rsx.success) {
              this.itemExamUser = rsx.data;
              sessionStorage.setItem('examUser', JSON.stringify(this.itemExamUser));
              if (rsx.data.examStatus >= 3) {
                this._notifierService.showWarning('Bạn đã hoàn thành bài thi');
              }
              else if (rsx.data.countDown < 0) {
                this._notifierService.showWarning('Thời gian làm bài đã hết');
                sessionStorage.removeItem("examUser");
                if (this.itemExamUser.examStatus <= 2) {
                  this.autoFinishExam();
                }
                return;
              }
              else {
                this._router.navigate([`/examing/${rs.data.instanceId}`])

              }
            }
          })
        }
      })
    } else {
      this._router.navigate(['/authentication/signin'])
    }
  }

  doRegister2() {
    this.itemExamUser = new ExamUser();
    this.itemExamUser.idExam = this.itemDetail.id;
    if (this.currentUser?.userId > 0) {
      this._examUserService.registerExam(this.itemExamUser).then(rs => {
        if (rs.success) {
          this.itemExamUser = rs.data;
          this.isRegister = true;
          this._notifierService.showSuccess('Đăng ký thành công');
          this._examUserService.doingExam(rs.data).then(rsx => {
            if (rsx.success) {
              this.itemExamUser = rsx.data;
              sessionStorage.setItem('examUser', JSON.stringify(this.itemExamUser));
              if (rsx.data.examStatus >= 3) {
                this._notifierService.showWarning('Bạn đã hoàn thành bài thi');
              }
              else if (rsx.data.countDown < 0) {
                this._notifierService.showWarning('Thời gian làm bài đã hết');
                sessionStorage.removeItem("examUser");
                if (this.itemExamUser.examStatus <= 2) {
                  this.autoFinishExam();
                }
                return;
              }
              else {
                this._router.navigate([`/examing/${rs.data.instanceId}`])

              }
            }
          })
        }
      })
    } else {
      this._router.navigate(['/authentication/signin'])
    }
  }

  autoFinishExam() {
    this._examUserService.finishExam(this.itemExamUser.instanceId).then(rs => {
      if (rs.success) {
        this.isDoingExam = false;
        this.itemExamUser = rs.data;
      }
    });
  }

  goExaming() {
    this._examUserService.doingExam(this.itemExamUser).then(rsx => {
      if (rsx.success) {
        this.itemExamUser = rsx.data;
        sessionStorage.setItem('examUser', JSON.stringify(this.itemExamUser));
        if (rsx.data.examStatus >= 3) {
          this._notifierService.showWarning('Bạn đã hoàn thành bài thi');
        }
        else if (rsx.data.countDown < 0) {
          this._notifierService.showWarning('Thời gian làm bài đã hết');
          sessionStorage.removeItem("examUser");
          if (this.itemExamUser.examStatus <= 2) {
            this.autoFinishExam();
          }
          return;
        }
        else {
          this._router.navigate([`/examing/${this.itemExamUser.instanceId}`])

        }
      }
    })
  }

  goTestResult() {
    this._router.navigate([`/examing/${this.itemExamUser.instanceId}`])
  }

}
