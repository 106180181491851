import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs';
import { ComponentBase } from 'sv-shared';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'app-document-detail-app',
  templateUrl: './document-detail-app.component.html',
  styleUrls: ['./document-detail-app.component.scss']
})
export class DocumentDetailAppComponent extends ComponentBase implements OnInit {

  instanceId = '';
  itemDetail: any = {};
  config: any = {};

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _documentService: DocumentService,
  ) {
    super();
  }

  ngOnInit() {
    this._activatedRoute.params
      .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
      .subscribe(pid => {
        if (pid) {
          this.instanceId = pid;
          this.getDetail(pid);
        }

      });
  }

  getDetail(id) {
    this._documentService.getByInstanceId(id).then(rs => {
      if (rs.success) {
        this.itemDetail = rs.data;
        const fileType = this.getFileType(rs.data.fileViewUrl);
        const documentType = this.getDocumentType(fileType);


        this.config = {
          'documentType': `${documentType}`,
          'editorConfig': {
            'mode': 'view',
          },
          'document': {
            'permissions': {
              'comment': false,
              'download': true,
              'edit': false,
              'print': true,
              'review': false,
              'reader': true
            },
            'fileType': `${fileType}`,
            'title': `${rs.data.title}`,
            'url': `${rs.data.fileViewUrl}`,
            'key': `${rs.data.instanceId}`,
          }
        };
      }
    });
  }

  getFileType(fileName: string) {
    console.log(fileName);

    const arr = fileName.split('.');
    return arr[arr.length - 1];
  }

  getDocumentType(fileType: string) {
    if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
      return 'image';
    } else if ((/(doc|docx)$/i).test(fileType)) {
      return 'word';
    }
    else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
      return 'word';
    } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
      return 'spreadsheet';
    } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
      return 'presentation';
    }

    return '';
  }

  onDocumentReady = (event) => {
    console.log("Document is loaded");
  };

  onLoadComponentError = (errorCode, errorDescription) => {
    switch (errorCode) {
      case -1: // Unknown error loading component
        console.log(errorDescription);
        break;

      case -2: // Error load DocsAPI from http://documentserver/
        console.log(errorDescription);
        break;

      case -3: // DocsAPI is not defined
        console.log(errorDescription);
        break;
    }
  };

}
