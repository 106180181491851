import { Component, EventEmitter, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { AuthenticationService, SecondPageIndexBase, BasicUserInfo } from 'sv-shared';
import { CourseTopicPostService } from '../../../services/course-topic-post.service';
import { CourseTopicService } from '../../../services/course-topic.service';

@Component({
  selector: 'app-learning-space-v3-forum',
  templateUrl: './learning-space-v3-forum.component.html',
  styleUrls: ['./learning-space-v3-forum.component.scss']
})
export class LearningSpaceV3ForumComponent extends SecondPageIndexBase implements OnInit {

  @Input() courseDetail = { id: 0, instanceId: '', code: '', title: '', idCategoryLearningStatus: 0, options_LearnInOrder: false };
  @Input() isTeacher: boolean;
  showAdd = false;
  dataSource = [];
  showTopic = false;
  itemTopic = { id: 0, idCourse: 0, title: '', bodyHtml: '', viewCount: 0, veplyCount: 0, pin: false, modified: '', modifiedByName: '' };

  showAddPost = false;
  itemPost = { id: 0, idCourseTopic: 0, idParent: 0, bodyHtml: '', likeCount: 0, dislikeCount: 0, loveCount: 0 };
  dataPost = [];
  showAddReplyPost = false;
  currentIdPost = 0;
  isScroll = false;
  isScrollPost = false;
  pageIndexPost = 1;
  totalRecordPost = 0;

  @Input() notifier: Subject<number>;

  formGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    bodyHtml: new FormControl(''),
  });
  itemDetail = {};
  currentUser: BasicUserInfo = new BasicUserInfo();
  constructor(
    private _courseTopicService: CourseTopicService,
    private _courseTopicPostService: CourseTopicPostService,
    protected _injector: Injector,
  ) {
    super(_courseTopicPostService, _injector);
    this.currentUser = this._authenticationService.getCurrentUser();
  }

  ngOnInit() {
    this.pageSize = 10;
    this.getData();

    this.notifier.subscribe(data => {
      if (data > 0) {
        console.log('------- ' + data);
        this.showTopic = true;
        this.viewTopicDetail(data);
      }
    });
  }

  getData() {
    if (this.isScroll == false) {
      this.dataSource = [];
    }

    var searchModel = {
      keyword: this.query,
      status: this.status,
      instanceId: this.courseDetail.instanceId,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      offset: (this.pageIndex - 1) * this.pageSize,
      orderCol: this.orderCol ? this.orderCol : 'Modified',
      isDesc: this.isDesc,
    };
    this._courseTopicService.find(searchModel).then(rs => {
      if (rs.success) {
        this.totalRecord = rs.totalRecord;
        if (this.isScroll == false) {
          this.dataSource = rs.data;
        } else {
          this.dataSource.push(...rs.data);
        }
      }
    })
  }

  onScroll() {
    this.isScroll = true;
    this.pageIndex += 1;
    this.getData();
  }

  onShowAddTopic() {
    this.showAdd = true;
    this.itemDetail = { id: 0, idCourse: this.courseDetail.id, userId: this.currentUser.userId, viewCount: 0, replyCount: 0, status: 1 };
  }

  addTopic() {
    this._courseTopicService.post(this.itemDetail)
      .then(response => {
        this._notifierService.showInsertDataSuccess();
        this.showAdd = false;
        this.getData();
      }, error => {
        this._notifierService.showInsertDataFailed(this._translateService.instant(error.error.error));
      });
  }


  onPopupClosed(data) {
    if (data) {
      this.getData();
    }
  }

  viewTopicDetail(id: number) {
    this.showTopic = true;
    this.showAddPost = false;
    this.showAddReplyPost = false;
    this._courseTopicService.getById(id).then(rs => {
      if (rs.success) {
        this.itemTopic = rs.data;
      }
    });
    this.getPosts(id);
    console.log(this.showTopic)

  }

  getPosts(id: number) {
    if (this.isScrollPost == false) {
      this.dataPost = [];
    }
    var searchModel = {
      keyword: '',
      status: this.status,
      idCourseTopic: id,
      pageIndex: this.pageIndexPost,
      pageSize: this.pageSize,
      offset: (this.pageIndexPost - 1) * this.pageSize,
      orderCol: this.orderCol ? this.orderCol : 'Modified',
      isDesc: this.isDesc,
    };
    this._courseTopicPostService.find(searchModel).then(rs => {
      if (rs.success) {
        this.totalRecordPost = rs.totalRecord;
        if (this.isScrollPost == false) {
          this.dataPost = rs.data;
        } else {
          this.dataPost.push(...rs.data);
        }
      }
    })
  }

  onScrollPost() {
    this.isScrollPost = true;
    this.pageIndexPost += 1;
    this.getPosts(this.itemTopic.id);
  }

  viewTopics() {
    this.showTopic = false;
  }

  addPost() {
    this.showAddPost = true;
    this.showAddReplyPost = false;
    this.itemPost = { id: 0, idCourseTopic: this.itemTopic.id, idParent: 0, bodyHtml: '', likeCount: 0, dislikeCount: 0, loveCount: 0 };
  }
  savePost() {
    if (!this.itemPost.bodyHtml) {
      this._notifierService.showWarning('Bạn chưa nhập nội dung trả lời');
      return;
    } else {
      this._courseTopicPostService.post(this.itemPost).then(rs => {
        if (rs.success) {
          this._notifierService.showInsertDataSuccess();
          this.showAddPost = false;
          this.showAddReplyPost = false;
          this.getPosts(this.itemTopic.id);
          this.currentIdPost = 0;
        }
      })
    }
  }

  replyPost(idParent: number) {
    this.showAddPost = false;
    this.showAddReplyPost = true;
    this.currentIdPost = idParent;
    this.itemPost = { id: 0, idCourseTopic: this.itemTopic.id, idParent: idParent, bodyHtml: '', likeCount: 0, dislikeCount: 0, loveCount: 0 };
  }
  cancelPost() {
    this.showAddPost = false;
    this.showAddReplyPost = false;
  }

  updateCount(itemPost: any, likeCount: number, dislikeCount: number, loveCount: number) {
    this._courseTopicPostService.updateCount(itemPost.id, likeCount, dislikeCount, loveCount).then(rs => {
      if (rs.success) {
        //this._notifierService.showUpdateDataSuccess();
        itemPost.likeCount += likeCount;
        itemPost.dislikeCount += dislikeCount;
        itemPost.loveCount += loveCount;
      }
    })
  }

  updatePin(topic: any, pin: boolean) {
    if (this.isTeacher == true) {
      this._notifierService.showConfirm("Bạn có muốn ghim / bỏ ghim topic này?").then(() => {
        this._courseTopicService.updatePin(topic.id, pin).then(rs => {
          if (rs.success) {
            topic.pin = pin;
          }
        })
      });
    }
  }
}
