import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from 'sv-shared';
import { WorkshopService } from '../../services/workshop.service';
import { SysSchoolService } from '../../services/sys-school.service';

@Component({
  selector: 'app-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.scss']
})
export class WorkshopComponent extends SecondPageIndexBase implements OnInit {


  idSysSchool = 0;

  isListView = true;
  isShowFilter = true;
  showCategories = true;
  showSubCategories = true;

  selectedValues: string[] = [];
  ingredient: string;
  showRating = true;
  showVideoTime = true;

  showLanguages = false;


  constructor(
    protected _workshopService: WorkshopService,
    protected _injector: Injector,
    private _sysSchoolService: SysSchoolService,
  ) {
    super(_workshopService, _injector);
    this.idSysSchool = this._sysSchoolService.getSelectedSchool();
  }
  async ngOnInit() {
    this.pageSize = 12;
    this._activatedRoute.queryParams.subscribe((params) => {
      let keyword = params['keyword'];
      if (keyword) {
        this.query = keyword;
      }
    });
    await this.getData();
  }

  async getData() {
    this.dataSource = [];
    var searchModel = {
      keyword: this.query,
      idSysSchool: this.idSysSchool,
      status: 1,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      offset: (this.pageIndex - 1) * this.pageSize,
      orderCol: this.orderCol ? this.orderCol : 'Id',
      isDesc: true,
    };
    await this._workshopService.find(searchModel)
      .then(response => {
        this.dataSource = response.data;
        this.totalRecord = response.totalRecord;
      }, error => {
        this.isLoading = false;
        this._notifierService.showHttpUnknowError();
      });
  }

  changeOrderBy(orderCol: string) {
    this.isDesc = true;
    this.orderCol = orderCol;
    this.getData();
  }
}
