import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs';
import { ComponentBase, NotifierService, SvCorePublicService } from 'sv-shared';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends ComponentBase implements OnInit {

  newPassword = '';
  instanceId = '';
  code = '';

  constructor(
    private _notifierService: NotifierService,
    private _svCorePublicService: SvCorePublicService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
  ) {
    super();
  }

  async ngOnInit() {

    this._activatedRoute.params
      .pipe(map(params => params['instanceId']), takeUntil(this._unsubscribeAll))
      .subscribe(instanceId => {
        if (instanceId) {
          this.instanceId = instanceId;
        }
      });
    this._activatedRoute.params
      .pipe(map(params => params['code']), takeUntil(this._unsubscribeAll))
      .subscribe(code => {
        if (code) {
          this.code = code;
        }
      });

    if (!this.instanceId || !this.code) {
      this._notifierService.showWarning("Thông tin không chính xác");
      top.location.href = '/authentication/signin';
    }
  }

  doResetPassword() {
    if (this.newPassword) {
      var model = {
        newPassword: this.newPassword,
        instanceId: this.instanceId,
        resetPasswordCode: this.code
      }
      this._svCorePublicService.doResetPassword(model).then(rs => {
        if (rs.success) {
          this._notifierService.showSuccess("Đặt mật khẩu thành công");
          this._router.navigate(['/authentication/signin']);
        } else {
          this._notifierService.showSuccess("Thông tin không chính xác");
        }
      })
    } else {
      this._notifierService.showWarning("Bạn chưa nhập mật khẩu mới")
    }
  }

}
