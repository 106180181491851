<nav class="nav-section my-3">
  <div class="container nav-container">
    <ul class="flex justify-content-between align-items-center">
      <!-- <li>
        <a routerLink="">Hội thảo/Sự kiện</a>
      </li> -->
      <li>
        <a routerLink="/courses">Khoá học</a>
      </li>
      <li>
        <a routerLink="/exam">Cuộc thi</a>
      </li>
      <li>
        <a routerLink="/news">Tin tức/Bài viết</a>
      </li>
      <!-- <li>
        <a routerLink="/authors">Giáo viên/Đối tác</a>
      </li> -->
      <li>
        <a routerLink="/document">Thư viện</a>
      </li>
      <li>
        <a routerLink="/workshop">Hội thảo</a>
      </li>
      <li>
        <div class="card flex justify-content-center">
          <p-dropdown [options]="listSysSchool" [(ngModel)]="idSysSchool" optionLabel="title"
            (onChange)="onChangeSysSchool()" optionValue="id"></p-dropdown>
        </div>
      </li>
    </ul>
  </div>

  <div class="nav-mobile-container">

  </div>
</nav>